import type { ReactNode } from "react";
import { cn } from "utils/classNames";

interface IProps {
  icon?: string;
  messageType?: "inactive" | "success" | "alert" | "error" | "error-dark";
  messageText?: string | ReactNode;
  classes?: string;
}

const FieldMessage = ({ classes, messageType, icon, messageText }: IProps) => {
  return (
    <div className={cn(`field-message field-message--${messageType}`, classes)}>
      <span className="field-message__icon material-icons-round">{icon}</span>
      <div className="field-message__info">{messageText}</div>
    </div>
  );
};

export default FieldMessage;
