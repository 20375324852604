import type { SvgIconProps } from "types/Icon";

export const SwapHorizontal = (props: SvgIconProps) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>Swap Horizontal</title>
      <path
        d="M3.82127 12.0289L5.56183 13.7695C5.68933 13.8998 5.75308 14.0508 5.75308 14.2223C5.75308 14.3936 5.69177 14.5421 5.56914 14.6676C5.43977 14.796 5.28827 14.8601 5.11464 14.8601C4.94102 14.8601 4.79171 14.7965 4.66671 14.6691L1.83546 11.8378C1.76696 11.7693 1.71721 11.6978 1.68621 11.6231C1.65533 11.5485 1.63989 11.4686 1.63989 11.3833C1.63989 11.301 1.65533 11.2233 1.68621 11.1503C1.71721 11.0774 1.76696 11.0067 1.83546 10.9382L4.66671 8.10695C4.79421 7.98245 4.94414 7.91945 5.11652 7.91795C5.28889 7.91645 5.43977 7.97945 5.56914 8.10695C5.69177 8.23445 5.75308 8.38439 5.75308 8.55677C5.75308 8.72914 5.68933 8.87758 5.56183 9.00208L3.82127 10.747H8.92821C9.10858 10.747 9.26089 10.8086 9.38514 10.932C9.50939 11.0555 9.57152 11.2078 9.57152 11.389C9.57152 11.5732 9.50939 11.726 9.38514 11.8472C9.26089 11.9683 9.10858 12.0289 8.92821 12.0289H3.82127ZM14.1831 7.25308H9.07183C8.89146 7.25308 8.73989 7.19139 8.61714 7.06802C8.49439 6.94452 8.43302 6.7907 8.43302 6.60658C8.43302 6.42533 8.49439 6.27408 8.61714 6.15283C8.73989 6.03171 8.89146 5.97114 9.07183 5.97114H14.1831L12.4382 4.22608C12.3107 4.0987 12.247 3.94927 12.247 3.77777C12.247 3.60639 12.3083 3.45645 12.4309 3.32795C12.5603 3.20258 12.7118 3.13989 12.8854 3.13989C13.059 3.13989 13.2083 3.20358 13.3333 3.33096L16.1646 6.16221C16.2331 6.23071 16.2828 6.30227 16.3138 6.37689C16.3447 6.45152 16.3601 6.53002 16.3601 6.61239C16.3601 6.69764 16.3447 6.77677 16.3138 6.84977C16.2828 6.92264 16.2331 6.99333 16.1646 7.06183L13.3333 9.88858C13.2058 10.0161 13.0559 10.0806 12.8835 10.0821C12.7111 10.0836 12.5603 10.0191 12.4309 9.88858C12.3083 9.76408 12.247 9.61564 12.247 9.44327C12.247 9.27089 12.3107 9.12095 12.4382 8.99345L14.1831 7.25308Z"
        fill="currentColor"
      />
    </svg>
  );
};
