import {
  ERROR_MSGS_INIT_STATE,
  type TErrorDetail,
  errorCodes,
} from "constants/errorMessages";
import get from "lodash/get";
import toast from "react-hot-toast";

export const parseApiErrorResponse = (error: unknown): TErrorDetail => {
  let errorDetail = {
    errorCode: get(error, "response.data.error_code", "NEW_ERROR_CODE"),
    errorData: get(error, "response.data.data", null),
    errors: get(error, "response.data.errors", ERROR_MSGS_INIT_STATE), //e.g Topup API
  };

  if (errorDetail.errorCode === errorCodes.PLATFORM_ERROR) {
    errorDetail = {
      ...errorDetail,
      errors: ERROR_MSGS_INIT_STATE,
    };
  }

  return errorDetail;
};

export const copyToClipboard = (text: string, successMsg: string) => {
  window.Android
    ? window.Android.postMessage(`{"action":"copy", "message":"${text}"}`)
    : navigator.clipboard.writeText(text);
  if (successMsg) {
    toast.success(successMsg, {
      duration: 3000,
    });
  }
};
