import AnalyticsIllustration from "assets/illustrations/analytics-empty-state.svg";

export const EmptyState = () => {
  return (
    <div className="flex flex-col items-center gap-16 pb-24 text-14 text-black-200">
      <img src={AnalyticsIllustration} alt=" " />
      <p>There is no data to show you right now</p>
    </div>
  );
};
