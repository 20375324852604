import { useContext } from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import TextInput from "components/Inputs/TextInput";

import { isAppPlatform } from "helpers/isAppPlatform";
import { teamRoutes } from "../../shared/constant";
import { UserContext } from "../../shared/context/UserProvider";

type TUserInformation = {
  setCurrentStep: any;
  merchantName: string | undefined;
};

const UserInformation = ({
  setCurrentStep,
  merchantName,
}: TUserInformation) => {
  const { handleSubmit, register, formState } = useFormContext();
  const { errors } = formState;
  const { userInfo, setUserInfo } = useContext(UserContext);
  const navigate = useNavigate();

  const handleGoToTeamPage = () => navigate(teamRoutes.overview);

  const onSubmit = async (data: UserContext) => {
    setUserInfo(data);
    setCurrentStep(2);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {!isAppPlatform() && (
        <header>
          <button
            type="button"
            onClick={handleGoToTeamPage}
            className="create-link__close-button material-icons-round"
          >
            close
          </button>
        </header>
      )}
      <section className="create-link__customize-link-step">
        {/* PROCESS COMPONENT */}
        <div className="process space-y-40 md:space-y-48">
          <div className="process__content">
            <h1 className="process__content__heading">Invite a new user</h1>
            <p className="process__content__info">
              Invite a new user to {merchantName} team by adding their name and
              email address
            </p>
          </div>

          <div className="md:space-y-16">
            <div className="md:-mb-24 flex flex-col md:flex-row md:gap-24">
              <TextInput
                inputId="firstName"
                name="firstName"
                labelText="First name"
                defaultValue={userInfo.firstName}
                register={register}
                errors={errors.firstName}
                errorMessage={errors.firstName?.message}
                autoFocus
                autoComplete="given-name"
              />
              <TextInput
                inputId="lastName"
                name="lastName"
                labelText="Last name"
                defaultValue={userInfo.lastName}
                register={register}
                errors={errors.lastName}
                errorMessage={errors.lastName?.message}
                autoComplete="family-name"
              />
            </div>

            <TextInput
              inputId="email"
              name="email"
              hideReplay
              labelText="Email"
              defaultValue={userInfo.email}
              register={register}
              errors={errors.email}
              errorMessage={errors.email?.message}
              autoComplete="email"
            />
          </div>

          <div className="text-center">
            <button
              type="submit"
              className="button button--primary button--solid button--lg w-full md:w-auto"
            >
              Continue
            </button>
          </div>
        </div>
      </section>
    </form>
  );
};

export default UserInformation;
