import MenuItem from "@mui/material/MenuItem";
import moment from "moment";
import { useEffect, useState } from "react";
import type { FieldErrors } from "react-hook-form";

import { Dropdown } from "design_system/Dropdown/DropdownV2";
import { DatePicker } from "design_system/Inputs/DatePicker";
import { Switch } from "design_system/Inputs/Switch";
import { TextInput } from "design_system/Inputs/TextInput";

import { dateFormat } from "../constant";
import type { IAddAdvancedSettingsForm } from "../typeDefs";
import {
  expirationItems,
  expirationOptionLabels,
  expirationOptions,
} from "./index";

interface IAddExpirationDateProps {
  errors: FieldErrors<IAddAdvancedSettingsForm>;
  expirationPeriod?: string;
  handleSelectDate: (val: string) => void;
  handleSelectPeriod: (val: string) => void;
  handleToggleExpiration: () => void;
  isIncludeExpiration: boolean;
  register: any;
}

export const AddExpirationDate = ({
  errors,
  expirationPeriod,
  handleSelectDate,
  handleSelectPeriod,
  handleToggleExpiration,
  isIncludeExpiration,
  register,
}: IAddExpirationDateProps) => {
  const [customDateValue, setCustomDateValue] = useState<Date | null>(null);

  const handleDateChange = (newValue: Date) => {
    setCustomDateValue(newValue);
    handleSelectDate(moment(newValue).format(dateFormat));
  };

  useEffect(() => {
    if (!isIncludeExpiration && expirationPeriod === "custom") {
      setCustomDateValue(null);
    }
  }, [isIncludeExpiration]);

  return (
    <div className="space-y-16">
      <Switch
        id="expiration-date-switch"
        title="Set expiration date"
        description="Ensure payments are made within a specified timeframe. The payment link becomes inactive after the set date."
        checked={isIncludeExpiration}
        onChange={handleToggleExpiration}
      />

      {isIncludeExpiration && (
        <Dropdown
          type="select"
          selectProps={{
            value: expirationPeriod,
            onChange: (e: React.ChangeEvent<HTMLSelectElement>) =>
              handleSelectPeriod(e.target.value),
          }}
          presentInModal
        >
          {expirationItems.map((opt) => (
            <MenuItem key={opt.id} value={opt.value}>
              {expirationOptionLabels[opt.value]}
            </MenuItem>
          ))}
        </Dropdown>
      )}

      {isIncludeExpiration &&
        expirationPeriod === expirationOptions["custom"] && (
          <DatePicker
            label="Date"
            value={customDateValue}
            minDate={new Date()}
            onChange={handleDateChange}
            renderInput={(params) => (
              <TextInput
                {...params}
                autoFocus
                name="expirationDate"
                error={!!errors.expirationDate}
                helperText={errors.expirationDate?.message}
                ref={register}
              />
            )}
            className="w-full"
            inputRef={register}
            presentInModal
          />
        )}
    </div>
  );
};
