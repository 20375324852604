import {
  CheckCircle,
  ErrorOutline,
  Pending,
  Replay,
} from "design_system/Icons";

export const repaymentOptionsMapping = {
  Completed: {
    id: "success",
    value: "success",
    label: "Completed",
  },
  Pending: {
    id: "pending",
    value: "pending",
    label: "Pending",
  },
  Refunded: {
    id: "refunded",
    value: "refunded",
    label: "Refunded",
  },
  Reversed: {
    id: "reversed",
    value: "reversed",
    label: "Reversed",
  },
  Declined: {
    id: "declined",
    value: "declined",
    label: "Declined",
  },
};

export const repaymentsStatusMapping = {
  Completed: {
    ...repaymentOptionsMapping.Completed,
    color: "green",
    Icon: CheckCircle,
  },
  Pending: {
    ...repaymentOptionsMapping.Pending,
    color: "blue",
    Icon: Pending,
  },
  Refunded: {
    ...repaymentOptionsMapping.Refunded,
    color: "gray",
    Icon: Pending,
  },
  Reversed: {
    ...repaymentOptionsMapping.Reversed,
    color: "gray",
    Icon: Replay,
  },
  Declined: {
    ...repaymentOptionsMapping.Declined,
    color: "red",
    Icon: ErrorOutline,
  },
};

export const capitalizeRepaymentStatusMapping = {
  success: "Completed",
  completed: "Completed",
  declined: "Declined",
  refunded: "Refunded",
  reversed: "Reversed",
  pending: "Pending",
};
