import ListSubheader from "@mui/material/ListSubheader";
import MenuItem from "@mui/material/MenuItem";
import isEmpty from "lodash/isEmpty";
import { useEffect, useRef } from "react";
import { useController } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Button } from "design_system/Button";
import { Dropdown } from "design_system/Dropdown/DropdownV2";
import { TextInput } from "design_system/Inputs/TextInput";

import { useAppSelector } from "store";
import { useRedirectToSignUp } from "../shared/useRedirectToSignUp";
import { useStartWithBasicsForm } from "./hooks/useStartWithBasicsForm";

import routes from "constants/routes";
import {
  updateActiveStep,
  updateData,
  updateStepAnimationDirection,
} from "reducers/redux/Access/SignUp/signUpSlice";
import type { SignUpRole } from "reducers/redux/Access/SignUp/typeDefs";
import {
  noTradeLicenseRoleOptions,
  tradeLicenseRoleOptions,
} from "../shared/constant";
import type { StartWithBasicsFormProps } from "../shared/typeDefs";

export const StartWithBasics = () => {
  const { activeSignUpStep, data } = useAppSelector((state) => state.signUp);
  const { email, onboardingRole, mode } = data;
  const { control, errors, getValues, trigger, watch } =
    useStartWithBasicsForm();
  const { redirectToSignUp } = useRedirectToSignUp();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const firstNameInputRef = useRef(null);

  const hasTradeLicense =
    onboardingRole === "employee" ||
    onboardingRole === "manager" ||
    onboardingRole === "owner";
  const hasNoTradeLicense =
    onboardingRole === "freelancer" || onboardingRole === "personal_use";

  const handleFormFieldBlur = (fieldName: keyof StartWithBasicsFormProps) => {
    return () => {
      trigger(fieldName).then(() => {
        const isValid = !errors[fieldName];
        if (isValid) {
          dispatch(
            updateData({
              data: {
                ...getValues(),
              },
            }),
          );
        }
      });
    };
  };

  const handleRoleChange = (e) => {
    const onboardingRole: SignUpRole = e.target.value;

    dispatch(
      updateData({
        data: {
          onboardingRole,
        },
      }),
    );
  };

  const handleGoToNextStep = () => {
    if (mode === "email") {
      navigate(routes.ONBOARDING.CREATE_PASSWORD());
    }

    if (mode === "googleSso") {
      navigate(routes.ONBOARDING.TELL_US_MORE_BUSINESS());
    }

    dispatch(updateActiveStep({ activeSignUpStep: activeSignUpStep + 1 }));
    dispatch(updateStepAnimationDirection({ stepAnimationDirection: "right" }));
  };

  const isContinueButtonDisabled =
    !!errors.firstName ||
    !!errors.lastName ||
    isEmpty(watch().firstName) ||
    isEmpty(watch().lastName) ||
    isEmpty(onboardingRole);

  useEffect(() => {
    if (hasTradeLicense) {
      dispatch(updateData({ data: { complianceType: "business" } }));
    }
    if (hasNoTradeLicense) {
      dispatch(
        updateData({ data: { complianceType: "individual", planToUse: [] } }),
      );
    }
  }, [hasTradeLicense, hasNoTradeLicense]);

  useEffect(() => {
    redirectToSignUp(email);

    if (firstNameInputRef.current) {
      firstNameInputRef.current.focus();
    }
  }, []);

  return (
    <div className="flex flex-col gap-32">
      <div className="space-y-8 text-center">
        <h1 className="header-large md:display-medium">
          Let&rsquo;s get to know you
        </h1>
        <p className="body-medium md:body-large">
          Please share your full name and your role in the business
        </p>
      </div>

      <div className="flex flex-col gap-16 md:gap-24">
        <div className="flex flex-col gap-16 md:flex-row md:gap-24">
          <TextInput
            {...useController({ name: "firstName", control }).field}
            ref={firstNameInputRef}
            label="First name"
            className="w-full"
            error={!!errors.firstName}
            helperText={errors.firstName?.message}
            onBlur={handleFormFieldBlur("firstName")}
          />
          <TextInput
            {...useController({ name: "lastName", control }).field}
            label="Last name"
            className="w-full"
            error={!!errors.lastName}
            helperText={errors.lastName?.message}
            onBlur={handleFormFieldBlur("lastName")}
          />
        </div>

        <Dropdown
          type="select"
          selectProps={{
            name: "onboardingRole",
            defaultValue: onboardingRole,
            onChange: handleRoleChange,
          }}
          label="Role"
        >
          <ListSubheader>For businesses with a trade license</ListSubheader>
          {tradeLicenseRoleOptions.map(({ id, value, label }) => (
            <MenuItem key={id} value={value}>
              {label}
            </MenuItem>
          ))}
          <ListSubheader>For individuals without a trade license</ListSubheader>
          {noTradeLicenseRoleOptions.map(({ id, value, label }) => (
            <MenuItem key={id} value={value}>
              {label}
            </MenuItem>
          ))}
        </Dropdown>
      </div>

      <Button
        classes="w-full"
        disabled={isContinueButtonDisabled}
        label="Continue"
        size="lg"
        variant="filled"
        onClick={!isContinueButtonDisabled ? handleGoToNextStep : undefined}
      />
    </div>
  );
};
