import axios from "axios";
import { useState } from "react";

import routes from "constants/routes";
import { posLinkIdentifier } from "../utils/posLinkIdentifier";

export const useGetPOSQrCode = () => {
  const [qrCodeUrl, setQrCodeUrl] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const getQrCode = async (identifier: string | undefined) => {
    setLoading(true);

    try {
      const url = routes.MANAGE.LINKS.QR_CODE(posLinkIdentifier(identifier));
      const { data } = await axios.get<{ qr_code_url: string }>(url);
      setQrCodeUrl(data.qr_code_url);
    } catch (error) {
      console.error(
        `[useGetPOSQrCode][getQrCode] Trouble getting link info: ${error}`,
      );
    } finally {
      setLoading(false);
    }
  };

  return {
    getQrCode,
    loading,
    qrCodeUrl,
  };
};
