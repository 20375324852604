import { Button } from "design_system/Button";

import { colors } from "constants/colors";
import routes from "constants/routes";

import Microsoft from "assets/logos/microsoft.svg";
import QuickBooks from "assets/logos/quickbooks.svg";
import Xero from "assets/logos/xero.svg";

const handleUpgradePlan = () => {
  window.location.href = routes.MANAGE.PLANS.INDEX();
};

export const AccountingPaywall = () => {
  return (
    /* Content Wrapper */
    /* Content */
    <section className="-mt-12 relative flex w-full flex-col-reverse overflow-hidden px-20 md:mt-48 md:flex-row md:justify-between md:px-40 md:pb-20">
      {/* Text Section */}
      <section className="mt-40 flex flex-col justify-center pb-20 md:mt-0 md:w-512 md:pb-0">
        <p className="display-small md:display-medium">
          Integrate with your accounting software
        </p>
        <p className="body-large mt-16 text-text-color-02 md:mt-24">
          Streamline your finance operations and close your books faster by
          syncing with popular accounting softwares like QuickBooks Online,
          Xero, and MS Dynamics 365.
        </p>

        <Button
          label="Upgrade plan"
          size="lg"
          variant="filled"
          classes="w-full md:w-fit-content mt-24"
          onClick={handleUpgradePlan}
        />
      </section>

      {/* Image Section */}
      <div
        className="flex h-402 flex-col items-center justify-center gap-16 rounded-16 md:w-402 md:gap-40"
        style={{
          backgroundColor: colors.blue[25],
        }}
      >
        <div className="flex gap-40">
          <img
            src={QuickBooks}
            alt="paywall image"
            className="rounded-16"
            width={120}
            height={120}
          />
          <img
            src={Xero}
            alt="xero image"
            className="rounded-16"
            width={120}
            height={120}
          />
        </div>
        <img
          src={Microsoft}
          alt="microsoft image"
          className="rounded-16 bg-surface-1"
          width={120}
          height={120}
        />
      </div>
    </section>
  );
};
