import { animated, to, useTransition } from "@react-spring/web";
import type { PieArcDatum } from "@visx/shape/lib/shapes/Pie";

import { colorsV2 } from "constants/colors";
import { typography } from "constants/typography";
import { pluralize } from "helpers/stringUtils";
import { useState } from "react";

const tinycolor = require("tinycolor2");

type AnimatedStyles = { startAngle: number; endAngle: number; opacity: number };

const fromLeaveTransition = ({ endAngle }: PieArcDatum<any>) => ({
  startAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  endAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  opacity: 0,
});

const enterUpdateTransition = ({ startAngle, endAngle }: PieArcDatum<any>) => ({
  startAngle,
  endAngle,
  opacity: 1,
});

type AnimatedPieProps<Datum> = any & {
  animate?: boolean;
  delay?: number;
  getColor: (d: PieArcDatum<Datum>) => string;
  getDetailValue: (d: PieArcDatum<Datum>) => number;
  getKey: (d: PieArcDatum<Datum>) => string;
  getValue: (d: PieArcDatum<Datum>) => number;
  hideTooltip: any;
  onClickDatum: (d: PieArcDatum<Datum>) => void;
  segmentText: string;
  showText: boolean;
  showTooltip: any;
};

export function AnimatedDonut<Datum>({
  animate,
  arcs,
  getColor,
  getDetailValue,
  getKey,
  getValue,
  hideTooltip,
  onClickDatum,
  path,
  segmentText,
  showText,
  showTooltip,
}: AnimatedPieProps<Datum>) {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  const transitions = useTransition<PieArcDatum<Datum>, AnimatedStyles>(arcs, {
    from: animate ? fromLeaveTransition : enterUpdateTransition,
    enter: enterUpdateTransition,
    update: enterUpdateTransition,
    leave: animate ? fromLeaveTransition : enterUpdateTransition,
    keys: getKey,
  });

  return (
    <g>
      {transitions((props, arc, { key }) => {
        const [centroidX, centroidY] = path.centroid(arc);
        const baseColor = getColor(arc);
        const isHovered = hoveredIndex !== null && hoveredIndex === arc.index;

        return (
          <g key={key}>
            <animated.path
              d={to(
                [props.startAngle, props.endAngle],
                (startAngle, endAngle) =>
                  path({
                    ...arc,
                    startAngle,
                    endAngle,
                  }),
              )}
              fill={
                isHovered
                  ? tinycolor(baseColor).setAlpha(0.75).toRgbString()
                  : baseColor
              }
              onClick={() => onClickDatum(arc)}
              onMouseEnter={() => {
                setHoveredIndex(arc.index);
                showTooltip({
                  tooltipData: arc.data,
                  tooltipLeft: centroidX,
                  tooltipTop: centroidY,
                });
              }}
              onMouseLeave={() => {
                setHoveredIndex(null);
                hideTooltip();
              }}
            />
          </g>
        );
      })}

      {showText &&
        arcs.map((arc, index) => {
          return (
            <g key={getKey(arc, index)}>
              <text
                dy="-2.4em"
                textAnchor="middle"
                fill={colorsV2.text[2]}
                pointerEvents="none"
                {...typography.body.medium}
              >
                {getKey(arc)}
              </text>
              <text
                dy=".4em"
                textAnchor="middle"
                fill={colorsV2.text[1]}
                pointerEvents="none"
                {...typography.display.small}
              >
                {getValue(arc)}%
              </text>
              <text
                dy="3.4em"
                textAnchor="middle"
                fill={colorsV2.text[2]}
                pointerEvents="none"
                {...typography.body.small}
              >
                {getDetailValue(arc)}{" "}
                {pluralize(getDetailValue(arc), segmentText)}
              </text>
            </g>
          );
        })}
    </g>
  );
}
