import get from "lodash/get";

import { StoreFront } from "design_system/Icons";

import type { TapixData } from "types/Tapix";
import { transactionAvatarMapping } from "../features/Cards/Transactions/TransactionsTable/constants/mappings";
interface TapixLogo {
  isIcon: boolean;
  url?: string;
  Icon?: typeof StoreFront;
}

export const getTapixAvatarFallback = (merchantName) => {
  let Icon = StoreFront;
  if (Object.keys(transactionAvatarMapping).includes(merchantName)) {
    Icon = transactionAvatarMapping[merchantName];
  }
  return Icon;
};

export const getTapixAvatar = (tapixShop: TapixData, merchantName: string) => {
  try {
    let tapixLogo: TapixLogo = {
      isIcon: false,
      url: get(tapixShop, "tapixMerchant.logo.link", undefined),
    };
    if (!tapixLogo.url) {
      tapixLogo = {
        isIcon: true,
        Icon: getTapixAvatarFallback(merchantName),
      };
    }
    return tapixLogo;
  } catch (error: unknown) {
    console.error(error);
  }
};
