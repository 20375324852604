import { Group } from "@visx/group";
import { scaleOrdinal } from "@visx/scale";
import Pie from "@visx/shape/lib/shapes/Pie";
import { useTooltip } from "@visx/tooltip";
import { useState } from "react";

import { Legend } from "design_system/Statistics/Legend";
import { AnimatedDonut } from "./AnimatedDonut";

import { colorsV2 } from "constants/colors";
import { sharedTooltipClasses } from "design_system/shared/constant";

const defaultMargin = { top: 0, right: 0, bottom: 0, left: 0 };
type TTooltipData = {
  name: string;
  value: number;
  detailValue: number;
};

export type TDonutChartProps = {
  data: any;
  width: number;
  height: number;
  margin?: typeof defaultMargin;
  /** This should *always* be singular. If there's a value that's more than 1 then the logic will be handled accordingly */
  segmentText: string;
  animate?: boolean;
};

export const DonutChart = ({
  animate = true,
  data,
  height,
  margin = defaultMargin,
  segmentText,
  width,
}: TDonutChartProps) => {
  const [selectedSection, setSelectedSection] = useState<string | null>(null);
  const [showText, setShowText] = useState(false);
  const {
    tooltipOpen,
    tooltipData,
    tooltipLeft,
    tooltipTop,
    showTooltip,
    hideTooltip,
  } = useTooltip<TTooltipData>();

  // accessor functions
  const value = (d: any) => d.value;

  // color scale
  const getColor = scaleOrdinal({
    domain: data[0].values.map((d) => d.name),
    range: [
      colorsV2.qualitative.aqua,
      colorsV2.qualitative.blue[1],
      colorsV2.qualitative.orange,
      colorsV2.qualitative.pink,
      colorsV2.qualitative.blue[2],
      colorsV2.qualitative.green[1],
      colorsV2.qualitative.azure,
      colorsV2.qualitative.purple,
      colorsV2.qualitative.yellow[1],
      colorsV2.qualitative.yellow[2],
      colorsV2.qualitative.green[2],
      colorsV2.qualitative.green[3],
      colorsV2.qualitative.gray,
    ],
  });

  if (width < 10) return null;

  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;
  const radius = Math.min(innerWidth, innerHeight) / 2;
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2;
  const donutThickness = 40;

  return (
    <div className="relative flex flex-col gap-40">
      <svg width={width} height={height} className="self-center">
        <Group top={centerY + margin.top} left={centerX + margin.left}>
          <Pie
            data={
              selectedSection
                ? data[0].values.filter(({ name }) => name === selectedSection)
                : data[0].values
            }
            pieValue={value}
            outerRadius={radius}
            innerRadius={radius - donutThickness}
            cornerRadius={3}
            padAngle={0.005}
          >
            {(pie) => (
              <AnimatedDonut<any>
                {...pie}
                animate={animate}
                getKey={(arc) => arc.data.name}
                getValue={(arc) => arc.data.value}
                getDetailValue={(arc) => arc.data.detailValue}
                onClickDatum={({ data: { name } }) => {
                  setShowText(!showText);
                  animate &&
                    setSelectedSection(selectedSection === name ? null : name);
                }}
                getColor={(arc) => getColor(arc.data.name)}
                showText={showText}
                segmentText={segmentText}
                showTooltip={showTooltip}
                hideTooltip={hideTooltip}
              />
            )}
          </Pie>
        </Group>
      </svg>
      {!showText && tooltipOpen && (
        <div
          className={`hidden lg:flex ${sharedTooltipClasses("top")}`}
          style={{
            position: "absolute",
            top: tooltipTop + height / 2,
            left: tooltipLeft + width / 2,
            pointerEvents: "none",
          }}
        >
          <span className="font-medium">{tooltipData.name}</span>
          <span className="text-black-200">{tooltipData.value}%</span>
        </div>
      )}
      <Legend
        colorScale={getColor}
        data={data[0].values}
        selectedLegend={selectedSection}
        onLegendClick={(name) => {
          if (selectedSection !== name) {
            setShowText(true);
          } else {
            setShowText(false);
          }
          animate && setSelectedSection(selectedSection === name ? null : name);
        }}
      />
    </div>
  );
};
