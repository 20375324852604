import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogOverlay,
  DialogPortal,
  DialogTrigger,
} from "@radix-ui/react-dialog";
import axios from "axios";
import routes from "constants/routes";
import { useState } from "react";

import { cn } from "utils/classNames";

import Button from "components/Button/Button";
import Card from "components/ui/Card";

import { snack } from "design_system/Snackbar";
import { SentryLoggingService } from "init/SentryLoggingService";
import type IRailsContext from "interfaces/IRailsContext";
import type IShareLinkCardProps from "interfaces/IShareLinkCardProps";
import { copyText } from "pages/Payment/Pay/helpers";

type ShareLinkCardContainer = {
  linkCard: IShareLinkCardProps;
  railsContext: IRailsContext;
};

export const ShareLinkCardContainer = ({
  linkCard,
}: ShareLinkCardContainer) => {
  const { linkUrl, active, identifier } = linkCard;

  const [qrCodeLoaded, setQRCodeLoaded] = useState<boolean>(false);
  const [qrCodeUrl, setQrCodeUrl] = useState<string>("");

  const handleCopyLink = () => {
    copyText(linkUrl);
    snack({
      title: "Link copied!",
      leadingElement: "icon",
      variant: "success",
    });
  };

  const handleQRCodePopup = () => {
    axios
      .get<{ qr_code_url: string }>(routes.MANAGE.LINKS.QR_CODE(identifier))
      .then((response) => {
        setQrCodeUrl(response.data.qr_code_url);
      })
      .catch((error) => {
        console.error("ShareLinkCardContainer - ", error);

        SentryLoggingService.captureException(error.message, {
          feature: "[ShareLinkCardContainer][handleQRCodePopup]",
          file: "ShareLinkCardContainer.tsx",
          function: "handleQRCodePopup",
          data: JSON.stringify({ error: error.message }),
        });
      });
  };

  const downloadQRCode = () => {
    window.location.href = routes.MANAGE.LINKS.DOWNLOAD_QR_CODE(identifier);
  };

  return (
    <Card hasBorder>
      <div className="share-link">
        <div className="share-link__copy-and-button">
          <div className="share-link__copy">
            <h3 className="share-link__copy__heading">
              {active ? "Share your link" : "Inactive link"}
            </h3>
            <p className="share-link__copy__description">
              {active
                ? "Copy and send this link to your customers to get paid."
                : "You can activate this link anytime to start using it again"}
            </p>
          </div>

          {active && (
            <div className="share-link__button">
              <Dialog modal onOpenChange={handleQRCodePopup}>
                <DialogTrigger asChild>
                  <Button
                    color="secondary"
                    size="md"
                    iconPosition="left"
                    iconFont="qr_code_2"
                    label="QR code"
                  />
                </DialogTrigger>

                <DialogPortal>
                  <DialogOverlay className="modal__overlay" />
                  <div className="modal modal--full">
                    <DialogContent className="modal__box modal__box--full flex flex-col items-center justify-center">
                      <DialogClose className="modal__box__close material-icons-round">
                        close
                      </DialogClose>

                      <div className="qr-code lg:mb-32">
                        <img
                          src={qrCodeUrl}
                          alt="QR code"
                          className={qrCodeLoaded ? "" : "hidden"}
                          onLoad={() => setQRCodeLoaded(true)}
                        />

                        <div
                          className={cn("skeleton", {
                            hidden: qrCodeLoaded,
                          })}
                        />
                      </div>

                      <div className="modal__box__content">
                        <h1 className="modal__box__content__title -mt-32 lg:mt-0">
                          Scan and pay
                        </h1>
                        <p className="modal__box__content__text">
                          Your customers can scan this QR code to open the
                          payment link
                        </p>

                        <DialogClose asChild className="hidden lg:block">
                          <Button
                            color="secondary"
                            size="md"
                            iconPosition="left"
                            iconFont="download"
                            label="Download"
                            isDisabled={!qrCodeUrl}
                            classes="w-full hidden lg:inline-flex"
                            onClick={() => downloadQRCode()}
                          />
                        </DialogClose>
                      </div>
                    </DialogContent>
                  </div>
                </DialogPortal>
              </Dialog>
              <Button
                color="primary"
                size="md"
                iconPosition="left"
                iconFont="content_copy"
                label="Copy link"
                onClick={handleCopyLink}
              />
            </div>
          )}
        </div>

        <div className="share-link__link">
          <div className="share-link__link__icon-url" aria-disabled={!active}>
            <span className="material-icons-round text-24">link</span>
            {active ? (
              <a
                href={linkUrl}
                target="_blank"
                data-testid="share-link-active"
                className="share-link__link__url"
                rel="noreferrer"
              >
                {linkUrl}
              </a>
            ) : (
              <span
                data-testid="share-link-inactive"
                className="share-link__link__url"
              >
                {linkUrl}
              </span>
            )}
          </div>

          {active && (
            <div className="share-link__link__button">
              <Dialog modal onOpenChange={handleQRCodePopup}>
                <DialogTrigger asChild>
                  <Button
                    color="secondary"
                    size="md"
                    iconPosition="left"
                    iconFont="qr_code_2"
                    label="QR code"
                  />
                </DialogTrigger>

                <DialogPortal>
                  <DialogOverlay className="modal__overlay" />
                  <div className="modal modal--full">
                    <DialogContent className="modal__box modal__box--full flex flex-col items-center justify-center">
                      <DialogClose className="modal__box__close material-icons-round">
                        close
                      </DialogClose>

                      <div className="qr-code lg:mb-32">
                        {qrCodeUrl ? (
                          <img src={qrCodeUrl} alt="QR code" />
                        ) : (
                          <div className="skeleton" />
                        )}
                      </div>

                      <div className="modal__box__content">
                        <h1 className="modal__box__content__title -mt-32 lg:mt-0">
                          Scan and pay
                        </h1>
                        <p className="modal__box__content__text">
                          Your customers can scan this QR code to open the
                          payment link
                        </p>

                        <DialogClose asChild>
                          <Button
                            color="secondary"
                            size="md"
                            iconPosition="left"
                            iconFont="download"
                            label="Download"
                            classes="w-full hidden lg:inline-flex"
                            onClick={() => downloadQRCode()}
                          />
                        </DialogClose>
                      </div>
                    </DialogContent>
                  </div>
                </DialogPortal>
              </Dialog>
              <Button
                color="primary"
                size="md"
                iconPosition="left"
                iconFont="content_copy"
                label="Copy link"
                onClick={handleCopyLink}
              />
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};
