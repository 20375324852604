import { TooltipProvider } from "@radix-ui/react-tooltip";
import { ReactQueryProvider } from "components/Providers";
import { Provider } from "react-redux";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import { WalletContainer } from "./WalletContainer";
import { DetailPage } from "./WalletV2/DetailPage/DetailPage";

import { store } from "store";

import { routes } from "constants/routes";
// Cashback Routes
import { CashbackRoot } from "features/Wallet/WalletV2/features/Cashback/CashbackRoot";
import { Dashboard } from "features/Wallet/WalletV2/features/Cashback/_views/Dashboard/Dashboard";
import { CreditCardDetailPage } from "features/Wallet/WalletV2/features/CreditCardDetailPage";
import { TransferFundsRoot } from "features/Wallet/WalletV2/features/TransferFunds/TransferFundsRoot";

const walletRoutes = createBrowserRouter([
  {
    path: "/manage/wallet/:identifier",
    element: <DetailPage />,
  },
  {
    path: routes.MANAGE.WALLET.INDEX(),
    element: <WalletContainer />,
  },
  {
    path: routes.MANAGE.WALLET.CREDIT_CARD_DETAIL(),
    element: <CreditCardDetailPage />,
  },
  {
    path: routes.MANAGE.WALLET.CASHBACK.INDEX(),
    element: <CashbackRoot />,
    children: [
      {
        path: routes.MANAGE.WALLET.CASHBACK.SINGLE(":identifier"),
        element: <Dashboard />,
      },
    ],
  },
  {
    path: routes.MANAGE.WALLET.TRANSFER(),
    element: <TransferFundsRoot />,
  },
]);

export const WalletRoot = () => {
  return (
    <ReactQueryProvider>
      <TooltipProvider>
        <Provider store={store}>
          <RouterProvider router={walletRoutes} />
        </Provider>
      </TooltipProvider>
    </ReactQueryProvider>
  );
};
