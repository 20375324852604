import type React from "react";

import { Button } from "design_system/Button";
import { Description } from "design_system/Icons";
import { Modal } from "design_system/Modal";

import UaeFlag from "assets/icons/uae-flag.svg";

interface BeforeWeSetupModalProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  continueAction?: () => void;
}

export const BeforeWeSetupModal = ({
  showModal,
  setShowModal,
  continueAction,
}: BeforeWeSetupModalProps) => {
  return (
    <Modal
      show={showModal}
      onClose={() => setShowModal(false)}
      hasCloseButton
      modalWidth="48rem"
    >
      <div className="space-y-40">
        <div className="space-y-16">
          <h1 className="header-medium text-center">
            Before we get you set up, here are a couple of things to know about
            Mamo
          </h1>

          <ul>
            <li className="flex items-center gap-16 p-16">
              <div className="flex h-40 w-40 flex-shrink-0 items-center justify-center rounded-full bg-surface-2">
                <img src={UaeFlag} alt="uae flag" className="h-24" />
              </div>
              <span className="body-medium text-text-color-02">
                Mamo is currently only available for UAE-based businesses and
                freelancers.{" "}
                <a
                  href="https://www.mamopay.com/blog/how-movingo-gets-foreign-companies-up-and-running-in-the-uae-in-a-couple-of-days"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-primary-1"
                >
                  Click here to register your business in the&nbsp;UAE
                </a>
              </span>
            </li>
            <li className="flex items-center gap-16 p-16">
              <div className="flex h-40 w-40 flex-shrink-0 items-center justify-center rounded-full bg-surface-2">
                <Description size="24" />
              </div>
              <span className="body-medium text-text-color-02">
                To use our features, we need to verify your business. Have a
                look at the{" "}
                <a
                  href="https://help.mamopay.com/en/articles/7200023-getting-started-with-mamo-business#h_a3f08b8550"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-primary-1"
                >
                  list of required documents
                </a>
              </span>
            </li>
          </ul>
        </div>

        <Button
          variant="filled"
          size="lg"
          label="Continue"
          classes="w-full"
          onClick={continueAction}
        />
      </div>
    </Modal>
  );
};
