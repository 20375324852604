import { type VariantProps, cva } from "class-variance-authority";
import type { ReactNode } from "react";

import { cn, pureCn } from "utils/classNames";

export const timelineStepVariants = cva("flex grow flex-col items-start", {
  variants: {
    variant: {
      completed: "",
      current: "",
      upcoming: "",
      warning: "",
    },
  },
  defaultVariants: {
    variant: "upcoming",
  },
});

const figureVariants = cva(
  "relative flex h-20 w-20 flex-shrink-0 items-center justify-center rounded-full border-1 md:h-40 md:w-40 md:border-2",
  {
    variants: {
      variant: {
        completed: "bg-qualitative-green-01 text-white",
        current: "h-32 w-32 border-border-4 bg-white text-qualitative-blue-01",
        upcoming: "border-border-3 bg-white",
        warning:
          "h-32 w-32 border-none bg-accent-yellow-04 text-accent-yellow-02",
      },
    },
    defaultVariants: {
      variant: "upcoming",
    },
  },
);

const labelVariants = cva(
  "-bottom-36 md:-bottom-33 -translate-x-1/2 absolute left-1/2 transform text-center text-text-color-01 md:whitespace-nowrap",
  {
    variants: {
      variant: {
        completed: "caption md:body-medium",
        current:
          "label-small -bottom-44 md:label-medium !leading-none text-wrap",
        upcoming: "caption md:body-medium",
        warning: "-bottom-24 label-medium whitespace-nowrap text-text-color-01",
      },
    },
    defaultVariants: {
      variant: "upcoming",
    },
  },
);

const dividerVariants = cva("h-2 w-full rounded-full", {
  variants: {
    variant: {
      completed: "bg-qualitative-green-01",
      current: "bg-border-3",
      upcoming: "bg-border-3",
      warning: "bg-border-3",
    },
  },
});

const svgIconVariants = cva("h-12 w-12 md:h-18 md:w-18", {
  variants: {
    variant: {
      completed: "text-white",
      current: "text-qualitative-blue-01",
      upcoming: "text-text-color-01",
      warning: "text-text-color-01",
    },
  },
});

export type TimelineStepVariants = VariantProps<typeof timelineStepVariants>;

export type TimelineStepProps = {
  icon?: ReactNode;
  label?: ReactNode;
  className?: string;
  isLast?: boolean;
} & TimelineStepVariants;
export const TimelineStep = ({
  className,
  icon,
  label,
  variant,
  isLast,
}: TimelineStepProps) => {
  return (
    <div
      className={cn(timelineStepVariants({ variant }), className, {
        "w-full": !isLast,
      })}
    >
      <div
        className={cn("flex h-[918px] items-center gap-6", {
          "w-full": !isLast,
        })}
      >
        <figure
          className={pureCn(figureVariants({ variant }), "text-10 md:text-18")}
        >
          {icon && (
            <span className={cn(svgIconVariants({ variant }))}>{icon}</span>
          )}

          <figcaption className={cn(labelVariants({ variant }))}>
            {label}
          </figcaption>
        </figure>
        {!isLast && (
          <div data-divider className={pureCn(dividerVariants({ variant }))} />
        )}
      </div>
    </div>
  );
};
