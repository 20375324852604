export const showHideIntercom = (visibility: "show" | "hide") => {
  const intercom: HTMLCollectionOf<Element> = document.getElementsByClassName(
    "intercom-lightweight-app",
  );

  if (intercom[0]) {
    if (visibility === "hide") {
      (intercom[0] as HTMLElement).style.display = "none";
    }
    if (visibility === "show") {
      (intercom[0] as HTMLElement).style.display = "block";
    }
  }
};

export const checkAndHideIntercom = () => {
  const intercom: HTMLCollectionOf<Element> = document.getElementsByClassName(
    "intercom-lightweight-app",
  );

  if (intercom[0]) {
    (intercom[0] as HTMLElement).style.display = "none";
  }
};
