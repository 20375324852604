import { isEmpty } from "lodash";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import Button from "components/Button/Button";
import LoaderBusiness from "components/Loader/LoaderBusiness";
import Toast from "components/ui/Toast";

import { generalErrors } from "constants/errorMessages";
import routes from "constants/routes";
import { useGetBankName } from "features/shared/hooks/useGetBankName";
import { isAppPlatform } from "helpers/isAppPlatform";
import { handleClose } from "../shared/utils/close";
import { useGenerateOtp, useUpdateIbanForm } from "../useEditBankDetails";

export const ReviewDetails = () => {
  const navigate = useNavigate();
  const { fields } = useUpdateIbanForm();
  const { generateOtp } = useGenerateOtp();
  const { getBankName } = useGetBankName(fields.iban);
  const { data: bankName, isLoading } = getBankName;

  const onSubmit = async () => {
    try {
      await generateOtp.mutateAsync();
      navigate(routes.MANAGE.ACCOUNT_SETTINGS.BANK.VERIFICATION_CODE());
    } catch (error) {
      toast.error(generalErrors.SOMETHING_WENT_WRONG, {
        duration: 3000,
      });
    }
  };

  useEffect(() => {
    // data is not going to be here when the user refreshes the page so
    // we should take them back to the first step
    if (isEmpty(fields.iban) || isEmpty(fields.accountHolderName)) {
      navigate(routes.MANAGE.ACCOUNT_SETTINGS.BANK.UPDATE_IBAN(), {
        replace: true,
      });
    }
  }, []);

  if (isLoading) {
    return <LoaderBusiness />;
  }

  return (
    <>
      <Toast />
      <header>
        {!isAppPlatform() && (
          <button
            type="button"
            className="create-link__close-button material-icons-round"
            onClick={handleClose}
          >
            close
          </button>
        )}

        <button
          type="button"
          className="create-link__back-button material-icons-round"
          onClick={() => navigate(-1)}
        >
          arrow_back
        </button>
      </header>

      <section className="bg-white pt-48 md:mx-auto md:max-w-3xl md:rounded-16 md:p-48 lg:max-w-5xl">
        <div className="space-y-48">
          <div className="flex flex-col gap-16 text-center">
            <span data-testid="page-title" className="font-bold text-28">
              Review your details
            </span>
            <span className="heading-03 text-black-300">
              Check and confirm your bank details before you proceed
            </span>
          </div>

          <div className="text-16">
            <div className="space-y-4 border-black-50 border-b py-24">
              <p className="font-medium">Account holder name</p>
              <p className="text-black-300">{fields.accountHolderName}</p>
            </div>

            <div className="space-y-4 border-black-50 border-b py-24">
              <p className="font-medium">IBAN</p>
              <p className="text-black-300">{fields.iban}</p>
            </div>

            <div className="space-y-4 border-black-50 border-b py-24">
              <p className="font-medium">Bank name</p>
              <p className="text-black-300">{bankName}</p>
            </div>
          </div>

          <div className="text-center">
            <Button
              type="button"
              color="primary"
              size="lg"
              label="Save changes"
              onClick={onSubmit}
            />
          </div>
        </div>
      </section>
    </>
  );
};
