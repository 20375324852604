import { Divider } from "components/Divider";
import {
  Description,
  Done,
  ErrorOutline,
  Pending,
  VerifiedUser,
} from "design_system/Icons";
import { TimelineStep, TimelineStepper } from "design_system/TimelineStepper";

import { useTimelineSteps } from "features/VerificationSetup/_hooks/useTimelineSteps";
import { useVerificationProcess } from "features/VerificationSetup/_hooks/useVerificationProcess";

export const VerificationTimeline = () => {
  const {
    ourReviewVariant,
    completeVerificationVariant,
    accountVerifiedVariant,
  } = useTimelineSteps();
  const { isMissingInformation, isFailed } = useVerificationProcess();

  return (
    !isFailed && (
      <>
        <TimelineStepper className="mb-24 px-32 pt-16 pb-28 md:px-68 md:py-32">
          <TimelineStep
            variant="completed"
            label="Create account"
            icon={<Done fill="currentColor" />}
          />
          <TimelineStep
            variant={completeVerificationVariant}
            label="Complete verification"
            icon={
              completeVerificationVariant === "current" ? (
                <Description fill="currentColor" />
              ) : (
                <Done fill="currentColor" />
              )
            }
          />
          <TimelineStep
            variant={ourReviewVariant}
            label="Our review"
            icon={
              isMissingInformation ? (
                <ErrorOutline
                  fill="currentColor"
                  className="text-accent-yellow-02"
                />
              ) : (
                <Pending fill="currentColor" />
              )
            }
          />
          <TimelineStep
            isLast={true}
            variant={accountVerifiedVariant}
            label="Account verified"
            icon={<VerifiedUser fill="currentColor" />}
          />
        </TimelineStepper>
        <Divider classNames="md:hidden" />
      </>
    )
  );
};
