import {
  type HTMLAttributes,
  type ReactElement,
  type ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { createPortal } from "react-dom";

import { Button } from "design_system/Button/Button";
import { ChevronLeft, Close } from "design_system/Icons";
import { cn } from "utils/classNames";
import { SideSheetContext } from "./SideSheetContext";

const ANIMATION_DURATION = 500;

export type SideSheetProps = HTMLAttributes<HTMLDivElement> & {
  children: ReactNode;
  onClose?: () => void;
};
const SideSheet = ({ children, onClose, ...props }: SideSheetProps) => {
  const { isSideSheetOpen, closeSideSheet } = useContext(SideSheetContext);
  const [shouldRender, setShouldRender] = useState(isSideSheetOpen);

  const handleClose = () => {
    // Initiates the slide-out animation and starts the closing process
    closeSideSheet();
    onClose?.();
  };

  useEffect(() => {
    if (isSideSheetOpen) {
      setShouldRender(true);
      return;
    }

    const timer = setTimeout(() => {
      setShouldRender(false);
    }, ANIMATION_DURATION);

    return () => clearTimeout(timer);
  }, [isSideSheetOpen, open]);

  if (!shouldRender) return null;

  return createPortal(
    <div
      className="fixed inset-0 overflow-hidden"
      style={{ zIndex: 1200 }}
      {...props}
    >
      {/* Backdrop */}
      <div
        className={cn(
          "fixed inset-0 z-10 bg-overlay-1",
          isSideSheetOpen ? "" : "hidden",
        )}
        onClick={handleClose}
      />

      {/* SideSheet */}
      <div
        style={{
          width: "600px",
        }}
        className={cn(
          "fixed inset-y-0 right-0 z-20 max-w-full rounded-l-16 bg-surface-1 shadow-sidesheet",
          isSideSheetOpen ? "animate-slideIn" : "animate-slideOut",
        )}
      >
        <div className="relative flex h-full flex-col">{children}</div>
      </div>
    </div>,
    document.body,
  );
};

export default SideSheet;

export { SideSheet };

// Compound components for the SideSheet parts

export type SideSheetNavbarProps = {
  children: ReactNode;
  className?: string;
};
SideSheet.Navbar = ({ children, className }: SideSheetNavbarProps) => (
  <div className={cn("py-12 pr-24 pl-16 md:pt-24 md:pb-12", className)}>
    {children}
  </div>
);

SideSheet.Display = ({ children }) => (
  <div className="display-small">{children}</div>
);

SideSheet.Description = ({
  children,
  classNames = "",
}: { children: ReactElement | string; classNames?: string }) => (
  <div className={cn(`body-large text-text-color-02 ${classNames}`)}>
    {children}
  </div>
);

SideSheet.Display = ({ children }) => (
  <div className="display-small">{children}</div>
);

SideSheet.Description = ({
  children,
  classNames = "",
}: { children: ReactElement | string; classNames?: string }) => (
  <div className={cn(`body-large text-text-color-02 ${classNames}`)}>
    {children}
  </div>
);

export type SideSheetHeaderProps = {
  children: ReactElement | string;
  classNames?: string;
};
SideSheet.Header = ({ children, classNames = "" }: SideSheetHeaderProps) => (
  <div className={cn("p-16 md:px-32 md:pt-12 md:pb-24", classNames)}>
    {children}
  </div>
);

SideSheet.Content = ({ children }) => (
  <div className="flex-grow overflow-y-auto p-16 md:p-32">{children}</div>
);

SideSheet.Actions = ({ children }) => (
  <div className="w-full flex-none p-16 md:p-24">{children}</div>
);

export type SideSheetCloseProps = {
  onClose: () => void;
  className?: string;
};
SideSheet.Close = ({ onClose, className }: SideSheetCloseProps) => (
  <Button
    iconOnly
    Icon={Close}
    size="lg"
    variant="outlined"
    classes={cn(className)}
    onClick={onClose}
  />
);

SideSheet.Back = ({ onBack }) => (
  <Button
    iconOnly
    Icon={ChevronLeft}
    size="lg"
    variant="outlined"
    classes="ml-0 md:ml-16"
    onClick={onBack}
  />
);
