import { colorsV2 } from "constants/colors";
import type { SvgIconProps } from "types/Icon";

export const AccountBox = ({
  size,
  fill = colorsV2.text[1],
  ...rest
}: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M4.55384 18.4315C5.55384 17.5141 6.67734 16.7739 7.92436 16.2109C9.17136 15.6478 10.5291 15.3663 11.9974 15.3663C13.4658 15.3663 14.8244 15.6458 16.0731 16.2049C17.3219 16.7639 18.4462 17.5022 19.4462 18.4195V4.55379H4.55384V18.4315ZM12.0493 13.5647C13.0284 13.5647 13.8543 13.2286 14.5269 12.5564C15.1996 11.8843 15.5359 11.0586 15.5359 10.0795C15.5359 9.10042 15.1998 8.27554 14.5277 7.60489C13.8555 6.93424 13.0299 6.59892 12.0508 6.59892C11.0717 6.59892 10.2458 6.934 9.57314 7.60417C8.90049 8.27434 8.56416 9.09897 8.56416 10.0781C8.56416 11.0572 8.90024 11.8831 9.57241 12.5557C10.2446 13.2283 11.0702 13.5647 12.0493 13.5647ZM4.55384 21.1494C4.09385 21.1494 3.69498 20.9806 3.35721 20.6428C3.01946 20.3051 2.85059 19.9062 2.85059 19.4462V4.55379C2.85059 4.09218 3.01946 3.6919 3.35721 3.35297C3.69498 3.01402 4.09385 2.84454 4.55384 2.84454H19.4462C19.9079 2.84454 20.3081 3.01402 20.6471 3.35297C20.986 3.6919 21.1555 4.09218 21.1555 4.55379V19.4462C21.1555 19.9062 20.986 20.3051 20.6471 20.6428C20.3081 20.9806 19.9079 21.1494 19.4462 21.1494H4.55384ZM5.87504 19.4462H18.125V19.2511C17.225 18.4844 16.2584 17.9072 15.225 17.5196C14.1917 17.1319 13.1167 16.938 12 16.938C10.8834 16.938 9.80837 17.1319 8.77504 17.5196C7.7417 17.9072 6.77504 18.4844 5.87504 19.2511V19.4462ZM12.0479 11.9989C11.5156 11.9989 11.0639 11.8122 10.6927 11.4388C10.3215 11.0654 10.1359 10.6119 10.1359 10.0784C10.1359 9.54498 10.3222 9.09265 10.6948 8.72147C11.0674 8.35027 11.5199 8.16467 12.0521 8.16467C12.5844 8.16467 13.0362 8.35097 13.4074 8.72357C13.7786 9.09617 13.9642 9.54862 13.9642 10.0809C13.9642 10.6132 13.7779 11.0659 13.4053 11.4391C13.0327 11.8123 12.5802 11.9989 12.0479 11.9989Z"
        fill={fill}
      />
    </svg>
  );
};
