import { colorsV2 } from "constants/colors";
import type { SvgIconProps } from "types/Icon";

export const Done = ({
  size,
  fill = colorsV2.text[1],
  ...rest
}: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g id="Name=done, Size=24">
        <path
          id="Vector"
          d="M9.44997 15.5446L18.4472 6.55325C18.6188 6.38334 18.8223 6.29838 19.0577 6.29838C19.2931 6.29838 19.4958 6.38378 19.6657 6.55458C19.8357 6.72538 19.9206 6.92905 19.9206 7.1656C19.9206 7.40215 19.8357 7.60619 19.6657 7.77773L10.0527 17.3908C9.88131 17.5607 9.68004 17.6457 9.44889 17.6457C9.21773 17.6457 9.01718 17.5607 8.84724 17.3908L4.30922 12.8527C4.13928 12.6801 4.05748 12.4753 4.06382 12.2385C4.07017 12.0016 4.15874 11.7982 4.32954 11.6283C4.50034 11.4583 4.70402 11.3734 4.94057 11.3734C5.17712 11.3734 5.38116 11.4583 5.55269 11.6283L9.44997 15.5446Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};
