import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import numeral from "numeral";
import toast from "react-hot-toast";

// import { CREDIT_CARD_DETAIL_MOCK } from "../../mocks/CreditDetail.mock";
import { endpoints } from "constants/apiEndpoints";
import { generalErrors } from "constants/errorMessages";
import { SentryLoggingService } from "init/SentryLoggingService";
// import type { GetCreditCardDetail } from "../typeDefs";

export const useGetCreditCardDetail = () => {
  // const getCreditCardDetail: GetCreditCardDetail = useQuery({
  const getCreditCardDetail = useQuery({
    queryKey: ["getCreditCardDetail"],
    queryFn: async () => {
      try {
        const { data } = await axios.get(
          endpoints.MANAGE.API.CREDIT_ACCOUNTS.INDEX,
        );
        // const data = CREDIT_CARD_DETAIL_MOCK;
        return formattedResponse(data);
      } catch (error: any) {
        toast.error(generalErrors.SOMETHING_WENT_WRONG, {
          duration: 3000,
        });
        SentryLoggingService.captureException(error.message, {
          feature: "[Wallets V2][Credit Card Detail]",
          file: "useGetCreditCardDetail.tsx",
          function: "useGetCreditCardDetail",
        });
        return undefined;
      }
    },
  });

  return {
    getCreditCardDetail,
  };
};

function formattedResponse(data) {
  try {
    const creditConsumed =
      data.creditLimit === 0 ? 0 : data.creditLimit - data.balance;
    const creditConsumedFormatted = `${data.currency} ${numeral(creditConsumed).format("0,0.00")}`;
    const progress =
      data.creditLimit > 0
        ? ((creditConsumed / data.creditLimit) * 100).toFixed(0)
        : 0;

    return {
      creditConsumed,
      progress,
      creditConsumedFormatted,
      ...data,
    };
  } catch (error: any) {
    // Generic message since we don't know what went wrong
    toast.error(generalErrors.SOMETHING_WENT_WRONG, {
      duration: 3000,
    });

    SentryLoggingService.captureException(error.message, {
      feature: "[Wallets V2][Credit Card Detail]",
      file: "useGetCreditCardDetail.tsx",
      function: "useGetCreditCardDetail",
      data: JSON.stringify({ data }),
    });

    // Fallback values
    return {
      balance: 0.0,
      balanceFormatted: "AED 0.00",
      creditConsumed: 0,
      creditConsumedFormatted: "AED 0.00",
      creditLimit: 0.0,
      creditLimitFormatted: "AED 0.00",
      currency: "AED",
      id: "",
      progress: 0,
    };
  }
}
