import { SideSheetProvider } from "design_system/Sidesheet/SideSheetContext";
import { Header } from "./_components/Header";
import { CashbackTable } from "./_containers/Table/CashbackTable";

export const Dashboard = () => {
  return (
    <SideSheetProvider>
      {() => (
        <>
          <Header />
          <CashbackTable />
        </>
      )}
    </SideSheetProvider>
  );
};
