import isEmpty from "lodash/isEmpty";

import { Banner } from "design_system/Banner";
import {
  AdminPanelSettings,
  CheckCircleFill,
  ErrorFill,
  Info,
  Schedule,
} from "design_system/Icons";

import routes from "constants/routes";
import { handleGoToVerification } from "utils/verificationUtils";

interface VerificationBannerProps {
  isVerificationCompleteAndPricingNotSelected: boolean;
  isVerificationFailed: boolean;
  isVerificationNotComplete: boolean;
  isVerificationPending: boolean;
  isVerificationPendingAndPricingNotSelected: boolean;
  notCompleteMessages: string[];
}

export const VerificationBanner = ({
  isVerificationCompleteAndPricingNotSelected,
  isVerificationFailed,
  isVerificationNotComplete,
  isVerificationPending,
  isVerificationPendingAndPricingNotSelected,
  notCompleteMessages,
}: VerificationBannerProps) => {
  const hasNotCompleteMessages = !isEmpty(notCompleteMessages);

  const handleGoToPlanSelect = () => {
    window.location.href = `${routes.MANAGE.PLANS.INDEX()}`;
  };

  return (
    <div className="space-y-16">
      {isVerificationPending && (
        <Banner
          id="verification-pending"
          variant="info"
          leadingElement="icon"
          Icon={Schedule}
          title="Verification under review"
          description="We're reviewing your documents and will email you if we need anything else."
        />
      )}
      {isVerificationFailed && (
        <Banner
          id="verification-failed"
          variant="critical"
          leadingElement="icon"
          Icon={ErrorFill}
          title="Update on your verification"
          description="While we appreciate your interest in Mamo, we won't be able to move forward with your application at this time."
        />
      )}
      {isVerificationCompleteAndPricingNotSelected && (
        <Banner
          id="verification-complete-pricing-not-selected"
          variant="success"
          leadingElement="icon"
          Icon={CheckCircleFill}
          title="You&rsquo;re all set!"
          description="Your account is now fully verified. Pick a plan to start accepting payments and managing expenses seamlessly."
          hasButton
          buttonProps={{
            label: "Pick a plan",
            onClick: () => handleGoToPlanSelect(),
          }}
        />
      )}
      {isVerificationPendingAndPricingNotSelected && (
        <Banner
          id="verification-pending-pricing-not-selected"
          variant="info"
          leadingElement="icon"
          Icon={Schedule}
          title="Verification under review. Save time and pick a plan!"
          description="We're reviewing your documents and will email you if we need anything else."
          hasButton
          buttonProps={{
            label: "Pick a plan",
            onClick: () => handleGoToPlanSelect(),
          }}
        />
      )}
      {isVerificationNotComplete && (
        <Banner
          id="verification-not-complete"
          variant="attention"
          leadingElement="icon"
          Icon={hasNotCompleteMessages ? Info : AdminPanelSettings}
          title={
            hasNotCompleteMessages
              ? "Review missing information"
              : "Complete your verification"
          }
          description={
            hasNotCompleteMessages ? (
              <div className="body-medium flex flex-col">
                <span>
                  We need some additional information from you. To continue with
                  your verification, please:
                </span>
                {notCompleteMessages.map((message: string, index: number) => (
                  <li key={index}>{message}</li>
                ))}
              </div>
            ) : (
              "Have your documents ready and complete your verification to start using Mamo. This won't take long!"
            )
          }
          hasButton
          buttonProps={{
            label: "Continue",
            onClick: () => handleGoToVerification(),
          }}
        />
      )}
    </div>
  );
};
