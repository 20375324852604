import { ItemRow } from "design_system/LabeledGrid/ItemRow";
import type { ReactNode } from "react";

import { cn } from "utils/classNames";

export type SectionTitleProps = {
  title: string | ReactNode;
  children: (props: {
    ItemRow: typeof ItemRow;
  }) => ReactNode;

  className?: string;
};

export const SectionTitle = ({
  title,
  children,
  className,
}: SectionTitleProps) => {
  return (
    <div className="py-8">
      <span
        className={cn("label-large block py-8 text-text-color-02", className)}
      >
        {title}
      </span>

      {children({ ItemRow })}
    </div>
  );
};
