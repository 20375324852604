export type TFiltersDateRange = {
  id: string;
  value: string;
  label:
    | "All time"
    | "Today"
    | "Last 7 days"
    | "This month"
    | "Last month"
    | "Last 3 months"
    | "Custom range";
};

export const defaultDateFilter: TFiltersDateRange = {
  id: "all-time",
  value: "all time",
  label: "All time",
};

export const dateFiltersBasic: TFiltersDateRange[] = [
  defaultDateFilter,
  {
    id: "today",
    value: "today",
    label: "Today",
  },
  {
    id: "last-7-days",
    value: "last 7 days",
    label: "Last 7 days",
  },
  {
    id: "this-month",
    value: "this month",
    label: "This month",
  },
  {
    id: "last-3-months",
    value: "last 3 months",
    label: "Last 3 months",
  },
];

export const dateFilters: TFiltersDateRange[] = [
  ...dateFiltersBasic,
  {
    id: "custom-range",
    value: "custom range",
    label: "Custom range",
  },
];
