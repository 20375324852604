import { useContext } from "react";
import { useDispatch } from "react-redux";

import { Button } from "design_system/Button/Button";
import { Close, Delete } from "design_system/Icons";

import Island from "design_system/Island/Island";

import { useAppSelector } from "store";

import { colorsV2 } from "constants/colors";
import { IslandContext } from "design_system/Island/IslandContext";
import {
  clearSelectedRows,
  showHideDeleteCategoryModal,
} from "../../reducer/expenseSettingsSlice";

export const CategoriesIsland = () => {
  /* ---------------------------------
   *  Context APIS & CACHES
   * ---------------------------------
   */
  const { closeIsland } = useContext(IslandContext);

  /* ---------------------------------
   *  Hooks
   * ---------------------------------
   */
  const dispatch = useDispatch();

  const {
    dashboard: { selectedCount },
  } = useAppSelector((state) => state.expenseSettings);

  /* ---------------------------------
   *  Helpers
   * ---------------------------------
   */
  const closeIslandAndResetSelections = () => {
    dispatch(clearSelectedRows());
    closeIsland();
  };

  return (
    <Island>
      <div className="flex items-center gap-48">
        <Island.Label className="min-w-104 whitespace-nowrap">
          {selectedCount} selected
        </Island.Label>
        <div className="flex items-center gap-12">
          <Button
            label="Delete"
            size="md"
            variant="outlined"
            Icon={Delete}
            onClick={() =>
              dispatch(
                showHideDeleteCategoryModal({
                  showOrHideModal: "show",
                  shouldClearState: false,
                }),
              )
            }
          />
        </div>
        <div>
          <Close
            size="24"
            className="cursor-pointer"
            fill={colorsV2.text[2]}
            onClick={closeIslandAndResetSelections}
          />
        </div>
      </div>
    </Island>
  );
};
