import { cn } from "utils/classNames";

// OTHERS
import { colorsV2 } from "constants/colors";
import type { NavigationIconName } from "../../core/DashboardNavigation.types";
import { iconMapping } from "../../core/utils/iconMapping";

interface NavigationRailPrimaryItemProps {
  active: boolean;
  Icon: NavigationIconName;
  label: string;
  url: string;
}

export const NavigationRailSecondaryItem = ({
  active,
  Icon,
  label,
  url,
}: NavigationRailPrimaryItemProps) => {
  const IconComponent = iconMapping[Icon];

  return (
    <a
      href={url}
      className={cn("flex items-center gap-8 rounded-8 px-12 py-8", {
        "hover:bg-surface-3": !active,
        "bg-accent-blue-02": active,
      })}
    >
      <IconComponent
        size="18"
        fill={active ? colorsV2.primary[1] : colorsV2.text[2]}
      />
      <span className="label-small">{label}</span>
    </a>
  );
};
