import * as RadixDropdown from "@radix-ui/react-dropdown-menu";
import isEmpty from "lodash/isEmpty";
import { useEffect, useState } from "react";
import { cn } from "utils/classNames";

// COMPONENTS
import { IconButton } from "design_system/IconButton";
import { Done, UnfoldMore } from "design_system/Icons";

// HOOKS
import { useChangeUsersAccount } from "hooks/useChangeUsersAccount";
import { useGetUsersAccounts } from "hooks/useGetUsersAccounts";
import { useMerchant } from "hooks/useMerchant";
import { useScreenDimension } from "hooks/useScreenDimension";

// OTHERS
import { colorsV2 } from "constants/colors";
import { shrinkString } from "helpers/stringUtils";

export const SwitchBusiness = () => {
  const { merchantDetails, getMerchantDetails } = useMerchant();
  const { getUsersAccounts } = useGetUsersAccounts();
  const { changeUsersAccount } = useChangeUsersAccount();
  const { isMobile, isTablet } = useScreenDimension();

  const [currentSignedInBusiness, setCurrentSignedInBusiness] =
    useState<string>("");

  const { currentMerchant } = merchantDetails;
  const { name } = currentMerchant;
  const businessName =
    isMobile || isTablet
      ? shrinkString(name, 2, 20)
      : shrinkString(name, 5, 30);
  const hasNoMultipleAccounts = isEmpty(getUsersAccounts.data);

  useEffect(() => {
    getMerchantDetails();
  }, []);

  useEffect(() => {
    if (!hasNoMultipleAccounts) {
      getUsersAccounts.data.map((item) => {
        if (currentMerchant?.identifier === item.merchant.identifier) {
          setCurrentSignedInBusiness(item.identifier);
        }
      });
    }
  }, [hasNoMultipleAccounts, currentMerchant]);

  return (
    <div>
      {/* If user has access to only one account */}
      {hasNoMultipleAccounts && (
        <span className="label-large">{businessName}</span>
      )}

      {/* If user has access to more than one account */}
      {!hasNoMultipleAccounts && (
        <RadixDropdown.Root>
          <RadixDropdown.Trigger className="AccordionTrigger flex items-center gap-8 rounded-8 p-4 hover:bg-surface-2">
            <span className="label-large">{businessName}</span>
            <IconButton size="xs" Icon={UnfoldMore} />
          </RadixDropdown.Trigger>

          <RadixDropdown.Content
            align="start"
            alignOffset={4}
            sideOffset={-47}
            className="AccordionContent rounded-16 bg-surface-1 p-8 shadow-lg"
          >
            <RadixDropdown.RadioGroup
              value={currentSignedInBusiness}
              onValueChange={(value) => changeUsersAccount.mutateAsync(value)}
            >
              {getUsersAccounts.data.map((account, index) => (
                <RadixDropdown.RadioItem
                  className={cn(
                    "body-medium flex cursor-pointer items-center justify-between gap-12 rounded-8 p-8 outline-none focus-within:bg-surface-2 hover:bg-surface-2",
                    {
                      "bg-surface-2":
                        currentSignedInBusiness === account.identifier,
                    },
                  )}
                  value={account.identifier}
                  key={index}
                >
                  <span>{account.merchant.name}</span>
                  <RadixDropdown.ItemIndicator>
                    <Done size="18" fill={colorsV2.accent.green[1]} />
                  </RadixDropdown.ItemIndicator>
                </RadixDropdown.RadioItem>
              ))}
            </RadixDropdown.RadioGroup>
          </RadixDropdown.Content>
        </RadixDropdown.Root>
      )}
    </div>
  );
};
