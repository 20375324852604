export type CurrencyCode =
  | "AED"
  | "AUD"
  | "CAD"
  | "CHF"
  | "DZD"
  | "EGP"
  | "EUR"
  | "GBP"
  | "IDR"
  | "INR"
  | "PKR"
  | "QAR"
  | "SAR"
  | "TRY"
  | "USD";

export interface CurrencyConfigEntry {
  locale: string;
  currency: CurrencyCode;
  currencyName: string;
}

export interface CurrencyConfig {
  [key: string]: CurrencyConfigEntry;
}

export const currencyConfig: CurrencyConfig = {
  AE: {
    locale: "en-AE",
    currency: "AED",
    currencyName: "United Arab Emirates Dirham",
  },
  AU: { locale: "en-AU", currency: "AUD", currencyName: "Australian Dollar" },
  CA: { locale: "en-CA", currency: "CAD", currencyName: "Canadian Dollar" },
  CH: { locale: "de-CH", currency: "CHF", currencyName: "Swiss Franc" },
  DE: { locale: "de-DE", currency: "EUR", currencyName: "Euro" },
  DZ: { locale: "ar-DZ", currency: "DZD", currencyName: "Algerian Dinar" },
  EG: { locale: "ar-EG", currency: "EGP", currencyName: "Egyptian Pound" },
  GB: { locale: "en-GB", currency: "GBP", currencyName: "British Pound" },
  ID: { locale: "id-ID", currency: "IDR", currencyName: "Indonesian Rupiah" },
  IN: { locale: "en-IN", currency: "INR", currencyName: "Indian Rupee" },
  PK: { locale: "ur-PK", currency: "PKR", currencyName: "Pakistani Rupee" },
  QA: { locale: "ar-QA", currency: "QAR", currencyName: "Qatari Riyal" },
  SA: { locale: "ar-SA", currency: "SAR", currencyName: "Saudi Riyal" },
  TR: { locale: "tr-TR", currency: "TRY", currencyName: "Turkish Lira" },
  US: {
    locale: "en-US",
    currency: "USD",
    currencyName: "United States Dollar",
  },
};

export const currencyCodes: CurrencyCode[] = Object.values(currencyConfig).map(
  (config) => config.currency,
);

function getCurrencyNames(config) {
  return Object.values(config).map((c: any) => c.currencyName);
}

export const currencyNames: string[] = getCurrencyNames(currencyConfig);
