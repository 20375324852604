import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { cn } from "utils/classNames";

import { Divider } from "components/Divider";
import { TableRecord } from "components/Table/TableRecord";
import Table from "components/Table/V2/TableV2";
import Pagination from "components/ui/Pagination/PaginationV2";
import { Snackbar } from "design_system/Snackbar";
import { CategoriesIsland } from "./Island";
import { TableEmptyState } from "./TableEmptyState";

import { useScreenDimension } from "hooks/useScreenDimension";
import { useExpenseCategoriesTable } from "../hooks/useExpenseCategoriesTable";

import type { ExpenseCategory } from "types/Expenses";
import { setSelectedRows } from "../../reducer/expenseSettingsSlice";
import type { ExpenseCategoriesTableProps } from "../../typeDefs";
import { ExpenseCategoryActions } from "./Actions/ExpenseCategoryActions";

export const ExpenseCategoriesTable = ({
  categories,
}: ExpenseCategoriesTableProps) => {
  /* ---------------------------------
   *  Hooks
   * ---------------------------------
   */
  const dispatch = useDispatch();
  const { isMobile } = useScreenDimension();

  const { COLUMNS, selectedRows, handleSetPage } = useExpenseCategoriesTable();

  /* ---------------------------------
   *  Constants
   * ---------------------------------
   */
  const paginationMeta = {
    ...categories.paginationMeta,
    itemName: "Categories",
  };

  const columns = useMemo(() => COLUMNS, []);
  const tableData = useMemo(() => categories.data, [categories.data]);

  const isEmptyTable = !categories || categories.data?.length === 0;

  if (!isMobile) {
    return (
      <div className="flex w-full justify-center">
        <Table
          name="payment-accounts-table"
          columns={columns}
          data={tableData}
          paginationMeta={paginationMeta}
          onSetPage={handleSetPage}
          tableClass="table"
          headerRowClass="bg-black-25"
          headerCellClass="w-auto"
          rowProps={{
            selectable: true,
            selectedRows,
            setSelectedRows: (params) => {
              // Note: Our Table component does not currently return a flag that let's us know if all rows are selected or not
              dispatch(
                setSelectedRows({ rows: params, areAllSelected: false }),
              );
            },
          }}
          emptyState={<TableEmptyState />}
        />
        <CategoriesIsland />
      </div>
    );
  }
  return isEmptyTable ? (
    <TableEmptyState />
  ) : (
    <>
      <Snackbar />
      {categories.data.map((category: ExpenseCategory, index) => {
        return (
          <div key={`${category.identifier}_${index}`}>
            <TableRecord
              variant="noAvatar"
              className={cn({ "-mt-16": index === 0 })}
            >
              <TableRecord.Column1 label={category.name} />
              <TableRecord.Actions>
                <ExpenseCategoryActions category={category} />
              </TableRecord.Actions>
            </TableRecord>
            <Divider />
          </div>
        );
      })}
      <div className="disbursements__pagination px-16">
        <Pagination
          itemName={paginationMeta.itemName}
          meta={paginationMeta}
          onSetPage={handleSetPage}
        />
      </div>
    </>
  );
};
