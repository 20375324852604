import type { VisibilityState } from "@tanstack/react-table";

export const getColumnVisibility = (
  hiddenColumns: string[] | null | undefined,
) => {
  const visibilityState: VisibilityState = {};

  try {
    if (!hiddenColumns) return visibilityState;
    hiddenColumns.forEach((element: string) => {
      visibilityState[element] = false;
    });
    return visibilityState;
  } catch (error: any) {
    console.error(`[getColumnVisibility] - `, error);
    return visibilityState;
  }
};
