import axios from "axios";

import { stringifiedQueryParams } from "constants/queryParams";
import routes from "constants/routes";

export const getVerificationInfo = async () => {
  const response = await axios.get(
    `${routes.MANAGE.ONBOARDING.VERIFICATION_INFO()}?${stringifiedQueryParams}`,
  );

  return response.data;
};
