import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useEffect } from "react";

import type {
  BankInformation,
  BusinessDocuments,
  BusinessInformation,
  BusinessOwners,
  PersonalDetails,
} from "features/VerificationSetup/_types/verification-process";
import { SentryLoggingService } from "init/SentryLoggingService";

export type VerificationProcessResponse = {
  data: {
    personalDetails: PersonalDetails;
    businessInformation: BusinessInformation;
    businessDocuments: BusinessDocuments;
    bankInformation: BankInformation;
    businessOwners: BusinessOwners;
  };
};

/**
 * Get the verification process
 * It returns a query result
 */
export const getVerificationProcess = (enabled: boolean) => {
  // REST EP
  const url = "/manage/api/v1/setup/verification_progress";

  const verificationProcess = useQuery({
    queryKey: ["getVerificationProcess"],
    enabled,
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const response = await axios.get<VerificationProcessResponse>(url);

      return response.data.data;
    },
  });

  useEffect(() => {
    if (verificationProcess.error) {
      if (verificationProcess.error instanceof Error) {
        SentryLoggingService.captureException(
          verificationProcess.error.message,
          {
            feature: "[Verification Setup][Get Verification Process]",
            file: "verification-process.query.ts",
            function: "getVerificationProcess",
          },
        );
      }
    }
  }, [verificationProcess.isError, verificationProcess.error]);

  return {
    verificationProcess,
  };
};
