export const maskedApiKey = "•••••••••••••••••••••••••••••••••••••••";

export const scriptString = `<head>
  <script src='https://assets.mamopay.com/stable/checkout-inline-2.0.0.min.js'></script>
</head>

<body>
  <div id='mamo-checkout-element'></div>

  <script>
    const mamoPay = new MamoPay();
    mamoPay.addIframeToWebsite("mamo-checkout-element", paymentLink);
  </script>
</body>`;
