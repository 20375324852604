import { isEmpty } from "lodash";

import Button from "components/Button/Button";
import EmptyState from "components/EmptyState";
import LoaderBusiness from "components/Loader/LoaderBusiness";
import Card from "components/ui/Card";
import Toast from "components/ui/Toast";
import { PageHeader } from "./PageHeader";

import { getSettlementOptions } from "components/ChoosePlan/Step2/constant";
import type IRailsContext from "interfaces/IRailsContext";
import { useGetPlanDetails } from "./shared/hooks/useGetPlanDetails";
import type { TBalanceTransaction, TPlans } from "./shared/types";
import {
  sharedGoToPlanButtonProps,
  sharedGoToSettlementButtonProps,
} from "./shared/utils";

import EmptyStateIllustration from "assets/illustrations/generic-card-empty-state.svg";

interface IPlanContainerProps {
  _: any;
  railsContext: IRailsContext;
}

export const PlanContainer = (props: IPlanContainerProps) => {
  const { getPlanDetails } = useGetPlanDetails();
  const { data, isLoading: loadingPlanDetails } = getPlanDetails;

  const planDetails = data ?? [];
  const {
    activePlan,
    activePlanSubscription,
    balanceTransactions,
    cardFees,
    isBillingEmpty,
    monthlyFeeAed,
    settlementPeriod,
    transactionFees,
    ultraInfo,
  } = planDetails as TPlans;

  const hasPremium =
    props.railsContext.sessionContext.currentMerchant?.premiumAccess;
  const hasPremiumTrial =
    props.railsContext.sessionContext.currentMerchant?.trialAccess;

  const intlCardPercentage =
    +transactionFees?.intlPercentage - +transactionFees?.localPercentage;
  const settlementOptions = getSettlementOptions(activePlan?.name);
  const activeSettlementOption = settlementOptions.find((obj) => {
    return obj.settlementPeriod === settlementPeriod;
  });

  const displayLocalTransactionPercentage = () => {
    if (
      activePlan?.name === "Ultra" ||
      (activePlan?.name === "Enterprise" && ultraInfo.ultraBenefits)
    ) {
      return ultraInfo.ultraDiscountedLocalPercentage;
    }

    return transactionFees.localPercentage;
  };

  if (loadingPlanDetails) {
    return <LoaderBusiness />;
  }

  const fallbackToSettlementPeriodBusinessDays = (settlementPeriod) => {
    return settlementPeriod == "1"
      ? "1 business day"
      : `${settlementPeriod} business days`;
  };

  return (
    <>
      <Toast />
      <PageHeader />

      <section className="account-settings">
        <div className="account-settings__plan-details">
          {/* CURRENT PLAN */}
          <Card hasBorder classes="space-y-24">
            <div className="flex items-center justify-between">
              <h3>Current plan</h3>
              <div className="hidden md:block">
                <Button
                  {...sharedGoToPlanButtonProps(hasPremium, hasPremiumTrial)}
                />
              </div>
            </div>

            <div className="flex justify-between gap-16 text-16">
              <div className="flex flex-1 flex-col gap-8">
                <div className="flex flex-row items-center gap-8">
                  <p className="font-medium">{activePlan?.name}</p>
                  {activePlan?.name === "Premium" && hasPremiumTrial && (
                    <span className="rounded-full bg-blue-50 px-12 py-2 font-medium text-12 text-blue-500">
                      Trial
                    </span>
                  )}
                </div>

                {activePlanSubscription?.autoRenew === false && (
                  <p className="text-14 text-black-300">
                    Your {activePlanSubscription?.name} plan will be active
                    until {activePlanSubscription?.endDate}, and will change to{" "}
                    {activePlanSubscription?.downgradePlanName} after
                  </p>
                )}

                {activePlan?.name === "Premium" && hasPremiumTrial && (
                  <p className="text-14 text-black-300">
                    Your free trial ends on {activePlanSubscription?.endDate}
                  </p>
                )}
              </div>
              <p className="flex-1 text-right">{`AED ${monthlyFeeAed}/month`}</p>
            </div>

            <div className="block md:hidden">
              <Button
                {...sharedGoToPlanButtonProps(hasPremium, hasPremiumTrial)}
                classes="w-full"
              />
            </div>
          </Card>

          {/* CARDS */}
          <Card hasBorder classes="space-y-24">
            <h3>Cards</h3>

            <div>
              <div className="flex justify-between gap-16 border-black-50 border-b py-16 text-16">
                <p className="flex-1 font-medium">Free cards</p>
                <p className="flex-1 text-right">
                  {cardFees.numberOfFreeCards <= 0
                    ? "Unlimited"
                    : cardFees.numberOfFreeCards}
                </p>
              </div>

              {activePlan?.name !== "Ultra" && (
                <div className="space-y-8 border-black-50 border-b py-16">
                  <div className="flex justify-between gap-16 text-16">
                    <p className="flex-1 font-medium">Additional cards</p>
                    <p className="flex-1 text-right">{`AED ${cardFees.perCardFee}/month`}</p>
                  </div>
                  <p className="text-14 text-black-300">
                    Monthly cost applied to each additional card.
                  </p>
                </div>
              )}

              <div className="flex justify-between gap-16 border-black-50 border-b py-16 text-16">
                <p className="flex-1 font-medium">ATM withdrawals in the UAE</p>
                <p className="flex-1 text-right">
                  {cardFees.localCashWithdrawalFee === 0
                    ? "Free"
                    : cardFees.localCashWithdrawalFee}
                </p>
              </div>

              <div className="flex justify-between gap-16 py-16 text-16">
                <p className="flex-1 font-medium">
                  ATM withdrawals internationally
                </p>
                <p className="flex-1 text-right">
                  AED {cardFees.intlCashWithdrawalFee}
                </p>
              </div>
            </div>
          </Card>

          {/* TRANSACTION FEES */}
          <Card hasBorder classes="space-y-24">
            <h3>Transaction fees</h3>

            <div>
              <div className="space-y-8 border-black-50 border-b py-16">
                <div className="flex justify-between gap-16 text-16">
                  <div className="flex flex-1 flex-col gap-8">
                    <p className="font-medium">UAE cards</p>
                    {(activePlan?.name === "Ultra" ||
                      (activePlan?.name === "Enterprise" &&
                        ultraInfo.ultraBenefits)) && (
                      <p className="text-14 text-black-300">
                        Spend with Mamo Card and get{" "}
                        {ultraInfo.ultraDiscountedLocalPercentage}
                        %&nbsp;+&nbsp;AED&nbsp;{transactionFees.fixedFee} on
                        payments equivalent to your total card spend. Payments
                        that exceed this amount will incur{" "}
                        {transactionFees.localPercentage}
                        %&nbsp;+&nbsp;AED&nbsp;{transactionFees.fixedFee}{" "}
                        transaction fees.{" "}
                        <a
                          href="https://help.mamopay.com/en/articles/7234173-pricing-plans-and-subscriptions#h_460e362096"
                          target="_blank"
                          className="text-blue-500"
                          rel="noreferrer"
                        >
                          Learn&nbsp;more
                        </a>
                      </p>
                    )}
                  </div>
                  <p className="flex-1 text-right">
                    {displayLocalTransactionPercentage()}%
                    {transactionFees.fixedFee &&
                      ` + AED ${transactionFees.fixedFee}`}
                  </p>
                </div>
              </div>

              <div>
                <div className="flex justify-between gap-16 border-black-50 border-b py-16 text-16">
                  <p className="flex-1 font-medium">International cards</p>
                  <p className="flex-1 text-right">
                    + {Number.parseFloat(intlCardPercentage.toFixed(2))}%
                  </p>
                </div>
              </div>

              <div className="flex justify-between gap-16 py-16 text-16">
                <p className="flex-1 font-medium">
                  Payments with other currencies
                </p>
                <p className="flex-1 text-right">
                  + {transactionFees.usdExtraPercentage}%
                </p>
              </div>
            </div>
          </Card>

          {/* SETTLEMENT PERIOD */}
          <Card hasBorder classes="space-y-24">
            <div className="flex items-center justify-between">
              <h3>Settlement period</h3>
              <div className="hidden md:block">
                <Button {...sharedGoToSettlementButtonProps} />
              </div>
            </div>

            <div className="flex justify-between gap-16 py-16 text-16">
              <p className="flex-1 font-medium">
                {isEmpty(activeSettlementOption?.text)
                  ? fallbackToSettlementPeriodBusinessDays(settlementPeriod)
                  : activeSettlementOption?.text}
              </p>
              <p className="flex-1 text-right">
                {transactionFees.settlementExtraPercentage === 0
                  ? "Free"
                  : `+${transactionFees.settlementExtraPercentage}% transaction fee`}
              </p>
            </div>

            <div className="block md:hidden">
              <Button {...sharedGoToSettlementButtonProps} classes="w-full" />
            </div>
          </Card>

          {/* BILLING HISTORY */}
          <>
            {isBillingEmpty ? (
              <Card hasBorder>
                <h3 className="mb-24">Billing history</h3>
                <div className="py-24">
                  <EmptyState
                    imgSrc={EmptyStateIllustration}
                    imgAlt="Person lying down catching z's"
                    title="No billing history yet"
                    info="Premium fees are deducted from your settlement each month."
                  />
                </div>
              </Card>
            ) : (
              <>
                {/* BILLING HISTORY */}
                <Card hasBorder>
                  <h3 className="account-settings__plan__table__heading">
                    Billing history
                  </h3>
                  <div className="table-container">
                    <div className="table-container__header">
                      <div className="table-container__header__row">
                        <p className="table-container__heading">
                          Transaction ID
                        </p>
                        <p className="table-container__heading">Payment for</p>
                        <p className="table-container__heading">Amount</p>
                        <p className="table-container__heading">Date</p>
                      </div>
                    </div>

                    <div className="table-container__body">
                      {/* TABLE ROW */}
                      {/* PAYMENT FOR */}
                      {balanceTransactions.map(
                        ({
                          identifier,
                          type,
                          amount,
                          date,
                        }: TBalanceTransaction) => (
                          <div
                            key={identifier}
                            className="table-container__row table-container__row--billing-history"
                          >
                            <div className="table-container__data-group">
                              {/* [PHONE] NAME & ID ROW, [DESKTOP] ID ROW */}
                              <div className="table-container__data">
                                <div className="table-container__data__billing-history">
                                  <div className="table-container__data__billing-history__plan-id">
                                    <p className="table-container__data__billing-history__plan">
                                      {type}
                                    </p>
                                    <p className="table-container__data__billing-history__id">
                                      {identifier}
                                    </p>
                                  </div>
                                  <div className="hidden xl:block">
                                    <p className="table-container__data__billing-history__id">
                                      {identifier}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              {/* [DESKTOP] NAME ROW  */}
                              <div className="table-container__data hidden xl:table-cell">
                                <div className="table-container__data__billing-history">
                                  <p className="table-container__data__billing-history__plan">
                                    {type}
                                  </p>
                                </div>
                              </div>

                              {/* AMOUNT ROW */}
                              <div className="table-container__data">
                                <div className="table-container__data__billing-history">
                                  <p className="table-container__data__billing-history__amount">
                                    {amount}
                                  </p>
                                  <p className="table-container__data__billing-history__date xl:hidden">
                                    {date}
                                  </p>
                                </div>
                              </div>

                              {/* [DESKTOP] DATE */}
                              <div className="table-container__data hidden xl:table-cell">
                                <div className="table-container__data__billing-history">
                                  <p className="table-container__data__billing-history__date">
                                    {date}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ),
                      )}
                    </div>
                  </div>
                </Card>
              </>
            )}
          </>

          <div className="plan__vat">
            <span className="material-icons-round text-24">info</span>
            <p>All costs exclude 5% VAT</p>
          </div>
        </div>
      </section>
    </>
  );
};
