import type React from "react";
import { Provider } from "react-redux";
import { store } from "store";

// Define the HOC function
export default function withReduxProvider(
  Component: React.ComponentType<any>,
  compounds?: Record<string, React.ComponentType<any>>,
): any {
  // Create a function component that wraps the provided component with ReduxProvider
  const WrappedComponent = (props: any) => (
    <Provider store={store}>
      <Component {...props} />
    </Provider>
  );

  // If there are compound components, add them to the WrappedComponent
  if (compounds) {
    Object.entries(compounds).forEach(([name, compound]) => {
      WrappedComponent[name] = compound;
    });
  }

  // Return the wrapped component
  return WrappedComponent;
}
