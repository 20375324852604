import { useEffect } from "react";

import LoaderBusiness from "components/Loader/LoaderBusiness";
import { WalletV1 } from "./WalletV1/WalletV1";
import { WalletV2 } from "./WalletV2/WalletV2";

import { useMerchant } from "hooks/useMerchant";

export const WalletContainer = () => {
  const { merchantDetails, isLoading, getMerchantDetails } = useMerchant();

  // Objects will be undefined when api is in loading state
  const isFreelancer = !merchantDetails?.featureToggles?.cardsFeatureEnabled;
  const isWalletV2Enabled =
    merchantDetails?.featureToggles?.walletFeatureEnabled;

  useEffect(() => {
    getMerchantDetails();
  }, []);

  if (isLoading) {
    return (
      <section>
        <LoaderBusiness />
      </section>
    );
  }

  if (isWalletV2Enabled) {
    return <WalletV2 isFreelancer={isFreelancer} />;
  }
  return <WalletV1 />;
};
