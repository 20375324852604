import { isEmpty } from "lodash";

import Button from "components/Button/Button";
import routes from "constants/routes";
import { convertToLocaleStringHelper } from "helpers/currency";
import { BalanceTransactionRow, PaymentRow } from "./component/index";
import {
  type IBalanceTransaction,
  type IRevenue,
  type ISettlement,
  SettlementDetailsHeader,
  balancetTransactionPluralMapping,
  balancetTransactionSingularMapping,
} from "./index";

interface IProps {
  settlement: ISettlement;
}

var groupBy = function (array_object: any, key: any) {
  try {
    return array_object.reduce(function (attribute: any, index: any) {
      (attribute[index[key]] = attribute[index[key]] || []).push(index);
      return attribute;
    }, {});
  } catch (error: any) {
    console.error(`[SettlementDetails][groupBy] - ${error}`);
  }
};

var sumAmount = function (array_object: any, key: any) {
  try {
    return array_object.reduce(function (a: any, b: any) {
      return Number.parseFloat(a) + Number.parseFloat(b[key]);
    }, 0);
  } catch (error: any) {
    console.error(`[SettlementDetails][sumAmount] - ${error}`);
  }
};
export const SettlementDetails = (props: IProps) => {
  const {
    identifier,
    revenues,
    amountWithCurrency,
    balanceTransactions,
    totalRevenueFeeWithCurrency,
    totalRevenueChargeAmountWithCurrency,
  } = props.settlement;
  const paymentDisplayText = revenues.length > 1 ? "Payments" : "Payment";
  const feeDisplayText =
    revenues.length > 1 ? "Transaction fees" : "Transaction fee";
  const balanceTransactionGroupByKind = groupBy(balanceTransactions, "kind");

  const DisplayBalanceTransaction = ({ kind }: { kind: string }) => {
    if (isEmpty(kind)) {
      return <></>;
    }

    const totalAmount = sumAmount(
      Object.values(balanceTransactionGroupByKind[kind]),
      "amountWithoutCurrency",
    );
    const isPlural = balanceTransactionGroupByKind[kind].length > 1;

    if (kind === "vat") {
      return (
        <li className="settlement-details__summary__item">
          <p className="settlement-details__summary__item__label">
            {balancetTransactionSingularMapping[kind]}
          </p>
          <p className="settlement-details__summary__item__description">
            AED {convertToLocaleStringHelper(totalAmount)}
          </p>
        </li>
      );
    }

    return (
      <li className="settlement-details__summary__item">
        <p className="settlement-details__summary__item__label">
          {balancetTransactionPluralMapping[kind]}
        </p>
        <p className="settlement-details__summary__item__description">
          AED {convertToLocaleStringHelper(totalAmount)}
        </p>
      </li>
    );
  };

  const handleDownloadCsv = () => {
    window.location.href = routes.MANAGE.SETTLEMENTS.DOWNLOAD_CSV(identifier);
  };

  return (
    <>
      <SettlementDetailsHeader {...props.settlement} />
      <section className="settlement-details">
        {/* SUMMARY */}
        <div className="settlement-details__summary">
          <div className="settlement-details__summary__heading">
            <h3>Summary</h3>
          </div>

          <ul className="settlement-details__summary__list">
            {!isEmpty(revenues) && (
              <>
                {/* PAYMENTS */}
                <li className="settlement-details__summary__item">
                  <p className="settlement-details__summary__item__label">
                    {revenues.length} {paymentDisplayText}
                  </p>
                  <p className="settlement-details__summary__item__description">
                    {totalRevenueChargeAmountWithCurrency}
                  </p>
                </li>

                {/* FEES */}
                <li className="settlement-details__summary__item">
                  <p className="settlement-details__summary__item__label">
                    {feeDisplayText}
                  </p>
                  <p className="settlement-details__summary__item__description">
                    {totalRevenueFeeWithCurrency}
                  </p>
                </li>
              </>
            )}

            {/* BALANCE TRANSACTIONS */}
            {Object.keys(balanceTransactionGroupByKind).map((kind: string) => (
              <DisplayBalanceTransaction kind={kind} />
            ))}

            {/* TOTAL */}
            <li className="settlement-details__summary__item">
              <p className="settlement-details__summary__item__label">Total</p>
              <p className="settlement-details__summary__item__description">
                {amountWithCurrency}
              </p>
            </li>
          </ul>
        </div>

        {/* PAYMENTS LIST MOBILE:CARD / DESKTOP:TABLE */}
        <div className="space-y-12 xl:space-y-24 xl:rounded-16 xl:border xl:border-black-50 xl:bg-white xl:p-32">
          <div className="flex items-center justify-between">
            <h3>Transactions</h3>
            <Button
              color="secondary"
              size="sm"
              isIcon
              iconFont="download"
              onClick={handleDownloadCsv}
            />
          </div>
          <div className="table-container">
            <div className="table-container__header">
              <div className="table-container__header__row">
                <p className="table-container__heading">Reference ID</p>
                <p className="table-container__heading">Type</p>
                <p className="table-container__heading">Date</p>
                <p className="table-container__heading">Customer</p>
                <p className="table-container__heading">Amount</p>
                <p className="table-container__heading">VAT</p>
              </div>
            </div>

            <div className="table-container__body">
              {Object.values(revenues)?.map((i: IRevenue) => (
                <PaymentRow {...i} key={i?.identifier} />
              ))}
              {Object.values(balanceTransactions)?.map(
                (i: IBalanceTransaction) => (
                  <BalanceTransactionRow {...i} key={i?.identifier} />
                ),
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
