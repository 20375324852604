export function calculateGratitudeWithAmount(
  amount: number,
  percent?: number | null,
) {
  if (!percent || percent <= 0 || amount <= 0) return amount;

  return ((100 + percent) * amount) / 100;
}

export function calculateGratitude(amount: number, percent: number) {
  // if (!percent || percent <= 0 || amount <= 0) return amount;

  return amount * (percent / 100);
}

export function calculateWithQuantity(amount: number, quantity: number) {
  return amount * quantity;
}

export function decimalAmountToCents(amount: number, decimalPlaces = 2) {
  return Math.round(10 ** decimalPlaces * amount);
}

export function showCustomerDetails(paymentLink: any): boolean {
  return (
    paymentLink?.showFirstName &&
    paymentLink?.showLastName &&
    paymentLink?.showEmail &&
    paymentLink?.showPhoneNumber
  );
}

export const showEmailField = (paymentLink: any) => {
  if (paymentLink?.invoice) {
    return false;
  } else if (showCustomerDetails(paymentLink)) {
    return false;
  } else {
    return true;
  }
};

// helper function to determine the copy target based on session client
export const copyText = (text: string) => {
  window.Android
    ? window.Android.postMessage(`{"action":"copy", "message":"${text}"}`)
    : navigator.clipboard.writeText(text);
};

export const isIOS = (context: any): boolean => {
  const isIOS = context.platform == "IOS";
  return isIOS;
};

export const isAppSession = (context: any): boolean => {
  const isIOS = context.platform == "IOS";
  return isIOS || window.Android;
};

// ====================================================================
export const isAppSession_v2 = (platform: string): boolean => {
  const isIOS = platform == "IOS";
  return isIOS || window.Android;
};
// ====================================================================
