import type React from "react";

import type IRailsContext from "interfaces/IRailsContext";
import { POSLinkConfirmationContainer } from "./POSLinkConfirmationContainer";

type IProps = {};

export const POSLinkConfirmationRoot: React.FC<IProps> = (
  props: IProps,
  railsContext: IRailsContext,
) => {
  return (
    <POSLinkConfirmationContainer pos={props} railsContext={railsContext} />
  );
};
