import * as Dialog from "@radix-ui/react-dialog";
import Lottie from "lottie-react-web";
import { type FunctionComponent, useEffect } from "react";

import Button from "components/Button/Button";

import { convertToLocaleStringHelper } from "helpers/currency";
import { isAppPlatform } from "helpers/isAppPlatform";
import type IRailsContext from "interfaces/IRailsContext";
import { useGetPOSLinkDetails } from "../shared/hooks/useGetPOSLinkDetails";
import { useGetPOSQrCode } from "../shared/hooks/useGetPOSQrCode";
import { handleClose } from "../shared/utils/close";

import GreenCheckMark from "assets/animations/green-checkmark.json";
import LoaderBlue from "assets/animations/loader-blue.json";

interface IProps {
  pos: any;
  railsContext: IRailsContext;
}

export const POSLinkConfirmationContainer: FunctionComponent<IProps> = (
  props: IProps,
) => {
  const {
    getPOSLinkDetails,
    linkDetails,
    loading: linkLoading,
  } = useGetPOSLinkDetails();
  const { getQrCode, qrCodeUrl, loading: qrCodeLoading } = useGetPOSQrCode();

  useEffect(() => {
    getPOSLinkDetails(
      props.railsContext?.sessionContext?.currentMerchant?.identifier,
    );
    getQrCode(props.railsContext?.sessionContext?.currentMerchant?.identifier);
  }, []);

  return (
    <main className="create-link flex items-center justify-center">
      {!isAppPlatform() && (
        <button
          className="create-link__close-button material-icons-round"
          onClick={handleClose}
        >
          close
        </button>
      )}
      <section
        className="rounded-32 bg-white px-24 py-40 text-center"
        style={{ width: "40rem" }}
      >
        {linkLoading ? (
          <Lottie
            options={{
              animationData: LoaderBlue,
              loop: true,
            }}
            width={160}
            height={160}
          />
        ) : (
          <div className="space-y-24">
            <Lottie
              options={{
                animationData: GreenCheckMark,
                loop: false,
              }}
              width={80}
              height={80}
            />

            <div className="space-y-12 font-medium">
              <p className="text-24 leading-32">Your payment link is ready!</p>
              <p className="text-40 leading-48">
                <span className="text-38">AED </span>
                {convertToLocaleStringHelper(linkDetails?.amount)}
              </p>
            </div>

            <p className="text-16 text-black-300 leading-24">
              Your customers can tap or scan the QR code to open the payment
              link
            </p>

            <Dialog.Root modal>
              <Dialog.Trigger asChild>
                <Button
                  data-testid="show-pos-qr-code-btn"
                  color="secondary"
                  size="lg"
                  iconPosition="left"
                  iconFont="qr_code_2"
                  label="Show QR code"
                  classes="w-full"
                />
              </Dialog.Trigger>

              <Dialog.Portal>
                <Dialog.Overlay className="modal__overlay" />

                <div className="modal modal--full">
                  <Dialog.Content className="modal__box modal__box--full">
                    <Dialog.Close className="modal__box__close material-icons-round">
                      close
                    </Dialog.Close>

                    <div className="modal__box__content mx-auto md:h-512 md:w-512">
                      {qrCodeLoading ? (
                        <Lottie
                          options={{
                            animationData: LoaderBlue,
                            loop: true,
                          }}
                          width={160}
                          height={160}
                        />
                      ) : (
                        <img
                          src={qrCodeUrl}
                          alt={qrCodeUrl}
                          className="rounded-24"
                        />
                      )}
                    </div>
                  </Dialog.Content>
                </div>
              </Dialog.Portal>
            </Dialog.Root>
          </div>
        )}
      </section>
    </main>
  );
};
