import { type UseMutationResult, useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import ReactOnRails from "react-on-rails";

import { useAppSelector } from "store";

import { axiosClient } from "config/axios";
import { queryClient } from "config/reactQueryClient";
import { endpoints } from "constants/apiEndpoints";
import { generalErrors } from "constants/errorMessages";
import { SentryLoggingService } from "init/SentryLoggingService";
import type { ExpenseAccountsWithPagination } from "types/accounting/ExpenseAccount";

type BulkUpdateExpenseAccountsPayload = {
  identifiers: string[];
  enabled: boolean;
};

export const useBulkUpdateExpenseAccounts = (): {
  bulkUpdateExpenseAccounts: UseMutationResult<
    void,
    unknown,
    BulkUpdateExpenseAccountsPayload,
    unknown
  >;
} => {
  /* ---------------------------------
   *  Cache
   * ---------------------------------
   */
  const { table } = useAppSelector((state) => state);

  /* ---------------------------------
   *  Cache
   * ---------------------------------
   */
  const page = table.expenseAccounts.page;
  const queryKey = ["getExpenseAccounts", { page }];

  /* ---------------------------------
   *  API CALL
   * ---------------------------------
   */
  const bulkUpdateExpenseAccounts = useMutation<
    void,
    unknown,
    BulkUpdateExpenseAccountsPayload
  >({
    mutationKey: ["bulkUpdateExpenseAccounts"],
    mutationFn: async (payload: BulkUpdateExpenseAccountsPayload) => {
      const url =
        endpoints.MANAGE.API.ACCOUNTING_INTEGRATIONS
          .BULK_UPDATE_EXPENSE_ACCOUNTS;

      await axiosClient.patch(url, {
        identifiers: payload.identifiers,
        enabled: payload.enabled,
        authenticity_token: ReactOnRails.authenticityToken(),
      });
    },
    onMutate: async (payload: BulkUpdateExpenseAccountsPayload) => {
      await queryClient.cancelQueries({ queryKey });

      const previousData = queryClient.getQueryData(queryKey);

      queryClient.setQueryData(
        queryKey,
        (old: ExpenseAccountsWithPagination) => {
          return {
            data: old?.data?.map((account) =>
              payload.identifiers.includes(account.identifier)
                ? { ...account, enabled: payload.enabled }
                : account,
            ),
            paginationMeta: old?.paginationMeta,
          };
        },
      );

      return { previousData };
    },
    onError: (
      error: any,
      _,
      context: { previousData: ExpenseAccountsWithPagination },
    ) => {
      if (context?.previousData) {
        queryClient.setQueryData(queryKey, context.previousData);
      }
      SentryLoggingService.captureException(error.message, {
        feature: "[Accounting][AccountingDashboard]",
        file: "useBulkUpdateExpenseAccounts.ts",
        function: "bulkUpdateExpenseAccounts",
      });
      toast.error(generalErrors.SOMETHING_WENT_WRONG, {
        duration: 3000,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  return {
    bulkUpdateExpenseAccounts,
  };
};
