import routes from "constants/routes";

export const PERSONAL_DETAILS_PAGE =
  routes.MANAGE.VERIFICATION.PERSONAL_DETAILS();
export const BUSINESS_DETAILS_PAGE =
  routes.MANAGE.VERIFICATION.BUSINESS_DETAILS();
export const REVENUE_INFO_PAGE =
  routes.MANAGE.VERIFICATION.REVENUE_INFORMATION();
export const SPEND_INFO_PAGE = routes.MANAGE.VERIFICATION.SPEND_INFORMATION();
export const TRADE_LICENSE_PAGE = routes.MANAGE.VERIFICATION.TRADE_LICENSE();
export const MEMORANDUM_PAGE = routes.MANAGE.VERIFICATION.MEMORANDUM();
export const PROOF_OF_ADDRESS_PAGE = routes.MANAGE.VERIFICATION.PROOF_ADDRESS();
export const OTHER_DOCUMENTS_PAGE =
  routes.MANAGE.VERIFICATION.OTHER_DOCUMENTS();
export const BANK_INFO_PAGE = routes.MANAGE.VERIFICATION.BANK_INFORMATION();
export const BUSINESS_OWNERS_PAGE =
  routes.MANAGE.VERIFICATION.BUSINESS_OWNERS();
export const SUCCESS_PAGE = routes.MANAGE.VERIFICATION.SUCCESS();
