import type { AnalyticsState } from "../Types/AnalyticsState";

export enum AnalyticsActionTypes {
  SET_DATA = "SET_DATA",
}

export const initialState = {
  analytics: [],
  paginationMeta: {
    page: 1,
    perPage: 10,
    totalPages: null,
    nextPage: null,
    prevPage: null,
    from: null,
    to: null,
    totalCount: null,
  },
  isLoading: true,
};

const analyticsReducer = (state: AnalyticsState, action: any) => {
  switch (action.type) {
    case AnalyticsActionTypes.SET_DATA:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        analytics: [...state.analytics, ...action.payload.data],
        paginationMeta: {
          ...state.paginationMeta,
          ...action.payload.paginationMeta,
        },
      };
    default:
      return state;
  }
};

export default analyticsReducer;
