import { useContext, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";

import { Divider } from "components/Divider";
import { TableRecord } from "components/Table/TableRecord";
import Table from "components/Table/V2/TableV2";
import { Switch } from "design_system/Inputs/Switch";
import { TaxRatesIsland } from "./Island/TaxRatesIsland";
import { TableEmptyState } from "./TableEmptyState";

import { useScreenDimension } from "hooks/useScreenDimension";
import { useAppSelector } from "store";
import type { TaxRateOption, TaxRatesWithPagination } from "types/TaxRate";
import { useTaxRatesTable } from "./useTaxRatesTable";

import { IslandContext } from "design_system/Island/IslandContext";

import { setSelectedRows } from "reducers/redux/TableReducer/tableReducer";
import { cn } from "utils/classNames";

type TaxRatesTableProps = {
  taxRates?: TaxRatesWithPagination;
};

export const TaxRatesTable = ({ taxRates }: TaxRatesTableProps) => {
  /* ---------------------------------
   *  Context APIS & CACHES
   * ---------------------------------
   */

  const {
    table: {
      taxRates: { selectedRows },
    },
  } = useAppSelector((state) => state);
  const { closeIsland, openIsland } = useContext(IslandContext);

  /* ---------------------------------
   *  Hooks
   * ---------------------------------
   */
  const dispatch = useDispatch();
  const { isMobile } = useScreenDimension();

  const { bulkUpdateTaxRates, COLUMNS, handleSetPage, handleToggleClick } =
    useTaxRatesTable();

  /* ---------------------------------
   *  Constants
   * ---------------------------------
   */
  const paginationMeta = {
    ...taxRates.paginationMeta,
    itemName: "Tax rates",
  };

  const columns = useMemo(() => COLUMNS, []);
  const tableData = useMemo(() => taxRates.data, [taxRates.data]);

  const isEmptyTable = !taxRates || taxRates.data?.length === 0;

  /* ---------------------------------
   *  Handlers
   * ---------------------------------
   */
  const handleRowSelections = (rows: TaxRateOption[]) => {
    dispatch(setSelectedRows({ tableName: "taxRates", selectedRows: rows }));
  };

  /* ---------------------------------
   *  Effects
   * ---------------------------------
   */

  // OPEN/CLOSE ISLAND ON ROW SELECTIONS
  useEffect(() => {
    if (selectedRows?.length > 0) {
      openIsland();
    } else closeIsland();
  }, [selectedRows]);

  if (!isMobile) {
    return (
      <div className="flex w-full justify-center">
        <Table
          name="taxRates-table"
          // @ts-ignore
          columns={columns}
          data={tableData}
          paginationMeta={paginationMeta}
          onSetPage={handleSetPage}
          tableClass="table"
          headerRowClass="bg-black-25"
          headerCellClass="w-auto"
          rowProps={{
            selectable: true,
            selectedRows,
            setSelectedRows: handleRowSelections,
          }}
          emptyState={<TableEmptyState />}
        />
        <TaxRatesIsland />
      </div>
    );
  }
  return isEmptyTable ? (
    <TableEmptyState />
  ) : (
    <>
      {taxRates.data.map((taxRate: TaxRateOption, index) => {
        const { enabled, name, totalTaxRate, identifier } = taxRate;
        return (
          <>
            <TableRecord
              key={identifier}
              variant="noAvatar"
              className={cn({ "-mt-16": index === 0 })}
            >
              <TableRecord.Column1 label={name} />
              <TableRecord.Column2
                label={
                  <div className="flex items-center justify-end">
                    {totalTaxRate}

                    <Switch
                      id={identifier}
                      checked={enabled}
                      disabled={bulkUpdateTaxRates.isLoading}
                      onClick={() =>
                        handleToggleClick({ identifier, enabled: !enabled })
                      }
                    />
                  </div>
                }
              />
            </TableRecord>
            <Divider />
          </>
        );
      })}
    </>
  );
};
