import { cn } from "utils/classNames";
import type { FooterProps } from "../types/FullPageLayout.types";

export const FooterLayout = ({ children, className }: FooterProps) => {
  return (
    <section
      className={cn(
        "flex justify-center px-40 py-12 md:justify-end md:py-24 lg:px-80",
        className,
      )}
    >
      {children}
    </section>
  );
};
