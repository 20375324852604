import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import arTranslation from "./ar/translation.json";
import enTranslation from "./en/translation.json";

// Translation files
const resources = {
  en: {
    translation: enTranslation,
  },
  ar: {
    translation: arTranslation,
  },
};

i18n
  .use(initReactI18next) // Connects i18next to React
  .init({
    resources,
    lng: "en", // Default language
    fallbackLng: "en", // Fallback language if translation is missing
    interpolation: {
      escapeValue: false, // React already does escaping
    },
    supportedLngs: ["en", "ar"], // Supported languages
  });

export default i18n;
