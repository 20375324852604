import isEmpty from "lodash/isEmpty";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import routes from "constants/routes";
import {
  initialState,
  updateData,
  updateErrorMessage,
} from "reducers/redux/Access/SignUp/signUpSlice";

export const useRedirectToSignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const redirectToSignUp = (email: string) => {
    if (isEmpty(email)) {
      navigate(routes.ONBOARDING.SIGN_UP(), {
        replace: true,
      });
      dispatch(updateData(initialState));
      dispatch(updateErrorMessage({ errorMessage: null }));
    }
  };

  return { redirectToSignUp };
};
