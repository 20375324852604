import type { Column } from "components/Table/V2/typeDefs";

import { TableCell } from "components/Table/TableCell";
import { NorthEast, SouthWest } from "design_system/Icons";
import { Tag } from "design_system/Tag";
import type { TagColors } from "design_system/Tag/typeDefs";
import {
  type WalletTransaction,
  WalletTransactionStatus,
} from "features/Wallet/WalletV2/typeDefs";

export const STATUS_MAP = {
  [WalletTransactionStatus.credited]: "Earned",
  [WalletTransactionStatus.debited]: "Transferred",
};

export const STATUS_COLOR_MAP = {
  [WalletTransactionStatus.credited]: "green",
  [WalletTransactionStatus.debited]: "red",
};

export const STATUS_ICON_MAP = {
  [WalletTransactionStatus.credited]: SouthWest,
  [WalletTransactionStatus.debited]: NorthEast,
};

export type UseCashbackTableProps = {
  onClick: (props: WalletTransaction) => void;
};
export const useCashbackTable = ({ onClick }: UseCashbackTableProps) => {
  const COLUMNS: Column<WalletTransaction>[] = [
    {
      header: "Activity",
      accessorKey: "activityName",
      cell: (props) => {
        const title = props.row.original.activityName || "Cashback";
        const id = props.row.original?.reference?.id;
        return (
          <TableCell
            onClick={() => onClick(props.row.original)}
            mainContent={title}
            secondaryContent={id}
          />
        );
      },
    },
    {
      header: "Date",
      accessorKey: "date",
      cell: (props) => {
        const date = props.row.original.date;
        return (
          <TableCell
            onClick={() => onClick(props.row.original)}
            mainContent={date}
          />
        );
      },
    },
    {
      header: "Amount",
      accessorKey: "amount",
      meta: {
        alignment: "end",
      },
      cell: (props) => {
        return (
          <TableCell
            mainContent={props.row.original.amountFormatted}
            onClick={() => onClick(props.row.original)}
            classNames={{
              mainContent: "text-right",
            }}
          />
        );
      },
    },
    {
      header: "Balance",
      accessorKey: "balance",
      meta: {
        alignment: "end",
      },
      cell: (props) => {
        return (
          <TableCell
            mainContent={props.row.original.balanceFormatted}
            onClick={() => onClick(props.row.original)}
            classNames={{
              mainContent: "text-right",
            }}
          />
        );
      },
    },
    {
      header: "Status",
      accessorKey: "active",
      cell: (props) => {
        const status = props.row.original.status;

        return (
          <TableCell
            mainContent={
              <Tag
                label={STATUS_MAP[status]}
                color={STATUS_COLOR_MAP[status] as TagColors}
                Icon={STATUS_ICON_MAP[status]}
              />
            }
          />
        );
      },
    },
  ];

  return {
    COLUMNS,
  };
};
