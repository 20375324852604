import type { ReactNode } from "react";
import { cn } from "utils/classNames";

export type ItemRowProps = {
  label: string;
  children: ReactNode;
  className?: string;
};

export const ItemRow = ({ label, children, className }: ItemRowProps) => {
  return (
    <div className={cn("grid grid-cols-2 py-8", className)}>
      <span className="body-large text-text-color-03">{label}</span>
      <span className="body-large text-text-color-01">{children}</span>
    </div>
  );
};
