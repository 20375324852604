import routes from "constants/routes";
import { useState } from "react";
import toast from "react-hot-toast";

import Button from "components/Button/Button";
import Toast from "components/ui/Toast";

import { generalErrors } from "constants/errorMessages";
import { isAppPlatform } from "helpers/isAppPlatform";
import { SentryLoggingService } from "init/SentryLoggingService";
import {
  BankAccountTypes,
  type IDisbursementManualState,
  Steps,
} from "reducers/newDisbursementsReducer";
import { useGenerateOtp } from "../hooks/useGenerateOtp";

interface IConfirmDisbursement {
  state: IDisbursementManualState;
  setCurrentStep: any;
  onPrevStep: any;
}

const ConfirmDisbursement = ({
  state,
  setCurrentStep,
  onPrevStep,
}: IConfirmDisbursement) => {
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const fullName = () => {
    if (state.bankAccountType === BankAccountTypes.BUSINESS) {
      return state.accountHolderName;
    }

    return `${state.firstName} ${state.lastName}`;
  };
  const { generateOtp } = useGenerateOtp();

  const onSubmit = () => {
    setIsCreating(true);
    generateOtp
      .mutateAsync({ purpose: "create_payout" })
      .then(() => {
        setIsCreating(false);
        setCurrentStep(Steps.VerificationCode);
      })
      .catch((error) => {
        setIsCreating(false);
        SentryLoggingService.captureException(error.message, {
          feature: "[Create Disbursement][Generate OTP]",
          file: "ConfirmDisbursement/index.tsx",
          function: "onSubmit",
          data: { purpose: "create_payout" },
        });
        toast.error(generalErrors.SOMETHING_WENT_WRONG, {
          duration: 3000,
        });
      });
  };

  const actionOnClick = (e: any) => {
    // When we focus on the description field and press the enter button.
    // It will trigger the action onclick in the form.
    // e.nativeEvent.pointerType will check type that you input
    // press enter => pointerType === ''
    if (e.nativeEvent.pointerType !== "") {
      onPrevStep();
    }
  };

  return (
    <>
      <Toast />
      <header>
        <button
          className="create-link__back-button material-icons-round"
          onClick={actionOnClick}
        >
          arrow_back
        </button>
        {!isAppPlatform() && (
          <a
            href={routes.MANAGE.DISBURSEMENTS.INDEX()}
            className="create-link__close-button material-icons-round"
          >
            close
          </a>
        )}
      </header>
      <section className="create-link__customize-link-step">
        {/* PROCESS COMPONENT */}
        <div className="process pb-80 md:pb-48">
          <div className="process__content">
            <h1 className="process__content__heading">Confirm payout</h1>
            <p className="process__content__info">
              Please review and confirm the information below
            </p>
          </div>

          <div className="space-y-32 md:space-y-48">
            {/* REVIEW PAYOUT TABLE */}
            <div className="review-disbursement">
              {/* TABLE FOR LARGE TABLETS, DESKTOPS AND ABOVE */}
              <div className="table-container hidden xl:table">
                <div className="table-container__header">
                  <div className="table-container__header__row">
                    <p className="table-container__heading">Method</p>
                    <p className="table-container__heading">Recipient</p>
                    <p className="table-container__heading">Name</p>
                    <p className="table-container__heading">Amount</p>
                  </div>
                </div>

                <div className="table-container__body text-14">
                  <div className="table-container__row table-container__row--settlement">
                    <div className="table-container__data-group">
                      <div className="table-container__data">
                        <p>Bank account</p>
                      </div>

                      <div className="table-container__data">
                        <p>{state.iban}</p>
                      </div>

                      <div className="table-container__data">
                        <p>{fullName()}</p>
                      </div>

                      <div className="table-container__data">
                        <p>AED {state.amount}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* CARD FOR MOBILE AND SMALL TABLETS */}
              <div className="card card--border flex flex-col space-y-4 xl:hidden">
                <div className="flex justify-between font-medium text-14">
                  <p>{fullName()}</p>
                  <p>AED {state.amount}</p>
                </div>
                <p className="text-12 text-black-300">
                  {state.email}
                  {state.iban}
                </p>
              </div>
            </div>

            {/* LARGE BUTTON FOR TABLET AND ABOVE */}
            <div className="hidden md:flex">
              <Button
                color="primary"
                size="lg"
                label="Confirm payout"
                classes="mx-auto"
                onClick={onSubmit}
                isDisabled={isCreating}
              />
            </div>

            {/* MEDIUM BUTTON FOR MOBILE */}
            <div className="md:hidden">
              <Button
                color="primary"
                size="md"
                label="Confirm payout"
                classes="w-full"
                onClick={onSubmit}
                isDisabled={isCreating}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ConfirmDisbursement;
