import { Suspense, lazy } from "react";

import { useScreenDimension } from "hooks/useScreenDimension";
import { ArticlesLoader, VideoLoader } from "./loader";

const LazyHelpfulArticles = lazy(() =>
  import("./HelpfulArticles").then((module) => ({
    default: module.HelpfulArticles,
  })),
);
const LazyHelpfulVideos = lazy(() =>
  import("./HelpfulVideos").then((module) => ({
    default: module.HelpfulVideos,
  })),
);

export const Aside = () => {
  const { isMobile } = useScreenDimension();

  return (
    <section
      className="absolute top-0 left-0 w-full space-y-48 border-black-50 border-l px-16 py-24 pb-80 md:p-24 md:pb-0"
      style={{ height: !isMobile ? "calc(100svh - 6.4rem)" : "auto" }}
    >
      <Suspense fallback={<VideoLoader />}>
        <LazyHelpfulVideos />
      </Suspense>

      <Suspense fallback={<ArticlesLoader />}>
        <LazyHelpfulArticles />
      </Suspense>
    </section>
  );
};
