import { type QueryObserverResult, useQuery } from "@tanstack/react-query";
import axios from "axios";
import get from "lodash/get";
import qs from "qs";

import { tableInitValues } from "components/Table/V2/TableV2Context";
import { endpoints } from "constants/apiEndpoints";
import { queryParams } from "constants/queryParams";
import { PAGINATION_META_INIT_STATE } from "constants/table";
import { SentryLoggingService } from "init/SentryLoggingService";
import { USERS_INIT_STATE } from "../../Dashboard/constants/initStates";

interface UseGetUsers {
  page?: number;
}

export const useGetUsers = (params?: UseGetUsers) => {
  const page = params.page || 1;

  const getUsers: QueryObserverResult<any> = useQuery({
    queryKey: ["getUsers", { page }],
    queryFn: async () => {
      try {
        const userQueryParams = {
          ...queryParams,
          page,
          per_page: tableInitValues.pageSize,
        };

        const url = `${endpoints.MANAGE.API.SETTINGS.TEAM.USERS}?${qs.stringify(
          { ...userQueryParams },
          {
            skipNulls: true,
            arrayFormat: "brackets",
          },
        )}`;

        const response = await axios.get(url);
        const users = get(response, "data", USERS_INIT_STATE);

        return users;
      } catch (error: any) {
        SentryLoggingService.captureException(error.message, {
          feature: "[Team][Dashboard]",
          file: "useGetUsers.ts",
          function: "useGetUsers",
          data: JSON.stringify(params),
        });
        return {
          data: {
            data: [],
            paginationMeta: PAGINATION_META_INIT_STATE,
          },
        };
      }
    },
  });

  return {
    getUsers,
  };
};
