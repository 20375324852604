import { useContext, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";

import { Divider } from "components/Divider";
import { TableRecord } from "components/Table/TableRecord";
import Table from "components/Table/V2/TableV2";
import { Switch } from "design_system/Inputs/Switch";
import { VendorsIsland } from "./Island/VendorsIsland";
import { TableEmptyState } from "./TableEmptyState";

import { useScreenDimension } from "hooks/useScreenDimension";
import { useAppSelector } from "store";
import type { VendorOption, VendorsWithPagination } from "types/Vendor";
import { useVendorsTable } from "./useVendorsTable";

import { IslandContext } from "design_system/Island/IslandContext";

import { setSelectedRows } from "reducers/redux/TableReducer/tableReducer";
import { cn } from "utils/classNames";

type VendorsTableProps = {
  vendors?: VendorsWithPagination;
};

export const VendorsTable = ({ vendors }: VendorsTableProps) => {
  /* ---------------------------------
   *  Context APIS & CACHES
   * ---------------------------------
   */

  const {
    table: {
      vendors: { selectedRows },
    },
  } = useAppSelector((state) => state);
  const { closeIsland, openIsland } = useContext(IslandContext);

  /* ---------------------------------
   *  Hooks
   * ---------------------------------
   */
  const dispatch = useDispatch();
  const { isMobile } = useScreenDimension();

  const { bulkUpdateVendors, COLUMNS, handleSetPage, handleToggleClick } =
    useVendorsTable();

  /* ---------------------------------
   *  Constants
   * ---------------------------------
   */
  const paginationMeta = {
    ...vendors.paginationMeta,
    itemName: "Vendors",
  };

  const columns = useMemo(() => COLUMNS, []);
  const tableData = useMemo(() => vendors.data, [vendors.data]);
  const isEmpty = !vendors || vendors.data.length === 0;

  /* ---------------------------------
   *  Handlers
   * ---------------------------------
   */
  const handleRowSelections = (rows: VendorOption[]) => {
    dispatch(setSelectedRows({ tableName: "vendors", selectedRows: rows }));
  };

  /* ---------------------------------
   *  Effects
   * ---------------------------------
   */

  // OPEN/CLOSE ISLAND ON ROW SELECTIONS
  useEffect(() => {
    if (selectedRows?.length > 0) {
      openIsland();
    } else closeIsland();
  }, [selectedRows]);

  if (!isMobile) {
    return (
      <div className="flex w-full justify-center">
        <Table
          name="vendors-table"
          // @ts-ignore
          columns={columns}
          data={tableData}
          paginationMeta={paginationMeta}
          onSetPage={handleSetPage}
          tableClass="table"
          headerRowClass="bg-black-25"
          headerCellClass="w-auto"
          rowProps={{
            selectable: true,
            selectedRows,
            setSelectedRows: handleRowSelections,
          }}
          emptyState={<TableEmptyState />}
        />
        <VendorsIsland />
      </div>
    );
  }
  return isEmpty ? (
    <TableEmptyState />
  ) : (
    <>
      {vendors.data.map((vendor: VendorOption, index) => {
        const { currency, enabled, name, identifier } = vendor;
        return (
          <>
            <TableRecord
              key={identifier}
              variant="noAvatar"
              className={cn({ "-mt-16": index === 0 })}
            >
              <TableRecord.Column1 label={name} />
              <TableRecord.Column2
                label={
                  <div className="flex items-center justify-end">
                    {currency}

                    <Switch
                      id={identifier}
                      checked={enabled}
                      disabled={bulkUpdateVendors.isLoading}
                      onClick={() =>
                        handleToggleClick({ identifier, enabled: !enabled })
                      }
                    />
                  </div>
                }
              />
            </TableRecord>
            <Divider />
          </>
        );
      })}
    </>
  );
};
