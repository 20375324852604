export const EXPENSE_DETAIL_FIELDS_INIT_STATE = {
  attachments: [],
  category: null,
  description: "",
  expenseAccount: null, //Filled from effect
  invoiceNumber: "",
  paymentAccount: null, //Filled from effect
  taxRate: null, //Filled from effect
  vendor: null, //Filled from effect
};
