import React from "react";

import { TableCell } from "components/Table/TableCell";
import { Dropdown } from "design_system/Dropdown/DropdownV2";
import { CheckCircle, MoreVert, Pending } from "design_system/Icons";
import { Tag } from "design_system/Tag";

import type { TeamMember, TeamMemberRole } from "types/Team";
import { useUserOptions } from "./useUserOptions";

type ColumnCell = {
  row: {
    original: TeamMember;
  };
};

export const useTeamTable = () => {
  const COLUMNS = [
    {
      header: "Name",
      accessorKey: "team.fullName",
      cell: (props: ColumnCell) => (
        <TableCell mainContent={props.row.original.fullName} />
      ),
    },
    {
      header: "Email",
      accessorKey: "team.email",
      cell: (props: ColumnCell) => (
        <TableCell mainContent={props.row.original.email} />
      ),
    },
    {
      header: "Role",
      accessorKey: "team.roles",
      cell: (props: ColumnCell) => (
        <TableCell
          mainContent={props.row.original.roles.map(
            (role: TeamMemberRole, index: number) => (
              <React.Fragment key={role.id}>
                {role.name}
                {index < props.row.original.roles.length - 1 && ", "}
              </React.Fragment>
            ),
          )}
        />
      ),
    },
    {
      header: "Status",
      accessorKey: "team.status",
      cell: (props: ColumnCell) => {
        const { isPending } = props.row.original;
        const { getOptions } = useUserOptions();
        const options = getOptions(props.row.original);

        return (
          <TableCell
            mainContent={
              <div className="flex items-center justify-between">
                <Tag
                  color={isPending ? "blue" : "green"}
                  label={isPending ? "Invite pending" : "Active"}
                  Icon={isPending ? Pending : CheckCircle}
                />
                <Dropdown
                  type="icon-button"
                  iconButtonProps={{
                    size: "md",
                    Icon: MoreVert,
                  }}
                  showTooltipOnButton={false}
                  options={options}
                />
              </div>
            }
          />
        );
      },
    },
  ];

  return {
    COLUMNS,
  };
};
