import { cn } from "utils/classNames";

import { CheckCircleFill } from "design_system/Icons";

import { colorsV2 } from "constants/colors";

export interface InputMessageProps {
  active?: boolean;
  label: string;
}

export const InputMessage = ({ active, label }: InputMessageProps) => {
  return (
    <div
      className={cn(
        "body-small flex w-fit-content items-center gap-4 rounded-8 p-8",
        {
          "bg-surface-2 text-text-color-02": !active,
          "bg-accent-green-02 text-text-color-01": active,
        },
      )}
    >
      <CheckCircleFill
        size="18"
        fill={active ? colorsV2.accent.green[1] : colorsV2.surface[4]}
      />
      <span>{label}</span>
    </div>
  );
};
