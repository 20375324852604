import { createContext } from "react";

import type { TBusinessDetails } from "../types";
export interface IBusinessDetailsContext {
  details: any;
  setDetails: (state: TBusinessDetails, key?: string) => void;
}

export const initState: IBusinessDetailsContext = {
  details: {
    _key: "",
    address: "",
    city: "",
    country: "",
    name: "",
    paymentUrlSlug: "",
    replyTo: "",
    trn: "",
    websiteUrl: "",
  },
  setDetails: () => {},
};

export const BusinessDetailsContext =
  createContext<IBusinessDetailsContext>(initState);

export const BusinessDetailsProvider = ({
  children,
  value,
}: { children: any; value: IBusinessDetailsContext }) => {
  return (
    <BusinessDetailsContext.Provider value={value}>
      {children}
    </BusinessDetailsContext.Provider>
  );
};
