import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import toast from "react-hot-toast";

// import { CREDIT_CARD_ACTIVITIES_MOCK } from "../../mocks/CreditActivities.mock";
import { endpoints } from "constants/apiEndpoints";
import { generalErrors } from "constants/errorMessages";
import { SentryLoggingService } from "init/SentryLoggingService";
import { capitalizeRepaymentStatusMapping } from "../../constants/creditRepayments";
// import type { GetCreditCardDetail } from "../typeDefs";

export const useGetCreditCardActivities = () => {
  const getCreditCardActivities = useQuery({
    queryKey: ["getCreditCardActivities"],
    queryFn: async () => {
      try {
        const { data } = await axios.get(
          endpoints.MANAGE.API.CREDIT_ACCOUNTS.ACTIVITIES,
        );

        // const data = CREDIT_CARD_ACTIVITIES_MOCK;
        return {
          data: formatRepayments(data.data),
          paginationMeta: data.paginationMeta,
        };
      } catch (error: any) {
        toast.error(generalErrors.SOMETHING_WENT_WRONG, {
          duration: 3000,
        });
        SentryLoggingService.captureException(error.message, {
          feature: "[Wallets V2][Credit Card Detail]",
          file: "useGetCreditCardActivities.tsx",
          function: "useGetCreditCardActivities",
        });
        return undefined;
      }
    },
  });

  return {
    getCreditCardActivities,
  };
};

function formatRepayments(repayments) {
  try {
    return repayments.map((record) => {
      return {
        ...record,
        status: capitalizeRepaymentStatusMapping[record.status],
      };
    });
  } catch (error) {
    SentryLoggingService.captureException(error.message, {
      feature: "[Wallets V2][Credit Card Detail]",
      file: "useGetRepaymentTransactions.tsx",
      function: "formatRepayments",
    });

    return [];
  }
}
