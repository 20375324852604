import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import type {
  CurrentMerchant,
  MerchantInfo,
  MerchantState,
} from "types/MerchantInfo";

export type MerchantSlice = MerchantState & {
  isMCSPlatform: boolean;
};

const initialState: MerchantSlice = {
  currentMerchant: undefined,
  currentUser: undefined,
  featureToggles: undefined,
  isMCSPlatform: false,
};

export const merchantSlice = createSlice({
  name: "merchant",
  initialState,
  reducers: {
    setMerchantData(state, action: PayloadAction<MerchantState>) {
      return {
        ...state,
        ...action.payload,
        isMCSPlatform: action.payload.currentMerchant?.cardPlatform === "mcs",
      };
    },
    updateCurrentMerchant(
      state,
      action: PayloadAction<Partial<CurrentMerchant>>,
    ) {
      if (state.currentMerchant) {
        state.currentMerchant = { ...state.currentMerchant, ...action.payload };
      }
    },
    updateCurrentUser(
      state,
      action: PayloadAction<Partial<MerchantInfo["currentUser"]>>,
    ) {
      if (state.currentUser) {
        state.currentUser = { ...state.currentUser, ...action.payload };
      }
    },
    updateFeatureToggles(
      state,
      action: PayloadAction<Partial<MerchantInfo["featureToggles"]>>,
    ) {
      if (state.featureToggles) {
        state.featureToggles = { ...state.featureToggles, ...action.payload };
      }
    },
    resetMerchantState(state) {
      return initialState;
    },
  },
});

export const {
  setMerchantData,
  updateCurrentMerchant,
  updateCurrentUser,
  updateFeatureToggles,
  resetMerchantState,
} = merchantSlice.actions;

export default merchantSlice.reducer;
