import { useNavigate, useSearchParams } from "react-router-dom";

import { cn } from "utils/classNames";

import { ArrowBack } from "design_system/Icons";
import { Snackbar } from "design_system/Snackbar";

import { routes } from "constants/routes";
import { CodeVerificationForm } from "features/FirstTimeLogin/_views/CodeVerificationStep/Form";

export const CodeVerificationStep = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const phoneNumber = searchParams.get("phone_number");

  if (!phoneNumber) {
    navigate(routes.MANAGE.FIRST_TIME_LOGIN.PHONE());
  }

  return (
    <div className="grid w-full grid-cols-1 gap-24">
      <a
        className="cursor-pointer"
        href={routes.MANAGE.FIRST_TIME_LOGIN.PHONE()}
      >
        <ArrowBack size="18px" />
      </a>

      {/* Header */}
      <div className="grid grid-cols-1 gap-8 text-center">
        <span className={cn("header-medium text-text-color-01")}>
          Verify your phone number
        </span>
        <span className={cn("body-medium text-text-color-0")}>
          Enter the code you received at {decodeURIComponent(phoneNumber)}
        </span>
      </div>

      <CodeVerificationForm />
      <Snackbar />
    </div>
  );
};
