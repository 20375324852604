export const bytesToSize = (fileSize: any) => {
  const sizes: string[] = ["Bytes", "KB", "MB", "GB", "TB"];

  if (fileSize === 0) return "";

  const i: number = Number.parseInt(
    Math.floor(Math.log(fileSize) / Math.log(1024)).toString(),
  );

  if (i === 0) return `${fileSize} ${sizes[i]}`;

  return `${(fileSize / 1024 ** i).toFixed(1)} ${sizes[i]}`;
};
