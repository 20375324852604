import { type HTMLProps, useState } from "react";

import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import { cn } from "utils/classNames";
import FieldMessage from "../FieldMessage/FieldMessage";

interface IProps extends HTMLProps<HTMLInputElement> {
  classes?: string;
  inputId?: string;
  autoComplete?: string;
  labelText?: string;
  maxLength?: any;
  maxLengthMsg?: string;
  hideReplay?: boolean;
  errors?: any;
  errorMessage?: string;
  onFocusText?: string;
  isDisabled?: boolean;
  register?: any;
}

const TextInputWithCircularProgress = ({
  classes,
  inputId,
  autoComplete,
  labelText,
  maxLength,
  maxLengthMsg,
  hideReplay = true,
  errors,
  errorMessage,
  onFocusText = " ",
  isDisabled,
  register,
  ...nativeProps
}: IProps) => {
  const [character, setCharacter] = useState<any>(0);

  return (
    <div className="form-field">
      <div className={cn("form-field__control", classes)}>
        <div className="relative">
          <input
            className={cn(
              "form-field__input",
              {
                "form-field__input--error": errors,
                "sentry-mask [data-sentry-mask]": hideReplay,
                "sentry-unmask [data-sentry-unmask]": !hideReplay,
              },
              classes,
            )}
            id={inputId || nativeProps.name}
            autoComplete={autoComplete}
            placeholder=" "
            maxLength={maxLength}
            onFocus={(e) => (e.target.placeholder = onFocusText)}
            onBlurCapture={(e) => (e.target.placeholder = " ")}
            onChange={(e: any) => setCharacter(e.target.value.length)}
            disabled={isDisabled}
            ref={register}
            data-testid={nativeProps.name}
            {...nativeProps}
          />
          {labelText && (
            <label htmlFor={inputId} className="form-field__label">
              {labelText}
            </label>
          )}
          {character > 0 && (
            <div className={`absolute right-0 bottom-12 h-32 w-32`}>
              <CircularProgressbar
                maxValue={maxLength}
                value={character}
                text={`${maxLength - character}`}
                styles={{
                  path: {
                    stroke: `rgba(51, 51, 255, ${character / maxLength})`,
                  },
                  trail: {
                    stroke: "#E5E9F0",
                  },
                  text: {
                    fill: "#9BA1B3",
                    fontSize: "2.8rem",
                    fontFamily: "inherit",
                    marginTop: ".8rem",
                  },
                }}
              />
            </div>
          )}
        </div>

        {character === maxLength && maxLengthMsg && (
          <p className="mt-8 block text-12 text-black-200">{maxLengthMsg}</p>
        )}

        {errors && (
          <FieldMessage
            messageType="error"
            icon="error"
            messageText={errorMessage}
          />
        )}
      </div>
    </div>
  );
};

export default TextInputWithCircularProgress;
