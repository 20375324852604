import { posIdentifiers } from "constants/posIdentifiers";

export const posLinkIdentifier = (merchantIdentifier: string | undefined) => {
  if (posIdentifiers[merchantIdentifier!] != null) {
    return posIdentifiers[merchantIdentifier!].linkIdentifier;
  }

  return "";

  // NOTE: BELOW IS DEV (LOCALHOST) IDENTIFIER. UNCOMMENT/CHANGE WHEN YOU WANT TO TEST
  // return "MB-LINK-91D9189907";
};
