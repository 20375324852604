export const MerchantOnboardingStatus = {
  not_complete: "not_complete",
  pending_verification: "pending_verification",
  verified: "verified",
  failed_verification: "failed_verification",
  suspended: "suspended",
  on_hold: "on_hold",
} as const;

export type MerchantOnboardingStatus =
  (typeof MerchantOnboardingStatus)[keyof typeof MerchantOnboardingStatus];
