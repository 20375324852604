import routes from "constants/routes";

export const handleGoToCreateInvoice = () => {
  localStorage.removeItem("duplicatedInvoiceId");
  window.location.href = routes.MANAGE.INVOICES.NEW();
};

export const handleDuplicateInvoice = (identifier: string) => {
  localStorage.setItem("duplicatedInvoiceId", identifier);
  window.location.href = routes.MANAGE.INVOICES.NEW();
};

export const handleCloseToInvoices = () => {
  localStorage.removeItem("duplicatedInvoiceId");
  window.location.href = routes.MANAGE.INVOICES.INDEX();
};
