import type { RenderFunctionResult } from "react-on-rails/node_package/lib/types";
import { Provider } from "react-redux";

import { ReactQueryProvider } from "components/Providers";
import TeamContainer from "./TeamContainer";

import type IRailsContext from "interfaces/IRailsContext";
import { CurrentUserProvider } from "providers/current-user.provider";
import { store } from "store";

export default (
  props: any,
  railsContext: IRailsContext,
): RenderFunctionResult => {
  const { currentUser } = railsContext.sessionContext;
  return () => (
    <ReactQueryProvider>
      <Provider store={store}>
        <CurrentUserProvider currentUser={currentUser}>
          <TeamContainer props={props} railsContext={railsContext} />
        </CurrentUserProvider>
      </Provider>
    </ReactQueryProvider>
  );
};
