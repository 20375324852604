import { useNavigate } from "react-router-dom";

import { VisaCardWithBackground } from "design_system/Icons";
import { MasterHeader } from "design_system/MasterHeader";

import { useScreenDimension } from "hooks/useScreenDimension";

import routes from "constants/routes";
// import type { WalletV2HeaderProps } from "../typeDefs";

export const PageHeader = ({ availableCredit }) => {
  const navigate = useNavigate();
  const { isMobile } = useScreenDimension();

  const handleBackButtonClick = () => {
    navigate(routes.MANAGE.WALLET.INDEX());
  };

  return (
    <MasterHeader onBack={handleBackButtonClick}>
      <MasterHeader.Image>
        <VisaCardWithBackground size="56" variant="credit" />
      </MasterHeader.Image>
      <MasterHeader.Label>Available credit</MasterHeader.Label>
      <MasterHeader.Title>{availableCredit}</MasterHeader.Title>
    </MasterHeader>
  );
};
