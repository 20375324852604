import EmptyStateIllustration from "assets/illustrations/payments-empty-state.svg";
import EmptyState from "components/EmptyState/EmptyStateV2";

export const TableEmptyState = () => {
  return (
    <EmptyState
      data-testid="empty-state-wrapper"
      imgSrc={EmptyStateIllustration}
      title="No repayments to show at the moment"
      info="Once you start utilizing your credit, you'll be able to see a list of upcoming repayments due."
    />
  );
};
