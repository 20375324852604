import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import qs from "qs";
import ReactOnRails from "react-on-rails";

import { endpoints } from "constants/apiEndpoints";
import type { TBusinessDetails } from "../types";

export const useUpdateBusinessDetails = () => {
  const queryClient = useQueryClient();

  const url = endpoints.MANAGE.API.SETTINGS.BUSINESS.UPDATE_DETAILS;
  const queryParams = {
    authenticity_token: ReactOnRails.authenticityToken(),
  };

  const updateDetails: any = useMutation({
    mutationKey: ["updateBusinessDetails"],
    mutationFn: (updateDetail: TBusinessDetails) => {
      const requestData = {
        name: updateDetail.name,
        reply_to: updateDetail.replyTo,
        address: updateDetail.address,
        city: updateDetail.city,
        country: updateDetail.country,
        uae_tax_reference_number: updateDetail.trn,
      };
      return axios.patch(`${url}?${qs.stringify(queryParams)}`, requestData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getBusinessDetails"] });
    },
  });

  return {
    updateDetails,
  };
};
