import { Provider } from "react-redux";

import { ReactQueryProvider } from "components/Providers";
import { TopBarContainer } from "./TopBarContainer";

import { isAppPlatform } from "helpers/isAppPlatform";
import { store } from "store";

export const TopBarRoot = () => {
  if (isAppPlatform()) {
    return <></>;
  }

  return (
    <ReactQueryProvider>
      <Provider store={store}>
        <TopBarContainer />
      </Provider>
    </ReactQueryProvider>
  );
};
