import type Role from "interfaces/iRole";

interface IUsePermission {
  hasPermission: (permission: string, role: Role) => boolean;
}

/**
 * determine if a role has a permission
 * @returns {IUsePermission} An object containing the hasPermission function.
 */
export const useGetRole = (): IUsePermission => {
  const hasPermission = (permission: string, role: Role) => {
    if (role === null || role === undefined) {
      return false;
    }

    if (role.name === "Owner") {
      return true;
    }

    return role.permissions.includes(permission);
  };

  return {
    hasPermission,
  };
};
