export const NoResultsEmptyState = () => {
  return (
    <section className="my-40 space-y-4 text-center">
      <p className="label-large">No results to show</p>
      <p className="body-medium text-text-color-02">
        Try using different keywords or adjusting your filters
      </p>
    </section>
  );
};
