import routes from "constants/routes";

const ProcessingFile = () => {
  return (
    <>
      <section className="create-link__customize-link-step">
        {/* PROCESS COMPONENT */}
        <div className="process flex flex-col items-center">
          <div className="process__icon process__icon--blue">watch_later</div>
          <div className="process__content mb-0">
            <h1 className="process__content__heading">You&rsquo;re all set!</h1>
            <p className="process__content__info">
              The payout will take up to one business day to process.
              We&rsquo;ll notify you when the payment is complete.
            </p>
          </div>
          <div className="hidden justify-center md:flex">
            <a
              href={routes.MANAGE.DISBURSEMENTS.INDEX()}
              className="button button--primary button--solid button--lg"
            >
              Got it!
            </a>
          </div>
        </div>
        <div className="absolute right-20 bottom-20 left-20 md:hidden">
          <a
            href={routes.MANAGE.DISBURSEMENTS.INDEX()}
            className="button button--primary button--solid button--md w-full"
          >
            Got it!
          </a>
        </div>
      </section>
    </>
  );
};

export default ProcessingFile;
