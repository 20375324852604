import { useMemo } from "react";
import { cn } from "utils/classNames";

import { Info } from "design_system/Icons";

import { colorsV2 } from "constants/colors";

interface IInfoCardProps {
  description?: React.ReactNode | string;
  showIcon?: boolean;
  title?: string;
  variant?: "info" | "critical" | "attention";
}

export const InfoCard = ({
  description,
  showIcon = true,
  title,
  variant = "info",
}: IInfoCardProps) => {
  const fill = useMemo(() => {
    switch (true) {
      case variant === "critical": {
        return colorsV2.accent.red[1];
      }
      case variant === "attention": {
        return colorsV2.accent.yellow[2];
      }
      default:
        return colorsV2.text[3];
    }
  }, [variant]);

  return (
    <div
      className={cn("rounded-8 p-12", {
        "bg-surface-2": variant === "info",
        "bg-accent-red-04": variant === "critical",
        "bg-accent-yellow-03": variant === "attention",
      })}
    >
      <div className="flex gap-8">
        {showIcon && (
          <div className="py-2">
            <Info size="18" fill={fill} />
          </div>
        )}
        <div className="space-y-4">
          {title && <p className="label-medium">{title}</p>}
          {description && (
            <p
              className={cn("body-medium", {
                "text-text-color-02": variant !== "attention",
              })}
            >
              {description}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
