import PremiumTrialIllustration from "assets/illustrations/premium-trial-illustration.svg";
import type { ITrialStartModalProps } from "interfaces/ITrialStartModalProps";
import { type FunctionComponent, useState } from "react";
import { cn } from "utils/classNames";

const TrialStartModal: FunctionComponent<ITrialStartModalProps> = (
  props: ITrialStartModalProps,
) => {
  const [show, setShow] = useState<boolean>(props.show);
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);

  return (
    <>
      {show ? (
        <div className={cn("modal", { hidden: !imageLoaded })}>
          <div className="modal__box">
            <i
              className="modal__box__close material-icons-round"
              onClick={() => setShow(false)}
            >
              close
            </i>

            <div className="mb-24 w-full md:mb-32">
              <img
                onLoad={() => setImageLoaded(true)}
                src={PremiumTrialIllustration}
                alt="Happy man dancing illustration"
                className="mx-auto"
              />
            </div>

            <div className="modal__box__content">
              <h1 className="modal__box__content__title">
                Customized branding, QR codes and more!
              </h1>
              <p className="modal__box__content__text">
                Explore our Premium plan where you can customize your payment
                link with your own branding and more. Start exploring now with
                your {props.trialRemainingDays}-day free trial.
              </p>

              <div className="w-full">
                <button
                  className="button button--primary button--solid button--lg w-full"
                  onClick={() => setShow(false)}
                >
                  <span>Start exploring</span>
                </button>
              </div>
            </div>
          </div>
          <div className="modal__overlay"></div>
        </div>
      ) : null}
    </>
  );
};

export default TrialStartModal;
