import { MasterHeader } from "design_system/MasterHeader";

import { isAppPlatform } from "helpers/isAppPlatform";
import { handleGoBackToSettings } from "../shared/utils";

export const PageHeader = () => {
  if (isAppPlatform()) {
    return <></>;
  }

  return (
    <MasterHeader onBack={handleGoBackToSettings}>
      <MasterHeader.Label>Settings</MasterHeader.Label>
      <MasterHeader.Title>Plan</MasterHeader.Title>
    </MasterHeader>
  );
};
