import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import isEmail from "validator/lib/isEmail";
import * as yup from "yup";

import { useAppSelector } from "store";

import type { SignUpFormProps } from "../../shared/typeDefs";

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .required("Please enter a valid email")
    .email("Please enter a valid email"),
});

export const useSignUpForm = () => {
  const { data } = useAppSelector((state) => state.signUp);

  const initValues: SignUpFormProps = {
    email: data.email,
  };

  const form = useForm<SignUpFormProps>({
    defaultValues: initValues,
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
  });

  const isValidEmail = isEmail(form.watch().email);

  return {
    ...form,
    isValidEmail,
  };
};
