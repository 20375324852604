import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import axios from "axios";
import qs from "qs";
import { useEffect, useState } from "react";
import ReactOnRails from "react-on-rails";
import { cn } from "utils/classNames";

import LoaderBusiness from "components/Loader/LoaderBusiness";

import { getMerchantInfoFromLocalStorage } from "utils/getMerchantInfoFromLocalStorage";

import { endpoints } from "constants/apiEndpoints";
import routes from "constants/routes";
import { shrinkString } from "helpers/stringUtils";

import type { TAdminBusiness, TSwitchBusiness } from "./shared/types";

export const SwitchBusiness = ({ compact, noDropdown }: TSwitchBusiness) => {
  const [listBusiness, setListBusiness] = useState<TAdminBusiness[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [dropdownBusiness, setDropdownBusiness] = useState<string>("");

  const merchantDetails = getMerchantInfoFromLocalStorage();

  const { currentMerchant } = merchantDetails;
  const queryParams = {
    authenticity_token: ReactOnRails.authenticityToken(),
  };

  const fetchAllBusiness = () => {
    axios
      .get(
        `${endpoints.MANAGE.API.ACCOUNTS.INDEX}?${qs.stringify(queryParams)}`,
        {
          headers: { accept: "application/json" },
        },
      )
      .then((res) => {
        if (res.status === 200) {
          setListBusiness(res.data.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onChangeBusiness = (identifier: string) => {
    axios
      .post(
        `${routes.MANAGE.ACCOUNT_SETTINGS.SWITCH_BUSINESS()}?${qs.stringify(queryParams)}`,
        {
          identifier: identifier,
        },
        {
          headers: { accept: "application/json" },
        },
      )
      .then((res) => {
        if (res.status === 200) {
          window.location.href = res.data.url;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchAllBusiness();
  }, []);

  useEffect(() => {
    listBusiness.map((item) => {
      if (currentMerchant?.identifier === item.merchant.identifier) {
        setDropdownBusiness(item.identifier);
      }
    });
  }, [listBusiness, currentMerchant]);

  if (noDropdown) {
    return (
      <div className="md:hidden">
        {isLoading && (
          <div className=" scale-75 transform">
            <LoaderBusiness noWrapper />
          </div>
        )}
        {!isLoading && listBusiness.length > 1 && (
          <>
            <DropdownMenu.Separator className="h-1 w-full bg-black-50" />
            <DropdownMenu.Label className="mt-16 mb-4 px-12 font-regular text-12 text-black-300">
              Your business accounts
            </DropdownMenu.Label>
            <DropdownMenu.RadioGroup
              value={dropdownBusiness}
              onValueChange={onChangeBusiness}
            >
              {listBusiness.map((item, index) => (
                <DropdownMenu.RadioItem
                  className="flex cursor-pointer flex-row items-center justify-between gap-12 rounded-8 p-12 hover:bg-black-25 focus:outline-none"
                  value={item.identifier}
                  key={index}
                >
                  <div className="flex flex-row gap-12">
                    <span className="material-icons-round text-18 text-black-200">
                      store
                    </span>
                    <span className="text-14">{item.merchant.name}</span>
                  </div>
                  <DropdownMenu.ItemIndicator className="material-icons-round text-18 text-green-400">
                    check
                  </DropdownMenu.ItemIndicator>
                </DropdownMenu.RadioItem>
              ))}
            </DropdownMenu.RadioGroup>
          </>
        )}
      </div>
    );
  }

  return (
    <>
      {listBusiness.length > 1 && (
        <DropdownMenu.Root>
          <DropdownMenu.Trigger
            className={cn(
              "dropdown-menu__trigger flex w-full items-center justify-between rounded-full border border-black-50 bg-white hover:border-black-200 focus:border-black-200 focus:outline-none",
              {
                "h-40 w-40 text-center": compact,
                "px-16 py-8": !compact,
              },
            )}
          >
            {!compact && (
              <>
                <div className="flex flex-row items-center gap-8">
                  <span className="material-icons-round p-4 text-16 text-black-200">
                    store
                  </span>
                  <span className="font-medium text-12">
                    {shrinkString(currentMerchant?.name || "", 3, 20)}
                  </span>
                </div>

                <span className="dropdown-menu__icon material-icons-round text-18 text-black-200">
                  keyboard_arrow_down
                </span>
              </>
            )}

            {compact && (
              <div className="w-full font-medium text-12 uppercase">
                {currentMerchant?.name.charAt(0)}
              </div>
            )}
          </DropdownMenu.Trigger>

          <DropdownMenu.Content className="dropdown-menu__content mt-4 rounded-16 bg-white p-8 shadow-lg">
            <DropdownMenu.RadioGroup
              value={dropdownBusiness}
              onValueChange={onChangeBusiness}
            >
              {listBusiness.map((item, index) => (
                <DropdownMenu.RadioItem
                  className="flex cursor-pointer flex-row items-center justify-between gap-12 rounded-8 p-8 hover:bg-black-25 focus:outline-none"
                  value={item.identifier}
                  key={index}
                >
                  <span className="font-medium text-12">
                    {item.merchant.name}
                  </span>
                  <DropdownMenu.ItemIndicator className="material-icons-round text-18 text-green-400">
                    check
                  </DropdownMenu.ItemIndicator>
                </DropdownMenu.RadioItem>
              ))}
            </DropdownMenu.RadioGroup>
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      )}
    </>
  );
};
