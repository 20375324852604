import { cn } from "utils/classNames";

import Button from "components/Button/Button";
import Price from "components/Price/Price";
import Selector from "components/Selector/Selector";
import Card from "components/ui/Card";

import AmexLogo from "assets/logos/amex.svg";
import ApplePayMark from "assets/logos/apple-pay-mark.svg";
import GooglePayMark from "assets/logos/google-pay-mark.svg";
import MastercardLogo from "assets/logos/mastercard.svg";
import VisaLogo from "assets/logos/visa.svg";
import BgGradient from "assets/misc/gradient-bg.png";

var tinycolor = require("tinycolor2");

interface IDemoPaymentProps {
  brandColor: string;
  accent: string;
  hasPremium: boolean;
  logo: string | null;
  businessName: string;
}

const DemoPayment = ({
  brandColor,
  accent,
  hasPremium,
  logo,
  businessName,
}: IDemoPaymentProps) => {
  // tips array
  const tips = [5, 10, 15];

  const tinyBrandColor = tinycolor(brandColor);
  const tinyAccentColor = tinycolor(accent);

  return (
    <main
      className="min-h-100dvh bg-center bg-cover bg-fixed bg-no-repeat md:min-h-screen md:pb-48 lg:pb-64"
      style={{
        backgroundImage:
          brandColor === "#3333FF" ? `url(${BgGradient})` : "unset",
        backgroundColor: brandColor === "#3333FF" ? "unset" : brandColor,
      }}
    >
      <div className="md:grid md:auto-rows-max md:grid-cols-8 lg:mx-auto lg:max-w-1200 lg:grid-cols-12">
        <div className="md:col-span-4 md:col-start-3 lg:col-start-5">
          <header className="sticky flex flex-col items-center justify-center gap-12 p-20 md:min-h-96">
            {logo && (
              <img
                className="mx-auto h-32 w-auto object-contain md:h-48"
                src={logo}
              />
            )}

            <h2
              className={cn(
                "text-center font-bold text-16 text-white md:w-full md:text-18",
                {
                  "text-wl-black-500": tinyBrandColor.isLight(),
                },
              )}
            >
              {businessName}
            </h2>
          </header>

          <section
            className={cn(
              "absolute top-64 right-0 bottom-0 left-0 flex flex-col overflow-y-auto overflow-x-hidden rounded-t-32 bg-white px-24 shadow-md md:relative md:top-0 md:rounded-32",
              {
                "top-104": hasPremium && logo,
              },
            )}
          >
            <header className="space-y-8 p-24 text-center text-12">
              <p className="text-black-300"> Payment for</p>
              <div className="space-y-4">
                <p className="font-medium text-14">Premium box of chocolates</p>
                <p className="text-black-300">
                  Unleash your taste buds and indulge in the finest selection of
                  12 premium chocolates
                </p>
              </div>
            </header>

            <div className="flex flex-grow-2 flex-col justify-center gap-24 md:gap-0 md:py-48">
              <Price amount={550} amountCurrency="AED" />

              <Card classes="p-16 flex flex-col gap-16 md:mt-16" hasBorder>
                <span className="font-medium text-14">Add a tip</span>
                <div className="flex flex-row gap-8">
                  <Selector
                    items={tips}
                    onItemClick={() => {}}
                    activeItem={tips[0]}
                    hasCustomBranding={hasPremium}
                  />
                </div>
              </Card>
            </div>

            {/* FOOTER AKA WHERE THE CTAS LIVE */}
            <footer className="sticky bottom-0 space-y-12 bg-white py-12 md:py-24">
              {/* FANCY LOGO MARKS FOR TRUST */}
              <div className="flex flex-col items-center gap-8">
                <div className="flex flex-row items-center gap-12">
                  <img src={VisaLogo} alt="visa logo" className="h-24" />
                  <img
                    src={MastercardLogo}
                    alt="mastercard logo"
                    className="h-24"
                  />
                  <img src={AmexLogo} alt="amex logo" className="h-16" />
                  <img
                    src={GooglePayMark}
                    alt="google pay logo"
                    className="h-16"
                  />
                  <img
                    src={ApplePayMark}
                    alt="apple pay logo"
                    className="h-16"
                  />
                </div>

                <p className="text-12 text-black-200">
                  Powered by Mamo - Regulated by the DFSA
                </p>
              </div>

              {/* CONTINUE/SUBSCRIBE BUTTON */}
              {accent ? (
                <Button
                  type="submit"
                  size="lg"
                  label="Continue"
                  classes={cn("w-full hover:opacity-80", {
                    "border border-black-200 border-solid":
                      accent == "#FFFFFF" || accent == "#FFF",
                    "text-wl-white-500": tinyAccentColor.isDark(),
                    "text-wl-black-500": tinyAccentColor.isLight(),
                  })}
                  style={{ backgroundColor: accent }}
                />
              ) : (
                <Button
                  type="submit"
                  color="primary"
                  size="lg"
                  label="Continue"
                  classes="w-full"
                />
              )}
            </footer>
          </section>
        </div>
      </div>
    </main>
  );
};

export default DemoPayment;
