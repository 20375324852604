import { Provider } from "react-redux";

import { ReactQueryProvider } from "components/Providers";
import { store } from "store";

import type IRailsContext from "interfaces/IRailsContext";
import { SlackIntegration } from "./SlackIntegration";

export const SlackIntegrationRoot = (
  props: unknown,
  railsContext: IRailsContext,
) => {
  return () => (
    <ReactQueryProvider>
      <Provider store={store}>
        <SlackIntegration railsContext={railsContext} />
      </Provider>
    </ReactQueryProvider>
  );
};
