import { Divider } from "components/Divider";
import { Button } from "design_system/Button";
import { Card } from "design_system/Card";
import { CheckCircle, Pending } from "design_system/Icons";
import { Tag } from "design_system/Tag";

import { useAppSelector } from "store";
import { useRootfiConnect } from "./useRootfiConnect";

import type { AccountingIntegrationsFormattedResponse } from "hooks/AccountingIntegrations/useGetAccountingIntegrations";
import { allAccountingIntegrations } from "./constants";

const handleLearnMore = () => {
  window.open("https://help.mamopay.com/en/", "Mamo Help Center", "noopener");
};

type AccountingIntegration = {
  integrationsData: AccountingIntegrationsFormattedResponse;
};
export const AccountingIntegration = ({ integrationsData }) => {
  const { rootfi } = useAppSelector((store) => store.accountingDashboard);
  const { isLoading, initRootfi } = useRootfiConnect();

  const availableIntegrations = integrationsData?.integrations;
  const hasHealthyConnection = integrationsData?.hasHealthyConnection;
  const hasSubmittedConnectionRequest =
    integrationsData?.hasSubmittedConnectionRequest;

  return (
    <>
      {availableIntegrations &&
        allAccountingIntegrations.map((integration, index) => {
          const connectionStatus =
            availableIntegrations[integration.platform]?.connectionStatus ||
            "init";
          const submissionStatus =
            availableIntegrations[integration.platform]?.submissionStatus ||
            "init";

          return (
            <section key={integration.platform}>
              <div className="grid grid-cols-page-record-basic p-16 pl-0">
                <img
                  src={integration.imageSrc}
                  alt={integration.imageAltText}
                  className="h-40 w-40"
                />
                <div className="flex w-full flex-col pl-16">
                  <span className="body-large">{integration.title}</span>
                  <span className="body-small text-text-color-02">
                    {integration.subTitle}
                  </span>
                </div>
                {(["init", "expired", "disconnected"].includes(
                  connectionStatus,
                ) ||
                  (connectionStatus === "pending" &&
                    submissionStatus === "pending")) && (
                  <Button
                    variant="filled"
                    size="md"
                    label="Connect"
                    loading={
                      isLoading &&
                      rootfi.selectedIntegration === integration.platform
                    }
                    disabled={
                      hasHealthyConnection || hasSubmittedConnectionRequest
                    }
                    onClick={() => initRootfi(integration.platform)}
                  />
                )}
                {connectionStatus === "pending" &&
                  submissionStatus !== "pending" && (
                    <Tag label="Connecting" Icon={Pending} color="blue" />
                  )}
                {connectionStatus === "healthy" && (
                  <Tag label="Connected" Icon={CheckCircle} color="green" />
                )}
              </div>

              {index < allAccountingIntegrations.length - 1 && <Divider />}
            </section>
          );
        })}

      {/* LEARN MORE SECTION */}
      <Card cardClasses="hidden md:block w-460 md:mt-96 space-y-8 shadow-lg">
        <h3 className="label-large">Accounting</h3>
        <p className="body-medium text-text-color-02">
          Learn how to prepare, document and process expenses for all purchases
          made using your Mamo Cards.
        </p>
        <Button
          variant="outlined"
          size="md"
          label="Learn more"
          onClick={handleLearnMore}
        />
      </Card>
    </>
  );
};
