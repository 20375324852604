import type {
  NoTradeLicenseRoleOptions,
  TradeLicenseRoleOptions,
} from "./typeDefs";

export const errorsMapping: { [key: string]: string } = {
  EMAIL_ALREADY_EXISTED:
    "An account with this email already exists. Sign in or use another email address.",
};

export const errorType: { [key: string]: string } = {
  EMAIL_ALREADY_EXISTED: "email",
};

export const tradeLicenseRoleOptions: TradeLicenseRoleOptions[] = [
  {
    id: "owner",
    value: "owner",
    label: "Owner",
  },
  {
    id: "manager",
    value: "manager",
    label: "Manager or Director",
  },
  {
    id: "employee",
    value: "employee",
    label: "Employee",
  },
];

export const noTradeLicenseRoleOptions: NoTradeLicenseRoleOptions[] = [
  {
    id: "freelancer",
    value: "freelancer",
    label: "Freelancer",
  },
  {
    id: "personal_use",
    value: "personal_use",
    label: "Personal use",
  },
];

export enum SignUpSteps {
  SIGNUP = "signup",
  CREATE_PASSWORD = "create_password",
  TELL_US_MORE = "tell_us_more",
}
