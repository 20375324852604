import { type ReactNode, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { Snackbar } from "design_system/Snackbar";

import { FadeIn } from "components/AnimatedElements/FadeIn";
import LoaderBusiness from "components/Loader/LoaderBusiness";
import { VerificationTimeline } from "features/VerificationSetup/_containers/timeline";
import { useVerificationProcess } from "features/VerificationSetup/_hooks/useVerificationProcess";
import { PATH_TO_TITLE } from "features/VerificationSetup/views/_constants/path-to-title.const";

const getPathTitle = (pathname: string) => {
  return PATH_TO_TITLE[pathname] || "Verification";
};

export type VerificationSetupLayoutProps = {
  asideChildren?: ReactNode;
};

export const VerificationSetupLayout = ({
  asideChildren,
}: Readonly<VerificationSetupLayoutProps>) => {
  const [displayDom, setDisplayDom] = useState(false);
  const { pathname } = useLocation();
  const { validatePath } = useVerificationProcess();

  useEffect(() => {
    validatePath();

    const timer = setTimeout(() => {
      setDisplayDom(true);
    }, 400); // fake loading to give time for the validation to complete

    return () => clearTimeout(timer);
  }, [validatePath]);

  return (
    <>
      <Snackbar />
      {displayDom ? (
        <div
          className="grid grid-cols-1 lg:grid-cols-12 lg:gap-24"
          style={{ height: "calc(100% - 6.4rem)" }}
        >
          <section className="col-span-1 flex flex-col lg:col-span-8 2xl:col-span-9">
            <FadeIn custom={1}>
              <h1 className="header-large px-16 py-24 lg:px-40">
                {getPathTitle(pathname)}
              </h1>
            </FadeIn>

            <FadeIn custom={1}>
              <VerificationTimeline />
            </FadeIn>
            <div className="px-0 md:px-20 lg:px-40">
              <FadeIn custom={1}>
                <Outlet />
              </FadeIn>
            </div>
          </section>

          <aside className="relative col-span-1 w-full lg:col-span-4 2xl:col-span-3">
            <FadeIn custom={1}>{asideChildren}</FadeIn>
          </aside>
        </div>
      ) : (
        <LoaderBusiness />
      )}
    </>
  );
};
