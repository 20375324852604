import { cn } from "utils/classNames";

export type SkeletonProps = {
  className?: string;
};
export const Skeleton = ({ className }: SkeletonProps) => {
  return (
    <div
      className={cn("h-24 w-full max-w-94 rounded-4 bg-surface-2", className)}
    />
  );
};
