import { cn } from "utils/classNames";

export interface ILegendProps {
  colorScale: (value: string) => string;
  data: { name: string; value: number }[];
  selectedLegend: string | null;
  onLegendClick: (name: string) => void;
}

export const Legend = ({
  colorScale,
  data,
  selectedLegend,
  onLegendClick,
}: ILegendProps) => {
  return (
    <div className="flex flex-row flex-wrap items-center gap-x-32 gap-y-4">
      {data.map((d, i) => (
        <div
          className={cn("flex cursor-pointer items-center gap-12 py-4", {
            "opacity-100": selectedLegend === null || selectedLegend === d.name,
            "opacity-30": !(
              selectedLegend === null || selectedLegend === d.name
            ),
          })}
          key={`legend-${i}`}
          onClick={() => onLegendClick(d.name)}
        >
          <div
            className="h-12 w-12 rounded-4"
            style={{ backgroundColor: colorScale(d.name) }}
          ></div>
          <span className="body-large text-text-color-02">{d.name}</span>
        </div>
      ))}
    </div>
  );
};
