import { Outlet } from "react-router-dom";

import { Snackbar } from "design_system/Snackbar";

export const CashbackRoot = () => {
  return (
    <>
      <Snackbar />
      <section className="px-16 pt-16 md:px-40 md:pt-24">
        <Outlet />
      </section>
    </>
  );
};
