import axios from "axios";
import routes from "constants/routes";
import qs from "qs";
import ReactOnRails from "react-on-rails";

export async function deactivateLink(identifier: string) {
  const queryParams = {
    authenticity_token: ReactOnRails.authenticityToken(),
  };

  const response = await axios.patch(
    `${routes.MANAGE.LINKS.DEACTIVATE(identifier)}?${qs.stringify(queryParams)}`,
  );

  return response.data;
}
