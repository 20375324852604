import { useEffect } from "react";

import { Button } from "design_system/Button";
import { Card } from "design_system/Card";
import { MamoFavIcon, Slack, SyncAlt } from "design_system/Icons";
import { snack } from "design_system/Snackbar";

import { useHandleAllow } from "./hooks/use-handle-allow";

export const SlackIntegrationDialog = () => {
  const { isLoading, error, handleAllow } = useHandleAllow();

  useEffect(() => {
    if (error) {
      snack({
        title: "Oops! Something went wrong. Please try again.",
        description: error,
        leadingElement: "icon",
        variant: "critical",
      });
    }
  }, [error]);

  return (
    <Card
      style={{ maxWidth: "628px", marginTop: "60px" }} // Adjust it after new v of tailwind
      cardClasses="rounded-md mx-auto p-24 flex flex-col shadow-lg gap-32"
    >
      <div className="flex items-center gap-8">
        <MamoFavIcon size="32" />
        <SyncAlt
          fill="text-text-color-03"
          className="text-text-color-03"
          size="18"
        />
        <Slack size="32" />
      </div>

      <article className="flex flex-col gap-16">
        <div className="gap-4">
          <h2 className="text-header-small text-text-color-1">Authorization</h2>
          <span className="text-body-large text-text-color-2">
            Give Mamo permission to connect to your Slack account
          </span>
        </div>
        <Button
          loading={isLoading}
          size="md"
          variant="filled"
          classes="mr-auto"
          onClick={handleAllow}
          label="Allow"
        />
      </article>
    </Card>
  );
};
