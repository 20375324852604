import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import Button from "components/Button/Button";
import TextInput from "components/Inputs/TextInput";
import TextButton from "components/ui/TextButton";
import Toast from "components/ui/Toast";

import { isAppPlatform } from "helpers/isAppPlatform";
import { handleClose } from "../shared/utils/close";
import {
  useGenerateOtp,
  useUpdateBankDetails,
  useUpdateIbanForm,
} from "../useEditBankDetails";

import { useOtp } from "hooks/useOtp";
import { useToast } from "hooks/useToast";

import routes from "constants/routes";

export const VerificationCode = () => {
  const navigate = useNavigate();
  const { formControl, fields } = useUpdateIbanForm();
  const { generateOtp } = useGenerateOtp();
  const { buttonText, isDisabled, resetTimer } = useOtp();
  const { updateBankDetails } = useUpdateBankDetails();
  const { saveToastOnNavigation } = useToast();

  const [isVerifying, setIsVerifying] = useState<boolean>(false);

  const { control, errors } = formControl;

  const handleResendCode = async () => {
    await generateOtp.mutateAsync();
    resetTimer();
  };

  const onSubmit = async () => {
    setIsVerifying(true);

    try {
      await updateBankDetails.mutateAsync(fields);
      setIsVerifying(false);
      saveToastOnNavigation({
        type: "success",
        message: "Bank details have been updated",
      });
      window.location.href = routes.MANAGE.ACCOUNT_SETTINGS.BANK.INDEX();
    } catch (error) {
      setIsVerifying(false);
      if (error.response.data.error_code === "INVALID_OTP") {
        toast.error("Code is invalid. Please try again.", {
          duration: 3000,
        });
      }
    }
  };

  useEffect(() => {
    const resendLink = document.getElementById("resend-otp");

    if (!resendLink) return;

    function noClick(event: any) {
      event.preventDefault();
    }

    if (isDisabled) {
      resendLink.addEventListener("click", noClick);
    } else {
      resendLink.removeEventListener("click", noClick);
    }
  }, [isDisabled]);

  useEffect(() => {
    // data is not going to be here when the user refreshes the page so
    // we should take them back to the first step
    if (isEmpty(fields.iban) || isEmpty(fields.accountHolderName)) {
      navigate(routes.MANAGE.ACCOUNT_SETTINGS.BANK.UPDATE_IBAN(), {
        replace: true,
      });
    }
  }, []);

  return (
    <>
      <Toast />
      {!isAppPlatform() && (
        <header>
          <button
            type="button"
            className="create-link__close-button material-icons-round"
            onClick={handleClose}
          >
            close
          </button>
        </header>
      )}

      <section className="bg-white pt-48 md:mx-auto md:max-w-3xl md:rounded-16 md:p-48 lg:max-w-5xl">
        <div className="space-y-48">
          <div className="flex flex-col gap-16 text-center">
            <span data-testid="page-title" className="font-bold text-28">
              Enter verification code
            </span>
            <span className="heading-03 text-black-300">
              We sent a code to your registered email address
            </span>
          </div>

          <div className="space-y-48">
            <Controller
              name="otp"
              control={control}
              defaultValue=""
              render={(props) => (
                <TextInput
                  autoFocus
                  type="number"
                  inputId="otp"
                  labelText="Code"
                  onChange={(value) => {
                    props.onChange(value);
                  }}
                  onBlur={props.onBlur}
                  errors={errors.otp}
                  errorMessage={errors.otp?.message}
                />
              )}
            />

            <div className="flex flex-col items-center gap-24 text-center">
              <Button
                type="button"
                color="primary"
                size="lg"
                label={isVerifying ? "Verifying..." : "Verify"}
                onClick={onSubmit}
                isDisabled={isVerifying}
              />
              <TextButton
                id="resend-otp"
                type="button"
                color="primary"
                size="md"
                label={buttonText}
                onClick={handleResendCode}
                isDisabled={isDisabled}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
