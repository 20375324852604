import Lottie from "lottie-react-web";
import { cn } from "utils/classNames";

import LoaderBlue from "assets/animations/loader-blue.json";

type TLoaderBusiness = {
  noWrapper?: boolean;
  classes?: string;
  width?: number;
  height?: number;
};

const LoaderBusiness = ({
  noWrapper,
  classes,
  ...dimensions
}: TLoaderBusiness) => {
  return (
    <div
      className={cn({
        "loader-wrapper": !noWrapper,
        [`${classes}`]: !!classes,
      })}
    >
      <Lottie
        options={{
          animationData: LoaderBlue,
          loop: true,
        }}
        width={dimensions.width || 160}
        height={dimensions.height || 160}
      />
    </div>
  );
};

export default LoaderBusiness;
