import type IBrazeModalProps from "interfaces/IBrazeModalProps";
import { type FunctionComponent, useEffect } from "react";

const BrazeModal: FunctionComponent<IBrazeModalProps> = (
  props: IBrazeModalProps,
) => {
  const { extID } = props;
  useEffect(() => {
    import("@braze/web-sdk").then(
      ({
        initialize,
        automaticallyShowInAppMessages,
        changeUser,
        openSession,
      }) => {
        initialize(process.env.REACT_BRAZE_API_KEY || "", {
          baseUrl: process.env.REACT_BRAZE_SDK_ENPOINT || "",
          allowUserSuppliedJavascript: true,
        });

        automaticallyShowInAppMessages();
        changeUser(extID);
        openSession();
      },
    );
  }, []);

  return <></>;
};

export default BrazeModal;
