import isEmpty from "lodash/isEmpty";
import { useContext, useEffect } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import LoaderBusiness from "components/Loader/LoaderBusiness";
import TextButton from "components/TextButton/TextButton";
import Card from "components/ui/Card";
import Toast from "components/ui/Toast";
import { PageHeader } from "./PageHeader";

import { useToast } from "hooks/useToast";
import { useGetBusinessDetails } from "../shared/hooks/useGetBusinessDetails";

import { businessDetailsRoutes } from "../shared/constant";
import { BusinessDetailsContext } from "../shared/context/BusinessDetailsProvider";

export const Details = () => {
  const { getDetails } = useGetBusinessDetails();
  const { data, isLoading, isFetching } = getDetails;
  const { setDetails } = useContext(BusinessDetailsContext);
  const { toastDetails } = useToast();
  const navigate = useNavigate();

  // FUNCTION FOR GOING TO THE RESPECTIVE UPDATE PAGE
  const handleGoToUpdateDetails = (value: any, _key: string) => {
    const updatedDetails = JSON.parse(JSON.stringify({ [_key]: value, _key }));
    setDetails(updatedDetails);

    navigate(businessDetailsRoutes.updateDetail);
  };

  useEffect(() => {
    if (toastDetails.type) {
      toast[toastDetails.type](toastDetails.message, { duration: 3000 });
    }
  }, [toastDetails.message]);

  return (
    <>
      <PageHeader />

      <div className="account-settings">
        <Toast />
        <div className="mx-auto" style={{ maxWidth: "714px" }}>
          <Card hasBorder classes="text-16">
            {isLoading ? (
              <LoaderBusiness />
            ) : (
              <>
                {/* BUSINESS NAME */}
                <div className="mb-24 space-y-4 border-black-50 border-b pb-24">
                  <p className="font-medium">Business name</p>
                  <div className="flex flex-row items-center gap-16">
                    <p className="text-black-300">{data?.name}</p>
                    <span
                      className="material-icons-round cursor-pointer text-18"
                      onClick={() =>
                        handleGoToUpdateDetails(data?.name, "name")
                      }
                    >
                      edit
                    </span>
                  </div>
                </div>

                {/* BUSINESS ADDRESS */}
                <div className="mb-24 space-y-4 border-black-50 border-b pb-24">
                  <p className="font-medium">Business address</p>

                  {isEmpty(data?.address) ? (
                    <TextButton
                      color="primary"
                      size="sm"
                      label="Add business address"
                      onClick={() =>
                        handleGoToUpdateDetails(
                          { location: data?.address, city: data?.city },
                          "address",
                        )
                      }
                    />
                  ) : (
                    <div className="flex flex-row items-center gap-16">
                      <p className="text-black-300">
                        {data?.address}, {data?.city}, United Arab Emirates
                      </p>
                      <span
                        className="material-icons-round cursor-pointer text-18"
                        onClick={() =>
                          handleGoToUpdateDetails(
                            { location: data?.address, city: data?.city },
                            "address",
                          )
                        }
                      >
                        edit
                      </span>
                    </div>
                  )}
                </div>

                {/* SUPPORT EMAIL */}
                <div className="mb-24 space-y-4 border-black-50 border-b pb-24">
                  <p className="font-medium">Support email</p>

                  {isEmpty(data?.replyTo) ? (
                    <TextButton
                      color="primary"
                      size="sm"
                      label="Add a support email"
                      onClick={() =>
                        handleGoToUpdateDetails(data?.replyTo, "replyTo")
                      }
                    />
                  ) : (
                    <div className="flex flex-row items-center gap-16">
                      <p className="text-black-300">{data?.replyTo}</p>
                      <span
                        className="material-icons-round cursor-pointer text-18"
                        onClick={() =>
                          handleGoToUpdateDetails(data?.replyTo, "replyTo")
                        }
                      >
                        edit
                      </span>
                    </div>
                  )}
                </div>

                {/* TRN */}
                <div className="mb-24 space-y-4 border-black-50 border-b pb-24">
                  <p className="font-medium">Tax Registration Number</p>

                  {isEmpty(data?.trn) ? (
                    <TextButton
                      color="primary"
                      size="sm"
                      label="Add TRN"
                      onClick={() => handleGoToUpdateDetails(data?.trn, "trn")}
                    />
                  ) : (
                    <div className="flex flex-row items-center gap-16">
                      <p className="text-black-300">{data?.trn}</p>

                      <span
                        className="material-icons-round cursor-pointer text-18"
                        onClick={() =>
                          handleGoToUpdateDetails(data?.trn, "trn")
                        }
                      >
                        edit
                      </span>
                    </div>
                  )}
                </div>

                {/* MAMO BUSINESS TAG */}
                <div className="mb-24 space-y-4 border-black-50 border-b pb-24">
                  <p className="font-medium">Mamo Business tag</p>
                  <p className="text-black-300">@{data?.paymentUrlSlug}</p>
                </div>

                {/* BUSINESS WEBSITE */}
                <div className="space-y-4">
                  <p className="font-medium">Business website</p>
                  <a
                    href={data?.websiteUrl}
                    target="_blank"
                    className="text-blue-500 underline"
                    rel="noreferrer"
                  >
                    {data?.websiteUrl}
                  </a>
                </div>
              </>
            )}
          </Card>
        </div>
      </div>
    </>
  );
};
