import { type ReactNode, createContext, useContext, useReducer } from "react";

import {
  type Channel,
  type Modal,
  type QueryParams,
  VIEWS,
  type Views,
  querySchema,
} from "components/TwoFactorAuthentication/_shared/schema";
import { useSearchQueryParams } from "hooks/useSearchQueryParams";

// State
export type State = {
  hasCloseButton: boolean;
  view: Views | undefined;
  channel: Channel | undefined;
  phoneNumber: string | null | undefined;
  modal: Modal | undefined;
};

// Actions
export const ACTIONS = {
  UPDATE: "UPDATE",
  CLEAR: "CLEAR",
} as const;

export type ActionType = (typeof ACTIONS)[keyof typeof ACTIONS];

export type Action =
  | { type: typeof ACTIONS.UPDATE; payload: Partial<UpdateContextProps> }
  | { type: typeof ACTIONS.CLEAR };

// Initial State
const INITIAL_STATE: State = {
  hasCloseButton: true,
  view: undefined,
  channel: undefined,
  phoneNumber: undefined,
  modal: undefined,
};

// Reducer
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ACTIONS.UPDATE:
      return { ...state, ...action.payload };
    case ACTIONS.CLEAR:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export type UpdateContextProps = {
  modal?: Modal;
  view?: Views;
  channel?: Channel;
  phoneNumber?: string | null;
};

export type TwoFactorAuthenticationContextType = State & {
  searchParams: QueryParams;
  updateContext: (data: Partial<UpdateContextProps>) => void;
  clearContext: () => void;
  onSuccess?: () => void;
};

export const TwoFactorAuthenticationContext =
  createContext<TwoFactorAuthenticationContextType | null>(null);

export type TwoFactorAuthenticationProviderProps = {
  children: ReactNode;
  initialValues?: Partial<State>;
  onSuccess?: () => void;
};

export const TwoFactorAuthenticationProvider = ({
  children,
  initialValues,
  onSuccess,
}: TwoFactorAuthenticationProviderProps) => {
  const { searchParams, setSearchQueryParams } =
    useSearchQueryParams<QueryParams>(querySchema);

  const [state, dispatch] = useReducer(reducer, {
    ...INITIAL_STATE,
    ...initialValues,
    view: searchParams.view ?? VIEWS.PHONE_NUMBER,
    channel: searchParams.channel,
    phoneNumber: searchParams.phoneNumber ?? null,
    modal: searchParams.modal ?? undefined,
  });

  const updateContext = (payload: UpdateContextProps) => {
    dispatch({ type: ACTIONS.UPDATE, payload });

    setSearchQueryParams({
      view: payload.view ?? state.view,
      channel: payload.channel ?? state.channel,
      phoneNumber: payload.phoneNumber ?? state.phoneNumber,
      modal: payload.modal ?? state.modal,
    });
  };

  const clearContext = () => {
    dispatch({ type: ACTIONS.CLEAR });
    setSearchQueryParams({
      view: undefined,
      channel: undefined,
      phoneNumber: undefined,
      modal: undefined,
    });
  };

  const value: TwoFactorAuthenticationContextType = {
    searchParams,
    ...state,
    updateContext,
    clearContext,
    onSuccess,
  };

  return (
    <TwoFactorAuthenticationContext.Provider value={value}>
      {children}
    </TwoFactorAuthenticationContext.Provider>
  );
};

export const useTwoFactorAuthenticationContext = () => {
  const context = useContext(TwoFactorAuthenticationContext);
  if (context === null) {
    throw new Error(
      "useTwoFactorAuthenticationContext must be used within a TwoFactorAuthenticationProvider",
    );
  }
  return context;
};
