import { CardIcon } from "./CardIcon";
import { CardholderIcon } from "./CardholderIcon";
import { InvoiceIcon } from "./InvoiceIcon";
import { LinkIcon } from "./LinkIcon";
import { RecurringPaymentIcon } from "./RecurringPaymentIcon";
import { SubscriberIcon } from "./SubscriberIcon";

export {
  CardholderIcon,
  CardIcon,
  InvoiceIcon,
  LinkIcon,
  RecurringPaymentIcon,
  SubscriberIcon,
};
