import { bytesToSize } from "helpers/fileUtils";
import { upperCase } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { cn } from "utils/classNames";

import FieldMessage from "components/FieldMessage/FieldMessage";
import CustomIcons from "components/shared/CustomIcons";

interface ISingleFileUpload {
  id: string;
  icon?: string;
  customIcon?: string;
  label?: string;
  supportedFiles: Array<string>;
  supportedFilesContent: string;
  fileSize: number;
  fileSizeContent?: string;
  previewType: "imageOnly" | "attachmentWithInfo";
  compactLayout?: boolean;
  onGetSelectedFile: any;
  defaultFile?: any;
}

const SingleFileUpload = ({
  id,
  icon,
  customIcon,
  label,
  supportedFiles,
  supportedFilesContent,
  fileSize,
  fileSizeContent,
  previewType,
  compactLayout,
  onGetSelectedFile,
  defaultFile,
}: ISingleFileUpload) => {
  const [nameFile, setNameFile] = useState<string>(defaultFile?.name);
  const [sizeFile, setSizeFile] = useState<number>(defaultFile?.size);
  const [attachmentPreview, setAttachmentPreview] = useState<string | null>(
    defaultFile?.name,
  );
  const [isDragOver, setIsDragOver] = useState<boolean>(false);
  const [isAttachmentError, setIsAttachmentError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const onDragOver = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(true);

    return false;
  }, []);

  const onDragLeave = useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsDragOver(false);

    return false;
  }, []);

  const handleFileChange = (e: any) => {
    if (e.target.files && e.target.files[0]) {
      const newFiles = e.target.files;
      const name = newFiles[0].name;
      const size = newFiles[0].size;
      const extension = name?.split(".").pop().toLowerCase();
      const isSupported = supportedFiles.includes(extension);

      if (!isSupported) {
        setIsAttachmentError(true);
        setErrorMessage(
          `You’re trying to upload a ${upperCase(extension)} file. Please use ${supportedFilesContent}`,
        );
        setIsDragOver(false);
      } else if (size > fileSize) {
        setIsAttachmentError(true);
        setErrorMessage(
          `Please make sure your uploaded file is under ${fileSizeContent}`,
        );
        setIsDragOver(false);
      } else {
        setAttachmentPreview(e.target.files[0].name);
        setIsAttachmentError(false);
        setNameFile(e.target.files[0].name);
        setSizeFile(e.target.files[0].size);
        onGetSelectedFile(e.target.files[0]);
      }
    }
  };

  const handleCloseFileUpload = () => {
    onGetSelectedFile(null);
    setIsDragOver(false);
    setAttachmentPreview(null);
    setSizeFile(0);
  };

  useEffect(() => {
    const dragInput: any = document.getElementById(id);
    dragInput?.addEventListener("dragover", onDragOver);

    return () => {
      dragInput?.addEventListener("dragover", onDragOver);
    };
  }, [onDragOver, onDragLeave]);

  return (
    <>
      {/* ATTACHMENT PREVIEW - WITH/WITHOUT INFORMATION */}
      {/* LAYOUT PREVIEW */}
      {attachmentPreview && (
        <div className="form-field">
          {/* <div className="form-field__control file-uploaded"> */}
          <div
            className={cn("form-field__control", {
              "file-uploaded-compact": compactLayout,
              "file-uploaded": !compactLayout,
            })}
          >
            {/* IMAGE ONLY PREVIEW */}
            {previewType === "imageOnly" && (
              <img src={attachmentPreview} alt="" draggable={false} />
            )}

            {/* ATTACHMENT WITH INFO PREVIEW */}
            {previewType === "attachmentWithInfo" && (
              <div
                className={cn({
                  "flex items-center space-x-16": compactLayout,
                })}
              >
                {icon && (
                  <span
                    className={cn("material-icons-round", {
                      "p-12 text-40 text-blue-500": compactLayout,
                      "file-uploaded-icon": !compactLayout,
                    })}
                  >
                    description
                  </span>
                )}

                {customIcon && (
                  <div className="flex justify-center rounded-12 bg-white p-16">
                    <CustomIcons
                      name={customIcon}
                      width="32"
                      height="32"
                      fill="#3333ff"
                    />
                  </div>
                )}

                <div className="space-y-4">
                  <p className="text-16">{nameFile}</p>
                  <p className="text-14 text-black-300">
                    {bytesToSize(sizeFile)}
                  </p>
                </div>
              </div>
            )}

            <span
              className={cn("material-icons-round", {
                "file-uploaded-delete": compactLayout,
                "file-uploaded-close": !compactLayout,
              })}
              onClick={handleCloseFileUpload}
            >
              {compactLayout && "delete"}
              {!compactLayout && "close"}
            </span>
          </div>
        </div>
      )}

      {/* DRAG AND DROP INPUT */}
      {!attachmentPreview && (
        <div className="form-field">
          <div
            className={cn("form-field__control file-upload", {
              "file-upload-dragover": isDragOver,
              "file-upload-error": isAttachmentError,
            })}
          >
            <div>
              <input
                className="form-field__file"
                type="file"
                id={id}
                onChange={handleFileChange}
                onDragOver={onDragOver}
                onDragLeave={onDragLeave}
              />
              {icon && (
                <span
                  className="material-icons-round file-upload-icon"
                  style={{
                    animation: isDragOver
                      ? "pulsate 0.75s alternate infinite"
                      : "",
                  }}
                >
                  {icon && icon}
                  {!icon && "upload_file"}
                </span>
              )}

              {customIcon && (
                <div className="custom-upload-icon mb-12">
                  <CustomIcons
                    name={customIcon}
                    width="24"
                    height="24"
                    style={{
                      animation: isDragOver
                        ? "pulsate 0.75s alternate infinite"
                        : "",
                    }}
                  />
                </div>
              )}

              <div className="space-y-4">
                <span className="text-16">
                  {label && label}
                  {!label && (
                    <>
                      Drop your file or{" "}
                      <label
                        htmlFor={id}
                        className="relative z-10 text-button text-button--md text-button--primary text-button--solid"
                      >
                        browse
                      </label>
                    </>
                  )}
                </span>
                <p className="text-14 text-black-200">
                  Drag and drop here or click to upload.
                </p>
                <p className="text-14 text-black-200">
                  Supported file types: {supportedFilesContent}. Maximum file
                  size: {fileSizeContent}
                </p>
              </div>
            </div>
          </div>

          {isAttachmentError && (
            <FieldMessage
              messageType="error"
              icon="error"
              messageText={errorMessage}
            />
          )}
        </div>
      )}
    </>
  );
};

export default SingleFileUpload;
