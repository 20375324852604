import { type ReactNode, createContext, useContext, useState } from "react";

import { showHideIntercom } from "utils/displayUtils";
import { eventEmitter } from "utils/eventEmitter";

export type SideSheetContextType = {
  isSideSheetOpen: boolean;
  openSideSheet: () => void;
  closeSideSheet: () => void;
};

export const sideSheetContextInitValues = {
  isSideSheetOpen: false,
  openSideSheet: () => {},
  closeSideSheet: () => {},
};

// Create a context to manage the state of the SideSheet
export const SideSheetContext = createContext(sideSheetContextInitValues);

type SideSheetProviderProps = {
  children: (props?: SideSheetContextType) => ReactNode;
};
export const SideSheetProvider = ({ children }: SideSheetProviderProps) => {
  const [isSideSheetOpen, setIsSideSheetOpen] = useState(false);

  // Function to open the SideSheet
  const openSideSheet = () => {
    showHideIntercom("hide");
    setIsSideSheetOpen(true);
  };

  // Function to close the SideSheet
  const closeSideSheet = () => {
    showHideIntercom("show");
    setIsSideSheetOpen(false);
    eventEmitter.emit("sideSheetClosed");
  };

  return (
    <SideSheetContext.Provider
      value={{ isSideSheetOpen, openSideSheet, closeSideSheet }}
    >
      {children({ isSideSheetOpen, openSideSheet, closeSideSheet })}
    </SideSheetContext.Provider>
  );
};

export const useSideSheetContext = () => {
  const context = useContext(SideSheetContext);

  if (!context) {
    throw new Error(
      "useSideSheetContext must be used within a SideSheetProvider",
    );
  }

  return context;
};
