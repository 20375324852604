import { ToastBar, Toaster, toast } from "react-hot-toast";

interface IProps {
  isInDashboard?: boolean;
  hasCloseIcon?: boolean;
}

const Toast = ({ hasCloseIcon }: IProps) => {
  return (
    <Toaster
      toastOptions={{
        className: "m-0",
        style: {
          padding: "2rem 2.4rem",
          borderRadius: "1.2rem",
          backgroundColor: "#f3f3ff",
          boxShadow: "4px 5px #3333ff",
          color: "#080826",
          minWidth: "fit-content",
        },

        success: {
          style: {
            backgroundColor: "#e3faf1",
            boxShadow: "4px 5px #46d39a",
          },
        },

        error: {
          style: {
            backgroundColor: "#ffe8e3",
            boxShadow: "4px 5px #e3291d",
          },
        },
      }}
    >
      {(t) => (
        <ToastBar toast={t} position="bottom-center">
          {({ message }) => (
            <>
              <span className="toast__information">
                {t.type == "blank" && (
                  <span className="material-icons-round text-blue-500">
                    check_circle
                  </span>
                )}
                {t.type == "success" && (
                  <span className="material-icons-round text-green-400">
                    check_circle
                  </span>
                )}
                {t.type == "error" && (
                  <span className="material-icons-round text-red-500">
                    info
                  </span>
                )}
                <p>{message}</p>
              </span>

              {hasCloseIcon && t.type !== "loading" && (
                <i
                  className="toast__close material-icons-round"
                  onClick={() => toast.dismiss(t.id)}
                >
                  close
                </i>
              )}
            </>
          )}
        </ToastBar>
      )}
    </Toaster>
  );
};

export default Toast;
