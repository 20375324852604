import { yupResolver } from "@hookform/resolvers/yup";
import { FormItem } from "design_system/Form";

import * as Select from "@radix-ui/react-select";

import {
  RadioGroup,
  RadioGroupItem,
  RadioGroupLabel,
} from "design_system/Form/RadioGroup";
import { NumberInput } from "design_system/Inputs/NumberInput";
import { Label } from "design_system/Label";
import { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { type InferType, mixed, number, object, string } from "yup";

export const transferFundsFormSchema = object({
  fromId: string().required("From is required"),
  toId: string().required("To is required"),
  amount: number().required("Amount is required"),
  option: mixed().oneOf(["all", "partial"]),
});

type TransferFundsFormValues = InferType<typeof transferFundsFormSchema>;

export const TransferFundsForm = () => {
  const form = useForm<TransferFundsFormValues>({
    resolver: yupResolver(transferFundsFormSchema),
    defaultValues: {
      fromId: "",
      toId: "",
      amount: 0,
      option: "all",
    },
  });

  const isCustomAmount = useMemo(() => {
    return form.watch("option") === "partial";
  }, [form.watch("option")]);

  return (
    <form action="" className="mx-auto mt-40 max-w-380 space-y-40">
      <FormItem>
        <Label>From account</Label>
        <Controller
          name="option"
          control={form.control}
          defaultValue=""
          render={(props) => (
            <Select.Root {...props}>
              <Select.Trigger
                className="form-field__select"
                data-testid="from-account-dropdown"
              >
                <Select.Value aria-label={props.value}>
                  {props.value}
                </Select.Value>
                <Select.Icon className="material-icons-round">
                  keyboard_arrow_down
                </Select.Icon>
              </Select.Trigger>

              <Select.Portal>
                <Select.Content className="form-field__select__content">
                  <Select.Viewport>
                    {/* {data.map((name: string, index: number) => (
                      <Select.Item
                        key={index}
                        value={name}
                        className="form-field__select__item"
                      >
                        <Select.ItemText>{name}</Select.ItemText>
                      </Select.Item>
                    ))} */}
                  </Select.Viewport>
                </Select.Content>
              </Select.Portal>
            </Select.Root>
          )}
        />
      </FormItem>
      <FormItem>
        <Label>How much would you like to transfer?</Label>
        <input type="hidden" value={form.watch("amount")} />
        <Controller
          name="option"
          control={form.control}
          defaultValue=""
          render={(props) => (
            <RadioGroup
              onValueChange={props.onChange}
              defaultValue={props.value}
              className="space-y-12"
            >
              <div className="flex items-center space-x-8">
                <RadioGroupItem value="all" id="r1" />
                <RadioGroupLabel>Full balance - AED 55.05</RadioGroupLabel>
              </div>

              <div className="space-y-12">
                <div className="flex items-center space-x-8">
                  <RadioGroupItem value="partial" id="r2" />
                  <RadioGroupLabel>Partial amount</RadioGroupLabel>
                </div>

                {isCustomAmount && (
                  <Controller
                    name="amount"
                    control={form.control}
                    render={(props) => (
                      <NumberInput
                        {...props}
                        formatType="uaeCurrencyOnly"
                        className="w-full"
                        error={!!form.formState.errors.amount}
                        helperText={form.formState.errors.amount?.message}
                      />
                    )}
                  />
                )}
              </div>
            </RadioGroup>
          )}
        />
      </FormItem>

      <FormItem>
        <Label>To account</Label>
      </FormItem>
    </form>
  );
};
