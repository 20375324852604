import { useParams } from "react-router-dom";

import { routes } from "constants/routes";

import { Button } from "design_system/Button";
import { ArrowBack, SwapHorizontal } from "design_system/Icons";

import { Skeleton } from "design_system/Skeleton";
import { financesHooks } from "repositories/finances";
import { withCdn } from "utils/withCdn";

export const Header = () => {
  const { identifier } = useParams();

  const { data, isLoading } = financesHooks.useByIdentifier(identifier);

  return (
    <header className="w-full space-y-16 pb-24">
      <a href={routes.MANAGE.WALLET.INDEX()} className="block">
        <ArrowBack size="18px" />
      </a>

      <div className="flex w-full items-center justify-between">
        {/* Meta */}
        <div className="flex items-center gap-24">
          <figure className="h-56 w-56 overflow-hidden rounded-full bg-red-25">
            <img src={withCdn("assets/cashback_bag.png")} alt="" />
          </figure>

          <div className="space-y-4">
            <span className="body-medium md:body-large block">Cashback</span>
            <span className="header-medium md:header-large block">
              {isLoading ? (
                <Skeleton className="h-36 w-112" />
              ) : (
                data?.data?.totalBalanceFormatted
              )}
            </span>
          </div>
        </div>

        {/* Actions */}
        <a className="hidden" href={routes.MANAGE.WALLET.TRANSFER()}>
          <Button
            label="Transfer"
            Icon={SwapHorizontal}
            size="sm"
            variant="filled"
          />
        </a>
      </div>
    </header>
  );
};
