import { useTimer } from "./useTimer";

export const useOtp = () => {
  const { timer, resetTimer, isDisabled } = useTimer();
  const seconds = String(timer % 60).padStart(2, "0");
  const buttonText = isDisabled ? `Resend in 0:${seconds}` : "Resend code";

  return {
    buttonText,
    isDisabled,
    resetTimer,
  };
};
