import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import qs from "qs";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import ReactOnRails from "react-on-rails";
import * as Yup from "yup";

import TextInputWithCircularProgress from "components/Inputs/TextInputWithCircularProgress";

import routes from "constants/routes";
import { isAppPlatform } from "helpers/isAppPlatform";
import type { IEditProps } from "./index";

const validationEditSchema = Yup.object().shape({
  title: Yup.string().required("Please give your payment a title"),
});

export const PaymentLinkEdit = (props: IEditProps) => {
  const { title, description, identifier } = props;

  const { errors, handleSubmit, register, watch } = useForm<IEditProps>({
    defaultValues: {
      title: title,
      description: description,
    },
    resolver: yupResolver(validationEditSchema),
    mode: "onBlur",
  });

  const { title: newTitle, description: newDescription } = watch();

  const notifySendComplete = () => {
    const messageParams = {
      message: `Payment link details have been updated!`,
    };
    window.location.href = `${routes.MANAGE.LINKS.SHOW(identifier)}?${qs.stringify(messageParams)}`;
  };

  const onSubmit = () => {
    const queryParams = {
      authenticity_token: ReactOnRails.authenticityToken(),
    };

    axios
      .patch(
        `${routes.MANAGE.LINKS.SHOW(identifier)}?${qs.stringify(queryParams)}`,
        {
          title: newTitle,
          description: newDescription,
        },
      )
      .then((res) => {
        if (res.status === 200) {
          notifySendComplete();
        }
      })
      .catch((error) => {
        toast.error("Oops! Something went wrong. Try again later", {
          duration: 3000,
        });
        console.error(error);
      });
  };

  return (
    <main className="create-link">
      <form onSubmit={handleSubmit(onSubmit)}>
        <header>
          <a
            className="create-link__back-button material-icons-round"
            href={routes.MANAGE.LINKS.SHOW(identifier)}
          >
            arrow_back
          </a>
          {!isAppPlatform() && (
            <a
              href={routes.MANAGE.LINKS.SHOW(identifier)}
              className="create-link__close-button material-icons-round"
            >
              close
            </a>
          )}
        </header>
        <section className="create-link__customize-link-step">
          <div className="process">
            <div className="process__content">
              <h1 className="process__content__heading">
                Edit payment link details
              </h1>
              <p className="process__content__info">
                Edit your payment link title and description that appear for
                your customer
              </p>
            </div>

            <TextInputWithCircularProgress
              inputId="title"
              name="title"
              register={register}
              labelText="Title"
              defaultValue={title}
              maxLength={50}
              errors={errors.title}
              errorMessage={errors.title?.message}
              autoFocus
            />

            <TextInputWithCircularProgress
              inputId="description"
              name="description"
              register={register}
              labelText="Description (optional)"
              defaultValue={description || undefined}
              maxLength={75}
            />

            <div>
              <div className="mt-48 hidden items-center justify-center md:flex">
                <button
                  type="submit"
                  className="button button--primary button--solid button--lg"
                >
                  Save
                </button>
              </div>
            </div>
          </div>

          <button
            type="submit"
            className="button button--primary button--solid button--lg w-full md:hidden"
          >
            Save
          </button>
        </section>
      </form>
    </main>
  );
};
