import { useAnimation } from "framer-motion";
import { useEffect } from "react";

export const useLoadTransition = (duration: number) => {
  const controls = useAnimation();

  useEffect(() => {
    controls.start((index) => ({
      opacity: 1,
      y: 0,
      transition: { duration: duration, delay: index * 0.2 },
    }));
  }, [controls]);

  return controls;
};
