import { Button } from "design_system/Button";

import routes from "constants/routes";
import { withCdn } from "utils/withCdn";

export const CardsUpsellBanner = () => {
  const handleMoveToKyb = () => {
    window.location.href = routes.MANAGE.CARDS.KYB();
  };

  return (
    <section
      style={{ maxWidth: "850px" }}
      className="grid-absolute h-full max-h-338 w-full md:max-h-auto"
    >
      <figure className="h-full w-full overflow-hidden rounded-12">
        <img
          src={withCdn("assets/banner_bg_horizontal.webp")}
          className="hidden h-full w-full object-cover md:block"
          alt=""
        />
        <img
          src={withCdn("assets/banner_bg_vertical.webp")}
          className="h-full w-full object-cover md:hidden"
          alt=""
        />
      </figure>

      <div className="flex h-full flex-col items-center justify-between gap-20 p-24 md:flex-row md:gap-0 md:px-40">
        <div className="text-surface-1 md:w-7/12 ">
          <p className="header-small md:header-medium mt-6 text-center md:text-left">
            Corporate cards, built for saving
          </p>
          <p className="body-small md:body-medium mt-8 text-center md:text-left">
            Instant cashback. No transaction fees. Issue virtual and physical
            cards, set spend limits, and manage company-wide expenses in
            real-time.
          </p>
          <Button
            label="Get started"
            size="md"
            variant="outlined"
            classes="mt-16 mx-auto md:mx-0"
            onClick={handleMoveToKyb}
          />
        </div>

        <figure
          style={{
            filter: "drop-shadow(12.99px 20.2px 33.88px rgba(0, 0, 0, 0.44))",
          }}
        >
          <img
            className="grid-absolute max-w-92 overflow-hidden rounded-6 md:max-w-112"
            src={withCdn("assets/mamo_card_no_radius.png")}
            alt=""
          />
        </figure>
      </div>
    </section>
  );
};
