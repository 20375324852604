import { isEmpty } from "lodash";
import type React from "react";
import { cn } from "utils/classNames";

import { Help } from "design_system/Icons";
import { List } from "design_system/List";

import { colorsV2 } from "constants/colors";
import type { ISettlementBalanceProps } from "interfaces/overview/IOverview";

export const SettlementBalance: React.FC<ISettlementBalanceProps> = ({
  nextSettlementAmount,
  outstandingBalance,
  hasBorder,
}: ISettlementBalanceProps) => {
  const isNextSettlementAmountEmpty = isEmpty(nextSettlementAmount);

  return (
    <div
      className={cn("space-y-40", {
        "rounded-16 border border-black-50 p-24": hasBorder,
      })}
    >
      <div className="space-y-16 py-8 font-medium">
        <p className="label-large text-text-color-02">Available balance</p>
        <p className="display-medium">AED {outstandingBalance}</p>
      </div>

      {!isNextSettlementAmountEmpty && (
        <div className="w-full py-24 md:w-1/2">
          <List
            title="Upcoming settlements"
            columnHeader1="Date"
            columnHeader2="Amount"
            showTooltip
            tooltipProps={{
              content:
                "Upcoming settlement amounts may differ depending on payments received, refunds, disputes and other changes on your account",
              contentWidth: "24.4rem",
              Icon: <Help size="18" fill={colorsV2.text[3]} />,
            }}
          >
            {nextSettlementAmount?.slice(0, 6).map((next, index) => (
              <List.Item
                key={index}
                value1={next.settlementDate}
                value2={`${next.currency} ${next.amount}`}
              />
            ))}
          </List>
        </div>
      )}
    </div>
  );
};
