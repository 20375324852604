import { DEFAULT_SUCCESS_STATUS_FILTERS } from "constants/paymentStatusFilters";
import type IPaymentsAction from "interfaces/payments/IPaymentsAction";
import type IPaymentsState from "interfaces/payments/IPaymentsState";

export enum PaymentActionTypes {
  APPLY_SEARCH_FILTERS = "APPLY_SEARCH_FILTERS",
  CLEAR_SEARCH_FILTERS = "CLEAR_SEARCH_FILTERS",
  LOADING = "LOADING",
  SET_DATA = "SET_DATA",
  SET_IS_OPEN_SEARCH_FILTERS = "SET_IS_OPEN_SEARCH_FILTERS",
  SET_PAGE = "SET_PAGE",
  SET_SEARCH_QUERY = "SET_SEARCH_QUERY",
  APPLY_SEARCH_DATE = "APPLY_SEARCH_DATE",
}

export const initialState: IPaymentsState = {
  payments: [],
  isLoading: false,
  isOpenSearchFiltersDesktop: false,
  isOpenSearchFiltersMobile: false,
  filters: {
    query: "",
    status: [...DEFAULT_SUCCESS_STATUS_FILTERS],
    amount: {
      from: "",
      to: "",
    },
  },
  paginationMeta: {
    page: 1,
    perPage: 10,
    totalPages: null,
    nextPage: null,
    prevPage: null,
    from: null,
    to: null,
    totalCount: null,
  },
};

function paymentsReducer(state: IPaymentsState, action: IPaymentsAction) {
  switch (action.type) {
    case PaymentActionTypes.APPLY_SEARCH_FILTERS:
      return {
        ...state,
        isOpenSearchFiltersDesktop: false,
        isOpenSearchFiltersMobile: false,
        filters: {
          ...state.filters,
          status: [...action.payload.status],
          amount: {
            ...state.filters.amount,
            from: action.payload.amount.from || null,
            to: action.payload.amount.to || null,
          },
        },
        paginationMeta: {
          ...state.paginationMeta,
          page: 1,
        },
      };
    case PaymentActionTypes.CLEAR_SEARCH_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          status: [],
          amount: {
            from: null,
            to: null,
          },
        },
        paginationMeta: {
          ...state.paginationMeta,
          page: 1,
        },
      };
    case PaymentActionTypes.LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case PaymentActionTypes.SET_DATA:
      return {
        ...state,
        payments: action.payload.data,
        paginationMeta: {
          ...state.paginationMeta,
          ...action.payload.paginationMeta,
        },
      };
    case PaymentActionTypes.SET_IS_OPEN_SEARCH_FILTERS:
      return {
        ...state,
        isOpenSearchFiltersDesktop:
          action.payload.type == "popover" ? action.payload.open : false,
        isOpenSearchFiltersMobile:
          action.payload.type == "dialog" ? action.payload.open : false,
      };
    case PaymentActionTypes.SET_PAGE:
      return {
        ...state,
        paginationMeta: {
          ...state.paginationMeta,
          page: action.payload,
        },
      };
    case PaymentActionTypes.SET_SEARCH_QUERY:
      return {
        ...state,
        filters: {
          ...state.filters,
          query: action.payload,
        },
        paginationMeta: {
          ...state.paginationMeta,
          page: 1,
        },
      };
    case PaymentActionTypes.APPLY_SEARCH_DATE:
      return {
        ...state,
        paginationMeta: {
          ...state.paginationMeta,
          page: 1,
        },
      };
    default:
      return state;
  }
}

export default paymentsReducer;
