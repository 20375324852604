import type IChoosePlanProps from "../interfaces/IChoosePlanProps";

export enum ChoosePlanActionTypes {
  SET_DATA = "SET_DATA",
  SET_GROWTH_PLAN = "SET_GROWTH_PLAN",
  SET_PREMIUM_PLAN = "SET_PREMIUM_PLAN",
  SET_ENTERPRISE_PLAN = "SET_ENTERPRISE_PLAN",
  SET_ULTRA_PLAN = "SET_ULTRA_PLAN",
}

export enum SettlementOptions {
  STANDARD = "standard",
  EXPEDITED_4_DAYS = "expedited_4_days",
  ULTRA_3_DAYS = "ultra_3_days",
  BUSINESS_2_DAYS = "business_2_days",
  NEXT_DAY = "next_day",
  SAME_DAY = "same_day",
  GROWTH_EXPEDITED_4_DAYS = "growth_expedited_4_days",
}

export const initialState: IChoosePlanProps = {
  activePlan: {
    name: "",
    id: "",
  },
  plans: [
    {
      name: "",
      id: "",
    },
  ],
  selectedPlan: null, // Premium, Growth
  settlementOption: null,
  eligibleForExpeditedSettlements: false,
};

const choosePlanReducer = (state: IChoosePlanProps, action: any) => {
  switch (action.type) {
    case ChoosePlanActionTypes.SET_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case ChoosePlanActionTypes.SET_GROWTH_PLAN:
      return {
        ...state,
        selectedPlan: "Growth",
      };
    case ChoosePlanActionTypes.SET_PREMIUM_PLAN:
      return {
        ...state,
        selectedPlan: "Premium",
      };
    case ChoosePlanActionTypes.SET_ENTERPRISE_PLAN:
      return {
        ...state,
        selectedPlan: "Enterprise",
      };
    case ChoosePlanActionTypes.SET_ULTRA_PLAN:
      return {
        ...state,
        selectedPlan: "Ultra",
      };
    default:
      return state;
  }
};

export default choosePlanReducer;
