import isEmpty from "lodash/isEmpty";
import { cn } from "utils/classNames";

import { Checkbox } from "design_system/Inputs/Checkbox";

import { colorsV2 } from "constants/colors";
import type { ChoiceCardProps } from "./typeDefs";

export const ChoiceCard = ({
  checkboxProps,
  children,
  disabled,
  htmlFor,
  Icon,
  imgUrl,
  isSelected,
  label,
  leadingElement,
  supportingText,
}: ChoiceCardProps) => {
  return (
    <div
      className={cn(
        "flex flex-col gap-16 rounded-12 border border-border-2 bg-surface-1 p-16 md:gap-16",
        {
          "hover:border-border-1": !isSelected,
          "hover:border-border-4": isSelected,
        },
      )}
    >
      <label
        className={cn(
          "flex cursor-pointer items-center justify-between gap-12 md:gap-16",
          {
            "pointer-events-none ": disabled,
          },
        )}
        htmlFor={htmlFor}
      >
        {!isEmpty(leadingElement) && (
          <div
            className={cn("flex-shrink-0 py-2", {
              "opacity-60": disabled,
            })}
          >
            {leadingElement === "icon" && (
              <div
                className={cn(
                  "flex h-40 w-40 items-center justify-center rounded-full md:h-48 md:w-48",
                  {
                    "bg-surface-2": !isSelected,
                    "bg-surface-6": isSelected,
                  },
                )}
              >
                <Icon
                  size="18"
                  fill={isSelected ? colorsV2.accent.blue[1] : colorsV2.text[3]}
                />
              </div>
            )}
            {leadingElement === "image" && (
              <img
                src={imgUrl}
                alt=""
                className="h-40 w-40 object-contain md:h-48 md:w-48"
              />
            )}
          </div>
        )}

        <div className="flex-grow">
          <p
            className={cn("label-medium md:label-large", {
              "text-text-color-03": disabled,
            })}
          >
            {label}
          </p>
          <p
            className={cn("body-small md:body-medium text-text-color-02", {
              "text-text-color-03": disabled,
            })}
          >
            {supportingText}
          </p>
        </div>

        <div className="-mt-16">
          <Checkbox
            checkboxSize="sm"
            checked={isSelected}
            inputId={htmlFor}
            disabled={disabled}
            {...checkboxProps}
          />
        </div>
      </label>

      {children}
    </div>
  );
};
