import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";

import { useAppSelector } from "store";

import { setTrigger } from "reducers/redux/ScrollTrigger/scrollTriggerSlice";

export const useScrollTrigger = (bannerRef) => {
  const dispatch = useDispatch();
  const trigger = useAppSelector((state) => state.scrollTrigger.trigger);

  const handleScroll = useCallback(() => {
    if (bannerRef?.current) {
      const bannerHeight = bannerRef.current.getBoundingClientRect().height;
      const remainingHeightLeft =
        document.documentElement.scrollHeight -
        window.innerHeight -
        bannerHeight;

      if (bannerHeight + 80 < remainingHeightLeft) {
        dispatch(setTrigger(Math.round(window.scrollY) > bannerHeight));
      } else {
        dispatch(setTrigger(false));
      }
    } else {
      const remainingHeightLeft =
        document.documentElement.scrollHeight - window.innerHeight;
      const threshold = 120;

      if (threshold < remainingHeightLeft) {
        dispatch(setTrigger(Math.round(window.scrollY) > threshold));
      } else {
        dispatch(setTrigger(false));
      }
    }
  }, [dispatch, bannerRef]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    // Reset trigger when the URL changes
    dispatch(setTrigger(false));
  }, [location, dispatch]);

  return { trigger };
};
