// eventEmitter.ts
type EventListener<T = unknown> = (data: T) => void;

interface EventMap {
  [eventName: string]: EventListener[];
}

let events: EventMap = {};

export const eventEmitter = {
  /**
   * Registers an event listener for a given event name.
   * @param eventName The name of the event to listen for.
   * @param listener The callback function to be invoked when the event is emitted.
   */
  on<T>(eventName: string, listener: EventListener<T>): void {
    if (!events[eventName]) {
      events[eventName] = [];
    }
    events[eventName].push(listener as EventListener<unknown>);
  },

  /**
   * Removes an event listener for a given event name.
   * @param eventName The name of the event.
   * @param listenerToRemove The listener function to remove.
   */
  off<T>(eventName: string, listenerToRemove: EventListener<T>): void {
    if (!events[eventName]) {
      return;
    }
    events[eventName] = events[eventName].filter(
      (listener) => listener !== listenerToRemove,
    );
  },

  /**
   * Emits an event to all registered listeners, passing the provided data to each listener.
   * @param eventName The name of the event to emit.
   * @param data The data to pass to each listener function.
   */
  emit<T>(eventName: string, data?: T): void {
    if (!events[eventName]) {
      return;
    }

    for (const listener of events[eventName]) {
      listener(data);
    }
  },

  /**
   * Clears all listeners for all events. Useful for cleanup or testing teardown.
   */
  clear(): void {
    events = {};
  },
};
