import { InputAdornment } from "@mui/material";

import { Search } from "design_system/Icons";
import { TextInput } from "design_system/Inputs/TextInput";
import { EndAdornment } from "../components/EndAdornment";

import { colorsV2 } from "constants/colors";

export const useRenderInput = ({
  inputProps,
  searchText,
  isLoading,
  onClose,
  onBlur,
}) => {
  const renderInput = (muiInputProps) => {
    return (
      <TextInput
        {...muiInputProps}
        {...inputProps}
        color="secondary"
        variant="outlined"
        fullWidth
        InputProps={{
          ...muiInputProps.InputProps,
          onBlur,
          startAdornment: (
            <InputAdornment position="start">
              <Search size="18" fill={colorsV2.text[2]} />
            </InputAdornment>
          ),
          endAdornment: !!searchText ? (
            <EndAdornment
              searchText={searchText}
              isLoading={isLoading}
              onClose={onClose}
            />
          ) : null,
        }}
        className="BlazeSearchInput"
      />
    );
  };

  return {
    renderInput,
  };
};
