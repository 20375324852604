import { useLoadTransition } from "components/ChoosePlan/shared/hooks/useLoadTransition";
import { motion } from "framer-motion";

interface IFadeInProps {
  children: React.ReactNode;
  custom: number;
  duration?: number;
  yOffset?: number;
  className?: string;
}

export const FadeIn = ({
  children,
  custom,
  duration = 0.4,
  yOffset = 20,
  className,
}: IFadeInProps) => {
  const controls = useLoadTransition(duration);

  return (
    <motion.div
      custom={custom}
      initial={{ opacity: 0, y: yOffset }}
      animate={controls}
      className={className || ""}
    >
      {children}
    </motion.div>
  );
};
