import { createSlice } from "@reduxjs/toolkit";
import type {
  CardsDashboardSlice_InitState,
  SetActiveViewPayload,
} from "../CardsDashboardTypes";

const initialState: CardsDashboardSlice_InitState = {
  // TODO: Slowly migrate subviews to be managed by redux
  activeView: "paywall",
};

export const cardsDashboardSlice = createSlice({
  name: "cardsDashboard",
  initialState,
  reducers: {
    setActiveView: (state, action: SetActiveViewPayload) => {
      state.activeView = action.payload;
    },
  },
});

export const { setActiveView } = cardsDashboardSlice.actions;

export default cardsDashboardSlice.reducer;
