import { type QueryObserverResult, useQuery } from "@tanstack/react-query";
import axios from "axios";

import routes from "constants/routes";
import type { ISettlementBalanceProps } from "interfaces/overview/IOverview";

export const useGetUpcomingSettlements = (config?: { enabled: boolean }) => {
  // TODO: @Phu please create an endpoint for getting upcoming settlement balance,
  // we use routes for FE routing and not for API endpoints
  const url = routes.MANAGE.OVERVIEW.INDEX();

  const getUpcomingSettlements: QueryObserverResult<ISettlementBalanceProps> =
    useQuery({
      enabled: config?.enabled ?? true,
      queryKey: ["getUpcomingSettlements"],
      queryFn: () => axios.get(url).then((res) => res.data),
    });

  return {
    getUpcomingSettlements,
  };
};
