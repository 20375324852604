import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";

import { endpoints } from "constants/apiEndpoints";
import { stringifiedQueryParams } from "constants/queryParams";
import { snack } from "design_system/Snackbar";
import { SentryLoggingService } from "init/SentryLoggingService";

export const useRemoveUser = (identifier: string) => {
  const queryClient = useQueryClient();

  const removeUser = useMutation({
    mutationKey: ["removeUser"],
    mutationFn: async () => {
      return await axios.delete(
        `${endpoints.MANAGE.API.SETTINGS.TEAM.REMOVE_USER(identifier)}?${stringifiedQueryParams}`,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getUsers"] });
    },
    onError: (error: Error) => {
      SentryLoggingService.captureException(error.message, {
        feature: "[Team][RemoveUser]",
        function: "removeUser",
        file: "useRemoveUser",
        data: JSON.stringify({ error: error.message }),
      });
      snack({
        title: "Oops! Something went wrong. Try again later",
        leadingElement: "icon",
        variant: "critical",
      });
    },
  });

  return { removeUser };
};
