import { type InferType, mixed, object, string } from "yup";

export const Channels = {
  SMS: "sms",
  WHATSAPP: "whatsapp",
} as const;

export const CHANNEL_TO_NAME = {
  [Channels.SMS]: "SMS",
  [Channels.WHATSAPP]: "WhatsApp",
} as const;

export type Channel = (typeof Channels)[keyof typeof Channels];

export type Modal = "2fa" | undefined;

export const VIEWS = {
  PHONE_NUMBER: "phone-number",
  ONE_TIME_CODE: "one-time-code",
} as const;

export type Views = (typeof VIEWS)[keyof typeof VIEWS];

export const querySchema = object({
  view: mixed<Views>().oneOf(Object.values(VIEWS)),
  modal: mixed<Modal>().oneOf(["2fa"]),
  channel: mixed<Channel>().oneOf(Object.values(Channels)),
  phoneNumber: string(),
});

export type QueryParams = InferType<typeof querySchema>;
