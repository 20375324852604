export const expirationOptionLabels: { [key: string]: string } = {
  "after-7-days": "After 7 days",
  "after-30-days": "After 30 days",
  custom: "Custom",
};

export const expirationOptions: { [key: string]: string } = {
  "after-7-days": "after-7-days",
  "after-30-days": "after-30-days",
  custom: "custom",
};

export const expirationItems = [
  {
    id: "after-7-days",
    value: "after-7-days",
  },
  {
    id: "after-30-days",
    value: "after-30-days",
  },
  {
    id: "custom",
    value: "custom",
  },
];
