import { colorsV2 } from "constants/colors";
import type { SvgIconProps } from "types/Icon";

export const CreditCard = ({
  size,
  fill = colorsV2.text[1],
  ...rest
}: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g id="Name=credit card, Size=24">
        <path
          id="Vector"
          d="M22.1555 5.5538V18.4462C22.1555 18.9062 21.986 19.3051 21.6471 19.6428C21.3081 19.9806 20.9079 20.1495 20.4462 20.1495H3.55384C3.09385 20.1495 2.69498 19.9806 2.35721 19.6428C2.01946 19.3051 1.85059 18.9062 1.85059 18.4462V5.5538C1.85059 5.09218 2.01946 4.69191 2.35721 4.35298C2.69498 4.01403 3.09385 3.84455 3.55384 3.84455H20.4462C20.9079 3.84455 21.3081 4.01403 21.6471 4.35298C21.986 4.69191 22.1555 5.09218 22.1555 5.5538ZM3.55384 8.21305H20.4462V5.5538H3.55384V8.21305ZM3.55384 11.438V18.4462H20.4462V11.438H3.55384Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};
