import camelCase from "lodash/camelCase";
import upperFirst from "lodash/upperFirst";

export const flattenSearchResults = (searchResults) => {
  return searchResults?.data?.reduce((acc, section) => {
    const items = section.items.map((item) => ({
      ...item,
      query: searchResults.query,
      sectionId: section.sectionId,
      sectionName: section.sectionName,
      defaultIconName: section.defaultIconName,
      viewKind: section.viewKind,
    }));
    return [...acc, ...items];
  }, []);
};

export const getIconName = ({ defaultIconName, iconName, viewKind }) => {
  if (viewKind === "icon") {
    let name = defaultIconName;
    if (iconName) name = iconName;
    return upperFirst(camelCase(name));
  }
  if (viewKind === "status") {
    return iconName || defaultIconName;
  }
  return;
};
