import { useQuery } from "@tanstack/react-query";

import type { PaginatedRequest } from "repositories/_base/repository";
import { financesRepository } from "repositories/finances/finances.repository";
import { FINANCES_KEYS } from "repositories/finances/keys";

export const useTransactionsFor = (
  identifier: string,
  paginationMeta: PaginatedRequest = {
    page: 1,
    perPage: 100,
  },
) => {
  return useQuery({
    queryKey: FINANCES_KEYS.TRANSACTIONS(identifier, paginationMeta),
    keepPreviousData: true,
    queryFn: async () => {
      const response = await financesRepository.transactionsFor(
        identifier,
        paginationMeta,
      );

      if (!response.success && "error" in response) {
        financesRepository.sentryLogError({
          error: response.error.message,
          feature: "finances",
          functionName: "useTransactionsFor",
          data: JSON.stringify(response.error),
        });

        return response;
      }

      return response;
    },
  });
};
