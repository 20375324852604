import { type QueryObserverResult, useQuery } from "@tanstack/react-query";
import axios from "axios";

import { endpoints } from "constants/apiEndpoints";
import type { TBusinessDetails } from "../types";

export const useGetBusinessDetails = () => {
  const url = endpoints.MANAGE.API.SETTINGS.BUSINESS.SHOW_DETAILS;

  const getDetails: QueryObserverResult<TBusinessDetails> = useQuery({
    queryKey: ["getBusinessDetails"],
    queryFn: () => axios.get(url).then((res) => res.data),
  });

  return {
    getDetails,
  };
};
