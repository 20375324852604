import { MerchantOnboardingStatus } from "enums/merchant/merchant-onboarding-status.enum";
import { useMemo } from "react";
import type { CurrentMerchant, CurrentUser } from "types/MerchantInfo";

export type UseSetupVerificationPrimaryItemProps = {
  currentMerchant: CurrentMerchant;
  currentUser: CurrentUser;
};
export const useSetupVerificationPrimaryItem = ({
  currentMerchant,
  currentUser,
}: UseSetupVerificationPrimaryItemProps) => {
  return useMemo(() => {
    const isOwner = currentUser.roles.some((role) => role.name === "Owner");

    if (!isOwner) {
      return false;
    }

    const allowedStatuses: MerchantOnboardingStatus[] = [
      MerchantOnboardingStatus.not_complete,
      MerchantOnboardingStatus.pending_verification,
      MerchantOnboardingStatus.failed_verification,
    ];

    return allowedStatuses.includes(
      currentMerchant.status as MerchantOnboardingStatus,
    );
  }, [currentMerchant, currentUser]);
};
