import { ChoosePlan } from "components/ChoosePlan/ChoosePlan";
import { ReactQueryProvider } from "components/Providers";
import type IRailsContext from "interfaces/IRailsContext";

export const ChoosePlanRoot = (_, context: IRailsContext) => {
  return (
    <ReactQueryProvider>
      <ChoosePlan railsContext={context} />
    </ReactQueryProvider>
  );
};
