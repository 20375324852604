import { type QueryObserverResult, useQuery } from "@tanstack/react-query";
import axios from "axios";
import qs from "qs";
import { useEffect, useState } from "react";

import { endpoints } from "constants/apiEndpoints";

type UseGetGlobalSearchResults = {
  searchQuery: string;
  debouncedSearchText: string;
};

export const useGetGlobalSearchResults = (param: UseGetGlobalSearchResults) => {
  const [searchQuery, setSearchQuery] = useState(param.searchQuery);

  useEffect(() => {
    // Debounce or throttle API call here based on searchQuery
    const handler = setTimeout(() => {
      setSearchQuery(param.searchQuery);
    }, 1000);

    return () => clearTimeout(handler);
  }, [param.searchQuery]);

  const getGlobalSearchResults: QueryObserverResult<any> = useQuery({
    queryKey: ["getGlobalSearchResults", { searchQuery }],
    enabled: !!searchQuery && searchQuery.length > 2,
    queryFn: async () => {
      const url = `${endpoints.MANAGE.API.GLOBAL_SEARCH}?${qs.stringify(
        { query: searchQuery },
        {
          skipNulls: true,
          arrayFormat: "brackets",
        },
      )}`;
      const response: any = await axios.get(url);

      if (response.data) {
        return {
          ...response.data,
          query: searchQuery,
        };
      }
      return [];
    },
  });

  return {
    getGlobalSearchResults,
  };
};
