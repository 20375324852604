import { RouterProvider, createBrowserRouter } from "react-router-dom";

import { ReactQueryProvider, ReduxProvider } from "components/Providers";
import { CurrentSessionProvider } from "components/Providers/CurrentSession.provider";

import { routes } from "constants/routes";

import { Aside } from "features/VerificationSetup/_containers/aside";

import type IRailsContext from "interfaces/IRailsContext";
import type { SessionContext } from "types/SessionContext";
import { VerificationSetupLayout } from "./views/layout";

// Router definition
const router = createBrowserRouter([
  {
    path: "/manage/setup",
    element: <VerificationSetupLayout asideChildren={<Aside />} />,
    children: [
      {
        path: routes.MANAGE.SETUP.INDEX(),
        lazy: () =>
          import("./views/welcome").then((module) => ({
            Component: module.WelcomeRoot,
          })),
      },
      {
        path: routes.MANAGE.SETUP.OUR_REVIEW(),
        lazy: () =>
          import("./views/our-review").then((module) => ({
            Component: module.OurReview,
          })),
      },
      {
        path: routes.MANAGE.SETUP.SUBMISSION_SUCCESS(),
        lazy: () =>
          import("./views/submission-success").then((module) => ({
            Component: module.SubmissionSuccess,
          })),
      },
      {
        path: routes.MANAGE.SETUP.VERIFICATION_FAILED(),
        lazy: () =>
          import("./views/verification-failed").then((module) => ({
            Component: module.VerificationFailed,
          })),
      },
    ],
  },
]);

export const VerificationSetup = (props: unknown, context: IRailsContext) => {
  const sessionContext = context.sessionContext as SessionContext; // Temp, remove it when we will move from .d.ts to .ts

  return (
    <ReduxProvider>
      <CurrentSessionProvider sessionContext={sessionContext}>
        <ReactQueryProvider>
          <RouterProvider router={router} />
        </ReactQueryProvider>
      </CurrentSessionProvider>
    </ReduxProvider>
  );
};
