import { cn } from "utils/classNames";

import { Card } from "design_system/Card";
import { Help } from "design_system/Icons";
import { Tooltip } from "design_system/Tooltip";

import { colorsV2 } from "constants/colors";
import type { ITooltipProps } from "design_system/Tooltip/Tooltip";
import { convertToLocaleStringHelper } from "helpers/currency";

export interface IStatCardProps {
  /** Optional: Any additional classes to be added to the container */
  cardClasses?: string;
  /** If currency, then show AED */
  isCurrencyValue?: boolean;
  /** If the statistical data is weekly or monthly */
  isMonth?: boolean;
  /** To check if the change in percentage is positive or not */
  isPositiveValue: boolean;
  /** Title to give context of the data */
  title: string;
  tooltipProps?: ITooltipProps;
  /** Value to show relevance to the title */
  value: number;
  /** Value of percentage changed in context to the title */
  valuePercentage: number | string;
}

export const StatCard = ({
  cardClasses,
  isCurrencyValue = true,
  isMonth = false,
  isPositiveValue,
  title,
  tooltipProps,
  value,
  valuePercentage,
}: IStatCardProps) => {
  return (
    <Card
      hasBorder
      cardClasses={cn("space-y-24", {
        [cardClasses]: cardClasses,
      })}
    >
      <header className="flex gap-8">
        <p className="header-small text-text-color-02">{title}</p>
        {tooltipProps && (
          <Tooltip
            {...tooltipProps}
            Icon={<Help size="18" fill={colorsV2.text[3]} />}
          />
        )}
      </header>

      <p className="display-small">
        {isCurrencyValue && `AED ${convertToLocaleStringHelper(value)}`}
        {!isCurrencyValue && value}
      </p>

      <p className="body-medium text-text-color-02">
        <span
          className={cn("", {
            "text-accent-green-01": isPositiveValue,
            "text-accent-red-01": !isPositiveValue,
          })}
        >
          {isPositiveValue && "+"}
          {valuePercentage}%
        </span>{" "}
        compared to this time last {isMonth ? "month" : "week"}
      </p>
    </Card>
  );
};
