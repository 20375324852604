import { Fragment } from "react";

import routes from "constants/routes";
import type IPayment from "interfaces/payments/IPayment";
import PaymentStatus from "./PaymentStatus";

interface IPaymentsListProps {
  payments: Array<IPayment>;
}

const PaymentsList: React.FC<IPaymentsListProps> = ({ payments }) => {
  return (
    <div className="table-container">
      <div className="table-container__header">
        <div className="table-container__header__row">
          <p className="table-container__heading">Payment ID</p>
          <p className="table-container__heading">Customer</p>
          <p className="table-container__heading">Amount</p>
          <p className="table-container__heading">Payment date</p>
          <p className="table-container__heading">Payment status</p>
          <p className="table-container__heading">Settlement date</p>
        </div>
      </div>
      <div className="table-container__body">
        {payments.map((payment: IPayment) => (
          <Fragment key={payment.paymentId}>
            {/* CARD LAYOUT ON MOBILE */}
            <a
              className="card card--border block xl:hidden"
              href={routes.MANAGE.PAYMENTS.SHOW(payment.paymentId)}
            >
              <div className="space-y-8 text-14">
                <div className="flex justify-between font-medium xl:font-regular">
                  <p>{payment.customer.fullName}</p>
                  <p>{payment.amountFormatted}</p>
                </div>
                <div className="flex justify-between text-black-300">
                  <p>Payment ID</p>
                  <p>{payment.paymentId}</p>
                </div>
                {payment.externalId && (
                  <div className="flex justify-between text-black-300">
                    <p>External ID</p>
                    <p>{payment.externalId}</p>
                  </div>
                )}
                <div className="flex justify-between text-black-300">
                  <p>Payment date</p>
                  <p>{payment.paymentDate}</p>
                </div>
                <div className="flex justify-between text-black-300">
                  <p>Settlement date</p>
                  <p>{payment.settlementDate}</p>
                </div>
                <div className="flex items-center justify-between text-black-300">
                  <p>Payment status</p>
                  <PaymentStatus payment={payment} />
                </div>
              </div>
            </a>

            {/* TABLE LAYOUT ON DESKTOP */}
            <a
              className="table-container__row hidden xl:table-row"
              href={routes.MANAGE.PAYMENTS.SHOW(payment.paymentId)}
            >
              <div className="table-container__data-group text-14">
                <div className="table-container__data">
                  <p>{payment.paymentId}</p>
                  {payment.externalId && (
                    <p className="text-12 text-black-300">
                      External ID {payment.externalId}
                    </p>
                  )}
                </div>
                <div className="table-container__data">
                  <p>{payment.customer.fullName}</p>
                </div>
                <div className="table-container__data">
                  <p>{payment.amountFormatted}</p>
                </div>
                <div className="table-container__data">
                  <p>{payment.paymentDate}</p>
                </div>
                <div className="table-container__data">
                  <PaymentStatus payment={payment} />
                </div>
                <div className="table-container__data">
                  <p>{payment.settlementDate}</p>
                </div>
              </div>
            </a>
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default PaymentsList;
