import { yupResolver } from "@hookform/resolvers/yup";
import { isUndefined } from "lodash";
import type { FunctionComponent } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

import Button from "components/Button/Button";
import { MBCurrencyInput } from "components/Inputs/CurrencyInput";

import routes from "constants/routes";
import { convertToLocaleStringHelper } from "helpers/currency";
import { isAppPlatform } from "helpers/isAppPlatform";
import { useGlobalConfig } from "hooks/useGetGlobalConfig";
import type IRailsContext from "interfaces/IRailsContext";
import { useUpdateAmount } from "../shared/hooks/useUpdateAmount";
import { handleClose } from "../shared/utils/close";

interface IProps {
  pos: any;
  railsContext: IRailsContext;
}

interface IFormData {
  amount: number;
}

export const POSGeneratePaymentContainer: FunctionComponent<IProps> = (
  props: IProps,
) => {
  const { globalConfig } = useGlobalConfig();
  const { updateAmount } = useUpdateAmount();

  const minimumAmounts = (currency: string) => {
    if (isUndefined(globalConfig)) {
      return 2;
    }

    if (currency === "AED") {
      return globalConfig.minimumPaymentAmountAED;
    }

    return globalConfig.minimumPaymentAmountOther;
  };

  const generatePaymentFormSchema = Yup.object().shape({
    amount: Yup.string()
      .required("An amount is required")
      .test(
        "amount",
        `The minimum payment amount is AED ${convertToLocaleStringHelper(minimumAmounts("AED"))}`,
        (val) =>
          val != undefined && Number.parseFloat(val) >= minimumAmounts("AED"),
      )
      .test(
        "amount",
        `The maximum payment amount is AED ${convertToLocaleStringHelper(globalConfig?.maximumPaymentAmountAED)}`,
        (val) =>
          val != undefined &&
          Number.parseFloat(val) <=
            (globalConfig?.maximumPaymentAmountAED || 20000),
      ),
  });

  const { errors, handleSubmit, control } = useForm<IFormData>({
    resolver: yupResolver(generatePaymentFormSchema),
    mode: "onSubmit",
  });

  const onSubmit = async (data: IFormData) => {
    try {
      await updateAmount(
        props.railsContext?.sessionContext?.currentMerchant?.identifier,
        {
          amount: data.amount,
        },
      ).then(() => {
        window.location.href = routes.MANAGE.POS.CONFIRMATION();
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <main className="create-link">
      {!isAppPlatform() && (
        <header>
          <button
            className="create-link__close-button material-icons-round"
            onClick={handleClose}
          >
            close
          </button>
        </header>
      )}

      <section className="bg-white pt-48 md:mx-auto md:max-w-3xl md:rounded-16 md:p-48 lg:max-w-5xl">
        <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
          <div className="space-y-48">
            <div className="flex flex-col gap-16 text-center">
              <span data-testid="page-title" className="font-bold text-28">
                Enter the payment amount
              </span>
              <input data-testid="test___" className="hidden" />
              <span className="heading-03 text-black-300">
                This will update the in-store payment link amount
              </span>
            </div>

            <div className="flex flex-col items-center gap-12">
              <MBCurrencyInput
                autoFocus
                containerClasses="form-field__display-input--black text-center text-56"
                control={control}
                errors={errors}
                fieldName="amount"
                isDisplay
                placeholder="AED 0.00"
                prefix="AED "
              />
            </div>

            <div className="text-center">
              <Button
                type="submit"
                color="primary"
                size="lg"
                label="Confirm amount"
              />
            </div>
          </div>
        </form>
      </section>
    </main>
  );
};
