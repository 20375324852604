import axios from "axios";
import qs from "qs";

import { endpoints } from "constants/apiEndpoints";
import { useState } from "react";

export const useHandleAllow = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const url = new URL(window.location.href);
  const { user_id, team_id } = qs.parse(url.search, {
    ignoreQueryPrefix: true,
  });

  const handleAllow = () => {
    setIsLoading(true);
    axios
      .get(endpoints.MANAGE.API.ACCOUNTS.SLACK_DEEP_LINK, {
        headers: { accept: "application/json" },
        params: {
          user_id: user_id,
          team_id: team_id,
        },
      })
      .then((res) => {
        setIsLoading(false);
        window.location.href = res.data.redirect_url;
      })
      .catch((error) => {
        setIsLoading(false);
        setError(error);
        console.error(error);
      });
  };

  return { isLoading, error, handleAllow };
};
