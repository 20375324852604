import { MasterHeader } from "design_system/MasterHeader";

import { isAppPlatform } from "helpers/isAppPlatform";

export const PageHeader = () => {
  if (isAppPlatform()) {
    return <></>;
  }

  return (
    <MasterHeader>
      <MasterHeader.Title>In-store payments</MasterHeader.Title>
    </MasterHeader>
  );
};
