import { useMutation } from "@tanstack/react-query";

import { snack } from "design_system/Snackbar";
import { AxiosErrorCodesMap } from "repositories/_base/shared";

import { accountsRepository } from "repositories/accounts/accounts.repository";
import { ACCOUNTS_QUERY_KEYS } from "repositories/accounts/keys";

/**
 * Check if phone is already used
 * @param phoneNumber - string
 * @returns Object containing loading state and data
 */
export const useCheckPhoneNumber = () => {
  return useMutation({
    mutationKey: [ACCOUNTS_QUERY_KEYS.CHECK_PHONE_NUMBER],
    mutationFn: async (phoneNumber: string) => {
      const response = await accountsRepository.checkPhoneNumber(phoneNumber);

      if (!response.success && "error" in response) {
        if (
          response.error.key !== AxiosErrorCodesMap.PHONE_NUMBER_INVALID_FORMAT
        ) {
          snack({
            title: response.error.message,
            leadingElement: "icon",
            variant: "critical",
          });
        }

        accountsRepository.sentryLogError({
          error: response.error.message,
          feature: "accounts",
          functionName: "checkPhoneNumber",
          data: JSON.stringify(response.error),
        });
      }

      return response;
    },
  });
};
