import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { SentryLoggingService } from "init/SentryLoggingService";
import qs from "qs";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import ReactOnRails from "react-on-rails";
import * as Yup from "yup";

import Button from "components/Button/Button";
import TextInput from "components/Inputs/TextInput";
import TextButton from "components/ui/TextButton";
import Toast from "components/ui/Toast";

import { useOtp } from "hooks/useOtp";
import { useGenerateOtp } from "../hooks/useGenerateOtp";

import { endpoints } from "constants/apiEndpoints";
import { generalErrors } from "constants/errorMessages";
import { isAppPlatform } from "helpers/isAppPlatform";
import {
  BankAccountTypes,
  type IDisbursementManualState,
  NewDisbursementActionTypes,
  Steps,
} from "reducers/newDisbursementsReducer";

import routes from "constants/routes";
interface ConfirmDisbursement {
  state: IDisbursementManualState;
  setCurrentStep: any;
}

interface FormData {
  otp: string;
}

export const VerificationCode = ({
  state,
  setCurrentStep,
}: ConfirmDisbursement) => {
  const [isCreating, setIsCreating] = useState<boolean>(false);

  const formSchema = Yup.object().shape({
    otp: Yup.string().required("Please enter an OTP"),
  });
  const { errors, control, watch } = useForm<FormData>({
    defaultValues: {
      otp: "",
    },
    resolver: yupResolver(formSchema),
    mode: "onBlur",
  });
  const watchFields = watch();

  const { buttonText, isDisabled, resetTimer } = useOtp();
  const { generateOtp } = useGenerateOtp();

  const handleResendCode = async () => {
    generateOtp
      .mutateAsync({ purpose: "create_payout" })
      .then(() => {
        resetTimer();
      })
      .catch((error) => {
        SentryLoggingService.captureException(error.message, {
          feature: "[Verification Code][Generate OTP]",
          file: "VerificationCode.tsx",
          function: "onClick",
          data: { purpose: "create_payout" },
        });
        toast.error(generalErrors.SOMETHING_WENT_WRONG, {
          duration: 3000,
        });
      });
  };

  const actionOnClick = (e: any) => {
    if (e.nativeEvent.pointerType !== "") {
      switch (state.type) {
        case NewDisbursementActionTypes.SET_MANUAL_DETAILS:
          return setCurrentStep(Steps.ConfirmDisbursement);
        case NewDisbursementActionTypes.SET_BULK_UPLOAD:
          return setCurrentStep(Steps.BulkUpload);
        default:
          return setCurrentStep(Steps.ConfirmDisbursement);
      }
    }
  };

  const onSubmit = () => {
    setIsCreating(true);

    switch (state.type) {
      case NewDisbursementActionTypes.SET_MANUAL_DETAILS:
        return createManual();
      case NewDisbursementActionTypes.SET_BULK_UPLOAD:
        return bulkUpload();
      default:
        return createManual();
    }
  };

  const createManual = () => {
    const url = endpoints.MANAGE.API.DISBURSEMENTS.CREATE;
    const queryParams = {
      authenticity_token: ReactOnRails.authenticityToken(),
    };
    const disbursementsData = {
      otp: watchFields.otp,
      amount: state.amount,
      reason: state.reason,
      recipient_type: state.bankAccountType?.toLowerCase(),
      recipient_account: state.iban,
      recipient_first_name:
        state.bankAccountType === BankAccountTypes.BUSINESS
          ? state.accountHolderName
          : state.firstName,
      recipient_last_name:
        state.bankAccountType === BankAccountTypes.BUSINESS
          ? null
          : state.lastName,
    };

    axios
      .post(`${url}?${qs.stringify(queryParams)}`, disbursementsData)
      .then(() => {
        setIsCreating(false);
        setCurrentStep(Steps.ProcessingFile);
      })
      .catch((error) => {
        setIsCreating(false);
        if (error.response.data.error_code === "INVALID_OTP") {
          toast.error("Code is invalid. Please try again.", {
            duration: 3000,
          });
        } else {
          toast.error(generalErrors.SOMETHING_WENT_WRONG, {
            duration: 3000,
          });
        }
        SentryLoggingService.captureException(error.message, {
          feature: "[Verification Code][Create Manual]",
          file: "VerificationCode.tsx",
          function: "onSubmit",
          data: disbursementsData,
        });
      });
  };

  const bulkUpload = () => {
    const formData = new FormData();
    formData.append("file", state.selectedFile);
    formData.append("otp", watchFields.otp);
    const url = endpoints.MANAGE.API.DISBURSEMENTS.UPLOAD;
    const queryParams = {
      authenticity_token: ReactOnRails.authenticityToken(),
    };

    axios
      .post(`${url}?${qs.stringify(queryParams)}`, formData)
      .then(() => {
        setIsCreating(false);
        setCurrentStep(Steps.ProcessingFile);
      })
      .catch((error) => {
        setIsCreating(false);
        if (error.response.data.error_code === "INVALID_OTP") {
          toast.error("Code is invalid. Please try again.", {
            duration: 3000,
          });
        } else {
          toast.error(error.response.data.errors.join("\n"), {
            duration: 3000,
          });
        }
        SentryLoggingService.captureException(error.message, {
          feature: "[Verification Code][Bulk Upload]",
          file: "VerificationCode.tsx",
          function: "onSubmit",
          data: JSON.stringify(state.selectedFile),
        });
      });
  };

  useEffect(() => {
    const resendLink = document.getElementById("resend-otp");

    if (!resendLink) return;

    function noClick(event: any) {
      event.preventDefault();
    }

    if (isDisabled) {
      resendLink.addEventListener("click", noClick);
    } else {
      resendLink.removeEventListener("click", noClick);
    }
  }, [isDisabled]);

  return (
    <>
      <Toast />
      <header>
        <button
          className="create-link__back-button material-icons-round"
          onClick={actionOnClick}
        >
          arrow_back
        </button>

        {!isAppPlatform() && (
          <a
            href={routes.MANAGE.DISBURSEMENTS.INDEX()}
            className="create-link__close-button material-icons-round"
          >
            close
          </a>
        )}
      </header>

      <section className="bg-white pt-48 md:mx-auto md:max-w-3xl md:rounded-16 md:p-48 lg:max-w-5xl">
        <div className="space-y-48">
          <div className="flex flex-col gap-16 text-center">
            <span data-testid="page-title" className="font-bold text-28">
              Enter verification code
            </span>
            <span className="heading-03 text-black-300">
              We sent a code to your registered email address
            </span>
          </div>

          <div className="space-y-48">
            <Controller
              name="otp"
              control={control}
              defaultValue=""
              render={(props) => (
                <TextInput
                  autoFocus
                  type="number"
                  inputId="otp"
                  labelText="Code"
                  onChange={(value) => {
                    props.onChange(value);
                  }}
                  onBlur={props.onBlur}
                  errors={errors.otp}
                  errorMessage={errors.otp?.message}
                />
              )}
            />

            {/* LARGE BUTTON FOR TABLET AND ABOVE */}
            <div className="flex hidden flex-col items-center gap-24 text-center md:flex">
              <Button
                type="button"
                color="primary"
                size="lg"
                label={isCreating ? "Verifying..." : "Verify"}
                onClick={onSubmit}
                isDisabled={isCreating}
              />
              <TextButton
                id="resend-otp"
                type="button"
                color="primary"
                size="md"
                label={buttonText}
                onClick={handleResendCode}
                isDisabled={isDisabled}
              />
            </div>

            {/* MEDIUM BUTTON FOR MOBILE */}
            <div className="flex flex-col items-center gap-24 text-center md:hidden">
              <Button
                type="button"
                color="primary"
                size="md"
                label={isCreating ? "Verifying..." : "Verify"}
                onClick={onSubmit}
                isDisabled={isCreating}
              />
              <TextButton
                id="resend-otp"
                type="button"
                color="primary"
                size="md"
                label={buttonText}
                onClick={handleResendCode}
                isDisabled={isDisabled}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
