import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useState } from "react";
import { createPortal } from "react-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { Details } from "./Details";
import { UpdateDetails } from "./UpdateDetails";

import type IRailsContext from "interfaces/IRailsContext";
import { businessDetailsRoutes } from "./shared/constant";
import {
  BusinessDetailsProvider,
  initState,
} from "./shared/context/BusinessDetailsProvider";
import type { TBusinessDetails } from "./shared/types";

interface IProps {
  _: any;
  railsContext: IRailsContext;
}

export const BusinessContainer = (props: IProps) => {
  const queryClient = new QueryClient();
  const [details, setDetails] = useState<TBusinessDetails>(initState.details);

  return (
    <BusinessDetailsProvider value={{ details, setDetails }}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            <Route
              path={businessDetailsRoutes.overview}
              element={<Details />}
            />
            <Route
              path={businessDetailsRoutes.updateDetail}
              element={createPortal(
                <section
                  className="create-link absolute inset-0"
                  style={{
                    zIndex: 1003,
                  }}
                >
                  <UpdateDetails />
                </section>,
                document.body,
              )}
            />
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </BusinessDetailsProvider>
  );
};
