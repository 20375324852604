import { useCallback } from "react";

import { formatIban } from "helpers/stringUtils";
import { getTranslatedMessage } from "locales/helpers/lang-utils";
import { copyToClipboard } from "utils/sharedUtils";
import type { BankDetailObject, BankTransferDetailsData } from "../typeDef";

export const useBankDetails = (data: BankTransferDetailsData) => {
  const bankDetails: BankDetailObject = {
    ...(data.uniqueReferenceId && {
      uniqueReferenceId: {
        title: getTranslatedMessage("Your unique reference ID"),
        value: data.uniqueReferenceId,
      },
    }),
    ...(data.bank && {
      bank: {
        title: getTranslatedMessage("Bank"),
        value: data.bank,
      },
    }),
    ...(data.name && {
      name: {
        title: getTranslatedMessage("Name"),
        value: data.name,
      },
    }),
    ...(data.accountNumber && {
      accountNumber: {
        title: getTranslatedMessage("Account number"),
        value: data.accountNumber,
      },
    }),
    ...(data.iban && {
      iban: {
        title: getTranslatedMessage("IBAN"),
        value: formatIban(data.iban),
      },
    }),
    ...(data.swiftCode && {
      swiftCode: {
        title: getTranslatedMessage("Swift code"),
        value: data.swiftCode,
      },
    }),
    ...(data.bankBranch && {
      bankBranch: {
        title: getTranslatedMessage("Bank branch"),
        value: data.bankBranch,
      },
    }),
    ...(data.bankAddress && {
      bankAddress: {
        title: getTranslatedMessage("Bank address"),
        value: data.bankAddress,
      },
    }),
    ...(data.city && {
      city: {
        title: getTranslatedMessage("City"),
        value: data.city,
      },
    }),
    ...(data.country && {
      country: {
        title: getTranslatedMessage("Country"),
        value: data.country,
      },
    }),
  };

  const bankItems = Object.keys(bankDetails);

  const handleCopyBankInfo = useCallback((value: string) => {
    copyToClipboard(value, "Copied!");
  }, []);

  const handleCopyAllBankInfo = () => {
    const simplifyBankDetails = (details) => {
      const simplified = {};
      // Iterate over each property in the details object
      for (const key in details) {
        if (details.hasOwnProperty(key)) {
          // Assign the value from the 'value' field of each property to the corresponding key in the new object
          simplified[key] = details[key].value;
        }
      }
      return simplified;
    };
    copyToClipboard(
      JSON.stringify(simplifyBankDetails(bankDetails)),
      "Copied!",
    );
  };

  return {
    bankDetails,
    bankItems,
    handleCopyAllBankInfo,
    handleCopyBankInfo,
  };
};
