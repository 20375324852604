import { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";

import { TableCell } from "components/Table/TableCell";
import { IslandContext } from "design_system/Island/IslandContext";

import { useAppSelector } from "store";

import { setPagination } from "reducers/redux/TableReducer/tableReducer";
import type { TColumnCell } from "../../typeDefs";
import { ExpenseCategoryActions } from "../components/Actions/ExpenseCategoryActions";

export const useExpenseCategoriesTable = () => {
  const dispatch = useDispatch();
  const { openIsland, closeIsland } = useContext(IslandContext);

  const { selectedRows } = useAppSelector(
    (state) => state.expenseSettings.dashboard,
  );

  const COLUMNS = [
    {
      header: "Name",
      accessorKey: "name",
      cell: (props: TColumnCell) => (
        <TableCell
          mainContent={
            <div className="flex items-center justify-between">
              <span>{props.row.original.name}</span>
              <ExpenseCategoryActions category={props.row.original} />
            </div>
          }
          classNames={{
            mainContent: "mt-10",
          }}
        />
      ),
    },
  ];

  const handleSetPage = (newPageNumber: number) => {
    dispatch(
      setPagination({ tableName: "expenseCategories", page: newPageNumber }),
    );
  };

  /* ---------------------------------
   *  Effects
   * ---------------------------------
   */

  // OPEN/CLOSE ISLAND ON ROW SELECTIONS
  useEffect(() => {
    if (selectedRows?.length > 0) {
      openIsland();
    } else closeIsland();
  }, [selectedRows.length]);

  return {
    COLUMNS,
    selectedRows,
    handleSetPage,
  };
};
