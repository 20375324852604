import { type Theme, createTheme } from "@mui/material/styles";

import { colorsV2 } from "constants/colors";
import { typography } from "constants/typography";

export const customTheme = (outerTheme: Theme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode,
    },
    components: {
      // ============================================
      // MUI FORM CONTROL
      // ============================================
      MuiFormControl: {
        styleOverrides: {
          root: {
            "&:has(.MuiInputLabel-root) .MuiInputBase-input": {
              marginTop: "1.2rem",
            },
            "&:has(.MuiInputLabel-root) .MuiInputBase-inputMultiline": {
              marginTop: "0rem",
            },
            // CUSTOM CLASS ADDED BELOW (MuiInputBase-borderless): NOT PART OF MUI
            ".MuiInputBase-borderless": {
              border: "unset",
              padding: "1.6rem 1.2rem",
              height: "unset",
            },
            ".MuiInputBase-borderless:hover .MuiInputBase-hover-icon": {
              display: "block",
            },
            // CUSTOM CLASS (MuiAmountInput-disabled): NOT PART OF MUI
            "&.MuiAmountInput-disabled > .Mui-disabled, &.MuiAmountInput-disabled > .Mui-disabled:hover":
              {
                backgroundColor: "unset",
              },
            // CUSTOM CLASS (BlazeSearchInput): NOT PART OF MUI
            "&.BlazeSearchInput > .MuiOutlinedInput-root.MuiInputBase-sizeSmall":
              {
                padding: "0.8rem 1.2rem",
              },
            "&.BlazeSearchInput > .MuiOutlinedInput-root > .MuiInputAdornment-root.MuiInputAdornment-positionEnd":
              {
                marginRight: 0,
              },
          },
        },
      },
      // @ts-ignore
      // ============================================
      // MUI PICKERS CALENDAR HEADER
      // ============================================
      MuiPickersCalendarHeader: {
        styleOverrides: {
          root: {
            ".MuiSvgIcon-root": {
              width: "2.4rem",
              height: "2.4rem",
              fill: colorsV2.text[1],
            },
            ".MuiPickersCalendarHeader-labelContainer": {
              ...typography.label.medium,
            },
          },
        },
      },
      // ============================================
      // MUI POPPER
      // ============================================
      MuiPopper: {
        styleOverrides: {
          root: {
            "&.MuiPickersPopper-root": {
              zIndex: 100000000000,
            },
          },
        },
      },
      // ============================================
      // MUI TYPOGRAPHY
      // ============================================
      MuiTypography: {
        styleOverrides: {
          root: {
            "&.MuiDayCalendar-weekDayLabel, &.MuiDayPicker-weekDayLabel": {
              ...typography.body.large,
              color: colorsV2.text[3],
            },
          },
        },
      },
      // ============================================
      // MUI PICKERS DAY
      // ============================================
      MuiPickersDay: {
        styleOverrides: {
          root: {
            ...typography.body.large,
            color: colorsV2.text[1],
            "&.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus": {
              backgroundColor: colorsV2.primary[1],
              color: colorsV2.text.onprimary,
            },
            "&.MuiPickersDay-today": {
              borderColor: colorsV2.border[2],
            },
          },
        },
      },
      // ============================================
      // MUI PAPER
      // ============================================
      MuiPaper: {
        styleOverrides: {
          root: {
            "&.MuiPaper-rounded": {
              borderRadius: "1.6rem",
              boxShadow: "0px 4px 32px 0px rgba(0, 0, 0, 0.10)",
            },
          },
        },
      },
      // ============================================
      // MUI FILLED INPUT
      // ============================================
      MuiFilledInput: {
        styleOverrides: {
          root: {
            border: `1px solid ${colorsV2.border[2]}`,
            borderRadius: "0.8rem",
            paddingLeft: "1.2rem",
            paddingRight: "1.2rem",
            backgroundColor: "unset",
            height: "5.6rem",
            "&.MuiInputBase-sizeSmall": {
              height: "4rem",

              input: {
                ...typography.body.medium,
              },
            },
            "&:before": {
              all: "unset",
            },
            "&:after": {
              all: "unset",
            },
            "&:hover": {
              borderColor: colorsV2.border[1],
              backgroundColor: "unset",
            },
            "&.MuiAutocomplete-inputRoot": {
              height: "100%",
              minHeight: "5.6rem",
              paddingBottom: "0.8rem",
              "&.Mui-focused": {},
              ".MuiAutocomplete-input": {
                marginTop: "0rem",
                padding: "revert !important",
                "&::placeholder": {
                  opacity: 1,
                },
              },
              ".MuiAutocomplete-endAdornment": {
                top: "50%",
                transform: "translateY(-50%)",
              },
              ".MuiChip-root": {
                ...typography.body.small,
                color: colorsV2.text[1],
                backgroundColor: colorsV2.surface[3],
                padding: "0.2rem 0rem 0.2rem 0.8rem",
                height: "2.4rem",
                ".MuiChip-label": {
                  padding: "0rem",
                },
                ".MuiChip-deleteIcon": {
                  margin: "unset",
                },
              },
            },
            "&.Mui-focused": {
              backgroundColor: "unset",
              borderColor: colorsV2.border[4],
            },
            "&.Mui-focused:after": {
              backgroundColor: "unset",
              borderBottom: "unset",
            },
            "&.Mui-error": {
              borderColor: colorsV2.error[1],
            },
            "&.MuiInputBase-colorWarning": {
              borderColor: colorsV2.accent.yellow[1],
              backgroundColor: colorsV2.accent.yellow[3],
            },
            "&:hover:not(.Mui-disabled):before": {
              borderBottom: "unset",
            },
            "&.Mui-disabled": {
              backgroundColor: colorsV2.surface[2],
              borderColor: colorsV2.border[2],
            },
            "&.Mui-disabled:hover": {
              borderColor: colorsV2.border[2],
              backgroundColor: colorsV2.surface[2],
            },
          },
          input: {
            padding: "unset !important",
            caretColor: colorsV2.primary[1],
            color: colorsV2.text[1],
            ...typography.body.large,
            "&::placeholder": {
              opacity: 1,
              color: colorsV2.text[3],
            },
            "&:focus": {
              backgroundColor: "unset",
            },
          },
          multiline: {
            alignItems: "flex-start",
            height: "10.8rem",
            overflowY: "auto",
          },
        },
      },
      // ============================================
      // MUI OUTLINED INPUT
      // ============================================
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            border: `1px solid ${colorsV2.border[2]}`,
            borderRadius: "0.8rem",
            paddingLeft: "1.2rem",
            paddingRight: "1.2rem",
            backgroundColor: "unset",
            height: "5.6rem",
            "&.MuiInputBase-sizeSmall": {
              height: "4rem",

              input: {
                ...typography.body.medium,
              },
            },
            "&.MuiInputBase-colorSecondary": {
              backgroundColor: colorsV2.surface[2],
              borderColor: colorsV2.surface[2],
              "&:hover": {
                backgroundColor: colorsV2.surface[2],
                borderColor: colorsV2.border[2],
              },
              "&.Mui-focused": {
                backgroundColor: "unset",
                borderColor: colorsV2.border[4],
              },
            },
            "&:before": {
              all: "unset",
            },
            "&:after": {
              all: "unset",
            },
            "&:hover": {
              borderColor: colorsV2.border[1],
              backgroundColor: "unset",
            },
            "&.Mui-focused": {
              backgroundColor: "unset",
              borderColor: colorsV2.border[4],
            },
            "&.Mui-focused:after": {
              backgroundColor: "unset",
              borderBottom: "unset",
            },
            "&.Mui-error": {
              borderColor: colorsV2.error[1],
            },
            "&.MuiInputBase-colorWarning": {
              borderColor: colorsV2.accent.yellow[1],
              backgroundColor: colorsV2.accent.yellow[3],
            },
            "&:hover:not(.Mui-disabled):before": {
              borderBottom: "unset",
            },
            "&.Mui-disabled": {
              backgroundColor: colorsV2.surface[2],
              borderColor: colorsV2.border[2],
            },
            "&.Mui-disabled:hover": {
              borderColor: colorsV2.border[2],
              backgroundColor: colorsV2.surface[2],
            },
          },
          input: {
            padding: "unset !important",
            caretColor: colorsV2.primary[1],
            color: colorsV2.text[1],
            ...typography.body.large,
            "&::placeholder": {
              opacity: 1,
              color: colorsV2.text[3],
            },
            "&:focus": {
              backgroundColor: "unset",
            },
          },
          notchedOutline: {
            display: "none",
          },
        },
      },
      // ============================================
      // MUI INPUT ADORNMENT
      // ============================================
      MuiInputAdornment: {
        styleOverrides: {
          positionEnd: {
            marginRight: "1.4rem",
          },
        },
      },
      // ============================================
      // MUI INPUT LABEL
      // ============================================
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: colorsV2.text[3],
            ...typography.body.large,
            "&.Mui-focused": {
              color: colorsV2.text[3],
              ...typography.body.small,
            },
            "&.MuiInputLabel-shrink": {
              ...typography.body.small,
            },
            "&.Mui-error": {
              color: colorsV2.error[1],
            },
            "&.Mui-disabled": {
              color: colorsV2.text.disabled,
            },
          },
        },
      },
      // ============================================
      // MUI FORM HELPER TEXT
      // ============================================
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            color: colorsV2.error[1],
            letterSpacing: "unset",
            ...typography.body.small,

            // CUSTOM CLASS (BlazeHelperText): NOT PART OF MUI
            "&.BlazeHelperText": {
              color: colorsV2.text[3],
            },
          },
        },
      },
      // ============================================
      // MUI SELECT
      // ============================================
      MuiSelect: {
        styleOverrides: {
          root: {
            "&.Mui-error": {
              ".MuiInputAdornment-root": {
                marginRight: "3rem",
              },
            },
            "&:hover": {
              ".MuiSelect-icon": {
                borderRadius: "99rem",
                backgroundColor: colorsV2.surface[2],
              },
            },
          },
          icon: {
            top: "unset",
            width: "2.4rem",
            height: "2.4rem",
            padding: "0.4rem",
            "&.MuiInputBase-hover-icon": {
              display: "none",
            },
          },
        },
      },
      // ============================================
      // MUI MENU
      // ============================================
      MuiMenu: {
        styleOverrides: {
          paper: {
            marginTop: "0.8rem",
            marginBottom: "0.8rem",
            borderRadius: "0.8rem",
            backgroundColor: colorsV2.surface[1],
            padding: "0.8rem",
            boxShadow: "0px 4px 32px 0px rgba(0, 0, 0, 0.10)",
            maxHeight: "320px",
          },
        },
      },
      // ============================================
      // MUI LIST
      // ============================================
      MuiList: {
        styleOverrides: {
          root: {
            padding: "0rem",
            "& > .MuiListSubheader-root:not(:first-child)": {
              marginTop: "2.4rem",
            },
          },
        },
      },
      // ============================================
      // MUI LIST SUBHEADER
      // ============================================
      MuiListSubheader: {
        styleOverrides: {
          root: {
            padding: "0.8rem",
            color: colorsV2.text[1],
            ...typography.label.medium,
          },
        },
      },
      // ============================================
      // MUI MENU ITEM
      // ============================================
      MuiMenuItem: {
        styleOverrides: {
          root: {
            padding: "0.8rem",
            borderRadius: "0.8rem",
            ...typography.body.medium,
            "&:hover, &:focus": {
              backgroundColor: colorsV2.surface[2],
            },
            "&.Mui-selected": {
              backgroundColor: "unset",
              "&:hover, &:focus": {
                backgroundColor: colorsV2.surface[2],
              },
              "&::after": {
                fontFamily: "Material Icons Round",
                content: '"done"',
                position: "absolute",
                top: "50%",
                right: "0.8rem",
                transform: "translateY(-50%)",
                color: colorsV2.accent.blue[1],
              },
            },
            "&.MuiButtonBase-root.MuiMenuItem-root[hidden]": {
              display: "none",
            },
          },
        },
      },
      // ============================================
      // MUI TABS
      // ============================================
      MuiTabs: {
        styleOverrides: {
          root: {
            minHeight: "unset",
          },
          indicator: {
            backgroundColor: colorsV2.border[4],
            borderRadius: "99rem",
          },
          flexContainer: {
            gap: outerTheme.breakpoints.down("sm") ? "3.2rem" : "4.8rem",
          },
        },
      },
      // ============================================
      // MUI TAB
      // ============================================
      MuiTab: {
        styleOverrides: {
          root: {
            color: colorsV2.text[3],
            textTransform: "unset",
            letterSpacing: "unset",
            padding: "unset",
            minHeight: "unset",
            minWidth: "unset",
            maxWidth: "unset",
            boxSizing: "border-box",
            "&.Mui-md": {
              ...typography.label.medium,
              paddingBottom: "0.8rem",
            },
            "&.Mui-lg": {
              ...typography.label.large,
              paddingBottom: "1.6rem",
            },
            "&.Mui-selected": {
              color: colorsV2.text[1],
            },
            "&:hover::after, &.Mui-focusVisible::after": {
              content: '""',
              position: "absolute",
              height: "2px",
              bottom: 0,
              right: 0,
              left: 0,
              borderRadius: "99rem",
              backgroundColor: colorsV2.border[2],
            },
          },
        },
      },
      // ============================================
      // MUI AUTOCOMPLETE
      // ============================================
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            /**
             * if label exists in the Autocomplete component, keep the padding from the library
             * otherwise, use the padding from inputRoot when there's no label
             * */
            "&:has(.MuiFormControl-root):has(.MuiFormLabel-root) .MuiAutocomplete-inputRoot":
              {
                paddingTop: "19px",
              },
          },
          inputRoot: {
            paddingTop: "1rem",
          },
          paper: {
            borderRadius: "0.8rem",
            marginTop: "0.8rem",
          },
          listbox: {
            padding: "0.8rem",
          },
          option: {
            padding: "0.8rem !important",
            ...typography.body.medium,
            color: colorsV2.text[1],
            "&:hover, &:focus, &.Mui-focused, &.Mui-focusVisible": {
              backgroundColor: `${colorsV2.surface[2]} !important`,
              borderRadius: "0.8rem",
            },
            "&[aria-selected='true']": {
              backgroundColor: "unset !important",
              borderRadius: "0.8rem",
              position: "relative",
              "&:hover, &:focus, &.Mui-focused, &.Mui-focusVisible": {
                backgroundColor: `${colorsV2.surface[2]} !important`,
              },
              "&:after": {
                fontFamily: "Material Icons Round",
                content: '"done"',
                position: "absolute",
                top: "50%",
                right: "1.6rem",
                transform: "translateY(-50%)",
                color: colorsV2.accent.blue[1],
              },
            },
          },
        },
      },
      // ============================================
      // MUI SWITCH
      // ============================================
      MuiSwitch: {
        styleOverrides: {
          root: {
            width: "39px",
            height: "24px",
            padding: 0,
          },
          switchBase: {
            padding: 0,
            margin: 2,
            transitionDuration: "300ms",
            "&.Mui-checked": {
              transform: "translateX(15px)",
              color: colorsV2.surface[1],
              "& + .MuiSwitch-track": {
                backgroundColor: colorsV2.primary[1],
                opacity: 1,
                border: 0,
              },
              "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.5,
              },
            },
            "&.Mui-focusVisible .MuiSwitch-thumb": {
              color: colorsV2.primary[1],
              border: `8px solid ${colorsV2.surface[1]}`,
            },
            "&.Mui-disabled + .MuiSwitch-track": {
              opacity: 0.7,
            },
            "&.Mui-disabled": {
              color: `${colorsV2.surface[1]} !important`,
            },
          },
          thumb: {
            boxSizing: "border-box",
            width: 20,
            height: 20,
            boxShadow: "unset",
          },
          track: {
            borderRadius: 24 / 2,
            backgroundColor: colorsV2.surface[4],
            opacity: 1,
            transition: "background-color 0.5s",
          },
        },
      },
      // ============================================
      // MUI MODAL
      // ============================================
      MuiModal: {
        styleOverrides: {
          root: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",

            // Assigning the z-index in the root would cause unexpected issues for all components that share the MuiModal-root class. To avoid this, we've added a custom class to the Modal.tsx component which is to add the highest z-index for our Modal only.
            "&.BlazeModal": {
              zIndex: 10001,
            },

            "&.BlazeModal:has(.MuiSelect-select)": {
              zIndex: 1300,
            },

            "&:not(:has(.MuiBackdrop-invisible)) .MuiModal-backdrop": {
              backgroundColor: colorsV2.overlay[1],
              backdropFilter: "blur(8px)",
            },
          },
        },
      },
      // ============================================
      // MUI DRAWER
      // ============================================
      MuiDrawer: {
        styleOverrides: {
          root: {
            "& > .MuiPaper-root > .MuiDrawer-dragHandle-header": {
              paddingBottom: "2.4rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",

              "& > .MuiDrawer-dragHandle": {
                borderRadius: "99rem",
                width: "3.2rem",
                height: "0.4rem",
                opacity: 0.4,
                backgroundColor: colorsV2.text[2],
              },
            },
          },
          paper: {
            boxShadow: "unset",
          },
          paperAnchorBottom: {
            backgroundColor: colorsV2.surface[1],
            borderTopLeftRadius: "2.8rem",
            borderTopRightRadius: "2.8rem",
            padding: "1.6rem 1.6rem 4.8rem 1.6rem",
          },
        },
      },
      // ============================================
      // MUI CHIP
      // ============================================
      MuiChip: {
        styleOverrides: {
          root: {
            ...typography.body.small,
            color: colorsV2.text[1],
            backgroundColor: colorsV2.surface[3],
            padding: "0.2rem 0rem 0.2rem 0.8rem",
            height: "2.4rem",
          },
          label: {
            padding: "0rem",
          },
          deleteIcon: {
            margin: "unset",
          },
        },
      },
      // ============================================
      // MUI STEP CONNECTOR
      // ============================================
      MuiStepConnector: {
        styleOverrides: {
          root: {
            "&.Mui-active": {
              ".MuiStepConnector-line": {
                borderColor: colorsV2.primary[1],
              },
            },
            "&.Mui-completed": {
              ".MuiStepConnector-line": {
                borderColor: colorsV2.primary[1],
              },
            },
          },
          line: {
            borderColor: colorsV2.accent.blue[2],
            borderTopWidth: "0.6rem",
            borderRadius: "99rem",
          },
        },
      },
    },
  });
