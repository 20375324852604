import isEmpty from "lodash/isEmpty";
import { useContext, useEffect } from "react";

import { Divider } from "components/Divider";
import { Avatar } from "design_system/Avatar";
import { OpenInNew } from "design_system/Icons";
import SideSheet from "design_system/Sidesheet/Sidesheet";
import { Tag } from "design_system/Tag";

import { useAppSelector } from "store";

import { SideSheetContext } from "design_system/Sidesheet/SideSheetContext";

import { colorsV2 } from "constants/colors";
import routes from "constants/routes";
import { showHideIntercom } from "utils/displayUtils";
import { getTapixAvatar } from "utils/tapixAvatarUtil";
import { repaymentsStatusMapping } from "../../../../constants/creditRepayments";

export const CreditActivitySidesheet = () => {
  const { closeSideSheet } = useContext(SideSheetContext);
  const {
    table: { creditActivities },
  } = useAppSelector((state) => state);

  const record = creditActivities.recordDetail;
  const avatar = getTapixAvatar(record?.tapixShop, record?.merchantName);
  const tagProps = repaymentsStatusMapping[record.status];

  useEffect(() => {
    showHideIntercom("hide");
    return () => showHideIntercom("show");
  }, []);

  if (isEmpty(record)) {
    return (
      <SideSheet>
        <SideSheet.Navbar>
          <div className="flex justify-end md:pl-16">
            <SideSheet.Close onClose={() => closeSideSheet()} />
          </div>
        </SideSheet.Navbar>
        <SideSheet.Content>
          <h2>Unable to show activity details at the moment</h2>
        </SideSheet.Content>
      </SideSheet>
    );
  }
  return (
    <SideSheet>
      <SideSheet.Navbar>
        <div className="flex justify-between md:pl-16">
          {record?.status && !isEmpty(tagProps) && (
            <Tag
              Icon={tagProps?.Icon}
              color={tagProps?.color}
              label={tagProps?.label}
            />
          )}
          <SideSheet.Close onClose={() => closeSideSheet()} />
        </div>
      </SideSheet.Navbar>
      <SideSheet.Header>
        <div className="flex items-center gap-16">
          {avatar.isIcon && avatar.Icon && (
            <Avatar size="2xl" isIcon Icon={avatar.Icon} />
          )}
          {!avatar.isIcon && avatar.url && (
            <Avatar isIcon={false} size="2xl" url={avatar.url} />
          )}
          <div className="space-y-4">
            <p className="body-large text-text-color-02">
              {record.merchantName}
            </p>
            <p className="display-small">{record.billingAmountFormatted}</p>
          </div>
        </div>
      </SideSheet.Header>
      <Divider />
      <SideSheet.Content>
        <section className="flex flex-col gap-16">
          <h2 className="label-large text-text-color-02">Details</h2>

          <div className="grid grid-cols-2">
            <span className="body-large text-text-color-03">Amount</span>
            <span className="body-large">{record.billingAmountFormatted}</span>
          </div>

          <div className="grid grid-cols-2">
            <span className="body-large text-text-color-03">Date</span>
            <span className="body-large">{record.createdAt}</span>
          </div>
          <div className="grid grid-cols-2">
            <span className="body-large text-text-color-03">Late fee</span>
            <span className="body-large">{record.lateFeeAmountFormatted}</span>
          </div>
          <div className="grid grid-cols-2">
            <span className="body-large text-text-color-03">
              Transaction ID
            </span>
            <span className="body-large flex items-center gap-6">
              {record.identifier}
              <OpenInNew
                size="14"
                fill={colorsV2.text[3]}
                className="mt-4 cursor-pointer"
                onClick={() =>
                  window.open(
                    routes.MANAGE.CARDS.TRANSACTIONS.SHOW(
                      record.cardIdentifier,
                      record.identifier,
                    ),
                    "_blank",
                  )
                }
              />
            </span>
          </div>
        </section>
      </SideSheet.Content>
    </SideSheet>
  );
};
