import { curveMonotoneX } from "@visx/curve";
import {
  AnimatedAnnotation,
  AnimatedAreaSeries,
  AnimatedLineSeries,
  AnnotationCircleSubject,
  AnnotationLabel,
  Axis,
  Grid,
  Tooltip,
  XYChart,
} from "@visx/xychart";
import { useEffect, useRef, useState } from "react";

import { colors } from "constants/colors";
import { sharedTooltipClasses } from "design_system/shared/constant";
import { convertToLocaleStringHelper } from "helpers/currency";
import { useScreenDimension } from "hooks/useScreenDimension";
import { tooltipStyles } from "../constant";
import type { IChartProps } from "../typesDefs";
import { calculateDynamicDx, calculateDynamicDy } from "../utils";

export const LineChart = ({
  bottomNumTicks,
  data,
  hasCurrencyInTooltip = true,
  height,
  margin,
  numTicks,
  tooltipSubtext,
  width,
  xAccessor,
  xValueFormat,
  yAccessor,
  yValueFormat,
}: IChartProps) => {
  const [annotationDataKey, setAnnotationDataKey] = useState<any>();
  const [annotationDataIndex, setAnnotationDataIndex] = useState(13);
  const [svgPoint, setSvgPoint] = useState({ x: 0, y: 0 });
  const [isAnnotationPresented, setIsAnnotationPresented] = useState(false);
  const [isBarClicked, setIsBarClicked] = useState(false);

  const { isMobile, isTablet, isLaptop } = useScreenDimension();
  const graphRef = useRef(null);

  const annotationDatum = data[annotationDataIndex];
  const defaultMargin = { top: 0, right: 0, bottom: 40, left: 80 };

  const dynamicDx = calculateDynamicDx(svgPoint.x);
  const dynamicDy = calculateDynamicDy(height, svgPoint.y);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        graphRef.current &&
        !graphRef.current.contains(event.target) &&
        isAnnotationPresented
      ) {
        setIsAnnotationPresented(false);
        setIsBarClicked(false);
      }
    };
    window.addEventListener("click", handleClickOutside);

    // Clean up on unmount
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [isAnnotationPresented]);

  return (
    <div ref={graphRef}>
      <XYChart
        height={height}
        width={width}
        xScale={{ type: "band" }}
        yScale={{ type: "linear" }}
        margin={margin ? margin : defaultMargin}
        onPointerDown={(d) => {
          setAnnotationDataKey(d.key);
          setAnnotationDataIndex(d.index);
          setSvgPoint(d.svgPoint);
          setIsBarClicked(true);
        }}
        onPointerOut={(e) => {
          setIsAnnotationPresented(false);
          if (isBarClicked) {
            setIsAnnotationPresented(true);
          }
          setIsBarClicked(false);
        }}
      >
        <Grid
          columns={false}
          numTicks={numTicks}
          lineStyle={{
            stroke: colors.black[25],
            strokeWidth: 1,
          }}
        />
        {xAccessor && (
          <Axis
            hideAxisLine
            hideTicks
            orientation="bottom"
            numTicks={bottomNumTicks}
            tickFormat={xValueFormat}
            tickLength={16}
            tickLabelProps={{
              fontSize: 10,
              lineHeight: 14,
              color: colors.black[300],
              fontFamily: "CircularXXWeb-Regular",
            }}
          />
        )}
        {!isMobile && yAccessor && (
          <Axis
            hideAxisLine
            hideTicks
            orientation="left"
            numTicks={numTicks}
            tickFormat={yValueFormat}
            tickLabelProps={{
              fontSize: 10,
              lineHeight: 14,
              color: colors.black[300],
              fontFamily: "CircularXXWeb-Regular",
            }}
          />
        )}
        <AnimatedLineSeries
          stroke={colors.blue[500]}
          strokeWidth={3}
          dataKey="primary"
          data={data}
          xAccessor={xAccessor}
          yAccessor={yAccessor}
          curve={curveMonotoneX}
        />
        <AnimatedAreaSeries
          fill={colors.blue[25]}
          dataKey="primary"
          data={data}
          xAccessor={xAccessor}
          yAccessor={yAccessor}
          curve={curveMonotoneX}
        />
        {isAnnotationPresented && (isMobile || isTablet) && (
          <AnimatedAnnotation
            dataKey={annotationDataKey}
            datum={data[annotationDataIndex]}
            dx={dynamicDx}
            dy={dynamicDy}
          >
            <AnnotationLabel
              title={
                hasCurrencyInTooltip
                  ? `AED ${convertToLocaleStringHelper(yAccessor(annotationDatum))}`
                  : yAccessor(annotationDatum)
              }
              subtitle={tooltipSubtext(annotationDatum)}
              showAnchorLine={false}
              width={175}
              backgroundFill={colors.black[400]}
              backgroundProps={{
                fillOpacity: 1,
                rx: 12,
              }}
              titleProps={{
                style: {
                  fill: colors.white,
                  fontFamily: "CircularXXWeb-Medium",
                  fontWeight: "unset",
                  fontSize: 14,
                },
              }}
              subtitleProps={{
                style: {
                  fill: colors.black[200],
                  fontFamily: "CircularXXWeb-Regular",
                  fontWeight: "unset",
                  fontSize: 14,
                },
              }}
              backgroundPadding={{
                top: 12,
                right: 16,
                bottom: 12,
                left: 16,
              }}
            />
            <AnnotationCircleSubject radius={4} />
          </AnimatedAnnotation>
        )}
        {isLaptop && (
          <Tooltip
            snapTooltipToDatumX
            snapTooltipToDatumY
            showSeriesGlyphs
            glyphStyle={{
              fill: colors.blue[500],
              strokeWidth: 0,
            }}
            style={tooltipStyles}
            renderTooltip={({ tooltipData }) => {
              return (
                <div>
                  {Object.entries(tooltipData.datumByKey).map(
                    (lineDataArray) => {
                      const [key, value] = lineDataArray;

                      return (
                        <div className={sharedTooltipClasses("top")} key={key}>
                          {hasCurrencyInTooltip ? (
                            <span className="font-medium">
                              AED{" "}
                              {convertToLocaleStringHelper(
                                yAccessor(value.datum as any),
                              )}
                            </span>
                          ) : (
                            <span className="font-medium">
                              {yAccessor(value.datum as any)}
                            </span>
                          )}

                          {tooltipSubtext && (
                            <span className="text-black-200">
                              {tooltipSubtext(value.datum)}
                            </span>
                          )}
                        </div>
                      );
                    },
                  )}
                </div>
              );
            }}
          />
        )}
        <style>{`
        .visx-line {
          stroke: none;
        }
        .visx-annotation-subject-circle {
          stroke: none;
          fill: ${colors.blue[500]};
        }
        .graph-card > div > div > svg {
          overflow: visible !important;
        }
      `}</style>
      </XYChart>
    </div>
  );
};
