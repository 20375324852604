export function pluralize(
  count: number,
  singular: string,
  plural = singular + "s",
) {
  if (count === 0) return plural;

  return count > 1 ? plural : singular;
}

export const getStatusColor = (
  status: string,
): "gray" | "green" | "red" | "blue" | "yellow" => {
  const statusColorMapping: any = {
    cancelled: "gray",
    successful: "green",
    captured: "green",
    failed: "red",
    outstanding: "blue",
    paid: "green",
    partial_refund: "gray",
    pending: "blue",
    processed: "green",
    refund_pending: "yellow",
    refunded: "gray",
    rejected: "red",
    settled: "green",
  };

  return statusColorMapping[status] || "";
};

export const shrinkString = (
  longString: string | null | undefined,
  wordLength: number,
  characterLength: number,
) => {
  if (!longString) {
    return "";
  }

  const words = longString.split(" ");

  if (words.length > wordLength) {
    const truncatedWords = words.slice(0, wordLength).join(" ");
    return `${truncatedWords}...`;
  } else if (longString.length > characterLength) {
    return `${longString.substring(0, characterLength)}...`;
  }

  return longString;
};

export const getSingularOrPlural = (word, count = 0) => {
  return count > 1 ? `${word}s` : word;
};

export const formatIban = (iban: string) => {
  const cleanedIban = iban.replace(/\s+/g, "");
  return cleanedIban.replace(/(.{4})/g, "$1 ").trim();
};
