// isDataEmpty is when there's only one object in the array and the value is 0
export const isDataEmpty = (data) =>
  data.length === 1 && data?.[0]?.value === 0;

// isEntireDataArrayEmpty is checking if the whole array is empty
export const isEntireDataArrayEmpty = (data) => data.length === 0;

// areAllValuesZero is when regardless of the length of the array, checks for all the objects in the array, and value is 0 in all objects
export const areAllValuesZero = (data) =>
  data.every((item) => item?.value === 0);

export const isChartEmptyData = (data) =>
  isDataEmpty(data) || isEntireDataArrayEmpty(data) || areAllValuesZero(data);
