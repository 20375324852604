import { type ReactNode, createContext } from "react";
import type { CurrentUser } from "types/MerchantInfo";

/**
 * This is a provider that provides the current user to the app.
 * It's used to access the current user's information (currentUser).
 * Make sure it's used on the top of the Route and uses RailsContext.
 */

export type CurrentUserContext = {
  currentUser: CurrentUser | null;
};

export const CurrentUserContext = createContext<CurrentUserContext>({
  currentUser: null,
});

export type CurrentUserProviderProps = {
  currentUser: CurrentUser;
  children: ReactNode;
};
export const CurrentUserProvider = ({
  children,
  currentUser,
}: CurrentUserProviderProps) => {
  return (
    <CurrentUserContext.Provider
      value={{
        currentUser,
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
};
