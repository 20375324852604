import { colorsV2 } from "constants/colors";
import type { SvgIconProps } from "types/Icon";

export const ForwardToInbox = ({
  size,
  fill = colorsV2.text[1],
  ...rest
}: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g id="Name=forward_to_inbox, Size=24">
        <path
          id="Vector"
          d="M20.4462 5.5538H3.55384V18.4462H13.274V20.1495H3.55384C3.09385 20.1495 2.69498 19.9806 2.35721 19.6428C2.01946 19.3051 1.85059 18.9062 1.85059 18.4462V5.5538C1.85059 5.09218 2.01946 4.69191 2.35721 4.35298C2.69498 4.01403 3.09385 3.84455 3.55384 3.84455H20.4462C20.9079 3.84455 21.3081 4.01403 21.6471 4.35298C21.986 4.69191 22.1555 5.09218 22.1555 5.5538V13.4055H20.4462V5.5538ZM3.55384 5.5538V18.4462V13.4055V13.4995V5.5538ZM12 11.0038L20.4462 5.5538V7.0663L12.4598 12.3245C12.3179 12.4198 12.1665 12.4674 12.0055 12.4674C11.8446 12.4674 11.6895 12.4198 11.5403 12.3245L3.55384 7.2413V5.5538L12 11.0038ZM20.0892 20.1495H15.5658C15.3414 20.1495 15.1534 20.0731 15.0016 19.9205C14.8498 19.7679 14.774 19.5788 14.774 19.3531C14.774 19.1275 14.8498 18.9389 15.0016 18.7872C15.1534 18.6356 15.3414 18.5598 15.5658 18.5598H20.0892L18.8419 17.3125C18.6839 17.1545 18.6081 16.9696 18.6144 16.7576C18.6208 16.5457 18.7029 16.3574 18.8609 16.1929C19.0189 16.0375 19.2048 15.9608 19.4188 15.9628C19.6327 15.9648 19.8191 16.0452 19.9779 16.204L22.5288 18.7549C22.6988 18.9277 22.7837 19.1293 22.7837 19.3598C22.7837 19.5902 22.6988 19.7904 22.5288 19.9603L19.9778 22.5114C19.8151 22.6741 19.628 22.7545 19.4164 22.7525C19.2048 22.7505 19.0194 22.6682 18.8601 22.5058C18.7027 22.3483 18.6271 22.1656 18.6335 21.9576C18.6398 21.7496 18.7156 21.5647 18.8609 21.4027L20.0892 20.1495Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};
