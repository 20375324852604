export type TSettlementOption = {
  settlementPeriod: number;
  text: string;
  cost: string | null;
  fee: string | null;
};

export const premiumSettlementOptions: TSettlementOption[] = [
  {
    settlementPeriod: 5,
    text: "Standard 5 business days",
    cost: "Free",
    fee: null,
  },
  {
    settlementPeriod: 3,
    text: "Ultra fast 3 business days",
    cost: null,
    fee: "0.5%",
  },
];

export const premiumExpeditedSettlementOptions: TSettlementOption[] = [
  ...premiumSettlementOptions,
  {
    settlementPeriod: 0,
    text: "Same business day",
    cost: null,
    fee: "0.5%",
  },
];

export const growthSettlementOptions: TSettlementOption[] = [
  {
    settlementPeriod: 5,
    text: "Standard 5 business days",
    cost: "Free",
    fee: null,
  },
  {
    settlementPeriod: 3,
    text: "Ultra fast 3 business days",
    cost: null,
    fee: "0.75%",
  },
];

export const growthExpeditedSettlementOptions: TSettlementOption[] = [
  ...growthSettlementOptions,
  {
    settlementPeriod: 0,
    text: "Same business day",
    cost: null,
    fee: "0.75%",
  },
];

export const ultraSettlementOptions: TSettlementOption[] = [
  {
    settlementPeriod: 5,
    text: "Standard 5 business days",
    cost: "Free",
    fee: null,
  },
  {
    settlementPeriod: 3,
    text: "Ultra fast 3 business days",
    cost: "Free",
    fee: null,
  },
];

export const ultraExpeditedSettlementOptions: TSettlementOption[] = [
  ...ultraSettlementOptions,
  {
    settlementPeriod: 0,
    text: "Same business day",
    cost: null,
    fee: "1.4%",
  },
];

export const getSettlementOptions = (plan: string): TSettlementOption[] => {
  if (plan === "Premium") {
    return premiumExpeditedSettlementOptions;
  }

  if (plan === "Ultra") {
    return ultraExpeditedSettlementOptions;
  }

  return growthExpeditedSettlementOptions;
};
