import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import qs from "qs";
import ReactOnRails from "react-on-rails";

import { endpoints } from "constants/apiEndpoints";

type SubmissionStatus = "pending" | "submitted";
type SubmissionStatusPayload = {
  submissionStatus: SubmissionStatus;
};

export const useUpdateSubmissionStatus = () => {
  const queryParams = {
    authenticity_token: ReactOnRails.authenticityToken(),
  };
  const url = endpoints.MANAGE.API.ACCOUNTING_INTEGRATIONS.INDEX;

  const updateSubmissionStatus = useMutation({
    mutationKey: ["updateSubmissionStatus"],
    mutationFn: async (data: SubmissionStatusPayload) => {
      await axios.patch(`${url}?${qs.stringify(queryParams)}`, {
        submission_status: data.submissionStatus,
      });
    },
  });

  return { updateSubmissionStatus };
};
