import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

import type { PlanToUse } from "types/PlanToUse";
import type { Position } from "types/Position";
import type {
  SignUpState,
  UpdateDataPayload,
  UpdateErrorMessagePayload,
} from "./typeDefs";

export const initialState: SignUpState = {
  activeSignUpStep: 1,
  data: {
    accessToken: "",
    agreedTermsAndPrivacy: false,
    businessName: "",
    complianceType: null,
    email: "",
    firstName: "",
    industryId: null,
    lastName: "",
    mode: null,
    password: "",
    planToUse: [],
    onboardingRole: null,
    sessionId: "",
  },
  errorMessage: {
    email: null,
    otp: null,
  },
  sessionExpired: false,
  stepAnimationDirection: "bottom",
};

export const signUpSlice = createSlice({
  name: "signUp",
  initialState,
  reducers: {
    updateData(state, action: PayloadAction<UpdateDataPayload>) {
      state.data = {
        ...state.data,
        ...action.payload.data,
      };
    },
    updateActiveStep(
      state,
      action: PayloadAction<{ activeSignUpStep: number }>,
    ) {
      state.activeSignUpStep = action.payload.activeSignUpStep;
    },
    updateSessionExpired(
      state,
      action: PayloadAction<{ sessionExpired: boolean }>,
    ) {
      state.sessionExpired = action.payload.sessionExpired;
    },
    updateStepAnimationDirection(
      state,
      action: PayloadAction<{ stepAnimationDirection: Position }>,
    ) {
      state.stepAnimationDirection = action.payload.stepAnimationDirection;
    },
    updateErrorMessage(
      state,
      action: PayloadAction<UpdateErrorMessagePayload>,
    ) {
      state.errorMessage = {
        ...state.errorMessage,
        ...action.payload.errorMessage,
      };
    },
    togglePlanToUse(state, action: PayloadAction<PlanToUse>) {
      const plan = action.payload;
      const planIndex = state.data.planToUse.indexOf(plan);

      // If the plan is not found in the array (index is -1)
      if (planIndex === -1) {
        state.data.planToUse.push(plan);
      } else {
        // If the plan is already in the array, remove it by its index
        state.data.planToUse.splice(planIndex, 1);
      }
    },
    resetSignUpState(state) {
      state.activeSignUpStep = initialState.activeSignUpStep;
      state.data = initialState.data;
      state.errorMessage = initialState.errorMessage;
      state.stepAnimationDirection = initialState.stepAnimationDirection;
    },
  },
});

export const {
  resetSignUpState,
  togglePlanToUse,
  updateActiveStep,
  updateData,
  updateErrorMessage,
  updateSessionExpired,
  updateStepAnimationDirection,
} = signUpSlice.actions;

export default signUpSlice.reducer;
