import { colorsV2 } from "constants/colors";
import type { SvgIconProps } from "types/Icon";

export const Mamo = ({
  size,
  fill = colorsV2.text[1],
  ...rest
}: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g id="Name=Mamo, Size=24">
        <g id="Vector">
          <path
            d="M11.9893 1C8.96926 1 6.15425 2.20115 4.06169 4.38258C1.96738 6.56666 0.88326 9.43886 1.00997 12.4712C1.24757 18.1734 5.89818 22.7958 11.5968 22.993C11.7288 22.9974 11.8608 23 11.9928 23C14.8659 23 17.5683 21.9106 19.6406 19.9087C21.8062 17.817 22.9994 15.0082 22.9994 12.0004C22.9994 5.93572 18.0602 1 11.9893 1ZM20.4546 12.4395C20.2381 16.7179 16.7182 20.2387 12.4398 20.4543C10.0463 20.5748 7.70211 19.6755 6.01258 17.986C4.32304 16.2964 3.42372 13.954 3.54427 11.5587C3.76074 7.28031 7.2806 3.75957 11.559 3.54398C13.9551 3.42254 16.2967 4.32275 17.9863 6.01228C19.6758 7.70181 20.5751 10.0443 20.4546 12.4395Z"
            fill={fill}
          />
          <path
            d="M11.9999 18.572C15.5488 18.572 18.4456 15.7262 18.5284 12.1967H5.47144C5.55415 15.7262 8.451 18.572 11.9999 18.572Z"
            fill={fill}
          />
        </g>
      </g>
    </svg>
  );
};
