import { colorsV2 } from "constants/colors";
import type { SvgIconProps } from "types/Icon";

export const StackedBarChart = ({
  size,
  fill = colorsV2.text[1],
  ...rest
}: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g id="Name=stacked_bar_chart, Size=24">
        <path
          id="Vector"
          d="M5.74748 20.1016C5.2379 20.1016 4.8043 19.9222 4.4467 19.5635C4.08909 19.2047 3.91028 18.7709 3.91028 18.262V9.04783H7.58963V18.262C7.58963 18.7709 7.41 19.2047 7.05073 19.5635C6.69148 19.9222 6.25706 20.1016 5.74748 20.1016ZM3.91028 7.54783V5.70595C3.91028 5.20017 4.08991 4.76747 4.44918 4.40787C4.80843 4.04827 5.24285 3.86847 5.75243 3.86847C6.26201 3.86847 6.6956 4.0488 7.0532 4.40945C7.41082 4.77008 7.58963 5.20298 7.58963 5.70815V7.54783H3.91028ZM11.9975 20.1016C11.4879 20.1016 11.0543 19.9222 10.6967 19.5635C10.3391 19.2047 10.1603 18.7709 10.1603 18.262V12.0478H13.8396V18.262C13.8396 18.7709 13.66 19.2047 13.3007 19.5635C12.9415 19.9222 12.5071 20.1016 11.9975 20.1016ZM10.1603 10.5478V8.70595C10.1603 8.20017 10.3399 7.76747 10.6992 7.40787C11.0584 7.04827 11.4928 6.86847 12.0024 6.86847C12.512 6.86847 12.9456 7.0488 13.3032 7.40945C13.6608 7.77008 13.8396 8.20298 13.8396 8.70815V10.5478H10.1603ZM18.2475 20.1016C17.7379 20.1016 17.3043 19.9222 16.9467 19.5635C16.5891 19.2047 16.4103 18.7709 16.4103 18.262V15.0478H20.0896V18.262C20.0896 18.7709 19.91 19.2047 19.5507 19.5635C19.1915 19.9222 18.7571 20.1016 18.2475 20.1016ZM16.4103 13.5478V11.7059C16.4103 11.2002 16.5899 10.7675 16.9492 10.4079C17.3084 10.0483 17.7428 9.86847 18.2524 9.86847C18.762 9.86847 19.1956 10.0488 19.5532 10.4094C19.9108 10.7701 20.0896 11.203 20.0896 11.7081V13.5478H16.4103Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};
