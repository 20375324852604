import { RouterProvider, createBrowserRouter } from "react-router-dom";

import { FadeIn } from "components/AnimatedElements/FadeIn";
import { ReactQueryProvider } from "components/Providers";
import { routes } from "constants/routes";

import { FirstTimeLoginLayout } from "features/FirstTimeLogin/Layout";
import { CodeVerificationStep } from "features/FirstTimeLogin/_views/CodeVerificationStep/CodeVerificationStepRoot";
import { PhoneNumberStep } from "features/FirstTimeLogin/_views/PhoneNumberStep/PhoneNumberStepRoot";

const router = createBrowserRouter([
  {
    path: routes.MANAGE.FIRST_TIME_LOGIN.PHONE(),
    element: (
      <FadeIn custom={1} className="mx-auto w-full max-w-410">
        <PhoneNumberStep />
      </FadeIn>
    ),
  },
  {
    path: routes.MANAGE.FIRST_TIME_LOGIN.CODE(),
    element: (
      <FadeIn custom={1} className="mx-auto w-full max-w-410">
        <CodeVerificationStep />
      </FadeIn>
    ),
  },
]);

export const FirstTimeLoginRoot = () => {
  return (
    <>
      <ReactQueryProvider>
        <FirstTimeLoginLayout>
          <RouterProvider router={router} />
        </FirstTimeLoginLayout>
      </ReactQueryProvider>
    </>
  );
};
