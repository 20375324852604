import { type UseQueryResult, useQuery } from "@tanstack/react-query";
import axios from "axios";
import get from "lodash/get";
import qs from "qs";
import toast from "react-hot-toast";
import ReactOnRails from "react-on-rails";
import { useDispatch } from "react-redux";

import { endpoints } from "constants/apiEndpoints";

import { useAppSelector } from "store";

import { generalErrors } from "constants/errorMessages";
import { handleGetIntegrationsSuccess } from "features/Settings/Accounting/slice/accountingDashboardSlice";
import { SentryLoggingService } from "init/SentryLoggingService";

export type ConnectionStatus =
  | "init"
  | "pending"
  | "healthy"
  | "disconnected"
  | "expired";

export type ConnectionPlatform = "quickbooks" | "xero";
export type SubmissionStatus = "pending" | "submitted" | null;

export type ConnectionStatusItem = {
  apiPlatformCompanyId?: string;
  connectionStatus: ConnectionStatus;
  identifier?: string;
  inviteLinkId?: string;
  platform: ConnectionPlatform;
  submissionStatus: SubmissionStatus;
};

export type ConnectionStatuses = {
  ms_dynamics_365: ConnectionStatus;
  quickbooks: ConnectionStatus;
  xero: ConnectionStatus;
};

export type AccountingIntegrationObject = {
  connectionStatus: ConnectionStatus;
  canConnect: boolean;
};

export type AccountingIntegrations = {
  [key in keyof ConnectionStatuses]?: AccountingIntegrationObject;
};

export type AccountingIntegrationsFormattedResponse = {
  hasHealthyConnection: boolean;
  hasSubmittedConnectionRequest: boolean;
  integrations: AccountingIntegrations;
  isXeroConnected: boolean;
  isMS365Connected: boolean;
};

export type GetAccountingIntegrationsQueryResult = UseQueryResult<
  AccountingIntegrationsFormattedResponse,
  unknown
>;

export interface UseGetAccountingIntegrationsParams {
  enabled: boolean;
}

export interface UseGetAccountingIntegrationsResponse {
  getAccountingIntegrations: GetAccountingIntegrationsQueryResult;
  isLoadingIntegrations: boolean;
}

const queryParams = {
  authenticity_token: ReactOnRails.authenticityToken(),
};

export const useGetAccountingIntegrations = ({
  enabled,
}: UseGetAccountingIntegrationsParams): UseGetAccountingIntegrationsResponse => {
  const {
    accountingIntegrations: { isInitialFetch, shouldRefetch },
  } = useAppSelector((store) => store.accountingDashboard);

  const dispatch = useDispatch();

  const getAccountingIntegrations = useQuery({
    queryKey: ["getAccountingIntegrations"],
    enabled: enabled && (isInitialFetch || shouldRefetch),
    refetchInterval: shouldRefetch ? 1000 : false,
    queryFn: async () => {
      try {
        const response = await axios.get(
          `${endpoints.MANAGE.API.ACCOUNTING_INTEGRATIONS.INDEX}?${qs.stringify(queryParams)}`,
        );
        const data: ConnectionStatusItem[] = get(response, "data.data", []);

        const hasHealthyConnection = data.some(
          (item) => item.connectionStatus === "healthy",
        );
        const hasSubmittedConnectionRequest = data.some(
          (item) => item.submissionStatus === "submitted",
        );

        const integrations: AccountingIntegrations = data.reduce(
          (acc, item) => {
            acc[item.platform] = {
              connectionStatus: item.connectionStatus,
              submissionStatus: item.submissionStatus,
            };
            return acc;
          },
          {},
        );
        if (data?.length > 0) {
          dispatch(handleGetIntegrationsSuccess(data[0]));
        }

        const isXeroConnected =
          integrations.xero?.connectionStatus === "healthy";
        const isMS365Connected =
          integrations.ms_dynamics_365?.connectionStatus === "healthy";

        /**
         * Instead of returning just formatted api response, we will also derive data points which are
         * completely dependent on server state and return them as part of react-query response. This will
         * help us later on access these data points from react query cache
         */
        return {
          hasHealthyConnection,
          hasSubmittedConnectionRequest,
          integrations: integrations,
          isXeroConnected,
          isMS365Connected,
        };
      } catch (error: any) {
        toast.error(generalErrors.SOMETHING_WENT_WRONG, { duration: 3000 });
        SentryLoggingService.captureException(error.message, {
          feature: "[Settings][Accounting Settings]",
          file: "useGetAccountingIntegrations.tsx",
          function: "useGetAccountingIntegrations",
        });
      }
    },
  });

  return {
    getAccountingIntegrations,
    isLoadingIntegrations:
      (isInitialFetch || shouldRefetch) && getAccountingIntegrations.isLoading,
  };
};
