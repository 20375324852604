export function camelToHyphen(inputString: string) {
  try {
    return inputString
      .replace(/([a-z])([A-Z])/g, "$1-$2") // Handle lowercase followed by uppercase
      .replace(/([A-Z])([A-Z][a-z])/g, "$1-$2") // Handle consecutive uppercase letters followed by lowercase
      .toLowerCase();
  } catch (error) {
    console.error("[camelToHyphenUtil.ts]", error);
    return inputString;
  }
}
