export const initState = {
  styles: {
    table: {
      minWidth: "1180px",
      width: "100%",
      height: "100%",
      borderCollapse: "separate",
      borderSpacing: "0px 2px",
    },
  },
};
