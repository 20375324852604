import type { RenderFunctionResult } from "react-on-rails/node_package/lib/types";

import type IRailsContext from "interfaces/IRailsContext";
import type ISubscriberDetails from "interfaces/subscriptions/ISubscriberDetailsProps";
import SubscriberDetailsContainer from "./SubscriberDetailsContainer";

export default (
  props: ISubscriberDetails,
  railsContext?: IRailsContext,
): RenderFunctionResult => {
  return () => (
    <SubscriberDetailsContainer {...props} railsContext={railsContext} />
  );
};
