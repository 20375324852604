import Button from "components/Button/Button";
import type IPaginationMeta from "interfaces/IPaginationMeta";

interface IPaginationProps {
  meta: IPaginationMeta;
  onSetPage: any;
  itemName: string;
}

const Pagination: React.FC<IPaginationProps> = ({
  meta,
  onSetPage,
  itemName,
}) => {
  const { from, to, prevPage, nextPage, page, totalPages, totalCount } = meta;
  return (
    <div className="pagination">
      <p className="pagination__results">
        {from}-{to} of {totalCount} {itemName}
      </p>

      <div className="pagination__number-buttons">
        <p className="pagination__number-buttons__page-number">
          Page {page} of {totalPages}
        </p>

        <div className="pagination__number-buttons__buttons">
          <Button
            onClick={() => onSetPage(prevPage)}
            color="secondary"
            size="sm"
            isIcon
            iconFont="chevron_left"
            isDisabled={!prevPage}
          />
          <Button
            onClick={() => onSetPage(nextPage)}
            color="secondary"
            size="sm"
            isIcon
            iconFont="chevron_right"
            isDisabled={!nextPage}
          />
        </div>
      </div>
    </div>
  );
};

export default Pagination;
