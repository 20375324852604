import { yupResolver } from "@hookform/resolvers/yup";
import {
  type Channel,
  Channels,
} from "components/TwoFactorAuthentication/_shared/schema";
// Components
import { Button } from "design_system/Button";
import { Chat, WhatsApp } from "design_system/Icons";
import { PhoneNumberInput } from "design_system/Inputs/PhoneNumberInput";
import { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useCheckPhoneNumber } from "repositories/accounts/useCheckPhoneNumber";
import { useSendCode } from "repositories/accounts/useSendCode";
import { type InferType, object, string } from "yup";

const _formSchema = object({
  phoneNumber: string()
    .required("Phone number is required")
    .min(1, {
      message: "Phone number is required",
    })
    .default(null)
    .nullable(),
});

type FormSchema = InferType<typeof _formSchema>;

export const PhoneNumberForm = () => {
  const navigate = useNavigate();
  const {
    mutateAsync: checkPhoneNumber,
    isLoading,
    data,
  } = useCheckPhoneNumber();
  const { mutateAsync: sendCode, isLoading: isSendingCode } = useSendCode();

  // Form Declaration
  const {
    errors,
    control,
    getValues,
    formState: { isValid },
  } = useForm<FormSchema>({
    resolver: yupResolver(_formSchema),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const errorMessage = useMemo(() => {
    if (data && !data.success && "error" in data) {
      return data.error.message;
    }
    return null;
  }, [data]);

  const isButtonDisabled = useMemo(() => {
    return (
      isLoading || isSendingCode || !isValid || !!errors.phoneNumber?.message
    );
  }, [isLoading, isSendingCode, isValid, errors]);

  const onSubmit = async (channel: Channel) => {
    if (!isValid) return;

    const phoneNumber = getValues("phoneNumber");

    const checked = await checkPhoneNumber(phoneNumber);

    if (checked.success) {
      sendCode({ phoneNumber, channel });

      navigate(
        `/manage/first-time-login/code?phone_number=${phoneNumber}&channel=${channel}`,
      );
    }
  };

  const sendAsSms = () => {
    onSubmit(Channels.SMS);
  };

  const sendAsWhatsapp = () => {
    onSubmit(Channels.WHATSAPP);
  };

  return (
    <form className="space-y-24">
      <Controller
        control={control}
        name="phoneNumber"
        render={(props) => (
          <PhoneNumberInput
            {...props}
            error={!!errors.phoneNumber?.message || !!errorMessage}
            helperText={errors.phoneNumber?.message || errorMessage}
          />
        )}
      />

      <div className="grid grid-cols-1 gap-12">
        <Button
          size="lg"
          type="button"
          variant="filled"
          disabled={isButtonDisabled}
          onClick={sendAsSms}
          classes="w-full"
          label="Send code via SMS"
          iconPosition="left"
          Icon={Chat}
        />

        <Button
          size="lg"
          type="button"
          variant="outlined"
          disabled={isButtonDisabled}
          onClick={sendAsWhatsapp}
          classes="w-full"
          label="Send code via WhatsApp"
          iconPosition="left"
          Icon={WhatsApp}
        />
      </div>
    </form>
  );
};
