import { type JSXElementConstructor, useState } from "react";
import { cn } from "utils/classNames";

import { StoreFront } from "design_system/Icons";
import { Tag } from "design_system/Tag";

import { colorsV2 } from "constants/colors";
import type { TTagProps } from "design_system/Tag/Tag";
import type { SvgIconProps } from "types/Icon";

export type AvatarProps = {
  size: "md" | "lg" | "xl" | "2xl";
  tagProps?: TTagProps;
} & (AvatarImageProps | AvatarIconProps);

type AvatarIconProps = {
  isIcon: true;
  Icon: JSXElementConstructor<SvgIconProps>;
};
type AvatarImageProps = {
  isIcon: false;
  url: string;
};

const iconSizeMappings = {
  sm: "12",
  md: "12",
  lg: "18",
  xl: "18",
  "2xl": "24",
};

export const Avatar = (props: AvatarProps) => {
  const [showFallback, setShowFallback] = useState(false);
  const { tagProps, size } = props;

  let content = props.isIcon ? (
    props.Icon && (
      <props.Icon size={iconSizeMappings[size]} fill={colorsV2.text[2]} />
    )
  ) : (
    <img
      src={(props as AvatarImageProps).url}
      alt="Avatar"
      onError={() => setShowFallback(true)}
      className={cn({
        "h-24 w-24 rounded-full": size === "md",
        "h-32 w-32 rounded-full": size === "lg",
        "h-40 w-40 rounded-full": size === "xl",
        "h-56 w-56 rounded-full": size === "2xl",
      })}
    />
  );
  if (showFallback) {
    content = (
      <StoreFront size={iconSizeMappings[size]} fill={colorsV2.text[2]} />
    );
  }

  return (
    <div
      className={cn("flex items-center rounded-full", {
        // These conditions set the wrapper dimension
        "h-24 w-24 min-w-24 max-w-24": size === "md",
        "h-32 w-32 min-w-32 max-w-32": size === "lg",
        "h-40 w-40 min-w-40 max-w-40": size === "xl",
        "h-56 w-56 min-w-56 max-w-56": size === "2xl",
        "justify-center bg-surface-2": props.isIcon || showFallback,
        relative: tagProps?.color,
      })}
    >
      {content}
      {tagProps?.color && (
        <div
          className={cn("absolute", {
            "-right-4 bottom-0": size === "xl",
          })}
        >
          <Tag {...tagProps} size="sm" />
        </div>
      )}
    </div>
  );
};
