import { IslandProvider } from "design_system/Island/IslandContext";
import { RefreshInfoToolbar } from "../shared/RefreshInfoToolbar";
import { ExpenseAccountsTable } from "./ExpenseAccountsTable";

import type { ExpenseAccountsWithPagination } from "types/accounting/ExpenseAccount";

type ExpenseAccountsProps = {
  accounts?: ExpenseAccountsWithPagination;
};

export const ExpenseAccounts = ({ accounts }: ExpenseAccountsProps) => {
  return (
    <section className="flex flex-col gap-24">
      <RefreshInfoToolbar />
      <IslandProvider>
        <ExpenseAccountsTable accounts={accounts} />
      </IslandProvider>
    </section>
  );
};
