import type { PaginationMeta } from "types/PaginationMeta";

export const PAGINATION_META_INIT_STATE: PaginationMeta = {
  page: 1,
  perPage: 10,
  totalPages: null,
  nextPage: null,
  prevPage: null,
  from: null,
  to: null,
  totalCount: null,
};
