import { CalendarToday, ErrorOutline } from "design_system/Icons";
import type { TagColors } from "design_system/Tag/typeDefs";

import { getSingularOrPlural } from "helpers/stringUtils";

const getRemainingDaysText = (cardRepaymentRemainingDays: number) => {
  if (cardRepaymentRemainingDays > 5) {
    return `${cardRepaymentRemainingDays} days remaining`;
  }
  if (cardRepaymentRemainingDays === 0) {
    return "Due today";
  }
  if (cardRepaymentRemainingDays < 0) {
    const absoluteCardRepaymentRemainingDays = Math.abs(
      cardRepaymentRemainingDays,
    );
    return `${absoluteCardRepaymentRemainingDays} ${getSingularOrPlural("day", absoluteCardRepaymentRemainingDays)} overdue`;
  }
  return `${cardRepaymentRemainingDays} ${getSingularOrPlural("day", cardRepaymentRemainingDays)} remaining`;
};

export const getRemainingDaysMapping = (cardRepaymentRemainingDays: number) => {
  const isOverDue = cardRepaymentRemainingDays < 0;
  const isDueToday = cardRepaymentRemainingDays === 0;

  if (isOverDue) {
    return {
      color: "pink" as TagColors,
      label: getRemainingDaysText(cardRepaymentRemainingDays),
      textColor: undefined,
      Icon: ErrorOutline,
    };
  }
  if (isDueToday) {
    return {
      color: "yellow" as TagColors,
      label: "Due today",
      textColor: "text-accent-yellow-02",
      Icon: ErrorOutline,
    };
  }
  return {
    color: "green" as TagColors,
    label: getRemainingDaysText(cardRepaymentRemainingDays),
    textColor: "text-accent-green-01",
    Icon: CalendarToday,
  };
};
