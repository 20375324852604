import type { WalletTransaction } from "features/Wallet/WalletV2/typeDefs";
import {
  type ApiResponse,
  type PaginatedRequest,
  Repository,
} from "repositories/_base/repository";

import type { Card } from "types/Card";
import type { Wallet } from "types/Wallet";

class FinancesRepository extends Repository {
  constructor() {
    super({
      gateway: "/finances",
    });
  }

  public async getFinances() {
    return this._http
      .get<ApiResponse<{ wallets: Wallet[]; cards: Card[] }>>("/")
      .then(this.handleResponse)
      .catch(this.handleError);
  }

  public async byIdentifier(identifier: string) {
    return this._http
      .get<ApiResponse<Wallet>>(`/${identifier}`)
      .then(this.handleResponse)
      .catch(this.handleError);
  }

  public async transactionsFor(
    identifier: string,
    paginationMeta?: PaginatedRequest,
  ) {
    return this._http
      .get<ApiResponse<WalletTransaction[]>>(`/${identifier}/transactions`, {
        params: paginationMeta,
      })
      .then(this.handleResponse)
      .catch(this.handleError);
  }
}

export const financesRepository = new FinancesRepository();
