import { InputAdornment } from "@mui/material";

import LoaderBusiness from "components/Loader/LoaderBusiness";
import { IconButton } from "design_system/IconButton";
import { Close } from "design_system/Icons";

export const EndAdornment = ({ isLoading, searchText, onClose }) => {
  return (
    <InputAdornment position="end">
      {isLoading && searchText.length > 2 ? (
        <LoaderBusiness classes="-mr-24" width={64} height={64} />
      ) : (
        <IconButton Icon={Close} onClick={onClose} />
      )}
    </InputAdornment>
  );
};
