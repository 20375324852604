import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { IconButton } from "design_system/IconButton";
import { ArrowBack } from "design_system/Icons";
import { ProgressIndicator } from "design_system/ProgressIndicator";
import { PageHeader } from "../components";
import { SignUpAnimatedRoutes } from "./SignUpAnimatedRoutes";

import {
  updateActiveStep,
  updateStepAnimationDirection,
} from "reducers/redux/Access/SignUp/signUpSlice";
import { useAppSelector } from "store";

import routes from "constants/routes";
import { cn } from "utils/classNames";

export const SignUpContainer = () => {
  const { activeSignUpStep, data } = useAppSelector((state) => state.signUp);
  const { mode } = data;
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const isSuccessPage = location.pathname === routes.ONBOARDING.SUCCESS();

  const startWithBasicsStep = routes.ONBOARDING.START_WITH_BASICS();
  const createPasswordStep = routes.ONBOARDING.CREATE_PASSWORD();
  const tellUsMoreBusinessStep = routes.ONBOARDING.TELL_US_MORE_BUSINESS();

  const planToUseStep = routes.ONBOARDING.PLAN_TO_USE();

  const backButtonPaths = [
    createPasswordStep,
    tellUsMoreBusinessStep,
    planToUseStep,
  ];

  const progressIndicatorPaths = [startWithBasicsStep, ...backButtonPaths];

  const showBackButton = backButtonPaths.some((path) =>
    location.pathname.includes(path),
  );

  const showProgressIndicator = progressIndicatorPaths.some((path) =>
    location.pathname.includes(path),
  );

  const handleGoBack = () => {
    navigate(-1);
    dispatch(updateActiveStep({ activeSignUpStep: activeSignUpStep - 1 }));
    dispatch(updateStepAnimationDirection({ stepAnimationDirection: "left" }));
  };

  return (
    <>
      <PageHeader />
      <section className="global-grid mt-32 py-64 md:mt-48 md:py-72">
        {showProgressIndicator && (
          <div className="col-span-4 md:col-span-4 md:col-start-3 lg:col-start-5">
            <ProgressIndicator
              activeStep={activeSignUpStep}
              steps={mode === "email" ? 5 : 4}
            />
          </div>
        )}

        <div
          className={cn(
            "col-span-full mt-32 flex flex-col gap-32 md:col-span-6 md:col-start-2 md:mt-48 lg:col-start-4 xl:col-span-4 xl:col-start-5",
            {
              "xl:grid-col-start-4 lg:col-span-8 lg:col-start-3 xl:col-span-4 xl:col-start-5":
                isSuccessPage,
            },
          )}
        >
          {showBackButton && (
            <IconButton size="sm" Icon={ArrowBack} onClick={handleGoBack} />
          )}

          <SignUpAnimatedRoutes />
        </div>
      </section>
    </>
  );
};
