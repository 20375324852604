import { useDispatch } from "react-redux";

import { TableCell } from "components/Table/TableCell";
import { Switch } from "design_system/Inputs/Switch";

import { useBulkUpdateExpenseAccounts } from "./useBulkUpdateExpenseAccounts";

import { setPagination } from "reducers/redux/TableReducer/tableReducer";
import type { ExpenseAccount } from "types/accounting/ExpenseAccount";

type ColumnCell = {
  row: {
    original: ExpenseAccount;
  };
};
export const useExpenseAccountsTable = () => {
  const dispatch = useDispatch();

  const { bulkUpdateExpenseAccounts } = useBulkUpdateExpenseAccounts();

  const handleToggleClick = ({ identifier, enabled }) => {
    bulkUpdateExpenseAccounts.mutateAsync({
      identifiers: [identifier],
      enabled,
    });
  };

  const COLUMNS = [
    {
      header: "Name",
      accessorKey: "name",
      cell: (props: ColumnCell) => (
        <TableCell
          mainContent={props.row.original.name}
          classNames={{
            mainContent: "mt-10",
          }}
        />
      ),
    },
    {
      header: "Currency",
      accessorKey: "currency",
      meta: {
        alignment: "end",
      },
      cell: ({ row: { original: record } }: ColumnCell) => {
        const { currency, enabled, identifier } = record;

        return (
          <TableCell
            mainContent={
              <div className="flex items-center justify-end">
                {currency}

                <Switch
                  id={identifier}
                  checked={enabled}
                  disabled={bulkUpdateExpenseAccounts.isLoading}
                  onClick={() =>
                    handleToggleClick({ identifier, enabled: !enabled })
                  }
                />
              </div>
            }
            classNames={{
              container: "w-full",
              mainContent: "mt-10",
            }}
          />
        );
      },
    },
  ];

  const handleSetPage = (newPageNumber: number) => {
    dispatch(
      setPagination({ tableName: "expenseAccounts", page: newPageNumber }),
    );
  };

  return {
    bulkUpdateExpenseAccounts,
    COLUMNS,
    handleSetPage,
    handleToggleClick,
  };
};
