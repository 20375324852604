import { type QueryObserverResult, useQuery } from "@tanstack/react-query";
import axios from "axios";

import { endpoints } from "constants/apiEndpoints";
import type { TPlans } from "../types";

export const useGetPlanDetails = () => {
  const url = endpoints.MANAGE.API.MERCHANT.PLAN_DETAILS;

  const getPlanDetails: QueryObserverResult<TPlans> = useQuery({
    queryKey: ["getPlanDetails"],
    queryFn: () => axios.get(url).then((res) => res.data.data),
  });

  return {
    getPlanDetails,
  };
};
