import MUIAccordion from "@mui/material/Accordion";
import MUIAccordionDetails from "@mui/material/AccordionDetails";
import MUIAccordionSummary from "@mui/material/AccordionSummary";

import { colorsV2 } from "constants/colors";
import { ExpandMore } from "design_system/Icons";
import type {
  AccordionProps,
  AccordionRootProps,
  DetailsProps,
  SummaryProps,
} from "./typeDefs";

export const AccordionRoot = ({
  id,
  children,
  className,
}: AccordionRootProps) => {
  return (
    <div test-id={id} className={className}>
      {children}
    </div>
  );
};

export const Accordion = ({ children, ...restProps }: AccordionProps) => {
  return (
    <MUIAccordion
      sx={{
        ...restProps.sx,
        boxShadow: "none",
        borderBottom: `1px solid ${colorsV2.border[2]}`,
        "&:before": {
          display: "none",
        },
        "&:first-of-type": {
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        },
        "&:last-of-type": {
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        },
      }}
      {...restProps}
    >
      {children}
    </MUIAccordion>
  );
};

export const Summary = ({ id, children, ...restProps }: SummaryProps) => {
  return (
    <MUIAccordionSummary
      test-id={id}
      expandIcon={<ExpandMore size="18" color={colorsV2.text[3]} />}
      sx={{
        ...restProps.sx,
        "& .MuiAccordionSummary-content": {
          margin: "0",
        },
        "& .MuiAccordionSummary-content.Mui-expanded": {
          margin: "0",
        },
      }}
      {...restProps}
    >
      {children}
    </MUIAccordionSummary>
  );
};

export const Details = ({
  children,
  className,
  ...restProps
}: DetailsProps) => {
  return (
    <MUIAccordionDetails
      {...restProps}
      className={className}
      sx={{
        paddingLeft: "24px",
        ...restProps.sx,
      }}
    >
      {children}
    </MUIAccordionDetails>
  );
};

Accordion.Summary = Summary;
Accordion.Details = Details;
