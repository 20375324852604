import { createContext, useState } from "react";

import { RECORDS_PER_PAGE } from "constants/api";

export interface IBaseTableContextProps {
  pageSize: number;
  selectedRows: Set<any>;
  setPageSize: (params: any) => void;
  setSelectedRows: (params: any) => void;
}

export const tableInitValues: IBaseTableContextProps = {
  pageSize: RECORDS_PER_PAGE,
  selectedRows: new Set(),
  setPageSize: (params: any) => {},
  setSelectedRows: (params: any) => {},
};

// Create a context to manage the state of the Island
export const TableContext = createContext(tableInitValues);

const TableProvider = ({ children }) => {
  const [selectedRows, setSelectedRows] = useState<Set<any>>(new Set());
  const [pageSize, setPageSize] = useState(100);

  return (
    <TableContext.Provider
      value={{ pageSize, selectedRows, setPageSize, setSelectedRows }}
    >
      {children}
    </TableContext.Provider>
  );
};

export default TableProvider;
