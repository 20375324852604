import { useCallback, useState } from "react";
import toast from "react-hot-toast";
import { cn } from "utils/classNames";

import Button from "components/Button/Button";
import FieldMessage from "components/FieldMessage/FieldMessage";
import Toast from "components/ui/Toast";

import { generalErrors } from "constants/errorMessages";
import routes from "constants/routes";
import { bytesToSize } from "helpers/fileUtils";
import { isAppPlatform } from "helpers/isAppPlatform";
import { SentryLoggingService } from "init/SentryLoggingService";
import {
  NewDisbursementActionTypes,
  Steps,
} from "reducers/newDisbursementsReducer";
import { useGenerateOtp } from "../hooks/useGenerateOtp";

const BulkUpload = ({
  dispatch,
  setCurrentStep,
  onPrevStep,
  isEnabledBulkInternationalPayout,
}: any) => {
  const [fileName, setFileName] = useState<string>();
  const [fileSize, setFileSize] = useState<any>();
  const [isUploaded, setIsUploaded] = useState<boolean>(false);
  const [isDragOver, setIsDragOver] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [isDocumentError, setIsDocumentError] = useState<boolean>(false);
  const [isDocumentErrorMessage, setIsDocumentErrorMessage] = useState<any>();
  const { generateOtp } = useGenerateOtp();

  const onDragOver = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(true);

    return false;
  }, []);

  const onDragLeave = useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsDragOver(false);

    return false;
  }, []);

  const handleFileChange = (e: any) => {
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      const newFiles = e.target.files;
      const fileName = newFiles[0].name;
      const extension = fileName.split(".").pop();
      const isSupported = extension === "csv";
      if (!isSupported) {
        setIsDocumentError(true);
        setIsDocumentErrorMessage(
          `You\u2019re trying to upload a ${extension} file. Please use .csv`,
        );
        setIsDragOver(false);
      } else {
        reader.onload = () => {
          setIsUploaded(true);
        };
        reader.readAsDataURL(e.target.files[0]);
        setSelectedFile(e.target.files[0]);
        setFileName(e.target.files[0].name);
        setFileSize(e.target.files[0].size);
      }
    }
  };

  const handleCloseFileUpload = () => {
    setIsUploaded(false);
    setIsDragOver(false);
    setSelectedFile(null);
    setFileName("");
    setFileSize(null);
    setIsDocumentError(false);
  };

  const onSubmit = () => {
    setIsUploaded(false);
    const formData = {
      selectedFile,
    };

    generateOtp
      .mutateAsync({ purpose: "create_payout" })
      .then(() => {
        dispatch({
          type: NewDisbursementActionTypes.SET_BULK_UPLOAD,
          payload: formData,
        });
        setIsDragOver(false);
        setCurrentStep(Steps.VerificationCode);
      })
      .catch((error) => {
        setIsDragOver(false);
        SentryLoggingService.captureException(error.message, {
          feature: "[Create Disbursement][Generate OTP]",
          file: "BulkUpload/index.ts",
          function: "onSubmit",
          data: {
            purpose: "create_payout",
          },
        });
        toast.error(generalErrors.SOMETHING_WENT_WRONG, {
          duration: 3000,
        });
      })
      .finally(() => {
        setIsUploaded(true);
      });
  };

  const actionOnClick = (e: any) => {
    // When we focus on the description field and press the enter button.
    // It will trigger the action onclick in the form.
    // e.nativeEvent.pointerType will check type that you input
    // press enter => pointerType === ''
    if (e.nativeEvent.pointerType !== "") {
      onPrevStep();
    }
  };

  return (
    <>
      <Toast />
      <header>
        <button
          className="create-link__back-button material-icons-round"
          onClick={actionOnClick}
        >
          arrow_back
        </button>
        {!isAppPlatform() && (
          <a
            href={routes.MANAGE.DISBURSEMENTS.INDEX()}
            className="create-link__close-button material-icons-round"
          >
            close
          </a>
        )}
      </header>
      <section className="create-link__customize-link-step">
        {/* PROCESS COMPONENT */}
        <div className="process">
          <div className="process__content">
            <h1 className="process__content__heading">Create payout</h1>
            {isEnabledBulkInternationalPayout && (
              <>
                <p className="process__content__info">
                  Please upload your .csv file with the correct format using our{" "}
                  <a
                    href="/Payouts-sample.csv"
                    className="text-blue-500 underline"
                  >
                    local payouts template
                  </a>{" "}
                  or{" "}
                  <a
                    href="https://docs.google.com/spreadsheets/d/10hdNCaKMh5xunTZfzzWWdm7UrvNchj-iIEwzDdLWdX0/edit?usp=sharing"
                    className="text-blue-500 underline"
                  >
                    international payouts template
                  </a>
                  .
                  <br />
                  <br />
                  Please follow the instructions detailed on the templates. To
                  know more about the required format{" "}
                  <a
                    href="https://help.mamopay.com/en/articles/7201981-mass-payouts"
                    className="text-blue-500 underline"
                    target="_blank"
                    rel="noreferrer"
                  >
                    check our FAQs
                  </a>{" "}
                </p>
              </>
            )}

            {!isEnabledBulkInternationalPayout && (
              <p className="process__content__info">
                Please upload your .csv file with a list of payouts in the
                correct format.{" "}
                <a
                  href="/Payouts-sample.csv"
                  className="text-blue-500 underline"
                >
                  Download a sample file
                </a>{" "}
                or{" "}
                <a
                  href="https://help.mamopay.com/en/articles/7201981-mass-payouts"
                  className="text-blue-500 underline"
                  target="_blank"
                  rel="noreferrer"
                >
                  check our FAQs
                </a>{" "}
                to know more about the required format.
              </p>
            )}
          </div>
          <div className="space-y-16">
            {/* DRAG AND DROP INPUT */}
            <form>
              {!isUploaded ? (
                <div className="form-field">
                  <div
                    className={cn("form-field__control file-upload", {
                      "file-upload-dragover": isDragOver,
                      "file-upload-error": isDocumentError,
                    })}
                  >
                    <div>
                      <input
                        name="file"
                        className="form-field__file"
                        type="file"
                        id="js-file-input"
                        accept=".csv"
                        onChange={handleFileChange}
                        onDragOver={onDragOver}
                        onDragLeave={onDragLeave}
                      />
                      <i
                        className="material-icons-round file-upload-icon"
                        style={{
                          animation: isDragOver
                            ? "pulsate 0.75s alternate infinite"
                            : "",
                        }}
                      >
                        upload_file
                      </i>
                      <div className="space-y-4">
                        <span className="text-16">
                          Drop your file or{" "}
                          <label
                            htmlFor="js-file-input"
                            className="relative z-10 text-button text-button--md text-button--primary text-button--solid"
                          >
                            browse
                          </label>
                        </span>
                        <p className="text-14 text-black-300">
                          Supported file types: .csv
                        </p>
                      </div>
                    </div>
                  </div>
                  {isDocumentError && (
                    <FieldMessage
                      messageType="error"
                      icon="error"
                      messageText={isDocumentErrorMessage}
                    />
                  )}
                </div>
              ) : (
                <div className="form-field">
                  <div className="form-field__control file-uploaded">
                    <i
                      className="material-icons-round file-uploaded-close"
                      onClick={handleCloseFileUpload}
                    >
                      close
                    </i>
                    <div>
                      <i className="material-icons-round file-uploaded-icon">
                        description
                      </i>
                      <div className="space-y-4">
                        <p className="text-16">{fileName}</p>
                        <p className="text-14 text-black-300">
                          {bytesToSize(fileSize)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </form>
            {/* PAYOUT CHECKLIST */}
            {!isEnabledBulkInternationalPayout && (
              <div className="rounded-16 bg-yellow-50 p-24">
                <p className="mb-16 font-medium text-16">
                  Your payout checklist
                </p>
                <ul className="unordered-list space-y-8 px-16 text-14">
                  <li>Payout amount is in AED</li>
                  <li>Minimum amount per payout is AED 10</li>
                  <li>Your uploaded file follows the correct format</li>
                  <li>
                    The total payout amount doesn't exceed your current account
                    balance
                  </li>
                  <li>
                    All linked bank accounts are based in the United Arab
                    Emirates
                  </li>
                </ul>
              </div>
            )}
            {isEnabledBulkInternationalPayout && (
              <div className="rounded-16 bg-yellow-50 p-24">
                <p className="mb-16 font-medium text-16">
                  Your payout checklist
                </p>
                <ul className="unordered-list space-y-8 px-16 text-14">
                  <li>Min amount per payout doesn't exceed AED 10</li>
                  <li>Max amount per payout doesn't exceed AED 18,000</li>
                  <li>Your uploaded file follows the correct format</li>
                  <li>
                    The total payout amount is lower than your current account
                    balance
                  </li>
                </ul>
              </div>
            )}
          </div>
          <div className="mt-32 flex items-center justify-center md:mt-48">
            <Button
              color="primary"
              size="lg"
              label="Confirm payout"
              classes="w-full md:w-auto"
              onClick={onSubmit}
              isDisabled={!isUploaded}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default BulkUpload;
