import { type ReactNode, createContext } from "react";

interface FullPageLayoutProviderProps {
  hideDividers?: boolean;
  isMobile: boolean;
  children: ReactNode;
}

export const fullPageLayoutInitState = {
  hideDividers: false,
  isMobile: false,
};

// Create a context to manage the state of the FullPageLayout
export const FullPageLayoutContext = createContext(fullPageLayoutInitState);

export const FullPageLayoutProvider = ({
  isMobile,
  hideDividers,
  children,
}: FullPageLayoutProviderProps) => {
  return (
    <FullPageLayoutContext.Provider value={{ isMobile, hideDividers }}>
      {children}
    </FullPageLayoutContext.Provider>
  );
};
