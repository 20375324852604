import { errorCodes } from "./errroCodes";
import { generalErrors } from "./generalErrors";

type TErrorDetail = {
  errorCode: string;
  errorData: any;
  errors: any[];
};

const ERROR_MSGS_INIT_STATE = [
  { field: null, message: generalErrors.SOMETHING_WENT_WRONG },
];

export type { TErrorDetail };

export { errorCodes, ERROR_MSGS_INIT_STATE, generalErrors };
