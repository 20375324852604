import routes from "constants/routes";

const handleGoToPlans = () => {
  window.location.href = routes.MANAGE.PLANS.INDEX();
};

export const handleGoToSettlements = () => {
  window.location.href = routes.MANAGE.PLANS.CHOOSE_SETTLEMENT();
};

export const sharedGoToPlanButtonProps = (
  hasPremium?: boolean,
  hasPremiumTrial?: boolean,
) => {
  return {
    onClick: handleGoToPlans,
    color: "primary",
    size: "sm",
    label: !hasPremium || hasPremiumTrial ? "Upgrade" : "Change",
  };
};

export const sharedGoToSettlementButtonProps = {
  onClick: handleGoToSettlements,
  color: "primary",
  size: "sm",
  label: "Change",
};
