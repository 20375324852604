import { FullPageLayout } from "features/Cards/features/CreateCard/core/components/FullPageLayout/FullPageLayout";
import { TransferFundsForm } from "features/Wallet/WalletV2/features/TransferFunds/Form";
import { useScreenDimension } from "hooks/useScreenDimension";

export const TransferFundsRoot = () => {
  const { isMobile } = useScreenDimension();

  return (
    <FullPageLayout isLoading={false}>
      <FullPageLayout.Header
        title="Transfer"
        isMobile={isMobile}
        onClose={() => {
          window.history.back();
        }}
      />
      <TransferFundsForm />
    </FullPageLayout>
  );
};
