import { type UseMutationResult, useMutation } from "@tanstack/react-query";
import axios, { type AxiosResponse } from "axios";
import qs from "qs";
import ReactOnRails from "react-on-rails";

import { queryClient } from "config/reactQueryClient";
import { endpoints } from "constants/apiEndpoints";

export const useAddExpenseCategories = (): {
  addExpenseCategories: UseMutationResult<
    AxiosResponse<any>,
    unknown,
    any,
    unknown
  >;
} => {
  /* ---------------------------------
   *  MUTATION FN
   * ---------------------------------
   */
  const addExpenseCategories = useMutation<AxiosResponse, unknown, any>({
    mutationKey: ["addExpenseCategories"],
    mutationFn: async (payload: string[]) => {
      const queryParams = {
        authenticity_token: ReactOnRails.authenticityToken(),
      };
      const response = await axios.post(
        `${endpoints.MANAGE.API.EXPENSES.ADD_CATEGORIES}?${qs.stringify(queryParams)}`,
        {
          names: payload,
        },
      );
      return response;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["useGetExpenseCategories"],
      });
    },
  });
  return {
    addExpenseCategories,
  };
};
