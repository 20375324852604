import { useRef } from "react";
import { cn } from "utils/classNames";

// OTHERS
import { colorsV2 } from "constants/colors";
import type { NavigationIconName } from "../../core/DashboardNavigation.types";
import { iconMapping } from "../../core/utils/iconMapping";

interface NavigationRailPrimaryItemProps {
  active: boolean;
  Icon: NavigationIconName;
  label: string;
  url: string;
  progress?: number;
}

export const NavigationRailPrimaryItem = ({
  active,
  Icon,
  label,
  url,
  progress,
}: NavigationRailPrimaryItemProps) => {
  const iconDivRef = useRef<HTMLDivElement>(null);
  const IconComponent = iconMapping[Icon];

  const handleClick = () => {
    if (iconDivRef.current) {
      iconDivRef.current.focus(); // Manually focus on the icon div wrapper so we can add the focus state
    }
  };

  return (
    <a
      tabIndex={-1}
      href={url}
      className="group flex w-full flex-col items-center justify-center gap-4"
      onClick={handleClick}
    >
      <div
        ref={iconDivRef}
        tabIndex={0}
        className={cn(
          "flex h-32 w-32 items-center justify-center rounded-8 transition-all",
          {
            "rounded-full border bg-white": progress,
            "focus:bg-surface-3 group-hover:bg-surface-3": !active,
            "bg-accent-blue-02": active,
          },
        )}
      >
        <IconComponent
          size="18"
          fill={active ? colorsV2.primary[1] : colorsV2.text[2]}
          className={cn(
            "h-18 w-18 transform transition-transform group-hover:scale-110",
            {
              "text-primary-1": active,
              "text-text-color-02": !active,
            },
          )}
        />
      </div>

      <span
        className={cn("caption", {
          "text-text-color-02": !active,
        })}
      >
        {label}
      </span>
    </a>
  );
};
