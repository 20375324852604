import Autocomplete, {
  type AutocompleteProps,
} from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import TextField, { type TextFieldProps } from "@mui/material/TextField";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import isFunction from "lodash/isFunction";

import { Close } from "design_system/Icons";

import { colorsV2 } from "constants/colors";
import { customTheme } from "../../shared/theme";

export interface Tag {
  name: string;
  isValid: boolean;
}

export interface MultipleTextInputPropsV2 {
  dropdownProps?: AutocompleteProps<Tag, true, true, true>;
  errorMessage?: string;
  inputValue: string;
  tags: Tag[];
  textInputProps?: TextFieldProps;
  onDeleteTag?: (filteredTags: Tag[]) => void;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  setTags: React.Dispatch<React.SetStateAction<Tag[]>>;
}

export const MultipleTextInputV2 = ({
  dropdownProps,
  errorMessage,
  inputValue,
  tags,
  textInputProps,
  onDeleteTag,
  setInputValue,
  setTags,
}: MultipleTextInputPropsV2) => {
  const outerTheme = useTheme();

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  const handleAddTag = (newValue: string | null) => {
    if (newValue && newValue.trim() !== "") {
      const newTag: Tag = { name: newValue.trim(), isValid: true };
      setTags([...tags, newTag]);
      setInputValue(""); // Clear inputValue
    }
  };

  /**
   * When blur event fires, we will take whatever user was entering ( if any )
   * and create a tag out of it.
   * We will leave the validation up to the consumer of the component.
   */
  const handleBlur = () => handleAddTag(inputValue);

  const handleDeleteTag = (tagToDelete: Tag) => {
    const updatedTags = tags.filter((tag) => tag.name !== tagToDelete.name);
    if (isFunction(onDeleteTag)) {
      onDeleteTag(updatedTags);
    } else setTags(updatedTags);
  };

  const renderPlaceholder = () => {
    if (tags.length === 0) {
      return textInputProps?.placeholder;
    }
    return "";
  };

  return (
    <ThemeProvider theme={customTheme(outerTheme)}>
      <Autocomplete
        multiple
        options={tags}
        getOptionLabel={(option: Tag) => option.name}
        value={tags}
        onChange={(event, newValue: Tag[]) => {
          setTags(newValue);
        }}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        onBlur={handleBlur}
        renderInput={(params) => (
          <TextField
            {...params}
            {...textInputProps}
            fullWidth
            InputProps={{
              ...params.InputProps,
              style: {
                minHeight: "5.6rem",
                height: "100%",
              },
              endAdornment: null, // Remove the dropdown arrow
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter" || event.key === ",") {
                event.preventDefault();
                handleAddTag(inputValue);
              }
            }}
            error={!!errorMessage}
            helperText={errorMessage || ""}
            placeholder={renderPlaceholder()}
          />
        )}
        renderTags={(value, getTagProps) =>
          value.map((option: Tag, index) => {
            const { name, isValid } = option;
            return (
              <Chip
                key={index}
                label={name}
                onDelete={() => handleDeleteTag(option)}
                deleteIcon={
                  <div className="p-6">
                    <Close size="12" />
                  </div>
                }
                {...getTagProps({ index })}
                style={{
                  backgroundColor: isValid
                    ? colorsV2.surface[3]
                    : colorsV2.accent["red"][2],
                  color: colorsV2.text[1],
                }}
              />
            );
          })
        }
        open={false} // Do not show the dropdown menu
        clearOnBlur={false} // Prevent clearing on blur
        clearOnEscape={false} // Prevent clearing on escape key
        clearText="" // Hide clear all button
        {...dropdownProps}
      />
    </ThemeProvider>
  );
};
