import toast from "react-hot-toast";

import { deactivateLink } from "components/PaymentLinksRoot/Hooks/useDeactivate";
import type { ButtonMenuOptions } from "design_system/Dropdown/typeDefs";
import { copyText } from "pages/Payment/Pay/helpers";

interface DisableModalProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ShowSubscriptionDetailOptions extends DisableModalProps {
  subscriptionUrl: string;
}

interface HandleDisableSubscriptionLinkProps extends DisableModalProps {
  paymentLinkIdentifier: string;
}

const toggleDisableSubscriptionModal = ({
  showModal,
  setShowModal,
}: DisableModalProps) => setShowModal(!showModal);
const handleGoToSubscriptionLink = (subscriptionUrl: string) =>
  window.open(subscriptionUrl, "_blank");

const copyToClipboard = (subscriptionUrl: string) => {
  copyText(subscriptionUrl);
  notifyActionComplete("Link copied!");
};

export const notifyActionComplete = (message: string) => {
  toast.success(message, {
    duration: 5000,
    style: { fontSize: "16px" },
  });
};

export const handleDisableSubscriptionLink = async ({
  paymentLinkIdentifier,
  showModal,
  setShowModal,
}: HandleDisableSubscriptionLinkProps) => {
  await deactivateLink(paymentLinkIdentifier)
    .then(() => {
      toggleDisableSubscriptionModal({ showModal, setShowModal });
      notifyActionComplete("Recurring payment link disabled");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    })
    .catch((error) => {
      console.error(error);
    });
};

export const showSubscriptionDetailOptions = ({
  subscriptionUrl,
  showModal,
  setShowModal,
}: ShowSubscriptionDetailOptions): ButtonMenuOptions[] => {
  return [
    {
      label: "Preview payment link",
      onClick: () => handleGoToSubscriptionLink(subscriptionUrl),
    },
    {
      label: "Copy payment link",
      onClick: () => copyToClipboard(subscriptionUrl),
    },
    {
      label: <p className="text-error-1">Disable recurring payment</p>,
      onClick: () =>
        toggleDisableSubscriptionModal({ showModal, setShowModal }),
    },
  ];
};
