import { colorsV2 } from "constants/colors";
import type { SvgIconProps } from "types/Icon";

export const Description = ({
  size,
  fill = colorsV2.text[1],
  ...rest
}: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g id="Name=description, Size=24">
        <path
          id="Vector"
          d="M8.76091 17.762H15.2451C15.4661 17.762 15.6513 17.6863 15.8008 17.535C15.9503 17.3836 16.025 17.1961 16.025 16.9723C16.025 16.7503 15.9503 16.5654 15.8008 16.4177C15.6513 16.27 15.4661 16.1962 15.2451 16.1962H8.75494C8.53397 16.1962 8.34874 16.2713 8.19926 16.4215C8.04978 16.5717 7.97504 16.7579 7.97504 16.9799C7.97504 17.202 8.05035 17.3879 8.20099 17.5375C8.3516 17.6871 8.53824 17.762 8.76091 17.762ZM8.76091 13.5119H15.2451C15.4661 13.5119 15.6513 13.4363 15.8008 13.285C15.9503 13.1336 16.025 12.9461 16.025 12.7223C16.025 12.5003 15.9503 12.3154 15.8008 12.1677C15.6513 12.02 15.4661 11.9462 15.2451 11.9462H8.75494C8.53397 11.9462 8.34874 12.0213 8.19926 12.1715C8.04978 12.3217 7.97504 12.5079 7.97504 12.7299C7.97504 12.952 8.05035 13.1379 8.20099 13.2875C8.3516 13.4371 8.53824 13.5119 8.76091 13.5119ZM5.55384 22.1495C5.09385 22.1495 4.69498 21.9806 4.35721 21.6428C4.01946 21.3051 3.85059 20.9062 3.85059 20.4462V3.5538C3.85059 3.09218 4.01946 2.69191 4.35721 2.35298C4.69498 2.01403 5.09385 1.84455 5.55384 1.84455H13.8761C14.1119 1.84455 14.3366 1.8922 14.5502 1.9875C14.7639 2.08278 14.9473 2.20706 15.1006 2.36033L19.6397 6.89945C19.793 7.05272 19.9173 7.23617 20.0125 7.44982C20.1078 7.66347 20.1555 7.88817 20.1555 8.12392V20.4462C20.1555 20.9062 19.986 21.3051 19.6471 21.6428C19.3081 21.9806 18.9079 22.1495 18.4462 22.1495H5.55384ZM13.7212 7.35217V3.5538H5.55384V20.4462H18.4462V8.2038H14.5729C14.3325 8.2038 14.1304 8.12197 13.9667 7.9583C13.8031 7.79462 13.7212 7.59257 13.7212 7.35217Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};
