import { useDispatch } from "react-redux";

import { TableCell } from "components/Table/TableCell";
import { Tag } from "design_system/Tag";

import { setPagination } from "reducers/redux/TableReducer/tableReducer";
import { getTapixAvatar } from "utils/tapixAvatarUtil";
import { repaymentsStatusMapping } from "../../constants/creditRepayments";

type TColumnCell = {
  row: {
    original: any;
  };
};

export const useCreditCardActivitiesTable = () => {
  /* ---------------------------------
   *  Hooks
   * ---------------------------------
   */
  const dispatch = useDispatch();

  /* ---------------------------------
   *  Helpers
   * ---------------------------------
   */
  const handleSetPage = (newPageNumber: number) => {
    dispatch(
      setPagination({ tableName: "creditActivities", page: newPageNumber }),
    );
  };

  /* ---------------------------------
   *  COLUMN DEF
   * ---------------------------------
   */
  const COLUMNS = [
    {
      header: "Activity",
      accessorKey: "merchantName",
      cell: ({ row: { original: record } }: TColumnCell) => {
        const avatar = getTapixAvatar(record.tapixShop, record.merchantName);

        return (
          <TableCell
            avatar={avatar}
            mainContent={record.merchantName}
            secondaryContent={record.createdAt}
          />
        );
      },
    },
    {
      header: "Amount",
      accessorKey: "billingAmountFormatted",
      meta: {
        alignment: "end",
      },
      cell: ({ row: { original: record } }: TColumnCell) => {
        return (
          <TableCell
            mainContent={record.billingAmountFormatted}
            classNames={{
              container: "w-full",
              mainContent: "text-right",
            }}
          />
        );
      },
    },
    {
      header: "Available credit",
      accessorKey: "balanceAmountFormatted",
      meta: {
        alignment: "end",
      },
      cell: ({ row: { original: record } }: TColumnCell) => {
        return (
          <TableCell
            mainContent={record.balanceAmountFormatted || "-"}
            classNames={{
              container: "w-full",
              mainContent: "text-right",
            }}
          />
        );
      },
    },
    {
      header: "Cards",
      accessorKey: "cardName",
      cell: ({ row: { original: record } }: TColumnCell) => {
        return (
          <TableCell
            mainContent={record.cardName || "-"}
            secondaryContent={record.cardHolderName}
          />
        );
      },
    },
    {
      header: "Status",
      accessorKey: "payCTA",
      cell: ({ row: { original: record } }: TColumnCell) => {
        try {
          return (
            <TableCell
              mainContent={
                <div className="flex items-center justify-between">
                  <div>
                    <Tag
                      label={repaymentsStatusMapping[record.status].label}
                      color={repaymentsStatusMapping[record.status].color}
                      Icon={repaymentsStatusMapping[record.status].Icon}
                    />
                  </div>
                </div>
              }
            />
          );
        } catch (error) {
          return <TableCell mainContent="-" />;
        }
      },
    },
  ];

  return {
    COLUMNS,
    handleSetPage,
  };
};
