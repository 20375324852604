import { useCallback, useContext } from "react";
import { useDispatch } from "react-redux";

import { Button } from "design_system/Button/Button";
import { Close } from "design_system/Icons";
import Island from "design_system/Island/Island";

import { useAppSelector } from "store";

import { IslandContext } from "design_system/Island/IslandContext";

import { colorsV2 } from "constants/colors";
import {
  clearSelectedRows,
  setSelectedRows,
} from "reducers/redux/TableReducer/tableReducer";
import { useBulkUpdatePaymentAccounts } from "../useBulkUpdatePaymentAccounts";

export const PaymentAccountsIsland = () => {
  /* ---------------------------------
   *  Context APIS & CACHES
   * ---------------------------------
   */
  const { closeIsland } = useContext(IslandContext);
  const {
    table: { paymentAccounts },
  } = useAppSelector((state) => state);

  /* ---------------------------------
   *  Hooks
   * ---------------------------------
   */
  const dispatch = useDispatch();
  const { bulkUpdatePaymentAccounts } = useBulkUpdatePaymentAccounts();

  /* ---------------------------------
   *  Constants
   * ---------------------------------
   */
  const selectedRows = paymentAccounts.selectedRows ?? [];
  const selectedCount = selectedRows.length;

  const isEveryRowToggledOff = selectedRows.every((row) => !row.enabled);

  /* ---------------------------------
   *  Helpers
   * ---------------------------------
   */
  const closeIslandAndResetSelections = () => {
    dispatch(clearSelectedRows("paymentAccounts"));
    closeIsland();
  };

  const handleBulkUpdate = useCallback(
    ({ enabled }) => {
      bulkUpdatePaymentAccounts.mutateAsync(
        {
          identifiers: selectedRows.map((row) => row.identifier),
          enabled,
        },
        {
          onSuccess: () => {
            dispatch(
              setSelectedRows({
                tableName: "paymentAccounts",
                selectedRows: selectedRows?.map((row) => ({ ...row, enabled })),
              }),
            );
          },
        },
      );
    },
    [selectedRows],
  );

  return (
    <Island>
      <div className="flex items-center gap-48">
        <Island.Label className="min-w-104 whitespace-nowrap">
          {selectedCount} selected
        </Island.Label>
        <div className="flex items-center gap-12">
          <Button
            label={isEveryRowToggledOff ? "Show data" : "Hide data"}
            size="md"
            variant="outlined"
            disabled={bulkUpdatePaymentAccounts.isLoading}
            onClick={() => handleBulkUpdate({ enabled: isEveryRowToggledOff })}
          />
        </div>
        <div>
          <Close
            size="24"
            className={
              bulkUpdatePaymentAccounts.isLoading
                ? "cursor-not-allowed"
                : "cursor-pointer"
            }
            fill={colorsV2.text[2]}
            onClick={closeIslandAndResetSelections}
          />
        </div>
      </div>
    </Island>
  );
};
