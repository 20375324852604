import { useContext } from "react";
import { useDispatch } from "react-redux";

import { TableCell } from "components/Table/TableCell";
import { Button } from "design_system/Button";
import { ErrorOutline } from "design_system/Icons";
import { Tag } from "design_system/Tag";

import { SideSheetContext } from "design_system/Sidesheet/SideSheetContext";

import {
  setPagination,
  setRecordDetail,
} from "reducers/redux/TableReducer/tableReducer";
import { getRemainingDaysMapping } from "../../utils/repaymentUtils";

type TColumnCell = {
  row: {
    original: any;
  };
};

export const useUpcomingRepaymentsTable = () => {
  const { openSideSheet } = useContext(SideSheetContext);

  const dispatch = useDispatch();

  const handleSetPage = (newPageNumber: number) => {
    dispatch(setPagination({ tableName: "repayments", page: newPageNumber }));
  };

  const handlePaymentBtnClick = (recordDetail) => {
    dispatch(
      setRecordDetail({
        tableName: "repayments",
        recordDetail,
      }),
    );

    openSideSheet();
  };

  const COLUMNS = [
    {
      header: "Repayment due date",
      accessorKey: "cardRepaymentDate",
      cell: ({ row: { original: record } }: TColumnCell) => {
        const cardRepaymentDate = record.cardRepaymentDate;
        const cardRepaymentRemainingDays = record.cardRepaymentRemainingDays;
        const remainingDays = getRemainingDaysMapping(
          cardRepaymentRemainingDays,
        );

        let mainContent = cardRepaymentDate;
        let secondaryContent = remainingDays.label;

        if (cardRepaymentRemainingDays <= 0) {
          mainContent = (
            <div className="mt-4 flex items-center gap-12">
              {cardRepaymentDate}
              <Tag
                color={remainingDays.color}
                label={remainingDays.label}
                Icon={ErrorOutline}
              />
            </div>
          );
          secondaryContent = undefined;
        }

        return (
          <TableCell
            mainContent={mainContent}
            secondaryContent={secondaryContent}
            classNames={{
              secondaryContent: remainingDays.textColor,
            }}
          />
        );
      },
    },
    {
      header: "Amount spent",
      accessorKey: "billingAmountFormatted",
      meta: {
        alignment: "end",
      },
      cell: ({ row: { original: record } }: TColumnCell) => {
        return (
          <TableCell
            mainContent={record.billingAmountFormatted}
            classNames={{
              container: "w-full",
              mainContent: "text-right",
            }}
          />
        );
      },
    },
    {
      header: "Interest",
      accessorKey: "creditCardFeeAmountFormatted",
      meta: {
        alignment: "end",
      },
      cell: ({ row: { original: record } }: TColumnCell) => {
        return (
          <TableCell
            mainContent={record.creditCardFeeAmountFormatted}
            classNames={{
              container: "w-full",
              mainContent: "text-right",
            }}
          />
        );
      },
    },
    {
      header: "Total amount",
      accessorKey: "totalAmountFormatted",
      meta: {
        alignment: "end",
      },
      cell: ({ row: { original: record } }: TColumnCell) => {
        const showLateFee = record.creditCardLateFeeAmount > 0;
        const lateFeeFormatted = record.creditCardLateFeeAmountFormatted;

        return (
          <TableCell
            mainContent={record.totalAmountFormatted}
            secondaryContent={
              showLateFee ? `${lateFeeFormatted} late fee applied` : undefined
            }
            classNames={{
              container: "w-full",
              mainContent: "text-right",
              ...(showLateFee
                ? {
                    secondaryContent: "text-accent-red-01 text-right",
                  }
                : {}),
            }}
          />
        );
      },
    },
    {
      header: "",
      accessorKey: "payCTA",
      cell: ({ row: { original: record } }: TColumnCell) => (
        <TableCell
          mainContent={
            <Button
              label="Pay"
              variant="filled"
              size="md"
              onClick={() => handlePaymentBtnClick(record)}
            />
          }
        />
      ),
    },
  ];

  return {
    COLUMNS,
    handleSetPage,
    handlePaymentBtnClick,
    getRemainingDaysMapping,
  };
};
