import { ReactQueryProvider, ReduxProvider } from "components/Providers";
import { Dashboard } from "./Dashboard";

export const ExpenseSettingsRoot = () => {
  return (
    <ReactQueryProvider>
      <ReduxProvider>
        <Dashboard />
      </ReduxProvider>
    </ReactQueryProvider>
  );
};
