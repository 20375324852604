import { cn } from "utils/classNames";

import LoaderBusiness from "components/Loader/LoaderBusiness";
import { Snackbar } from "design_system/Snackbar";
import { Tabs } from "design_system/Tab";
import { PageHeader } from "./PageHeader/PageHeader";
import { TableRoot } from "./Table/TableRoot";

import { useScreenDimension } from "hooks/useScreenDimension";
import { useDashboard } from "./hooks/useDashboard";

import { IslandProvider } from "design_system/Island/IslandContext";

export const Dashboard = () => {
  const { isMobile } = useScreenDimension();

  const {
    currentTab,
    expenseCategories,
    isLoadingExpenseCategories,
    handleAddCategory,
    setCurrentTab,
  } = useDashboard();

  if (isLoadingExpenseCategories) {
    return (
      <>
        <Snackbar />
        <PageHeader isLoading onClickAddCategory={handleAddCategory} />
        <LoaderBusiness />
      </>
    );
  }

  return (
    <>
      <PageHeader onClickAddCategory={handleAddCategory} />
      <Tabs
        value={currentTab}
        onChange={(e, newTab) => setCurrentTab(newTab)}
        size={isMobile ? "md" : "lg"}
      >
        <Tabs.Tab value="Categories" label="Categories" />
      </Tabs>
      <section
        className={cn("mt-20 md:mt-24 md:px-40", {
          "pt-16": currentTab === "Categories",
        })}
      >
        {currentTab === "Categories" && (
          <IslandProvider>
            <TableRoot
              categories={expenseCategories}
              onClickAddCategory={handleAddCategory}
            />
          </IslandProvider>
        )}
      </section>
    </>
  );
};
