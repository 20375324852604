import { Button } from "design_system/Button";
import { Download } from "design_system/Icons";
import { Tooltip } from "design_system/Tooltip";

import type {
  TCommonProps,
  TDownloadableProps,
  TListItemProps,
  TShowTooltipProps,
} from "./typeDefs";

export type TListProps = TCommonProps & TShowTooltipProps & TDownloadableProps;

export const List = ({
  children,
  columnHeader1,
  columnHeader2,
  isDownloadable,
  onDownload,
  showTooltip,
  title,
  tooltipProps,
}: TListProps) => {
  return (
    <div className="List">
      {/* LIST HEADER */}
      <div className="flex justify-between">
        <div className="flex items-center gap-8">
          <span className="header-small text-text-color-02">{title}</span>
          {showTooltip && <Tooltip {...tooltipProps} />}
        </div>

        {isDownloadable && (
          <div className="hidden md:block">
            <Button
              variant="outlined"
              size="md"
              iconOnly
              Icon={Download}
              onClick={onDownload}
            />
          </div>
        )}
      </div>

      {/* LIST COLUMN HEADER */}
      <div className="label-medium mt-24 grid grid-cols-2 gap-16 text-text-color-03">
        <span>{columnHeader1}</span>
        <span className="text-right">{columnHeader2}</span>
      </div>

      {children}
    </div>
  );
};

List.Item = ({ value1, value2 }: TListItemProps) => (
  <div className="ListItem body-large grid grid-cols-2 gap-16 border-border-2 border-b py-12">
    <span>{value1}</span>
    <span className="text-right">{value2}</span>

    <style>{`
      .List .ListItem:last-child {
        border-bottom: 0;
      }
    `}</style>
  </div>
);
