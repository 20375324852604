import {
  AddCircle,
  CheckCircle,
  CreditCard,
  ErrorOutline,
  Pending,
  Replay,
} from "design_system/Icons";

export const transactionOptionsMapping = {
  Completed: {
    id: "completed",
    value: "completed",
    label: "Completed",
  },
  Pending: {
    id: "pending",
    value: "pending",
    label: "Pending",
  },
  Refunded: {
    id: "refunded",
    value: "refunded",
    label: "Refunded",
  },
  Reversed: {
    id: "reversed",
    value: "reversed",
    label: "Reversed",
  },
  Declined: {
    id: "declined",
    value: "declined",
    label: "Declined",
  },
};

export const transactionStatusMapping = {
  Completed: {
    ...transactionOptionsMapping.Completed,
    color: "green",
    Icon: CheckCircle,
  },
  Pending: {
    ...transactionOptionsMapping.Pending,
    color: "blue",
    Icon: Pending,
  },
  Refunded: {
    ...transactionOptionsMapping.Refunded,
    color: "gray",
    Icon: Pending,
  },
  Reversed: {
    ...transactionOptionsMapping.Reversed,
    color: "gray",
    Icon: Replay,
  },
  Declined: {
    ...transactionOptionsMapping.Declined,
    color: "red",
    Icon: ErrorOutline,
  },
};

// Used in search and filters
export const transactionStatusFilters = [
  {
    id: "all",
    value: "all",
    label: "All",
  },
  ...Object.entries(transactionStatusMapping).map(([key, value], index) => ({
    id: index,
    value: value.value,
    label: value.label,
  })),
];

export const transactionTypeFilters = [
  {
    id: "all",
    value: "all",
    label: "All",
  },
  {
    id: "atm_withdrawal",
    value: "atm_widthdrawal",
    label: "ATM cash widhdrawls",
  },
  {
    id: "card_vcc_issued",
    value: "card_vcc_issued",
    label: "Card VCC issued",
  },
  {
    id: "card_transaction",
    value: "card_transaction",
    label: "Card transaction",
  },
  {
    id: "top_up",
    value: "top_up",
    label: "Balance top up",
  },
];

export const transactionAvatarMapping = {
  ["Balance top up"]: AddCircle,
  ["Virtual card issued"]: CreditCard,
};
