import axios from "axios";
import { isEmpty, reverse } from "lodash";
import moment from "moment";
import { useEffect, useReducer } from "react";

import LoaderBusiness from "components/Loader/LoaderBusiness";
import { Help } from "design_system/Icons";
import { List } from "design_system/List";
import { ChartCard } from "design_system/Statistics/Charts/ChartCard";
import { DonutChart } from "design_system/Statistics/Charts/Donut";
import { LineChart } from "design_system/Statistics/Charts/LineChart";

import { colorsV2 } from "constants/colors";
import routes from "constants/routes";
import { convertToLocaleStringHelper, formatAmount } from "helpers/currency";
import { useScreenDimension } from "hooks/useScreenDimension";
import analyticsReducer, {
  AnalyticsActionTypes,
  initialState,
} from "../reducers/analyticsReducer";
import { isChartEmptyData } from "../shared/chartUtils";
import type { TAnalyticsFrequency } from "../shared/typeDefs";

export const CustomerMetricsInsights = () => {
  const [state, dispatch] = useReducer(analyticsReducer, initialState);
  const { analytics, isLoading } = state;
  const { isMobile } = useScreenDimension();

  const fetchAnalyticsData = (
    analyticsKind: string,
    frequencyType: TAnalyticsFrequency,
  ) => {
    axios
      .get(routes.MANAGE.ANALYTICS.INDEX(), {
        headers: { accept: "application/json" },
        params: {
          kind: [analyticsKind],
          perPage: 12,
          frequency_type: [frequencyType],
        },
      })
      .then((res) => {
        dispatch({
          type: AnalyticsActionTypes.SET_DATA,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchAnalyticsKind = (
    analyticsKind: string,
    frequencyType: TAnalyticsFrequency,
    /** Setting reversed to true will have show the last data first and first data last. This would be needed for BarCharts as the order of oldest data will come first and newest data last. In Donut chart, this would be different */
    reversed: boolean,
  ) => {
    const data = analytics
      .filter((item: any) => item.kind.indexOf(analyticsKind) !== -1)
      .filter((item: any) => item.frequencyType.indexOf(frequencyType) !== -1);

    return reversed ? reverse(data) : data;
  };

  const handleDownloadAnalyticsCsv = (kind: string, frequencyType: string) => {
    window.location.href = `${routes.MANAGE.ANALYTICS.DOWNLOAD_CSV(kind, frequencyType)}`;
  };

  const formatCreatedAtDate = (createdAt: string) => {
    return moment(createdAt).format("MMM D");
  };

  const handleWeeklyDateFormat = (valueDatum) => {
    const { week, year } = valueDatum;

    // Set the first day of the year to ensure correct week calculation
    const firstDayOfYear = moment().year(year).startOf("year");

    const startOfWeek = firstDayOfYear
      .clone()
      .add(week, "weeks")
      .startOf("isoWeek");
    const endOfWeek = startOfWeek.clone().endOf("isoWeek");

    const formattedStartDate = startOfWeek.format("MMM D");
    const formattedEndDate = endOfWeek.format("MMM D");

    return `${formattedStartDate} - ${formattedEndDate}, ${year}`;
  };

  const cardOriginData = fetchAnalyticsKind(
    "card_origin",
    "last_90_days",
    false,
  );
  const paymentMethodsData = fetchAnalyticsKind(
    "payment_method",
    "last_90_days",
    false,
  );
  const uniqueCustomerData = fetchAnalyticsKind(
    "unique_customers",
    "weekly",
    true,
  );
  const avgSpendCustomerData = fetchAnalyticsKind(
    "avg_spend_per_customer",
    "weekly",
    true,
  );
  const topCustomersData = fetchAnalyticsKind(
    "top_customers",
    "lifetime",
    false,
  );
  const failedPaymentReasonsData = fetchAnalyticsKind(
    "payment_failure_reasons",
    "last_90_days",
    false,
  );

  useEffect(() => {
    fetchAnalyticsData("card_origin", "last_90_days");
    fetchAnalyticsData("payment_method", "last_90_days");
    fetchAnalyticsData("unique_customers", "weekly");
    fetchAnalyticsData("avg_spend_per_customer", "weekly");
    fetchAnalyticsData("top_customers", "lifetime");
    fetchAnalyticsData("payment_failure_reasons", "last_90_days");
  }, []);

  return (
    <>
      {isLoading && <LoaderBusiness />}
      {!isLoading && (
        <div className="space-y-24 md:space-y-40">
          {/* DONUT CHARTS - CARD ORIGIN + PAYMENT METHOD */}
          <div className="grid grid-cols-1 gap-24 lg:grid-cols-2 lg:gap-40">
            <ChartCard
              isDataEmpty={isEmpty(cardOriginData?.[0]?.values)}
              showTitle
              title="Card origin - last 90 days"
            >
              <DonutChart
                data={cardOriginData}
                width={300}
                height={300}
                segmentText="payment"
              />
            </ChartCard>
            <ChartCard
              isDataEmpty={isEmpty(paymentMethodsData?.[0]?.values)}
              showTitle
              title="Payment method - last 90 days"
            >
              <DonutChart
                data={paymentMethodsData}
                width={300}
                height={300}
                segmentText="payment"
              />
            </ChartCard>
          </div>

          {/* LINE CHARTS - UNIQUE CUSTOMER + AVG SPEND PER CUSTOMER */}
          <div className="grid grid-cols-1 gap-24 lg:grid-cols-2 lg:gap-40">
            <ChartCard
              isDataEmpty={isChartEmptyData(uniqueCustomerData)}
              showTitle
              title="Unique customers"
              tooltipProps={{
                content:
                  "Total number of customers with one or more payments during this period",
                contentPosition: "bottom",
                contentWidth: isMobile ? "18rem" : "26rem",
                Icon: <Help size="18" fill={colorsV2.text[3]} />,
              }}
              showToolbar
              onDownload={() =>
                handleDownloadAnalyticsCsv("unique_customers", "weekly")
              }
              showValue
              value={uniqueCustomerData.slice(-1)[0]?.value}
            >
              <LineChart
                data={uniqueCustomerData}
                hasCurrencyInTooltip={false}
                height={270}
                margin={{
                  top: 25,
                  right: isMobile ? 5 : 0,
                  bottom: 30,
                  left: isMobile ? 5 : 30,
                }}
                numTicks={5}
                bottomNumTicks={1}
                xAccessor={(d) => d?.createdAt}
                xValueFormat={(createdAt: string) =>
                  formatCreatedAtDate(createdAt)
                }
                yAccessor={(d) => d?.value}
                yValueFormat={(value: any) => formatAmount(value)}
                tooltipSubtext={handleWeeklyDateFormat}
              />
            </ChartCard>
            <ChartCard
              isDataEmpty={isChartEmptyData(avgSpendCustomerData)}
              showTitle
              title="Average spend per customer"
              tooltipProps={{
                content:
                  "The total amount each customer has spent on your product/services during this period, on average",
                contentPosition: "bottom",
                contentWidth: isMobile ? "18rem" : "26rem",
                Icon: <Help size="18" fill={colorsV2.text[3]} />,
              }}
              showToolbar
              onDownload={() =>
                handleDownloadAnalyticsCsv("avg_spend_per_customer", "weekly")
              }
              showValue
              value={`AED ${convertToLocaleStringHelper(avgSpendCustomerData.slice(-1)[0]?.value)}`}
            >
              <LineChart
                data={avgSpendCustomerData}
                height={270}
                margin={{
                  top: 25,
                  right: isMobile ? 5 : 0,
                  bottom: 30,
                  left: isMobile ? 5 : 60,
                }}
                numTicks={5}
                bottomNumTicks={1}
                xAccessor={(d) => d?.createdAt}
                xValueFormat={(createdAt: string) =>
                  formatCreatedAtDate(createdAt)
                }
                yAccessor={(d) => d?.value}
                yValueFormat={(value: any) => `AED ${formatAmount(value)}`}
                tooltipSubtext={handleWeeklyDateFormat}
              />
            </ChartCard>
          </div>

          {/* LISTS - TOP CUSTOMERS + TOP FAILURE REASONS */}
          <div className="grid grid-cols-1 gap-24 lg:grid-cols-2 lg:gap-40">
            {!isEmpty(topCustomersData?.[0]?.values) && (
              <div className="p-24">
                <List
                  title="Top customers"
                  columnHeader1="Customer"
                  columnHeader2="Revenue"
                >
                  {topCustomersData?.[0]?.values?.map(({ name, value }) => (
                    <List.Item
                      key={name}
                      value1={name}
                      value2={`AED ${convertToLocaleStringHelper(value)}`}
                    />
                  ))}
                </List>
              </div>
            )}
            {!isEmpty(failedPaymentReasonsData?.[0]?.values) && (
              <div className="p-24">
                <List
                  title="Top payment failure reasons"
                  columnHeader1="Reason"
                  columnHeader2="Rate"
                >
                  {failedPaymentReasonsData?.[0]?.values?.map(
                    ({ name, value }) => (
                      <List.Item
                        key={name}
                        value1={name}
                        value2={`${value}%`}
                      />
                    ),
                  )}
                </List>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
