import type { VendorsWithPagination } from "types/Vendor";
import { RefreshInfoToolbar } from "../shared/RefreshInfoToolbar";
import { VendorsTable } from "./VendorsTable";

import { IslandProvider } from "design_system/Island/IslandContext";

type VendorsProps = {
  vendors?: VendorsWithPagination;
};

export const Vendors = ({ vendors }: VendorsProps) => {
  return (
    <section className="flex flex-col gap-24">
      <RefreshInfoToolbar />
      <IslandProvider>
        <VendorsTable vendors={vendors} />
      </IslandProvider>
    </section>
  );
};
