import { colorsV2 } from "constants/colors";
import type { SvgIconProps } from "types/Icon";

export const ArrowForward = ({
  size,
  fill = colorsV2.text[1],
  ...rest
}: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g id="Name=arrow_forward, Size=24">
        <path
          id="Vector"
          d="M16.9049 12.8516H4.70221C4.45764 12.8516 4.25456 12.7708 4.09296 12.6092C3.93138 12.4476 3.85059 12.2446 3.85059 12C3.85059 11.7554 3.93138 11.5523 4.09296 11.3907C4.25456 11.2292 4.45764 11.1484 4.70221 11.1484H16.9049L11.4033 5.64675C11.2334 5.47681 11.1473 5.27655 11.1449 5.04597C11.1426 4.81539 11.2266 4.61463 11.3968 4.4437C11.5671 4.27216 11.7678 4.1874 11.999 4.1894C12.2301 4.1914 12.4314 4.27735 12.6028 4.44727L19.5528 11.3973C19.6437 11.4892 19.7099 11.5849 19.7514 11.6843C19.7929 11.7837 19.8136 11.8892 19.8136 12.0007C19.8136 12.1122 19.7929 12.2175 19.7514 12.3165C19.7099 12.4156 19.6437 12.511 19.5528 12.6027L12.5968 19.5527C12.4209 19.7266 12.2195 19.8136 11.9926 19.8136C11.7657 19.8136 11.5672 19.7262 11.3973 19.5513C11.2274 19.3811 11.1424 19.1824 11.1424 18.9551C11.1424 18.7278 11.2274 18.5292 11.3973 18.3592L16.9049 12.8516Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};
