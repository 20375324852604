import { colorsV2 } from "constants/colors";
import type { SvgIconProps } from "types/Icon";

export const CalendarToday = ({
  size,
  fill = colorsV2.text[1],
  ...rest
}: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g id="Name=calendar_today, Size=24">
        <path
          id="Vector"
          d="M4.55384 22.1495C4.09385 22.1495 3.69498 21.9806 3.35721 21.6428C3.01946 21.3051 2.85059 20.9062 2.85059 20.4462V5.0538C2.85059 4.59218 3.01946 4.19191 3.35721 3.85298C3.69498 3.51403 4.09385 3.34455 4.55384 3.34455H6.12504V2.69837C6.12504 2.45914 6.20807 2.25703 6.37414 2.09205C6.54019 1.92705 6.74214 1.84455 6.97999 1.84455C7.22494 1.84455 7.43145 1.92705 7.59954 2.09205C7.76762 2.25703 7.85166 2.45914 7.85166 2.69837V3.34455H16.1484V2.69837C16.1484 2.45914 16.2314 2.25703 16.3975 2.09205C16.5636 1.92705 16.7655 1.84455 17.0034 1.84455C17.2483 1.84455 17.4548 1.92705 17.6229 2.09205C17.791 2.25703 17.875 2.45914 17.875 2.69837V3.34455H19.4462C19.9079 3.34455 20.3081 3.51403 20.6471 3.85298C20.986 4.19191 21.1555 4.59218 21.1555 5.0538V20.4462C21.1555 20.9062 20.986 21.3051 20.6471 21.6428C20.3081 21.9806 19.9079 22.1495 19.4462 22.1495H4.55384ZM4.55384 20.4462H19.4462V9.75H4.55384V20.4462ZM4.55384 8.25H19.4462V5.0538H4.55384V8.25Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};
