import type { HTMLProps } from "react";
import { cn } from "utils/classNames";

export interface ICheckboxProps extends HTMLProps<HTMLInputElement> {
  items?: Array<any>;
  classes?: string;
  inputId?: string;
  labelText?: string | React.ReactNode;
  labelClasses?: string;
  description?: string;
  isDisabled?: boolean;
  register?: any;
  checkboxSize?: "sm";
}

export const Checkbox = ({
  items,
  classes,
  inputId,
  labelText,
  labelClasses,
  description,
  isDisabled,
  register,
  checkboxSize,
  ...nativeProps
}: ICheckboxProps) => {
  return (
    <div
      className={cn("form-field__checkbox-group", {
        [`${classes}`]: !!classes,
      })}
    >
      <input
        type="checkbox"
        className={cn("form-field__checkbox-group__checkbox", {
          sm: checkboxSize === "sm",
        })}
        id={inputId}
        disabled={isDisabled}
        ref={register}
        {...nativeProps}
      />
      <label
        htmlFor={inputId}
        className={cn("form-field__checkbox-group__label", {
          [`${labelClasses}`]: !!labelClasses,
        })}
        id={`${inputId}-label`}
      >
        {labelText}

        {description && (
          <p className="mt-4 font-regular text-14 text-black-300">
            {description}
          </p>
        )}
      </label>
    </div>
  );
};
