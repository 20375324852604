export const PaymentStatusLabel: any = {
  failed: "Failed",
  partial_refund: "Partial refund",
  pending: "Pending",
  refund_pending: "Refund pending",
  refunded: "Refunded",
  rejected_by_bank: "Rejected by bank",
  settled: "Settled",
  successful: "Successful",
  captured: "Successful",
};

export default PaymentStatusLabel;
