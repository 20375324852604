import LoaderBusiness from "components/Loader/LoaderBusiness";
import { PageHeader } from "./PageHeader";
import { SettlementBalance } from "./SettlementBalance";

import { useGetUpcomingSettlements } from "hooks/useGetUpcomingSettlements";

export const WalletV1 = () => {
  const { getUpcomingSettlements } = useGetUpcomingSettlements();
  const { data, isLoading } = getUpcomingSettlements;

  return (
    <>
      <PageHeader />

      <section className="px-24 md:px-40">
        {isLoading ? (
          <LoaderBusiness />
        ) : (
          <SettlementBalance
            nextSettlementAmount={data?.nextSettlementAmount}
            outstandingBalance={data?.outstandingBalance}
          />
        )}
      </section>
    </>
  );
};
