import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import type React from "react";

import { PlanContainer } from "./index";

import type IRailsContext from "interfaces/IRailsContext";
import type { TActivePlan } from "./shared/types";

export const PlanRoot: React.FC<TActivePlan> = (
  props: any,
  railsContext: IRailsContext,
) => {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <PlanContainer _={props} railsContext={railsContext} />
    </QueryClientProvider>
  );
};
