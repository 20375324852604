import type { Channel } from "components/TwoFactorAuthentication/_shared/schema";
import { type ApiResponse, Repository } from "repositories/_base/repository";

class AccountsRepository extends Repository {
  constructor() {
    super({
      gateway: "/accounts", // This is the endpoint. Added to baseURL
    });
  }

  public async verifyPhoneNumber(otp: string) {
    return this._http
      .post<ApiResponse<{ success: boolean; is_create_password: boolean }>>(
        "/verify_phone_number",
        { otp },
      )
      .then(this.handleResponse)
      .catch(this.handleError);
  }

  public async sendPhoneNumberOtp(phoneNumber: string, channel: Channel) {
    return this._http
      .post("/send_phone_number_otp", {
        phone_number: phoneNumber,
        channel,
      }) // This one returns RAW AxiosResponse
      .then(this.handleResponse) // This one produces { success: true, data: { ... } }
      .catch(this.handleError); // This one produces { success: false, error: { ... } }
  }

  public async checkPhoneNumber(phoneNumber: string) {
    return this._http
      .post("/validate_phone_number", {
        phone_number: phoneNumber,
      })
      .then(this.handleResponse)
      .catch(this.handleError);
  }
}

export const accountsRepository = new AccountsRepository();
