import { useQuery } from "@tanstack/react-query";
import { snack } from "design_system/Snackbar";

import { userRepository } from "./user.repository";

export const useGetUserInfo = () => {
  return useQuery({
    queryKey: ["userInfo"],
    queryFn: async () => {
      const response = await userRepository.getInfo();

      if (!response.success && "error" in response) {
        snack({
          title: response.error.message,
          leadingElement: "icon",
          variant: "critical",
        });

        userRepository.sentryLogError({
          error: response.error.message,
          feature: "useGetUserInfo",
          functionName: "userInfoQuery",
          data: JSON.stringify(response.error),
        });

        return null;
      }

      return response.data;
    },
  });
};
