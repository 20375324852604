import { type QueryObserverResult, useQuery } from "@tanstack/react-query";
import axios from "axios";
import get from "lodash/get";

import { endpoints } from "constants/apiEndpoints";
import { SentryLoggingService } from "init/SentryLoggingService";
import type { BankTransferDetailsData } from "../typeDef";

export const useGetTopupBankDetails = () => {
  const getTopupBankDetails: QueryObserverResult<
    BankTransferDetailsData,
    Error
  > = useQuery({
    queryKey: ["getTopupBankDetails"],
    queryFn: async () => {
      try {
        const url = endpoints.MANAGE.API.MERCHANT.TOP_UP_BANK_DETAILS;
        const response = await axios.get(url);

        const bankDetails: BankTransferDetailsData = get(
          response,
          "data.data",
          [],
        );

        return bankDetails;
      } catch (error: any) {
        SentryLoggingService.captureException(error.message, {
          feature: "[Cards][CardTopup]",
          file: "useGetTopupBankDetails.ts",
          function: "useGetTopupBankDetails",
        });
      }
    },
  });

  return {
    getTopupBankDetails,
  };
};
