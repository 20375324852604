import * as Sentry from "@sentry/react";
import {
  type QueryObserverResult,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import axios from "axios";
import qs from "qs";
import { useFormContext } from "react-hook-form";
import ReactOnRails from "react-on-rails";

import { endpoints } from "constants/apiEndpoints";
import type { TBankDetailsWithOtp } from "./shared/utils/types";

export const useGetAccountHolderNames = () => {
  const url = endpoints.MANAGE.API.SETTINGS.BANK.ACCOUNT_HOLDER_NAMES;

  const getNames: QueryObserverResult<any> = useQuery({
    queryKey: ["getAccountHolderNames"],
    queryFn: () => axios.get(url).then((res) => res.data.data),
  });

  return {
    getNames,
  };
};

export const useGenerateOtp = () => {
  const url = endpoints.MANAGE.API.SETTINGS.BANK.GENERATE_OTP;
  const queryParams = {
    authenticity_token: ReactOnRails.authenticityToken(),
  };

  const generateOtp: any = useMutation({
    mutationKey: ["generateOtp"],
    mutationFn: () => {
      const requestData = {
        purpose: "update_bank_account",
      };
      return axios.patch(`${url}?${qs.stringify(queryParams)}`, requestData);
    },
    onError: (error: any) => {
      Sentry.captureException(`[useEditBankDetails][generateOtp] - ${error}`);
    },
  });

  return {
    generateOtp,
  };
};

export const useUpdateBankDetails = () => {
  const url = endpoints.MANAGE.API.SETTINGS.BANK.UPDATE_BANK_ACCOUNT;
  const queryParams = {
    authenticity_token: ReactOnRails.authenticityToken(),
  };

  const updateBankDetails: any = useMutation({
    mutationKey: ["updateBankDetails"],
    mutationFn: (updateDetail: TBankDetailsWithOtp) => {
      const requestData = {
        account_holder_name: updateDetail.accountHolderName,
        iban: updateDetail.iban,
        otp: updateDetail.otp,
      };
      return axios.patch(`${url}?${qs.stringify(queryParams)}`, requestData);
    },
    onError: (error: any) => {
      Sentry.captureException(
        `[useEditBankDetails][updateBankDetails] - ${error}`,
      );
    },
  });

  return {
    updateBankDetails,
  };
};

export const useUpdateIbanForm = () => {
  const formContext = useFormContext();

  const { watch, setValue } = formContext;
  const { accountHolderName } = watch();

  const setAccountHolderName = (value: string) => {
    if (value !== accountHolderName) {
      setValue("accountHolderName", value, { shouldDirty: true });
    }
  };

  return {
    fields: watch(),
    formControl: formContext,
    setAccountHolderName,
  };
};
