export const posIdentifiers: any = {
  // https://staging.consoul.business.mamopay.com/admin/merchants/85
  "MPB-MER-3E89170826": {
    name: "chocolateShop",
    linkIdentifier: "MB-LINK-0A53A8EB46",
  },

  // https://sandbox.dev.business.mamopay.com/admin/merchants/27
  "MER-946B9C62C2": {
    name: "chocolateShop",
    linkIdentifier: "MB-LINK-97788C51E1",
  },

  // https://consoul.business.mamopay.com/admin/merchants/28442
  "MER-DAE246933B": {
    name: "spoonFulOfTaste",
    linkIdentifier: "MB-LINK-DC6A6CE33B",
  },

  // https://consoul.business.mamopay.com/admin/merchants/6288
  "MPB-MER-86B27ACFBC": {
    name: "eScootAndRide",
    linkIdentifier: "MB-LINK-47F051DE85",
  },

  // https://consoul.business.mamopay.com/admin/merchants/10657
  "MPB-MER-DE20880BF1": {
    name: "woodAndSteelFurniture",
    linkIdentifier: "MB-LINK-61434A8AC4",
  },

  // https://consoul.business.mamopay.com/admin/merchants/1975
  "MPB-MER-3C6B1681FA": {
    name: "easyGoDubai",
    linkIdentifier: "MB-LINK-02D4D8E526",
  },

  // https://consoul.business.mamopay.com/admin/merchants/7809
  "MPB-MER-1FBD569E86": {
    name: "everluxeProperties",
    linkIdentifier: "MB-LINK-3693F0F097",
  },

  // https://consoul.business.mamopay.com/admin/merchants/1862
  "MPB-MER-1D5144AABE": {
    name: "everluxeHolidayHomes",
    linkIdentifier: "MB-LINK-3266369868",
  },

  // https://consoul.business.mamopay.com/admin/merchants/13137
  "MPB-MER-AEAD7BDAF9": {
    name: "goldCamellia",
    linkIdentifier: "MB-LINK-140C7F7B44",
  },

  // https://consoul.business.mamopay.com/admin/merchants/221
  "MPB-MER-B6DE32D1C6": {
    name: "hmc",
    linkIdentifier: "MB-LINK-01C2E40594",
  },

  // https://consoul.business.mamopay.com/admin/merchants/589
  "MPB-MER-EEDF2EDC61": {
    name: "bloomingBox",
    linkIdentifier: "MB-LINK-C19F64D1E3",
  },

  // https://consoul.business.mamopay.com/admin/merchants/2119
  "MPB-MER-3C75DB1B26": {
    name: "Hive Holiday Homes",
    linkIdentifier: "MB-LINK-8213538E12",
  },

  //https://consoul.business.mamopay.com/admin/merchants/1585
  "MPB-MER-92D3B39EC9": {
    name: "Xperts Ions General Trading LLC",
    linkIdentifier: "MB-LINK-E58A3BAE2F",
  },

  //https://consoul.business.mamopay.com/admin/merchants/15719
  "MPB-MER-269502A8C9": {
    name: "Consultancy Oasis LLC",
    linkIdentifier: "MB-LINK-C722559D28",
  },

  // https://consoul.business.mamopay.com/admin/merchants/98935
  "MPB-MER-A675A734F0": {
    name: "Arabian Industrial Gases Company LLC",
    linkIdentifier: "MB-LINK-3114FF4F05",
  },

  // https://consoul.business.mamopay.com/admin/merchants/23493
  "MER-58F2F8F92C": {
    name: "Permislib FZ LLC",
    linkIdentifier: "MB-LINK-AF03F9911A",
  },

  // https://consoul.business.mamopay.com/admin/merchants/25323
  "MER-F12FE77DD5": {
    name: "Walk N Wag Animal Sheltering",
    linkIdentifier: "MB-LINK-B5ACF6AE95",
  },

  // https://consoul.business.mamopay.com/admin/merchants/23688
  "MER-91FF7412BF": {
    name: "OSOQT",
    linkIdentifier: "MB-LINK-D6B125D65F",
  },

  // Playwright Test Account
  "MPB-MER-87205F9BC1": {
    name: "e2etest",
    linkIdentifier: "MB-LINK-3FF3B7E6B9",
  },
};
