import { colorsV2 } from "constants/colors";
import type { SvgIconProps } from "types/Icon";

export const Replay = ({
  size,
  fill = colorsV2.text[1],
  ...rest
}: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g id="Name=replay, Size=24">
        <path
          id="Vector"
          d="M12.0001 22.1793C10.7301 22.1793 9.53936 21.942 8.42778 21.4674C7.31618 20.9928 6.34507 20.3401 5.51445 19.5095C4.68384 18.6789 4.03121 17.7088 3.55658 16.5992C3.08193 15.4896 2.8446 14.2978 2.8446 13.0239C2.8446 12.7794 2.9254 12.5763 3.087 12.4147C3.2486 12.2531 3.45169 12.1723 3.69625 12.1723C3.94082 12.1723 4.1439 12.2531 4.30548 12.4147C4.46708 12.5763 4.54788 12.7794 4.54788 13.0239C4.54788 15.0953 5.27306 16.8542 6.72343 18.3006C8.1738 19.7469 9.93267 20.4701 12.0001 20.4701C14.0674 20.4701 15.8263 19.7459 17.2767 18.2976C18.727 16.8492 19.4522 15.0913 19.4522 13.0239C19.4522 10.9525 18.7479 9.19366 17.3392 7.74728C15.9305 6.30091 14.1904 5.57773 12.1191 5.57773H11.5691L12.8093 6.81793C12.9673 6.97591 13.0463 7.15888 13.0463 7.36685C13.0463 7.57482 12.9673 7.75778 12.8093 7.91575C12.6513 8.07373 12.4673 8.15173 12.2574 8.14973C12.0474 8.14774 11.8635 8.06776 11.7055 7.90978L9.06853 5.27283C8.89461 5.10289 8.80765 4.90298 8.80765 4.6731C8.80765 4.4432 8.89461 4.24329 9.06853 4.07338L11.7305 1.40543C11.8718 1.26811 12.0516 1.19846 12.2699 1.19648C12.4882 1.19448 12.668 1.26413 12.8093 1.40543C12.9506 1.54674 13.0203 1.72754 13.0183 1.94783C13.0163 2.16811 12.9446 2.34692 12.8033 2.48425L11.4191 3.86848H11.9941C13.268 3.86848 14.4607 4.10579 15.5723 4.58043C16.6839 5.05508 17.655 5.70771 18.4857 6.53833C19.3163 7.36894 19.9689 8.33905 20.4435 9.44865C20.9182 10.5583 21.1555 11.75 21.1555 13.0239C21.1555 14.2938 20.9182 15.4846 20.4435 16.5962C19.9689 17.7078 19.3163 18.6789 18.4857 19.5095C17.655 20.3401 16.6839 20.9928 15.5723 21.4674C14.4607 21.942 13.27 22.1793 12.0001 22.1793Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};
