import { colorsV2 } from "constants/colors";
import type { SvgIconProps } from "types/Icon";

export const FilterList = ({
  size,
  fill = colorsV2.text[1],
  ...rest
}: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g id="Name=filter_list, Size=24">
        <path
          id="Vector"
          d="M10.7441 18.2989C10.5028 18.2989 10.3005 18.2181 10.1373 18.0566C9.97404 17.895 9.89244 17.6931 9.89244 17.4509C9.89244 17.2086 9.97427 17.0055 10.1379 16.8415C10.3016 16.6776 10.5037 16.5956 10.7441 16.5956H13.262C13.5016 16.5956 13.7024 16.6791 13.8645 16.8459C14.0266 17.0128 14.1076 17.2174 14.1076 17.4596C14.1076 17.7019 14.0258 17.9024 13.8621 18.061C13.6985 18.2196 13.4964 18.2989 13.256 18.2989H10.7441ZM6.71416 12.8456C6.47238 12.8456 6.27099 12.7649 6.10999 12.6033C5.949 12.4418 5.86851 12.2397 5.86851 11.9971C5.86851 11.7545 5.949 11.5523 6.10999 11.3907C6.27099 11.2292 6.47238 11.1484 6.71416 11.1484H17.2799C17.5212 11.1484 17.7235 11.2306 17.8867 11.3951C18.0499 11.5597 18.1316 11.7632 18.1316 12.0058C18.1316 12.2485 18.0499 12.4491 17.8867 12.6077C17.7235 12.7663 17.5212 12.8456 17.2799 12.8456H6.71416ZM3.69624 7.39837C3.45444 7.39837 3.25304 7.31642 3.09206 7.15252C2.93108 6.98864 2.85059 6.78557 2.85059 6.5433C2.85059 6.30102 2.93242 6.09908 3.09609 5.9375C3.25977 5.7759 3.46181 5.6951 3.70221 5.6951H20.3038C20.5451 5.6951 20.7474 5.77736 20.9106 5.94187C21.0739 6.10639 21.1555 6.30979 21.1555 6.55207C21.1555 6.79434 21.0725 6.99596 20.9066 7.15692C20.7407 7.31789 20.5378 7.39837 20.2979 7.39837H3.69624Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};
