import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@radix-ui/react-dropdown-menu";
import { useDispatch } from "react-redux";

import {
  setCategoryDetail,
  showHideDeleteCategoryModal,
} from "../../../reducer/expenseSettingsSlice";

export const ExpenseCategoryActions = ({ category }) => {
  const dispatch = useDispatch();

  const handleDelete = () => {
    dispatch(setCategoryDetail(category));

    dispatch(
      showHideDeleteCategoryModal({
        showOrHideModal: "show",
        shouldClearState: false,
      }),
    );
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        data-testid="options-trigger-button"
        className="actions__link__item material-icons-round"
        style={{
          backgroundColor: "unset",
          border: "unset",
          transform: "rotate(90deg)",
        }}
      >
        more_horiz
      </DropdownMenuTrigger>

      <DropdownMenuContent className="actions--payment-link-menu">
        <DropdownMenuItem className="actions-menu__link" onClick={handleDelete}>
          <li className="actions-menu__item text-error-1">Delete</li>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
