export const handleSpendByCardChartHeight = (
  /** The length of the data array - to expect a number value  */
  dataLength: number,
) => {
  if (dataLength >= 1 && dataLength <= 2) {
    return 160;
  } else if (dataLength >= 3 && dataLength <= 5) {
    return 200;
  } else if (dataLength >= 6 && dataLength <= 10) {
    return 340;
  } else if (dataLength >= 11 && dataLength <= 15) {
    return 410;
  } else if (dataLength >= 16 && dataLength <= 20) {
    return 550;
  }
};
