import { Button } from "design_system/Button";
import { Add } from "design_system/Icons";
import { Snackbar } from "design_system/Snackbar";
import { ExpenseCategoriesTable } from "./components/ExpenseCategoriesTable";
import { AddCategoryModal } from "./components/Modals/AddCategoryModal/AddCategoryModal";
import { DeleteConfirmationModal } from "./components/Modals/DeleteConfirmationModal";

import { useScreenDimension } from "hooks/useScreenDimension";
import { useAppSelector } from "store";

export const TableRoot = ({ categories, onClickAddCategory }) => {
  const { isMobile } = useScreenDimension();
  const { showDeleteConfirmationModal, showAddCategoryModal } = useAppSelector(
    (state) => state.expenseSettings.dashboard,
  );

  if (!isMobile) {
    return (
      <>
        <Snackbar />
        <div className="space-y-24">
          <div className="flex justify-end">
            <Button
              variant="filled"
              size="md"
              Icon={Add}
              iconPosition="left"
              label="Add category"
              onClick={onClickAddCategory}
            />
          </div>
          <ExpenseCategoriesTable categories={categories} />

          <DeleteConfirmationModal show={showDeleteConfirmationModal} />
          {showAddCategoryModal && (
            <AddCategoryModal show={showAddCategoryModal} />
          )}
        </div>
      </>
    );
  }

  return (
    <>
      <ExpenseCategoriesTable categories={categories} />
      <DeleteConfirmationModal show={showDeleteConfirmationModal} />
      {showAddCategoryModal && <AddCategoryModal show={showAddCategoryModal} />}
    </>
  );
};
