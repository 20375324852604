import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
} from "@radix-ui/react-tooltip";
import Status from "components/Status";
import PaymentStatusLabel from "constants/paymentStatusLabel";
import { getStatusColor } from "helpers/stringUtils";
import type IPayment from "interfaces/payments/IPayment";

interface IPaymentStatusProps {
  payment: IPayment;
}

const PaymentStatus: React.FC<IPaymentStatusProps> = ({ payment }) => {
  const { status, gatewayResponseMessage, refundAmountFormatted } = payment;
  const statusColor = getStatusColor(status);
  const statusLabel = PaymentStatusLabel[status];
  const statusWithToolTips = ["failed", "partial_refund", "refund_pending"];

  if (statusWithToolTips.includes(status)) {
    const toolTipContent: any = {
      failed: `The payment failed because of the following reason “${gatewayResponseMessage}”`,
      partial_refund: `${refundAmountFormatted} refunded`,
      refund_pending:
        "The refund will be processed once you have enough balance to cover this amount. Collect more payments to increase your balance.",
    };

    return (
      <Tooltip delayDuration={100}>
        <TooltipTrigger
          style={{
            backgroundColor: "unset",
            border: "unset",
            padding: 0,
          }}
        >
          <Status
            color={statusColor}
            label={statusLabel}
            hasIcon={status === "refund_pending"}
            iconFont="info"
          />
        </TooltipTrigger>

        <TooltipContent
          className="tooltip"
          style={{ minWidth: "2rem", maxWidth: "30rem" }}
          sideOffset={10}
        >
          {toolTipContent[status]}
          <TooltipArrow fill="#26303e" />
        </TooltipContent>
      </Tooltip>
    );
  }

  return <Status color={statusColor} label={statusLabel} />;
};

export default PaymentStatus;
