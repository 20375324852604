import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { SentryLoggingService } from "init/SentryLoggingService";

import { snack } from "design_system/Snackbar";

import { stringifiedQueryParams } from "constants/queryParams";
import routes from "constants/routes";

interface ResendOtpProps {
  email: string;
}

export const useResendOtp = () => {
  const resendOtp = useMutation({
    mutationKey: ["resendOtp"],
    mutationFn: async (data: ResendOtpProps) => {
      await axios
        .post(
          `${routes.ONBOARDING.ACCOUNTS.RESEND_OTP()}?${stringifiedQueryParams}`,
          data,
        )
        .then(() => {
          snack({
            title: "Check your email",
            description: `A new code has been sent to ${data.email}`,
            leadingElement: "icon",
            variant: "success",
          });
        });
    },
    onError: (error: Error, payload) => {
      SentryLoggingService.captureException(error.message, {
        feature: "[SignUp][VerifyEmail]",
        function: "resendOtp",
        file: "useResendOtp",
        data: payload ? JSON.stringify(payload) : null,
      });
      return error;
    },
  });

  return {
    resendOtp,
  };
};
