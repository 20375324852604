import { Tabs, TabsContent, TabsList, TabsTrigger } from "@radix-ui/react-tabs";
import axios from "axios";
import qs from "qs";
import { useEffect, useReducer, useState } from "react";
import toast from "react-hot-toast";
import ReactOnRails from "react-on-rails";

import Modal from "components/Modal/Modal";
import Pagination from "components/ui/Pagination";
import { PageHeader } from "./PageHeader";
import SubscriptionList from "./SubscriptionList";

import { deactivateLink } from "components/PaymentLinksRoot/Hooks/useDeactivate";
import routes from "constants/routes";
import type ISubscriptionRootProps from "interfaces/subscriptions/ISubscriptionRootProps";
import subscriptionsReducer, {
  initialState,
  SubscriptionActionTypes,
} from "reducers/subscriptionsReducer";

const SubscriptionListContainer = (props: ISubscriptionRootProps) => {
  const { hasBasic, hasPremiumBadge, isMerchantVerified } = props;
  const [currentTab, setCurrentTab] = useState<string>("active");
  const [state, dispatch] = useReducer(subscriptionsReducer, initialState);
  const {
    subscriptions,
    paginationMeta,
    statusCounts,
    showDisableModal,
    focusingIdentifier,
  } = state;
  const [currentPage, setCurrentPage] = useState(paginationMeta.page);
  const isEmptySubscriptions = subscriptions.length == 0;

  let queryParams: any = {
    authenticity_token: ReactOnRails.authenticityToken(),
  };

  const handleTabChange = (tab: string) => {
    setCurrentTab(tab);
    setCurrentPage(1);
  };

  const setQueryParams = () => {
    queryParams = {
      ...queryParams,
      page: currentPage,
    };

    if (currentTab == "all") return queryParams;

    return {
      ...queryParams,
      status: currentTab,
    };
  };

  const handleSetPage = (page: number | null) => {
    if (page) setCurrentPage(page);
  };

  const fetchSubscriptions = () => {
    dispatch({ type: SubscriptionActionTypes.LOADING, payload: true });
    const queryParams = setQueryParams();

    axios
      .get(
        `${routes.MANAGE.SUBSCRIPTIONS.INDEX()}?${qs.stringify(queryParams)}`,
        {
          headers: { accept: "application/json" },
        },
      )
      .then((res) => {
        dispatch({ type: SubscriptionActionTypes.LOADING, payload: false });
        dispatch({
          type: SubscriptionActionTypes.SET_DATA,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleToggleDisableSubscriptionModal = (identifier: string) => {
    dispatch({
      type: SubscriptionActionTypes.OPEN_DISABLE_MODAL,
      payload: { identifier },
    });
  };

  const handleCloseDisableSubscriptionModal = () => {
    dispatch({ type: SubscriptionActionTypes.CLOSE_DISABLE_MODAL });
  };

  const handleDisable = async (identifier: string) => {
    await deactivateLink(identifier);
    fetchSubscriptions();
    dispatch({ type: SubscriptionActionTypes.CLOSE_DISABLE_MODAL });

    toast.success("Recurring payment link disabled");
  };

  const runEffect = () => {
    fetchSubscriptions();
  };
  useEffect(runEffect, [currentTab, currentPage]);

  return (
    <>
      <PageHeader
        hasBasic={hasBasic}
        hasPremiumBadge={hasPremiumBadge}
        isMerchantVerified={isMerchantVerified}
      />

      <section className="subscriptions">
        <div className="subscriptions__links">
          <Tabs defaultValue={currentTab} onValueChange={handleTabChange}>
            <div className="tabs tabs--divider relative">
              <TabsList className="tabs__list">
                <TabsTrigger value="active" className="tabs__link">
                  Active ({statusCounts.active})
                </TabsTrigger>
                <TabsTrigger value="inactive" className="tabs__link">
                  Inactive ({statusCounts.inactive})
                </TabsTrigger>
              </TabsList>
            </div>
            <TabsContent value="active" className="tabs__content" tabIndex={-1}>
              <SubscriptionList
                subscriptions={subscriptions}
                currentTab={currentTab}
                onDisable={handleToggleDisableSubscriptionModal}
                hasBasic={hasBasic}
              />
            </TabsContent>
            <TabsContent
              value="inactive"
              className="tabs__content"
              tabIndex={-1}
            >
              <SubscriptionList
                subscriptions={subscriptions}
                currentTab={currentTab}
                onDisable={handleToggleDisableSubscriptionModal}
                hasBasic={hasBasic}
              />
            </TabsContent>

            {!isEmptySubscriptions && (
              <div className="subscriptions__links__pagination">
                <Pagination
                  itemName="recurring payments"
                  meta={paginationMeta}
                  onSetPage={handleSetPage}
                />
              </div>
            )}
          </Tabs>
        </div>

        <Modal
          show={showDisableModal}
          close={handleCloseDisableSubscriptionModal}
          fullOnMobile
        >
          <h1 className="modal__box__content__title">
            Are you sure you want to permanently disable this recurring payment?
          </h1>
          <p className="modal__box__content__text">
            Your subscribers will no longer be charged any upcoming payments and
            will be notified instantly.
          </p>

          <div className="flex w-full xs:flex-col space-y-12">
            <button
              className="button button--secondary button--solid button--lg"
              onClick={handleCloseDisableSubscriptionModal}
            >
              No, I'll keep it
            </button>
            <button
              className="button button--red button--solid button--lg"
              onClick={() => handleDisable(focusingIdentifier)}
            >
              Yes, disable this recurring payment link
            </button>
          </div>
        </Modal>
      </section>
    </>
  );
};

export default SubscriptionListContainer;
