import { type QueryObserverResult, useQuery } from "@tanstack/react-query";
import axios from "axios";

import { endpoints } from "constants/apiEndpoints";
import { SentryLoggingService } from "init/SentryLoggingService";

const url = endpoints.MANAGE.API.SETTINGS.BANK.FIND_BANK_NAME;

export const useGetBankName = (iban: string | undefined) => {
  const ibanWithoutSpaces = iban ? iban.replace(/\s+/g, "") : "";
  const params = { iban: ibanWithoutSpaces };

  const getBankName: QueryObserverResult<any> = useQuery({
    enabled: !!ibanWithoutSpaces,
    queryKey: [
      "getBankName",
      ...(ibanWithoutSpaces ? [{ iban: ibanWithoutSpaces }] : []),
    ],
    queryFn: async () => {
      try {
        const result = await axios.get(url, { params });
        return result.data.data;
      } catch (error) {
        SentryLoggingService.captureException(error, {
          feature: "[Global][Get Bank Name]",
          file: "useGetBankName.ts",
          function: "getBankName",
          data: JSON.stringify(params),
        });
        return null;
      }
    },
  });

  return {
    getBankName,
  };
};
