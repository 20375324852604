import {
  type AnimationControls,
  type TargetAndTransition,
  type Transition,
  type VariantLabels,
  motion,
} from "framer-motion";
import isFunction from "lodash/isFunction";
import {
  Children,
  type ReactElement,
  type ReactNode,
  Suspense,
  cloneElement,
  isValidElement,
  lazy,
  useEffect,
  useRef,
  useState,
} from "react";
import { cn } from "utils/classNames";

import { GlobalBanner } from "components/GlobalBanner";
import { IconButton } from "design_system/IconButton";
import { ArrowBack } from "design_system/Icons";
import { Snackbar } from "design_system/Snackbar";
import { isAppPlatform } from "helpers/isAppPlatform";
import withReduxProvider from "hoc/withReduxProvider";
import { useScreenDimension } from "hooks/useScreenDimension";
import { useScrollTrigger } from "hooks/useScrollTrigger";
import { useAppSelector } from "store";

const LazyVerificationSuccessModal = lazy(() =>
  import("components/VerificationSuccessModal/VerificationSuccessModal").then(
    (m) => ({ default: m.VerificationSuccessModal }),
  ),
);

type FramerMotionAnimateProps =
  | AnimationControls
  | TargetAndTransition
  | VariantLabels
  | boolean;

const commonTransition: Transition = {
  duration: 0.3,
  ease: "easeInOut",
};

const Banner = ({ children }: { children: ReactElement }) => {
  return <section className="mb-16">{children}</section>;
};
const Label = ({ children }: { children: string }) => {
  return <p className="body-large text-text-color-02">{children}</p>;
};
const Title = ({
  className,
  children,
  trigger,
}: { className?: string; children: string; trigger?: boolean }) => {
  return (
    <h1 className={cn("header-medium lg:header-large", className)}>
      {children}
    </h1>
  );
};
const Tag = ({ children }: { children: ReactElement }) => {
  return <>{children}</>;
};
// todo(muj): change ts from ReactElement to ReactNode so DX is better on the consumption end
const Description = ({ children }: { children: string | ReactElement }) => {
  return <div className="body-medium text-text-color-02">{children}</div>;
};
const Actions = ({ children }: { children: ReactElement }) => {
  return <section className="w-fit-content">{children}</section>;
};
const Image = ({ children }: { children: ReactElement }) => {
  return <>{children}</>;
};

export interface MasterHeaderProps {
  children: ReactNode;
  onBack?: () => void;
}

const BlazeMasterHeader = ({ children, onBack }: MasterHeaderProps) => {
  const bannerRef = useRef(null);

  const { showGlobalBanner } = useAppSelector((state) => state.globalBanner);
  const { isMobile } = useScreenDimension();
  const { trigger } = useScrollTrigger(bannerRef);

  const [collapsedAnimation, setCollapsedAnimation] =
    useState<FramerMotionAnimateProps>();

  let component = {
    actions: {
      present: false,
      jsx: <></>,
    },
    banner: {
      present: false,
      jsx: <></>,
    },
    image: {
      present: false,
      jsx: <></>,
    },
    label: {
      present: false,
      jsx: <></>,
    },
    title: {
      present: false,
      jsx: <></>,
    },
    tag: {
      present: false,
      jsx: <></>,
    },
    description: {
      present: false,
      jsx: <></>,
    },
  };

  Children.forEach(children, (child) => {
    if (isValidElement(child) && child.type === BlazeMasterHeader.Actions) {
      component = {
        ...component,
        actions: {
          present: true,
          jsx: child,
        },
      };
    }
    if (isValidElement(child) && child.type === BlazeMasterHeader.Banner) {
      component = {
        ...component,
        banner: {
          present: true,
          jsx: child,
        },
      };
    }
    if (isValidElement(child) && child.type === BlazeMasterHeader.Image) {
      component = {
        ...component,
        image: {
          present: true,
          jsx: child,
        },
      };
    }
    if (isValidElement(child) && child.type === BlazeMasterHeader.Label) {
      component = {
        ...component,
        label: {
          present: true,
          jsx: child,
        },
      };
    }
    if (isValidElement(child) && child.type === BlazeMasterHeader.Title) {
      component = {
        ...component,
        title: {
          present: true,
          jsx: cloneElement(child, { trigger } as any),
        },
      };
    }
    if (isValidElement(child) && child.type === BlazeMasterHeader.Tag) {
      component = {
        ...component,
        tag: {
          present: true,
          jsx: child,
        },
      };
    }
    if (isValidElement(child) && child.type === BlazeMasterHeader.Description) {
      component = {
        ...component,
        description: {
          present: true,
          jsx: child,
        },
      };
    }
  });

  const { actions, banner, label, image, title, description, tag } = component;

  const toggleHeaderAnimation: FramerMotionAnimateProps = {
    paddingTop: isMobile ? "16px" : "24px",
    paddingBottom: isMobile ? "16px" : "24px",
  };

  useEffect(() => {
    trigger
      ? setCollapsedAnimation({
          y: -10,
          opacity: 0,
          height: 0,
          margin: 0,
          zIndex: -10,
          visibility: "hidden",
          maxHeight: 0,
        })
      : setCollapsedAnimation({
          y: 0,
          opacity: 1,
          height: "auto",
          margin: undefined,
          zIndex: undefined,
          visibility: "visible",
          maxHeight: "unset",
        });
  }, [trigger]);

  return (
    <>
      {/* Dialogs */}
      <Suspense>
        <LazyVerificationSuccessModal />
      </Suspense>
      <Snackbar />
      <div
        ref={bannerRef}
        className={cn("bg-surface-1 px-16 pt-24 md:px-40", {
          hidden: !showGlobalBanner && !banner.present,
        })}
      >
        <GlobalBanner />
        {banner.present ? banner.jsx : null}
      </div>
      <motion.header
        layout
        layoutRoot
        initial={false}
        animate={toggleHeaderAnimation}
        transition={commonTransition}
        className="bg-surface-1 px-16 pb-24 md:px-40"
        style={{
          position: "sticky",
          // NOTE: The below isAppPlatform logic is if the user is on the Mamo Business mobile app,
          // Then set the top position to 0px and ignore the isMobile condition since that's for web specifically
          top: !isAppPlatform() ? (isMobile ? "56px" : "64px") : "0px",
          zIndex: 1001,
        }}
      >
        <div className="flex items-start justify-between gap-16 md:items-center">
          <div className="space-y-16">
            <div>
              {isFunction(onBack) && !isMobile && (
                <motion.div
                  layout="position"
                  initial={false}
                  animate={collapsedAnimation}
                  transition={commonTransition}
                  className="mb-16"
                >
                  <IconButton size="sm" Icon={ArrowBack} onClick={onBack} />
                </motion.div>
              )}

              <div
                className={cn({
                  "flex items-center gap-24": !trigger,
                })}
              >
                {image.present && (
                  <motion.div
                    layout="position"
                    initial={false}
                    animate={collapsedAnimation}
                    transition={commonTransition}
                  >
                    {image.jsx}
                  </motion.div>
                )}
                <div className="space-y-4">
                  {label.present && (
                    <motion.div
                      layout="position"
                      initial={false}
                      animate={collapsedAnimation}
                      transition={commonTransition}
                    >
                      {label.jsx}
                    </motion.div>
                  )}
                  <div className="flex flex-col space-y-16 md:flex-row md:items-center md:space-x-16 md:space-y-0">
                    {title.jsx}
                    {tag.present && tag.jsx}
                  </div>
                </div>
              </div>
            </div>
            {description.present && (
              <motion.div
                layout="position"
                initial={false}
                animate={collapsedAnimation}
                transition={commonTransition}
              >
                {description.jsx}
              </motion.div>
            )}
          </div>

          <motion.div
            layout="position"
            initial={false}
            animate={collapsedAnimation}
            transition={commonTransition}
            className={cn("justify-self-end", {
              "mt-8": label.present && title.present,
            })}
          >
            {actions.jsx}
          </motion.div>
        </div>
      </motion.header>
    </>
  );
};

BlazeMasterHeader.Actions = Actions;
BlazeMasterHeader.Banner = Banner;
BlazeMasterHeader.Description = Description;
BlazeMasterHeader.Image = Image;
BlazeMasterHeader.Label = Label;
BlazeMasterHeader.Tag = Tag;
BlazeMasterHeader.Title = Title;

export const MasterHeader = withReduxProvider(BlazeMasterHeader, {
  Actions,
  Banner,
  Description,
  Image,
  Label,
  Tag,
  Title,
});
