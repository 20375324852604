export enum NewDisbursementActionTypes {
  SET_MANUAL_DETAILS = "SET_MANUAL_DETAILS",
  RESET_TRANSFER_BANK_ACCOUNT = "RESET_TRANSFER_BANK_ACCOUNT",
  SET_BULK_UPLOAD = "SET_BULK_UPLOAD",
}

export enum BankAccountTypes {
  BUSINESS = "Business",
  PERSONAL = "Personal",
}

export enum Steps {
  DisbursementType = 1,
  BulkUpload = 2,
  ManualUpload = 3,
  ConfirmDisbursement = 4,
  VerificationCode = 6,
  ProcessingFile = 7,
}

export interface IDisbursementManualState {
  amount: string;
  bankAccountType: BankAccountTypes;
  iban: string;
  firstName: string;
  lastName: string;
  accountHolderName: string;
  email: string;
  reason: string;
  selectedFile: any;
  type: string;
}

export const initialState: IDisbursementManualState = {
  amount: "",
  bankAccountType: BankAccountTypes.BUSINESS,
  iban: "",
  accountHolderName: "",
  firstName: "",
  lastName: "",
  email: "",
  reason: "",
  selectedFile: "",
  type: NewDisbursementActionTypes.SET_MANUAL_DETAILS,
};

const newDisbursementsReducer = (state: any, action: any) => {
  switch (action.type) {
    case NewDisbursementActionTypes.SET_MANUAL_DETAILS:
      return {
        ...state,
        ...action.payload,
        type: action.type,
      };
    case NewDisbursementActionTypes.RESET_TRANSFER_BANK_ACCOUNT:
      return {
        ...state,
        bankAccountTypeOptions: "",
      };
    case NewDisbursementActionTypes.SET_BULK_UPLOAD:
      return {
        ...state,
        ...action.payload,
        type: action.type,
      };
    default:
      return state;
  }
};

export default newDisbursementsReducer;
