import { CalendarToday } from "design_system/Icons";
import { IslandProvider } from "design_system/Island/IslandContext";
import { SideSheetProvider } from "design_system/Sidesheet/SideSheetContext";
import { RepaymentsTable } from "./Table";

import { useScreenDimension } from "hooks/useScreenDimension";

export const Repayments = () => {
  const { isMobile } = useScreenDimension();
  return (
    <section
      data-testid="upcoming-repayments-table"
      className="flex flex-col gap-24 py-40"
    >
      {/* TITLE AREA */}
      <div className="space-y-12 px-16 md:px-40">
        {/* TITLE */}
        <div className="flex items-center gap-12">
          <CalendarToday size={isMobile ? "18" : "24"} />
          <p className="header-small md:header-medium font-medium">
            Upcoming repayments
          </p>
        </div>
        <p className="body-medium md:body-large text-text-color-02">
          Below is a breakdown of all your upcoming repayments and their due
          dates.
        </p>
      </div>

      {/* TABLE AREA */}
      <SideSheetProvider>
        {() => (
          <IslandProvider>
            <RepaymentsTable />
          </IslandProvider>
        )}
      </SideSheetProvider>
    </section>
  );
};
