import { useByIdentifier } from "repositories/finances/useByIdentifier";
import { useGetFinances } from "repositories/finances/useGetFinances";
import { useTransactionsFor } from "repositories/finances/useTransactionsFor";

export * from "./finances.repository";

export const financesHooks = {
  useByIdentifier,
  useGetFinances,
  useTransactionsFor,
};
