import type { HTMLProps } from "react";
import { cn } from "utils/classNames";
import FieldMessage from "../FieldMessage/FieldMessage";

export interface ITextInputProps extends HTMLProps<HTMLInputElement> {
  classes?: string;
  inputId?: string;
  suffix?: string;
  autoComplete?: string;
  labelText?: string;
  helpText?: string;
  hideReplay?: boolean;
  errors?: any;
  errorMessage?: string;
  onFocusText?: string;
  hasPremium?: boolean;
  isDisabled?: boolean;
  register?: any;
}

const TextInput = ({
  classes,
  inputId,
  suffix,
  autoComplete,
  onFocusText = " ",
  labelText,
  helpText,
  hideReplay,
  errors,
  errorMessage,
  hasPremium,
  isDisabled,
  register,
  ...nativeProps
}: ITextInputProps) => {
  return (
    <div className="form-field">
      <div
        className={cn(
          `form-field__control`,
          {
            suffix: suffix,
          },
          classes,
        )}
      >
        <input
          className={cn(`form-field__input`, {
            "form-field__input--error": errors,
            "form-field__input--white-label": hasPremium,
            "sentry-mask [data-sentry-mask]": hideReplay,
            "sentry-unmask [data-sentry-unmask]": !hideReplay,
          })}
          id={inputId}
          autoComplete={autoComplete}
          placeholder=" "
          onFocus={(e) => (e.target.placeholder = onFocusText)}
          onBlurCapture={(e) => (e.target.placeholder = " ")}
          disabled={isDisabled}
          ref={register}
          {...nativeProps}
        />
        {suffix && <span className="suffix">{suffix}</span>}
        {labelText && (
          <label
            htmlFor={inputId}
            data-testid={inputId || nativeProps.name}
            className={cn("form-field__label", {
              "form-field__label--white-label": hasPremium,
            })}
          >
            {labelText}
          </label>
        )}
        {helpText && <p className="mt-8 text-12 text-black-200">{helpText}</p>}
      </div>
      {errors && (
        <FieldMessage
          messageType="error"
          icon="error"
          messageText={errorMessage}
        />
      )}
    </div>
  );
};

export default TextInput;
