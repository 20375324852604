import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import qs from "qs";
import toast from "react-hot-toast";
import ReactOnRails from "react-on-rails";
import { useDispatch } from "react-redux";

import { useUpdateSubmissionStatus } from "hooks/AccountingIntegrations/useUpdateSubmissionStatus";

import { queryClient } from "config/reactQueryClient";
import { endpoints } from "constants/apiEndpoints";
import { generalErrors } from "constants/errorMessages";
import { SentryLoggingService } from "init/SentryLoggingService";
import {
  onInitRootfi,
  onRootfiConnectionSuccess,
  onRootfiExit,
} from "../../../../slice/accountingDashboardSlice";

export const useRootfiConnect = () => {
  const { updateSubmissionStatus } = useUpdateSubmissionStatus();
  const dispatch = useDispatch();

  const createInviteLink = useMutation({
    mutationKey: ["createInviteLink"],
    mutationFn: async (platform) => {
      const queryParams = {
        authenticity_token: ReactOnRails.authenticityToken(),
        platform,
      };
      const response = await axios.post(
        `${endpoints.MANAGE.API.ACCOUNTING_INTEGRATIONS.CREATE_INVITE_LINK}?${qs.stringify(queryParams)}`,
      );
      return response;
    },
    onError: (error: any, variables) => {
      toast.error(generalErrors.SOMETHING_WENT_WRONG, {
        duration: 3000,
      });
      SentryLoggingService.captureException(error.message, {
        feature: "[Settings][Accounting Settings]",
        file: "useRootfiConnect.ts",
        function: "useRootfiConnect",
        data: JSON.stringify(variables),
      });
    },
  });

  const initRootfi = (platform) => {
    dispatch(onInitRootfi({ selectedIntegration: platform }));

    createInviteLink.mutateAsync(platform).then(({ data }: any) => {
      const inviteLink = data.inviteLinkId;
      if (window.RootfiLink) {
        window.RootfiLink.initialize({
          linkToken: inviteLink,
          onSuccess: () => {
            dispatch(onRootfiConnectionSuccess());

            // Inform backend of success
            updateSubmissionStatus.mutateAsync(
              { submissionStatus: "submitted" },
              {
                onSuccess: () => {
                  /**
                   * connection succeeded, wait for redux state update and then refetch accounting integrations
                   * again to update connection statuses
                   */
                  dispatch(onRootfiConnectionSuccess());
                  setTimeout(() => {
                    queryClient.invalidateQueries({
                      queryKey: ["getAccountingIntegrations"],
                    });
                  }, 300);
                },
              },
            );

            // Close the SDK
            window.RootfiLink.closeLink();
          },
          onReady: () => {
            // Ready to connect to rootfi SDK
            window.RootfiLink.openLink();
          },
          onExit: () => {
            dispatch(onRootfiExit());

            /**
             * connection failed, refetch accounting integrations again to update connection statuses
             */
            queryClient.invalidateQueries({
              queryKey: ["getAccountingIntegrations"],
            });

            // Close SDK when user clicks closed.
            window.RootfiLink.closeLink();
          },
        });
      } else {
        return;
      }
    });
  };

  return {
    isLoading: createInviteLink.isLoading,
    initRootfi,
  };
};
