import routes from "constants/routes";
import { FullPageLayout } from "features/Cards/features/CreateCard/core/components/FullPageLayout/FullPageLayout";
import { useScreenDimension } from "hooks/useScreenDimension";
import type IRailsContext from "interfaces/IRailsContext";

import { useEffect } from "react";
import { SlackIntegrationDialog } from "./_components/SlackIntegrationDialog";

export type SlackIntegrationProps = {
  railsContext: IRailsContext;
};
export const SlackIntegration = ({ railsContext }: SlackIntegrationProps) => {
  const { isMobile } = useScreenDimension();
  const { sessionContext } = railsContext;
  const { currentUser } = sessionContext;

  const redirectToSignUp = () => {
    window.location.href = routes.ONBOARDING.SIGN_UP();
  };

  const onClose = () => {
    window.location.href = routes.MANAGE.ACCOUNT_SETTINGS.INDEX();
  };

  useEffect(() => {
    if (!currentUser) {
      redirectToSignUp();
    }
  }, [currentUser]);

  return (
    <FullPageLayout isMobile={isMobile} isLoading={false}>
      <FullPageLayout.Header
        isMobile={isMobile}
        title="Integrate with Slack"
        onClose={onClose}
      />

      <FullPageLayout.Content
        hasFadeIn={false}
        position="left"
        className="h-svh w-full max-w-full"
      >
        <SlackIntegrationDialog />
      </FullPageLayout.Content>
    </FullPageLayout>
  );
};
