import { useDispatch } from "react-redux";

import { TableCell } from "components/Table/TableCell";
import { Switch } from "design_system/Inputs/Switch";

import { useBulkUpdatePaymentAccounts } from "./useBulkUpdatePaymentAccounts";

import {
  setPagination,
  setSelectedRows,
} from "reducers/redux/TableReducer/tableReducer";

import { useAppSelector } from "store";
import type { PaymentAccount } from "types/payments/PaymentAccount";

type ColumnCell = {
  row: {
    original: PaymentAccount;
  };
};
export const usePaymentAccountsTable = () => {
  const dispatch = useDispatch();
  const {
    table: { paymentAccounts },
  } = useAppSelector((state) => state);

  const { bulkUpdatePaymentAccounts } = useBulkUpdatePaymentAccounts();

  const handleToggleClick = ({ identifier, enabled }) => {
    bulkUpdatePaymentAccounts.mutateAsync(
      {
        identifiers: [identifier],
        enabled,
      },
      {
        onSuccess() {
          const affectedRows = paymentAccounts.selectedRows.filter(
            (account) => account.identifier === identifier,
          );
          const updatedRows = affectedRows.map((row) => ({
            ...row,
            enabled,
          }));
          dispatch(
            setSelectedRows({
              tableName: "paymentAccounts",
              selectedRows: updatedRows,
            }),
          );
        },
      },
    );
  };

  const COLUMNS = [
    {
      header: "Name",
      accessorKey: "institutionName",
      cell: (props: ColumnCell) => (
        <TableCell
          mainContent={props.row.original.institutionName}
          classNames={{
            mainContent: "mt-10",
          }}
        />
      ),
    },
    {
      header: "Currency",
      accessorKey: "currency",
      meta: {
        alignment: "end",
      },
      cell: ({ row: { original: record } }: ColumnCell) => {
        const { currency, enabled, identifier } = record;

        return (
          <TableCell
            mainContent={
              <div className="flex items-center justify-end">
                {currency}

                <Switch
                  id={identifier}
                  checked={enabled}
                  disabled={bulkUpdatePaymentAccounts.isLoading}
                  onClick={() =>
                    handleToggleClick({ identifier, enabled: !enabled })
                  }
                />
              </div>
            }
            classNames={{
              container: "w-full",
              mainContent: "mt-10",
            }}
          />
        );
      },
    },
  ];

  const handleSetPage = (newPageNumber: number) => {
    dispatch(
      setPagination({ tableName: "paymentAccounts", page: newPageNumber }),
    );
  };

  return {
    bulkUpdatePaymentAccounts,
    COLUMNS,
    handleSetPage,
    handleToggleClick,
  };
};
