import { array, object, string } from "yup";

import {
  alphanumericString,
  noDangerousChars,
  noHTMLDom,
  noNumbers,
  stringWithoutURL,
} from "utils/yup";

export const USER_INFORMATION_SCHEMA = object().shape({
  firstName: string()
    .required("Please enter first name")
    .concat(noHTMLDom())
    .concat(stringWithoutURL())
    .concat(alphanumericString())
    .concat(noDangerousChars())
    .concat(noNumbers()),
  lastName: string()
    .required("Please enter last name")
    .concat(noHTMLDom())
    .concat(stringWithoutURL())
    .concat(alphanumericString())
    .concat(noDangerousChars())
    .concat(noNumbers()),
  email: string().required("Please enter email").email("Email is not valid"),
});

export const USER_ROLES_SCHEMA = object().shape({
  roles: array().test(
    "roles",
    "Please select at least one role",
    (value: any) => value && value.length > 0,
  ),
});
