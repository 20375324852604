import { MasterHeader } from "design_system/MasterHeader";

import { handleGoBackToSettings } from "features/Settings/shared/utils";
import { isAppPlatform } from "helpers/isAppPlatform";

export const PageHeader = () => {
  if (isAppPlatform()) {
    return <></>;
  }

  return (
    <MasterHeader onBack={handleGoBackToSettings}>
      <MasterHeader.Label>Settings</MasterHeader.Label>
      <MasterHeader.Title>Business details</MasterHeader.Title>
    </MasterHeader>
  );
};
