import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useState } from "react";

import { Tabs } from "design_system/Tab";
import { CustomerMetricsInsights } from "./CustomerMetricsInsights";
import { RevenueInsights } from "./RevenueInsights";
import { SpendInsights } from "./SpendInsights";

import { useScreenDimension } from "hooks/useScreenDimension";

type TAnalyticsTabs = "revenue" | "spend" | "customer-metrics";

export const Analytics = () => {
  const [currentTab, setCurrentTab] = useState<TAnalyticsTabs>("revenue");
  const { isMobile } = useScreenDimension();
  const queryClient = new QueryClient();

  const handleTabChange = (
    event: React.SyntheticEvent,
    tab: TAnalyticsTabs,
  ) => {
    setCurrentTab(tab);
  };

  return (
    <QueryClientProvider client={queryClient}>
      <Tabs
        value={currentTab}
        onChange={handleTabChange}
        size={isMobile ? "md" : "lg"}
      >
        <Tabs.Tab value="revenue" label="Revenue" />
        <Tabs.Tab value="spend" label="Spend" />
        <Tabs.Tab value="customer-metrics" label="Customer metrics" />
      </Tabs>

      <div className="px-16 pt-24 md:p-40">
        {/* NOTE: WE'RE DOING THE BELOW THIS WAY SO THAT WE DONT HAVE TO MOUNT AND UNMOUNT THE COMPONENT EVERYTIME A TAB VALUE CHANGES */}
        <div className={`${currentTab === "revenue" ? "block" : "hidden"}`}>
          <RevenueInsights />
        </div>
        <div className={`${currentTab === "spend" ? "block" : "hidden"}`}>
          <SpendInsights />
        </div>
        <div
          className={`${currentTab === "customer-metrics" ? "block" : "hidden"}`}
        >
          <CustomerMetricsInsights />
        </div>
      </div>
    </QueryClientProvider>
  );
};
