import routes from "constants/routes";

import type { IRevenue } from "../index";

export const PaymentRow = ({
  paymentId,
  externalId,
  paymentDate,
  customerFullName,
  revenueAmountWithCurrency,
  revenueVatWithCurrency,
}: IRevenue) => {
  const handleOnClick = () => {
    window.location.href = routes.MANAGE.PAYMENTS.SHOW(paymentId);
  };

  return (
    // TABLE ROW
    <div
      onClick={handleOnClick}
      className="table-container__row table-container__row--settlement-details"
    >
      {/* PAYMENT ID */}
      <div className="table-container__data-group">
        <div className="table-container__data table-container__data--settlement-details">
          <div className="table-container__data__settlement-details">
            <p className="table-container__data__settlement-details__heading">
              Reference ID
            </p>
            <p className="table-container__data__settlement-details__data">
              {paymentId}
            </p>
          </div>

          {/* XL DEVICES AND ABOVE */}
          {externalId && (
            <p className="hidden text-12 text-black-300 xl:block">
              External ID {externalId}
            </p>
          )}
        </div>
      </div>

      {/* MOBILE AND ABOVE BUT BELOW XL DEVICES */}
      {externalId && (
        <div className="table-container__data-group block xl:hidden">
          <div className="table-container__data table-container__data--settlement-details">
            <div className="table-container__data__settlement-details">
              <p className="table-container__data__settlement-details__heading">
                External ID
              </p>
              <p className="table-container__data__settlement-details__data">
                {externalId}
              </p>
            </div>
          </div>
        </div>
      )}

      {/* TYPE */}
      <div className="table-container__data-group">
        <div className="table-container__data table-container__data--settlement-details">
          <div className="table-container__data__settlement-details">
            <p className="table-container__data__settlement-details__heading">
              Type
            </p>
            <p className="table-container__data__settlement-details__data">
              Payment
            </p>
          </div>
        </div>
      </div>

      {/* DATE */}
      <div className="table-container__data-group">
        <div className="table-container__data table-container__data--settlement-details">
          <div className="table-container__data__settlement-details">
            <p className="table-container__data__settlement-details__heading">
              Date
            </p>
            <p className="table-container__data__settlement-details__data">
              {paymentDate}
            </p>
          </div>
        </div>
      </div>

      {/* CUSTOMER */}
      <div className="table-container__data-group">
        <div className="table-container__data table-container__data--settlement-details">
          <div className="table-container__data__settlement-details">
            <p className="table-container__data__settlement-details__heading">
              Customer
            </p>
            <p className="table-container__data__settlement-details__data">
              {customerFullName}
            </p>
          </div>
        </div>
      </div>

      {/* AMOUNT */}
      <div className="table-container__data-group">
        <div className="table-container__data table-container__data--settlement-details">
          <div className="table-container__data__settlement-details">
            <p className="table-container__data__settlement-details__heading">
              Amount
            </p>
            <p className="table-container__data__settlement-details__data">
              {revenueAmountWithCurrency}
            </p>
          </div>
        </div>
      </div>

      {/* VAT */}
      <div className="table-container__data-group">
        <div className="table-container__data table-container__data--settlement-details">
          <div className="table-container__data__settlement-details">
            <p className="table-container__data__settlement-details__heading">
              VAT
            </p>
            <p className="table-container__data__settlement-details__data">
              {revenueVatWithCurrency}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
