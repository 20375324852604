import type { PaginatedRequest } from "repositories/_base/repository";

export const FINANCES_KEYS = {
  FINANCES: ["finances"],
  TRANSACTIONS: (identifier: string, paginationMeta?: PaginatedRequest) => [
    "finances",
    identifier,
    paginationMeta,
  ],
  BY_IDENTIFIER: (identifier: string) => ["finances", identifier],
} as const;
