import type React from "react";

import type IRailsContext from "interfaces/IRailsContext";
import type ISubscriptionDetailsProps from "interfaces/subscriptions/ISubscriptionDetailsProps";
import { SubscriptionDetailsContainer } from "./index";

export const SubscriptionDetailsRoot: React.FC<ISubscriptionDetailsProps> = (
  props: ISubscriptionDetailsProps,
  railsContext: IRailsContext,
) => {
  return (
    <SubscriptionDetailsContainer
      subscription={props}
      railsContext={railsContext}
    />
  );
};
