import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

interface ScrollTriggerState {
  trigger: boolean;
}

const initialState: ScrollTriggerState = {
  trigger: false,
};

export const scrollTriggerSlice = createSlice({
  name: "scrollTrigger",
  initialState,
  reducers: {
    setTrigger(state, action: PayloadAction<boolean>) {
      state.trigger = action.payload;
    },
  },
});

export const { setTrigger } = scrollTriggerSlice.actions;

export default scrollTriggerSlice.reducer;
