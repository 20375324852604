export enum SubscriberActionTypes {
  LOADING = "LOADING",
  SET_DATA = "SET_DATA",
}

export const initialState = {
  isLoading: false,
  subscribers: [],
  paginationMeta: {
    page: 1,
    perPage: 10,
    totalPages: null,
    nextPage: null,
    prevPage: null,
    from: null,
    to: null,
    totalCount: null,
  },
  statusCounts: {
    all: 0,
    active: 0,
    unsubscribed: 0,
  },
};

function subscribersReducer(state: any, action: any) {
  switch (action.type) {
    case SubscriberActionTypes.LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case SubscriberActionTypes.SET_DATA:
      return {
        ...state,
        subscribers: action.payload.data,
        paginationMeta: {
          ...state.paginationMeta,
          ...action.payload.paginationMeta,
        },
        statusCounts: {
          ...state.statusCounts,
          ...action.payload.statusCounts,
        },
      };
  }
}

export default subscribersReducer;
