import { useContext, useMemo } from "react";
import { Provider } from "react-redux";

import { AccountingDashboard } from "./AccountingDashboard";
import { AccountingPaywall } from "./AccountingPaywall";
import { PageHeader } from "./PageHeader";

import { checkPermission } from "hooks/useCheckPermissions";

import { GlobalConfigContext } from "providers/GlobalConfigProvider";
import { PermissionsContext } from "providers/PermissionsProvider";

import { store } from "store";

export const AccountingDashboardRoot = ({ railsContext }) => {
  const { globalConfig } = useContext(GlobalConfigContext);
  const { settings } = checkPermission(railsContext);

  const activePlan = globalConfig?.activePlan;

  const memoizedSettings = useMemo(() => settings, [JSON.stringify(settings)]);

  // Paywall is only for growth users, starter plan is depreciated and will be removed eventually
  if (activePlan === "starter" || activePlan === "growth") {
    return (
      <>
        <PageHeader />
        <AccountingPaywall />
      </>
    );
  }
  // Premium, Ultra & Enterprise users have access to dashboard
  return (
    <PermissionsContext.Provider value={memoizedSettings}>
      <Provider store={store}>
        <PageHeader />
        <AccountingDashboard />
      </Provider>
    </PermissionsContext.Provider>
  );
};
