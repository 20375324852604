import axios, { type AxiosError } from "axios";

import { generalErrors } from "constants/errorMessages";

// Map for possible BE Error Codes
export const AxiosErrorCodesMap = {
  PHONE_NUMBER_INVALID_FORMAT: "PHONE_NUMBER_INVALID_FORMAT",
  INVALID_OTP: "INVALID_OTP",
  RATE_LIMIT_EXCEEDED: "RATE_LIMIT_EXCEEDED",
  PHONE_NUMBER_DUPLICATE: "PHONE_NUMBER_DUPLICATE",

  // Default error code
  SOMETHING_WENT_WRONG: "SOMETHING_WENT_WRONG",
} as const;

export type AxiosErrorCodes =
  (typeof AxiosErrorCodesMap)[keyof typeof AxiosErrorCodesMap];

// Check if error is AxiosError
export const isAxiosError = (error: any): error is AxiosError => {
  return axios.isAxiosError(error);
};

// Map error code to snack message
export const mapAxiosErrorToErrorMessage = (
  key: AxiosErrorCodes,
): {
  key: AxiosErrorCodes;
  message: string;
} => {
  switch (key) {
    case AxiosErrorCodesMap.PHONE_NUMBER_INVALID_FORMAT:
      return {
        key: AxiosErrorCodesMap.PHONE_NUMBER_INVALID_FORMAT,
        message: "Please enter a valid phone number",
      };

    case AxiosErrorCodesMap.INVALID_OTP:
      return {
        key: AxiosErrorCodesMap.INVALID_OTP,
        message: "Code is invalid. Please try again.",
      };

    case AxiosErrorCodesMap.RATE_LIMIT_EXCEEDED:
      return {
        key: AxiosErrorCodesMap.RATE_LIMIT_EXCEEDED,
        message: "Maximum number of attempts reached.",
      };

    case AxiosErrorCodesMap.PHONE_NUMBER_DUPLICATE:
      return {
        key: AxiosErrorCodesMap.PHONE_NUMBER_DUPLICATE,
        message: "Phone number already exists.",
      };

    case AxiosErrorCodesMap.SOMETHING_WENT_WRONG:
      return {
        key: AxiosErrorCodesMap.SOMETHING_WENT_WRONG,
        message: generalErrors.SOMETHING_WENT_WRONG,
      };

    default:
      return {
        key: AxiosErrorCodesMap.SOMETHING_WENT_WRONG,
        message: generalErrors.SOMETHING_WENT_WRONG,
      };
  }
};

//
export const handleAxiosErrorMessage = (error: any) => {
  if (isAxiosError(error)) {
    return mapAxiosErrorToErrorMessage(error.response?.data.error_code);
  }

  if (error instanceof Error) {
    return {
      key: AxiosErrorCodesMap.SOMETHING_WENT_WRONG,
      message: error.message,
    };
  }

  return {
    key: AxiosErrorCodesMap.SOMETHING_WENT_WRONG,
    message: generalErrors.SOMETHING_WENT_WRONG,
  };
};
