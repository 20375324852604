import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import toast from "react-hot-toast";

import { endpoints } from "constants/apiEndpoints";
import { generalErrors } from "constants/errorMessages";
import { SentryLoggingService } from "init/SentryLoggingService";
import type {
  WalletDetailEnrichedWithMeta,
  WalletDetailResponse,
} from "../../typeDefs";

export const useGetWalletDetail = () => {
  const identifier = window.location.pathname.split("/")[3];

  const getWalletDetail: WalletDetailResponse = useQuery({
    queryKey: ["getWalletDetail", { identifier }],
    queryFn: async () => {
      try {
        const { data } = await axios.get(
          endpoints.MANAGE.API.WALLETS.DETAIL(identifier),
        );

        const state: WalletDetailEnrichedWithMeta = {
          // BANNER
          bannerDetail: undefined,
          // BALANCES
          availableBalance: undefined,
          incomingBalance: undefined,
          totalBalance: undefined,
          // WALLET
          selectedWallet: undefined,
        };

        const totalBalance = {
          isNegative: data.totalBalance < 0,
          isZero: data.totalBalance === 0,
        };

        const availableBalance = {
          isNegative: data.availableBalance < 0,
          isZero: data.availableBalance === 0,
        };

        // BANNER DETAIL STATE
        if (totalBalance.isNegative) {
          state.bannerDetail = {
            type: "negativeTotalBalance",
            variant: "critical",
            title: "You have an outstanding balance",
            description:
              "Outstanding amounts will be deducted from funds due to arrive. You can add funds to your account to settle your negative balance.",
          };
        } else if (data.autoSettlementEnabled) {
          state.bannerDetail = {
            type: "settlement",
            variant: "info",
            title: undefined,
            description:
              "Automated settlements are enabled for your account, so funds will be transferred to your bank account as per your settlement schedule.",
          };
        }

        // BALANCES
        state.availableBalance = {
          ...availableBalance,
          subText: (() => {
            switch (true) {
              case data.availableBalance <= 0: {
                return "No available funds to use or settle";
              }
              case data.availableBalance > 0: {
                return "These funds are ready to be used or settled";
              }
              default:
                return "";
            }
          })(),
        };

        state.incomingBalance = {
          subText: (() => {
            switch (true) {
              // Incoming balance will not be negative so we only check this case in contrast to what we do for availableBalance
              case data.incomingBalance === 0: {
                return "Incoming funds will be shown here with a breakdown of when you can expect each amount";
              }
              case data.incomingBalance > 0: {
                return "Below is a breakdown of when you can expect each amount to reflect in your Available balance";
              }
              default:
                return "";
            }
          })(),
        };

        state.totalBalance = totalBalance;

        // WALLET
        state.selectedWallet = data;

        return state;
      } catch (error: any) {
        toast.error(generalErrors.SOMETHING_WENT_WRONG, {
          duration: 3000,
        });
        SentryLoggingService.captureException(error.message, {
          feature: "[Wallets V2]",
          file: "useGetWalletDetail.tsx",
          function: "useGetWalletDetail",
        });
        return undefined;
      }
    },
  });

  return {
    getWalletDetail,
  };
};
