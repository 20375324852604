import { Suspense, lazy } from "react";

import { Button } from "design_system/Button";
import { ErrorFill } from "design_system/Icons";

import {
  TwoFactorAuthenticationProvider,
  useTwoFactorAuthenticationContext,
} from "components/TwoFactorAuthentication/_context/TwoFactorAuthenticationContext";
import { VIEWS } from "components/TwoFactorAuthentication/_shared/schema";
import { colorsV2 } from "constants/colors";

const LazyTwoFactorAuthenticationDialogRoot = lazy(() =>
  import("components/TwoFactorAuthentication/DialogRoot").then((m) => ({
    default: m.TwoFactorAuthenticationDialogRoot,
  })),
);

const Content = () => {
  const { updateContext } = useTwoFactorAuthenticationContext();

  const handleClick = () => {
    updateContext({ modal: "2fa", view: VIEWS.PHONE_NUMBER });
  };

  return (
    <aside
      data-id="verification-phone-banner"
      className="flex w-full flex-col items-start justify-between gap-16 rounded-8 bg-accent-yellow-03 p-16 md:flex-row md:gap-0"
    >
      {/* Left */}
      <div className="flex gap-8">
        <ErrorFill
          fill={colorsV2.accent.yellow[1]}
          className="mt-2 h-18 w-18 flex-shrink-0"
        />

        <div className="flex flex-col gap-4">
          <span className="label-large text-text-color-01">
            Add your phone number
          </span>
          <span className="body-medium text-text-color-02">
            Please provide a phone number to complete your account verification
          </span>
        </div>
      </div>

      {/* CTA */}
      <Button
        onClick={handleClick}
        classes="ml-24 md:ml-0"
        variant="outlined"
        size="md"
        label="Add phone number"
      />
    </aside>
  );
};

export const VerifyPhoneNumberBannerRoot = () => {
  return (
    <>
      <TwoFactorAuthenticationProvider
        onSuccess={() => window.location.reload()}
      >
        <Content />

        <Suspense fallback={null}>
          <LazyTwoFactorAuthenticationDialogRoot />
        </Suspense>
      </TwoFactorAuthenticationProvider>
    </>
  );
};
