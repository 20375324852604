import { Fragment } from "react";

import { Checkbox } from "design_system/Inputs/Checkbox";
import { Loader } from "design_system/Loader";
import { BankTransferDetailsItem } from "features/Cards/CardTopup/BankTransferDetailsItem";

import { useBankDetails } from "features/Cards/CardTopup/hooks/useBankDetails";

import type { BankDetailItem } from "features/Cards/CardTopup/typeDef";

export const ManualBankTransferSidesheet = ({
  data,
  isLoading,
  isManualPaymentConfirmed,
  onConfirmManualTransfer,
}) => {
  const { bankDetails, handleCopyBankInfo } = useBankDetails(data);
  if (isLoading) {
    return <Loader variant="fullPage" />;
  }

  return (
    <section className="flex flex-col gap-16">
      <div className="flex flex-col">
        {Object.keys(bankDetails).map((key) => {
          return (
            <Fragment key={key}>
              <BankTransferDetailsItem
                label={bankDetails[key as keyof BankDetailItem].title}
                value={bankDetails[key as keyof BankDetailItem].value}
                classNames={{ container: "py-14" }}
                onCopy={handleCopyBankInfo}
              />
            </Fragment>
          );
        })}
      </div>

      <Checkbox
        checked={isManualPaymentConfirmed}
        inputId="confirm-manual-transfer"
        labelText="I confirm that I've made the bank transfer"
        onChange={onConfirmManualTransfer}
      />
    </section>
  );
};
