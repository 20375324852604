import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import qs from "qs";
import ReactOnRails from "react-on-rails";

import { endpoints } from "constants/apiEndpoints";

interface GenerateOtpEmailOtpProps {
  purpose: string;
}

export const useGenerateOtp = () => {
  const queryParams = {
    authenticity_token: ReactOnRails.authenticityToken(),
  };
  const url = endpoints.MANAGE.API.DISBURSEMENTS.GENERATE_OTP;

  const generateOtp = useMutation({
    mutationKey: ["generateOtp"],
    mutationFn: async (data: GenerateOtpEmailOtpProps) => {
      await axios.patch(`${url}?${qs.stringify(queryParams)}`, data);
    },
  });

  return { generateOtp };
};
