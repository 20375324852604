import { useEffect, useState } from "react";

type TToastDetails = {
  type: "success" | "error" | "";
  message: string;
};

type TSaveToastOnNavigation = (toast: TToastDetails) => void;

type TUseToast = {
  toastDetails: TToastDetails;
  getToastDetails: () => TToastDetails;
  saveToast: TSaveToastOnNavigation;
  saveToastOnNavigation: TSaveToastOnNavigation;
};

const defaultState: TToastDetails = {
  type: "",
  message: "",
};

export const useToast = (showOnMount = true): TUseToast => {
  const [toastDetails, setToastDetails] = useState<TToastDetails>(defaultState);

  const getToastDetails = () => {
    const toast = localStorage.getItem("toast");
    localStorage.removeItem("toast");

    const detail: TToastDetails =
      toast && Object.keys(toast).length > 0 ? JSON.parse(toast) : defaultState;
    setToastDetails(detail);
    return detail;
  };

  useEffect(() => {
    if (showOnMount) {
      getToastDetails();
    }
  }, [showOnMount]);

  // TODO: Create a PR and remove this, replaced with saveToast
  const saveToastOnNavigation: TSaveToastOnNavigation = (toast) => {
    localStorage.setItem("toast", JSON.stringify(toast));
  };

  const saveToast: TSaveToastOnNavigation = (toast) => {
    localStorage.setItem("toast", JSON.stringify(toast));
  };

  return {
    toastDetails,
    getToastDetails,
    saveToast,
    saveToastOnNavigation,
  };
};
