import { useQuery } from "@tanstack/react-query";
import { snack } from "design_system/Snackbar";

import { bankAccountsRepository } from "repositories/bank-accounts/bank-accounts.repository";
import { BANK_ACCOUNTS_QUERY_KEYS } from "repositories/bank-accounts/keys";

export const useGetActiveAccount = () => {
  return useQuery({
    queryKey: [BANK_ACCOUNTS_QUERY_KEYS.GET_ACTIVE_ACCOUNT],
    queryFn: async () => {
      const response = await bankAccountsRepository.getActiveAccount();

      if (!response.success && "error" in response) {
        snack({
          title: response.error.message,
          leadingElement: "icon",
          variant: "critical",
        });

        bankAccountsRepository.sentryLogError({
          error: response.error.message,
          feature: "useGetActiveAccount",
          functionName: "activeAccountQuery",
          data: JSON.stringify(response.error),
        });
      }

      return response.data;
    },
  });
};
