import type { TaxRatesWithPagination } from "types/TaxRate";
import { RefreshInfoToolbar } from "../shared/RefreshInfoToolbar";
import { TaxRatesTable } from "./TaxRatesTable";

import { IslandProvider } from "design_system/Island/IslandContext";

type TaxRatesProps = {
  taxRates?: TaxRatesWithPagination;
};

export const TaxRates = ({ taxRates }: TaxRatesProps) => {
  return (
    <section className="flex flex-col gap-24">
      <RefreshInfoToolbar />
      <IslandProvider>
        <TaxRatesTable taxRates={taxRates} />
      </IslandProvider>
    </section>
  );
};
