/**
 * Converts a hex color code to an RGB array.
 *
 * @param {string} hexCode - The hex color code (e.g., "#FFFFFF" or "FFFFFF").
 * @returns {[number, number, number]} The RGB representation as an array of numbers.
 * @throws {Error} If the hex code is invalid.
 */
export const hexToRgb = (hexCode: string): [number, number, number] => {
  // Remove the '#' if it exists
  const sanitizedHexCode = hexCode.replace(/^#/, "");

  // Ensure the hex code has a valid length
  if (sanitizedHexCode.length !== 6) {
    throw new Error(
      `Invalid hex code: ${hexCode}. Expected a 6-character hex code.`,
    );
  }

  // Convert hex to RGB
  const r = Number.parseInt(sanitizedHexCode.substring(0, 2), 16);
  const g = Number.parseInt(sanitizedHexCode.substring(2, 4), 16);
  const b = Number.parseInt(sanitizedHexCode.substring(4, 6), 16);

  return [r, g, b];
};
