import { Loader } from "design_system/Loader";
import { PageHeader } from "./PageHeader";
import { TeamTable } from "./TeamTable";

import { useAppSelector } from "store";
import { useGetUsers } from "../shared/hooks/useGetUsers";

export const Dashboard = () => {
  const { team } = useAppSelector((store) => store.table);
  const { getUsers } = useGetUsers({ page: team.page });

  return (
    <>
      <PageHeader />

      <section className="md:pb-40">
        <div className="px-0 md:px-40">
          {getUsers.isLoading ? (
            <div className="flex items-center justify-center py-80">
              <Loader />
            </div>
          ) : (
            <TeamTable team={getUsers.data} />
          )}
        </div>
      </section>
    </>
  );
};
