import { yupResolver } from "@hookform/resolvers/yup";
import { isEmpty } from "lodash";
import { useContext, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import Button from "components/Button/Button";
import TextInput from "components/Inputs/TextInput";

import { isAppPlatform } from "helpers/isAppPlatform";
import { useToast } from "hooks/useToast";
import { businessDetailsRoutes } from "../shared/constant";
import { BusinessDetailsContext } from "../shared/context/BusinessDetailsProvider";
import { useUpdateBusinessDetails } from "../shared/hooks/useUpdateBusinessDetails";
import {
  ADDRESS_SCHEMA,
  BUSINESS_NAME_SCHEMA,
  SUPPORT_EMAIL_SCHEMA,
  TRN_SCHEMA,
} from "../shared/schema";
import type { TBusinessDetails } from "../shared/types";

export const UpdateDetails = () => {
  const { details } = useContext(BusinessDetailsContext);
  const { updateDetails } = useUpdateBusinessDetails();
  const { saveToastOnNavigation } = useToast();
  const navigate = useNavigate();

  const keys = Object.keys(details);

  const isDetailValueEmpty = isEmpty(
    details.name || details.address || details.replyTo || details.trn,
  );

  const displayKeyName = () => {
    if (keys.includes("name")) {
      return {
        title: "your business name",
        description:
          "This name will be displayed in payment links for your customers",
      };
    } else if (keys.includes("address")) {
      return {
        title: "your registered business address",
        description: "This must be your office address in the UAE",
      };
    } else if (keys.includes("replyTo")) {
      return {
        title: "a support email",
        description:
          "This email will be provided to your customers in case they need to reach out to you for support",
      };
    } else if (keys.includes("trn")) {
      return {
        title: "your Tax Registration Number",
        description:
          "Your TRN and address will be printed on your tax invoices, and the invoices sent to your customers",
      };
    } else {
      return {
        title: null,
        description: null,
      };
    }
  };

  const content = displayKeyName();

  const { formSchema } = useMemo(() => {
    switch (true) {
      case keys.includes("name"):
        return {
          formSchema: BUSINESS_NAME_SCHEMA,
        };
      case keys.includes("address"):
        return {
          formSchema: ADDRESS_SCHEMA,
        };
      case keys.includes("replyTo"):
        return {
          formSchema: SUPPORT_EMAIL_SCHEMA,
        };
      case keys.includes("trn"):
        return {
          formSchema: TRN_SCHEMA,
        };
      default:
        return {
          formSchema: Yup.object().shape({}),
        };
    }
  }, []);

  const { errors, handleSubmit, register } = useForm({
    defaultValues: {
      _key: details._key,
      address: keys.includes("address")
        ? details.address.location
        : details.address,
      city: details.city,
      country: "United Arab Emirates",
      name: details.name,
      replyTo: details.replyTo,
      trn: details.trn,
    },
    resolver: yupResolver(formSchema),
    mode: "onSubmit",
  });

  const onSubmit = async (data: TBusinessDetails) => {
    try {
      await updateDetails.mutateAsync(data);
      saveToastOnNavigation({
        type: "success",
        message: "Business details have been updated",
      });
    } catch (error) {
      saveToastOnNavigation({
        type: "error",
        message: "Oops! Something went wrong. Try again later",
      });
    }

    navigate(businessDetailsRoutes.overview);
  };

  useEffect(() => {
    if (isEmpty(details._key)) {
      navigate(businessDetailsRoutes.overview, { replace: true });
    }
  }, [details._key]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {!isAppPlatform() && (
        <header>
          <button
            type="button"
            onClick={() => navigate(businessDetailsRoutes.overview)}
            className="create-link__close-button material-icons-round"
          >
            close
          </button>
        </header>
      )}
      <section className="create-link__customize-link-step">
        {/* PROCESS COMPONENT */}
        <div className="process space-y-40 md:space-y-48">
          <div className="process__content">
            <h1 className="process__content__heading">
              {isDetailValueEmpty ? "Add" : "Update"} {content.title}
            </h1>
            {content.description && (
              <p className="process__content__info">{content.description}</p>
            )}
          </div>

          <div>
            {/* BUSINESS NAME */}
            {keys.includes("name") && (
              <TextInput
                inputId="name"
                name="name"
                labelText="Business name"
                defaultValue={details.name}
                register={register}
                errors={errors.name}
                errorMessage={errors.name?.message}
                autoFocus
              />
            )}

            {/* ADDRESS */}
            {keys.includes("address") && (
              <div className="space-y-24">
                <div className="space-y-16">
                  <p className="heading-04 font-bold">
                    Registered business address
                  </p>
                  <TextInput
                    inputId="address"
                    name="address"
                    labelText="Address"
                    defaultValue={
                      details.address ? details.address.location : ""
                    }
                    register={register}
                    errors={errors.address}
                    errorMessage={errors.address?.message}
                    autoFocus
                  />
                </div>

                <div className="flex flex-row gap-24">
                  <TextInput
                    inputId="city"
                    name="city"
                    labelText="City"
                    defaultValue={details.address.city}
                    register={register}
                    errors={errors.city}
                    errorMessage={errors.city?.message}
                  />

                  <TextInput
                    labelText="Country"
                    value="United Arab Emirates"
                    isDisabled
                  />
                </div>
              </div>
            )}

            {/* SUPPORT EMAIL */}
            {keys.includes("replyTo") && (
              <TextInput
                inputId="replyTo"
                name="replyTo"
                labelText="Email address"
                defaultValue={details.replyTo!}
                register={register}
                errors={errors.replyTo}
                errorMessage={errors.replyTo?.message}
                autoFocus
              />
            )}

            {/* TRN */}
            {keys.includes("trn") && (
              <TextInput
                inputId="trn"
                name="trn"
                labelText="TRN"
                defaultValue={details.trn!}
                register={register}
                errors={errors.trn}
                errorMessage={errors.trn?.message}
                autoFocus
              />
            )}
          </div>

          <div className="text-center">
            <Button
              type="submit"
              color="primary"
              size="lg"
              label="Save changes"
              classes="w-full md:w-auto"
            />
          </div>
        </div>
      </section>
    </form>
  );
};
