import Button from "components/Button/Button";
import type IPaginationMeta from "interfaces/IPaginationMeta";

type IPaginationProps = {
  meta: IPaginationMeta;
  onSetPage: any;
  itemName: string;
};

const Pagination = ({ meta, onSetPage, itemName }: IPaginationProps) => {
  const { from, to, prevPage, nextPage, page, totalPages, totalCount } = meta;

  return (
    <div className="pagination">
      <p className="body-large text-text-color-03">
        {from}-{to} of {totalCount} {itemName}
      </p>

      <div className="pagination__number-buttons space-x-8">
        <p className="body-large">
          Page {page} of {totalPages}
        </p>

        <div className="flex gap-4">
          <Button
            onClick={() => onSetPage(prevPage)}
            color="secondary"
            size="sm"
            isIcon
            iconFont="chevron_left"
            isDisabled={!prevPage}
            style={{ border: "none" }}
          />

          <Button
            onClick={() => onSetPage(nextPage)}
            color="secondary"
            size="sm"
            isIcon
            iconFont="chevron_right"
            isDisabled={!nextPage}
            style={{ border: "none" }}
          />
        </div>
      </div>
    </div>
  );
};

export default Pagination;
