import axios, { type AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import type { GlobalConfig } from "types/GlobalConfig";
import type { UserPlans } from "types/UserPlans";

interface GlobalConfigResponse {
  maximum_payment_amount_aed: number;
  minimum_payment_amount_aed: number;
  minimum_payment_amount_other: number;
  disbursement: {
    min_amount: number;
    max_amount: number;
  };
  feature_flags: {
    cards_feature_enabled: boolean;
    enabled_ultra_balance: boolean;
    expenses_accounting_integration_enabled: boolean;
    expenses_feature_enabled: boolean;
  };
  usd_extra_percentage: number;
  enabled_currencies: {
    name: string;
    code: string;
    maximumPaymentAmount: number;
  }[];
  card: {
    max_amount: number;
    min_amount: number;
  };
  active_plan: UserPlans;
  vat_available: boolean;
  holiday_banner?: {
    heading: string;
    subtext: string;
  };
}

export async function getGlobalConfig(): Promise<GlobalConfig> {
  const response: AxiosResponse<GlobalConfigResponse> = await axios.get(
    "/api/common/global_configs",
    {
      baseURL: "",
    },
  );
  const data = response.data;

  return {
    maximumPaymentAmountAED: data.maximum_payment_amount_aed,
    minimumPaymentAmountAED: data.minimum_payment_amount_aed,
    minimumPaymentAmountOther: data.minimum_payment_amount_other,
    disbursement: {
      minAmount: data.disbursement.min_amount,
      maxAmount: data.disbursement.max_amount,
    },
    featureFlags: {
      cardsFeatureEnabled: data.feature_flags.cards_feature_enabled,
      enabledUltraBalance: data.feature_flags.enabled_ultra_balance,
      expensesAccountingIntegrationEnabled:
        data.feature_flags.expenses_accounting_integration_enabled,
      expensesFeatureEnabled: data.feature_flags.expenses_feature_enabled,
    },
    // @ts-expect-error Frank to check it later
    enabledCurrencies: data.enabled_currencies,
    otherCurrencyExtraPercentage: data.usd_extra_percentage,
    card: data.card,
    activePlan: data.active_plan,
    usdExtraPercentage: data.usd_extra_percentage,
    vatAvailable: data.vat_available,
    holidayBanner: data.holiday_banner,
  };
}

export const useGlobalConfig = () => {
  const [globalConfig, setGlobalConfig] = useState<GlobalConfig>();
  const [loadingGlobalConfigs, setLoadingGlobalConfigs] =
    useState<boolean>(true);

  useEffect(() => {
    const fetchGlobalConfig = async () => {
      try {
        setLoadingGlobalConfigs(true);
        const config = await getGlobalConfig();
        setGlobalConfig(config);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingGlobalConfigs(false);
      }
    };

    fetchGlobalConfig();
  }, []);

  return {
    loadingGlobalConfigs,
    globalConfig,
  };
};
