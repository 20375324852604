import { cn } from "utils/classNames";
import Logo from "../Logo/Logo";

interface IProps {
  color?: string;
  containerClasses?: string;
  containerStyles?: object;
  iconSize?: string;
  textSize?: string;
  iconFont?: string;
  textContent?: string;
  mamoIcon?: boolean;
  mamoHeight?: string;
  mamoWidth?: string;
  mamoFill?: string;
}

const TextWithIcon = ({
  color,
  containerClasses,
  containerStyles,
  iconSize,
  textSize,
  iconFont,
  textContent,
  mamoIcon,
  mamoHeight,
  mamoWidth,
  mamoFill,
}: IProps) => {
  if (mamoIcon) {
    return (
      <div
        className={cn(
          "flex items-center justify-center",
          `text-${color}`,
          containerClasses,
        )}
        style={containerStyles}
      >
        <Logo height={mamoHeight} width={mamoWidth} fill={mamoFill} />
        <p className={cn("text- ml-8", textSize)}>{textContent}</p>
      </div>
    );
  } else {
    return (
      <div
        className={cn(
          "flex items-center justify-center",
          `text-${color}`,
          containerClasses,
        )}
        style={containerStyles}
      >
        <span className={cn("material-icons-round", `text-${iconSize}`)}>
          {iconFont}
        </span>
        <p className={cn("text- ml-8", textSize)}>{textContent}</p>
      </div>
    );
  }
};

export default TextWithIcon;
