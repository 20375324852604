import type { JSXElementConstructor } from "react";

import { Avatar } from "design_system/Avatar";

import type { SvgIconProps } from "types/Icon";
import { cn } from "utils/classNames";

export const TableCell = ({
  onClick,
  avatar,
  mainContent,
  secondaryContent,
  classNames = {
    // Provide a default object
    container: "",
    mainContent: "",
    secondaryContent: "",
  },
}: {
  onClick?: () => void;
  avatar?: {
    isIcon?: boolean;
    Icon?: JSXElementConstructor<SvgIconProps>;
    url?: string;
  };
  mainContent: any;
  secondaryContent?: any;
  classNames?: {
    container?: string;
    mainContent?: string;
    secondaryContent?: string;
  };
}) => {
  return (
    <div
      className={cn(`h-76 w-full cursor-pointer p-16 ${classNames.container}`)}
      onClick={onClick}
    >
      <div className="flex items-center gap-12">
        {avatar && avatar.isIcon && avatar.Icon && (
          <Avatar size="xl" isIcon Icon={avatar.Icon} />
        )}
        {avatar && !avatar.isIcon && avatar.url && (
          <Avatar isIcon={false} size="xl" url={avatar.url} />
        )}

        <div className="w-full">
          <p className={cn(`body-large min-h-24 ${classNames.mainContent}`)}>
            {mainContent}
          </p>
          <p
            className={cn(
              `body-small min-h-20 text-text-color-02 ${classNames.secondaryContent}`,
            )}
          >
            {secondaryContent}
          </p>
        </div>
      </div>
    </div>
  );
};
