import { colorsV2 } from "constants/colors";
import type { SvgIconProps } from "types/Icon";

export const MenuOpen = ({
  size,
  fill = colorsV2.text[1],
  ...rest
}: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g id="Name=menu_open, Size=24">
        <path
          id="Vector"
          d="M3.69624 18.1494C3.45444 18.1494 3.25304 18.0672 3.09206 17.9027C2.93108 17.7382 2.85059 17.5346 2.85059 17.292C2.85059 17.0494 2.93108 16.8487 3.09206 16.6901C3.25304 16.5315 3.45444 16.4522 3.69624 16.4522H15.0647C15.3025 16.4522 15.5029 16.5344 15.6659 16.6989C15.8289 16.8635 15.9104 17.067 15.9104 17.3096C15.9104 17.5523 15.8299 17.7529 15.6689 17.9115C15.5079 18.0701 15.3065 18.1494 15.0647 18.1494H3.69624ZM19.3712 16.2038L15.7321 12.5717C15.5622 12.4018 15.4772 12.2029 15.4772 11.975C15.4772 11.7471 15.5622 11.5482 15.7321 11.3782L19.3403 7.7462C19.5102 7.57228 19.7133 7.48533 19.9495 7.48533C20.1857 7.48533 20.3908 7.57228 20.5647 7.7462C20.7346 7.91612 20.8196 8.1172 20.8196 8.34945C20.8196 8.5817 20.7366 8.78279 20.5707 8.95272L17.5723 11.975L20.5957 15.0223C20.7616 15.1922 20.8436 15.3901 20.8416 15.616C20.8396 15.8419 20.7537 16.0399 20.5837 16.2098C20.4138 16.3797 20.2117 16.4647 19.9775 16.4647C19.7432 16.4647 19.5412 16.3777 19.3712 16.2038ZM3.69624 12.8016C3.45444 12.8016 3.25304 12.7194 3.09206 12.5548C2.93108 12.3903 2.85059 12.1868 2.85059 11.9441C2.85059 11.7015 2.93108 11.4999 3.09206 11.3393C3.25304 11.1787 3.45444 11.0984 3.69624 11.0984H12.1006C12.3384 11.0984 12.5388 11.1816 12.7018 11.3481C12.8647 11.5146 12.9462 11.7192 12.9462 11.9618C12.9462 12.2045 12.8657 12.4051 12.7048 12.5637C12.5438 12.7223 12.3424 12.8016 12.1006 12.8016H3.69624ZM3.69624 7.54783C3.45444 7.54783 3.25304 7.46557 3.09206 7.30105C2.93108 7.13653 2.85059 6.93297 2.85059 6.69035C2.85059 6.44772 2.93108 6.24609 3.09206 6.08547C3.25304 5.92486 3.45444 5.84455 3.69624 5.84455H15.0647C15.3025 5.84455 15.5029 5.92781 15.6659 6.09432C15.8289 6.26084 15.9104 6.46541 15.9104 6.70802C15.9104 6.95064 15.8299 7.15127 15.6689 7.3099C15.5079 7.46852 15.3065 7.54783 15.0647 7.54783H3.69624Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};
