import { colorsV2 } from "constants/colors";
import type { SvgIconProps } from "types/Icon";

export const Visibility = ({
  size,
  fill = colorsV2.text[1],
  ...rest
}: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g id="Name=visibility, Size=24">
        <path
          id="Vector"
          d="M12.0029 15.75C13.1843 15.75 14.1875 15.3365 15.0125 14.5095C15.8375 13.6826 16.25 12.6784 16.25 11.497C16.25 10.3157 15.8365 9.3125 15.0095 8.4875C14.1826 7.6625 13.1784 7.25 11.997 7.25C10.8157 7.25 9.81249 7.66348 8.98749 8.49045C8.16249 9.3174 7.74999 10.3216 7.74999 11.5029C7.74999 12.6843 8.16347 13.6875 8.99044 14.5125C9.81739 15.3375 10.8216 15.75 12.0029 15.75ZM11.9955 14.2043C11.2431 14.2043 10.605 13.941 10.0812 13.4143C9.55751 12.8876 9.29564 12.248 9.29564 11.4955C9.29564 10.7431 9.559 10.105 10.0857 9.58125C10.6124 9.05752 11.252 8.79565 12.0045 8.79565C12.7569 8.79565 13.395 9.05901 13.9187 9.58572C14.4425 10.1124 14.7043 10.752 14.7043 11.5045C14.7043 12.2569 14.441 12.895 13.9143 13.4187C13.3875 13.9425 12.748 14.2043 11.9955 14.2043ZM12 19.1375C9.67607 19.1375 7.55931 18.5045 5.64971 17.2386C3.74011 15.9726 2.26189 14.3041 1.21504 12.2328C1.15942 12.1426 1.11792 12.0315 1.09054 11.8996C1.06314 11.7677 1.04944 11.6342 1.04944 11.499C1.04944 11.3639 1.06314 11.2304 1.09054 11.0985C1.11792 10.9666 1.15942 10.8542 1.21504 10.7611C2.26181 8.69512 3.74001 7.02883 5.64966 5.7623C7.5593 4.49577 9.67607 3.8625 12 3.8625C14.3239 3.8625 16.4407 4.49577 18.3503 5.7623C20.26 7.02883 21.7382 8.69512 22.7849 10.7611C22.8406 10.8542 22.8821 10.9666 22.9094 11.0985C22.9368 11.2304 22.9505 11.3639 22.9505 11.499C22.9505 11.6342 22.9368 11.7677 22.9094 11.8996C22.8821 12.0315 22.8406 12.1426 22.7849 12.2328C21.7381 14.3041 20.2599 15.9726 18.3503 17.2386C16.4407 18.5045 14.3239 19.1375 12 19.1375ZM11.9963 17.5C14.0129 17.5 15.8657 16.9542 17.5546 15.8625C19.2436 14.7708 20.5297 13.3167 21.413 11.5C20.5297 9.68333 19.2448 8.22917 17.5583 7.1375C15.8718 6.04583 14.0203 5.5 12.0037 5.5C9.98705 5.5 8.13427 6.04583 6.44534 7.1375C4.75641 8.22917 3.46194 9.68333 2.56194 11.5C3.46194 13.3167 4.75518 14.7708 6.44166 15.8625C8.12813 16.9542 9.97968 17.5 11.9963 17.5Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};
