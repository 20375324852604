import { CreditConsumedProgressBar } from "./CreditConsumedProgressBar";
import { Repayments } from "./Repayments";

export const CreditCardOverview = ({ creditCard }) => {
  return (
    <>
      <CreditConsumedProgressBar creditCard={creditCard} />
      <Repayments />
    </>
  );
};
