import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import * as RadixAccordion from "@radix-ui/react-accordion";
import isEmpty from "lodash/isEmpty";
import React from "react";
import { useDispatch } from "react-redux";

// COMPONENTS
import { Divider } from "components/Divider";
import { IconButton } from "design_system/IconButton";
import { ExpandMore, KeyboardDoubleArrowLeft } from "design_system/Icons";
import { SwitchBusiness } from "design_system/Layout/core/components";
import { NavigationRailSecondaryItem } from "../NavigationRail/NavigationRailSecondaryItem";

// HOOKS
import { useAppSelector } from "store";

import { colorsV2 } from "constants/colors";
import { customTheme } from "design_system/shared/theme";
// OTHERS
import { allNavigationItems } from "../core/constant";
import { setOpenMobileNavigation } from "../core/reducer/dashboardNavigationSlice";

// ASSETS
import MamoFavicon from "assets/logos/mamo-business-favicon.svg";
import { SetupVerificationPrimaryItem } from "design_system/Layout/DashboardNavigation/NavigationRail/SetupVerificationPrimaryItem/SetupVerificationPrimaryItem";
import { useSetupVerificationPrimaryItem } from "design_system/Layout/DashboardNavigation/NavigationRail/SetupVerificationPrimaryItem/useSetupVerificationPrimaryItem";
import type { MerchantInfo } from "types/MerchantInfo";

export type MobileNavigationProps = {
  merchantDetails?: MerchantInfo;
};

export const MobileNavigation = ({
  merchantDetails,
}: MobileNavigationProps) => {
  const { currentMerchant, currentUser } = merchantDetails;
  const { dashboardNavigation, permissions } = useAppSelector((state) => state);
  const { isMobileNavigationOpen } = dashboardNavigation;
  const dispatch = useDispatch();
  const outerTheme = useTheme();

  const isSetupVerificationActive = useSetupVerificationPrimaryItem({
    currentMerchant,
    currentUser,
  });

  const iOS =
    typeof navigator !== "undefined" &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);

  const toggleDrawer = (open: boolean) => () => {
    dispatch(setOpenMobileNavigation(open));
  };

  return (
    <ThemeProvider theme={customTheme(outerTheme)}>
      <SwipeableDrawer
        anchor="left"
        open={isMobileNavigationOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        classes={{ paper: "w-3/4" }}
      >
        <header className="flex h-48 items-center justify-between px-16">
          <img
            src={MamoFavicon}
            alt="Mamo Business logo"
            className="h-24 w-24 rounded-8"
          />
          <KeyboardDoubleArrowLeft
            className="cursor-pointer"
            size="18"
            fill={colorsV2.text[2]}
            onClick={toggleDrawer(false)}
          />
        </header>

        <div className="h-48 px-12">
          <SwitchBusiness />
        </div>

        {isSetupVerificationActive && (
          <SetupVerificationPrimaryItem onClick={() => {}} /> // Do not remove this onClick.
        )}
        {allNavigationItems(permissions, currentMerchant?.signedUpFor).map(
          (item) => (
            <React.Fragment key={item.id}>
              {item.hasPermission && (
                <>
                  <div className="my-8 px-16 py-8">
                    {isEmpty(item.url) ? (
                      <RadixAccordion.Root type="single" collapsible>
                        <RadixAccordion.Item value={item.label}>
                          <RadixAccordion.Trigger className="AccordionTrigger label-medium flex w-full items-center justify-between gap-16">
                            {item.label}
                            {item.children && (
                              <IconButton
                                size="xs"
                                Icon={ExpandMore}
                                classes="AccordionTriggerIcon flex-shrink-0"
                                aria-hidden
                              />
                            )}
                          </RadixAccordion.Trigger>
                          {item?.children && (
                            <RadixAccordion.Content className="AccordionContent mt-16">
                              {item.children.map((subItem) => (
                                <React.Fragment key={subItem.id}>
                                  {subItem.hasPermission && (
                                    <NavigationRailSecondaryItem
                                      key={subItem.id}
                                      active={location.pathname.startsWith(
                                        subItem.url,
                                      )}
                                      Icon={subItem.Icon}
                                      label={subItem.label}
                                      url={subItem.url}
                                    />
                                  )}
                                </React.Fragment>
                              ))}
                            </RadixAccordion.Content>
                          )}
                        </RadixAccordion.Item>
                      </RadixAccordion.Root>
                    ) : (
                      <a href={item.url} className="label-medium">
                        {item.label}
                      </a>
                    )}
                  </div>
                  <Divider />
                </>
              )}
            </React.Fragment>
          ),
        )}
      </SwipeableDrawer>
    </ThemeProvider>
  );
};
