import { keyframes } from "@emotion/react";

import { colorsV2 } from "constants/colors";

export const autocompleteStyleOverrides = (isOpen) => ({
  padding: "1.2rem 2.4rem",
  background: isOpen ? colorsV2.surface[1] : "transparent",
  boxShadow: isOpen
    ? "0px 8px 20px 0px rgba(0, 0, 0, 0.10)"
    : "none !important",
  borderTopLeftRadius: "1.6rem",
  borderTopRightRadius: "1.6rem",
  animation: `${isOpen ? autocompleteBgFadeIn : "none"} 0.3s ease-in-out forwards`,
});

export const listboxStyleOverrides = {
  paddingLeft: "0px !important",
  paddingRight: "0px !important",
};

export const paperStyleOverrides = {
  borderRadius: "0rem !important",
  boxShadow: "none !important",
};

export const popperStyleOverrides = (isOpen) => ({
  background: isOpen ? colorsV2.surface[1] : "transparent",
  boxShadow: "0px 16px 20px 0px rgba(0, 0, 0, 0.10) !important",
  marginTop: "1.2rem !important",
  borderBottomLeftRadius: "1.6rem",
  borderBottomRightRadius: "1.6rem",
  border: `2.5rem solid ${colorsV2.surface[1]}`,
  borderTop: `1px solid ${colorsV2.border[3]}`,
  boxSizing: "content-box",
  animation: `${isOpen ? popperFadeIn : "none"} 0.3s ease-in-out forwards`,
});

const autocompleteBgFadeIn = keyframes`
  from {
    background-color: transparent;
    box-shadow: none;
  }
  to {
    background-color: ${colorsV2.surface[1]};
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.10);
  }
`;

const popperFadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
