import { ReactQueryProvider } from "components/Providers";
import { SubscriptionContainer } from "./SubscriptionContainer";

export const SubscriptionsRoot = () => {
  return (
    <ReactQueryProvider>
      <SubscriptionContainer />
    </ReactQueryProvider>
  );
};

export default SubscriptionsRoot;
