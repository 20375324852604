import * as RadixDropdown from "@radix-ui/react-dropdown-menu";

import { SwitchBusiness } from "components/SwitchBusiness";
import { Help, Logout, Settings } from "design_system/Icons";

import { useGetRole } from "utils/rbacUtils";

import { colorsV2 } from "constants/colors";
import routes from "constants/routes";
import type { MerchantInfo } from "types/MerchantInfo";
import type { Role } from "types/Role";

export type AvatarMenuProps = {
  permissions: Role;
  merchantDetails: MerchantInfo;
};

export const AvatarMenu = ({
  permissions,
  merchantDetails,
}: AvatarMenuProps) => {
  const { hasPermission } = useGetRole();

  const { currentUser, platform } = merchantDetails;

  return (
    <RadixDropdown.Root>
      <RadixDropdown.Trigger className="AccordionTrigger flex h-40 w-40 items-center justify-center rounded-full bg-surface-2 text-text-color-02">
        <p className="label-small md:label-medium">
          {currentUser?.nameInitials}
        </p>
      </RadixDropdown.Trigger>

      <RadixDropdown.Content
        className="AccordionContent rounded-16 bg-surface-1 p-8 shadow-lg"
        sideOffset={-47}
      >
        {hasPermission("settings:read", permissions) && (
          <a href={routes.MANAGE.ACCOUNT_SETTINGS.INDEX()}>
            <RadixDropdown.Item className="body-medium flex cursor-pointer gap-12 rounded-8 p-8 outline-none focus-within:bg-surface-2 hover:bg-surface-2">
              <div className="mt-2">
                <Settings size="18" fill={colorsV2.text[2]} />
              </div>
              <div className="space-y-8">
                <p className="label-medium">Settings</p>
                <p className="body-small text-text-color-02">
                  Your account settings
                </p>
              </div>
            </RadixDropdown.Item>
          </a>
        )}

        {platform != "IOS" && (
          <a
            href="https://help.mamopay.com/en/"
            target="_blank"
            rel="noreferrer"
          >
            <RadixDropdown.Item className="body-medium flex cursor-pointer gap-12 rounded-8 p-8 outline-none focus-within:bg-surface-2 hover:bg-surface-2">
              <div className="mt-2">
                <Help size="18" fill={colorsV2.text[2]} />
              </div>
              <div className="space-y-8">
                <p className="label-medium">Support</p>
                <p className="body-small text-text-color-02">Help center</p>
              </div>
            </RadixDropdown.Item>
          </a>
        )}

        {/* SWITCH BUSINESSES (NO DROPDOWN) */}
        <SwitchBusiness noDropdown />

        <a href={routes.MANAGE.SESSIONS.LOGOUT()} data-method="delete">
          <RadixDropdown.Item className="body-medium flex cursor-pointer gap-12 rounded-8 p-8 outline-none focus-within:bg-surface-2 hover:bg-surface-2">
            <div className="mt-2">
              <Logout size="18" fill={colorsV2.accent.red[1]} />
            </div>
            <p className="label-medium text-accent-red-01">Logout</p>
          </RadixDropdown.Item>
        </a>
      </RadixDropdown.Content>
    </RadixDropdown.Root>
  );
};
