import { type ReactNode, createContext } from "react";
import type { SessionContext } from "types/SessionContext";

/**
 * This is a provider that provides the current SessionContext to the app.
 * It's used to access the current session's information (currentUser).
 * Make sure it's used on the top of the Route and uses RailsContext.
 */

export type CurrentSessionContext = {
  sessionContext: SessionContext;
};

export const CurrentSessionContext = createContext<CurrentSessionContext>({
  sessionContext: null,
});

export type CurrentUserProviderProps = {
  sessionContext: SessionContext;
  children: ReactNode;
};

export const CurrentSessionProvider = ({
  children,
  sessionContext,
}: CurrentUserProviderProps) => {
  return (
    <CurrentSessionContext.Provider
      value={{
        sessionContext,
      }}
    >
      {children}
    </CurrentSessionContext.Provider>
  );
};
