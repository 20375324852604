import { Button } from "design_system/Button";
import { Card } from "design_system/Card";
import { Download } from "design_system/Icons";
import { Tooltip } from "design_system/Tooltip";
import { EmptyState } from "./EmptyState";

import type {
  TConditionalCardHeaderProps,
  TConditionalToolbarProps,
  TConditionalValueProps,
} from "./typesDefs";

type TCommonProps = {
  children?: React.ReactElement;
  isDataEmpty: boolean;
};

export type TChartCard = TCommonProps &
  TConditionalCardHeaderProps &
  TConditionalToolbarProps &
  TConditionalValueProps;

export const ChartCard = ({
  isDataEmpty,
  additionalToolbarActions,
  onDownload,
  showTitle,
  showToolbar,
  showValue,
  title,
  tooltipProps,
  value,
  children,
}: TChartCard) => {
  return (
    <Card hasBorder cardClasses="flex flex-col gap-48 graph-card">
      <>
        {(showTitle || showToolbar || showValue) && (
          <header className="flex flex-col gap-24">
            {(showTitle || showToolbar) && (
              <div className="flex items-center justify-between">
                {showTitle && (
                  <div className="flex w-fit-content items-center gap-8">
                    <p className="header-small text-text-color-02">{title}</p>
                    <Tooltip {...tooltipProps} />
                  </div>
                )}

                {showToolbar && (
                  <div className="flex items-center gap-12 md:gap-24">
                    {additionalToolbarActions && additionalToolbarActions}

                    <div className="hidden md:block">
                      <Button
                        variant="outlined"
                        size="md"
                        iconOnly
                        Icon={Download}
                        onClick={onDownload}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}

            {showValue && <span className="font-medium text-28">{value}</span>}
          </header>
        )}

        {isDataEmpty ? <EmptyState /> : children}
      </>
    </Card>
  );
};
