import {
  AnimatedBarSeries,
  Annotation,
  AnnotationLabel,
  Axis,
  Grid,
  Tooltip,
  XYChart,
} from "@visx/xychart";
import { useEffect, useRef, useState } from "react";

import { colors, colorsV2 } from "constants/colors";
import { sharedTooltipClasses } from "design_system/shared/constant";
import { convertToLocaleStringHelper } from "helpers/currency";
import { useScreenDimension } from "hooks/useScreenDimension";
import { tooltipStyles } from "../constant";
import type { IChartProps } from "../typesDefs";
import { calculateDynamicDx, calculateDynamicDy } from "../utils";
import { calculateBarColor, handleBarChartPadding } from "./utils";

export interface IBarProps {
  /** Decides the layout of the BarChart. Default is `horizontal` */
  horizontal?: boolean;
}

export const BarChart = ({
  bottomNumTicks,
  data,
  frequencyChange,
  hasCurrencyInTooltip = true,
  hasPercentageInTooltip = false,
  height,
  horizontal = false,
  leftNumTicks,
  margin,
  numTicks,
  tooltipSubtext,
  width,
  xAccessor,
  xValueFormat,
  yAccessor,
  yValueFormat,
}: IChartProps & IBarProps) => {
  const [annotationDataKey, setAnnotationDataKey] = useState<any>();
  const [annotationDataIndex, setAnnotationDataIndex] = useState(13);
  const [hoveredBarIndex, setHoveredBarIndex] = useState(-1);
  const [svgPoint, setSvgPoint] = useState({ x: 0, y: 0 });
  const [isAnnotationPresented, setIsAnnotationPresented] = useState(false);
  const [isBarClicked, setIsBarClicked] = useState(false);
  const [zeroLineData, setZeroLineData] = useState(null);

  const { isMobile, isTablet, isLaptop } = useScreenDimension();
  const graphRef = useRef(null);

  const annotationDatum = data[annotationDataIndex];
  const defaultMargin = { top: 0, right: 0, bottom: 40, left: 80 };

  const dynamicDx = calculateDynamicDx(svgPoint.x);
  const dynamicDy = calculateDynamicDy(height, svgPoint.y);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        graphRef.current &&
        !graphRef.current.contains(event.target) &&
        isAnnotationPresented
      ) {
        setIsAnnotationPresented(false);
        setIsBarClicked(false);
      }
    };
    window.addEventListener("click", handleClickOutside);

    // Clean up on unmount
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [isAnnotationPresented]);

  // Note: We're doing this because to allow the Axis component to set the zeroLineData based on the frequencyChange
  useEffect(() => {
    setZeroLineData(null);
  }, [frequencyChange]);

  return (
    <div ref={graphRef}>
      <XYChart
        horizontal={horizontal && true}
        height={height}
        width={width}
        xScale={
          horizontal
            ? { type: "linear" }
            : { type: "band", padding: handleBarChartPadding(data) }
        }
        yScale={
          horizontal ? { type: "band", padding: 0.3 } : { type: "linear" }
        }
        margin={margin ? margin : defaultMargin}
        onPointerDown={(d) => {
          setAnnotationDataKey(d.key);
          setAnnotationDataIndex(d.index);
          setSvgPoint(d.svgPoint);
          setIsBarClicked(true);
        }}
        onPointerOut={(e) => {
          setIsAnnotationPresented(false);
          if (isBarClicked) {
            setIsAnnotationPresented(true);
          }
          setIsBarClicked(false);
        }}
      >
        <Grid
          columns={horizontal ? true : false}
          rows={!horizontal ? true : false}
          numTicks={numTicks}
          children={({ lines }) => (
            <g>
              {lines.map((line, index) => {
                const isTickZero = zeroLineData?.to?.y === line.to.y;
                const lineColor = isTickZero
                  ? colorsV2.border[2]
                  : colors.black[25];

                return (
                  <line
                    key={index}
                    x1={line.from.x}
                    y1={line.from.y}
                    x2={line.to.x}
                    y2={line.to.y}
                    stroke={lineColor}
                    strokeWidth={1}
                  />
                );
              })}
            </g>
          )}
        />
        {xAccessor && (
          <Axis
            hideAxisLine
            hideTicks
            orientation="bottom"
            numTicks={bottomNumTicks}
            tickFormat={xValueFormat}
            tickLength={16}
            tickLabelProps={{
              fontSize: 10,
              lineHeight: 14,
              fill: colors.black[300],
              fontFamily: "CircularXXWeb-Regular",
            }}
          />
        )}
        {yAccessor && (
          <Axis
            labelClassName={`${isMobile ? "hidden" : "block"}`}
            tickClassName={`${isMobile ? "hidden" : "block"}`}
            hideAxisLine
            hideTicks
            orientation="left"
            numTicks={horizontal ? leftNumTicks : numTicks}
            tickFormat={yValueFormat}
            tickLabelProps={(tickValue, i, axisDatum) => {
              // We only want to set the zeroLineData one time based on current frequency
              if (tickValue === 0 && !zeroLineData) {
                setZeroLineData(axisDatum[i]);
              }

              return {
                fontSize: 10,
                lineHeight: 14,
                fill: tickValue === 0 ? colorsV2.text[1] : colors.black[300],
                fontFamily: "CircularXXWeb-Regular",
              };
            }}
          />
        )}
        <AnimatedBarSeries
          data={data}
          dataKey="primary"
          xAccessor={xAccessor}
          yAccessor={yAccessor}
          radius={4}
          radiusAll
          colorAccessor={(_, i) =>
            calculateBarColor(i === hoveredBarIndex, horizontal)
          }
          onPointerMove={({ index }) => setHoveredBarIndex(index)}
          onPointerOut={() => {
            setHoveredBarIndex(-1);
          }}
        />

        {isAnnotationPresented && (isMobile || isTablet) && (
          <Annotation
            dataKey={annotationDataKey}
            datum={data[annotationDataIndex]}
            dx={horizontal ? 15 : dynamicDx}
            dy={horizontal ? 15 : dynamicDy}
          >
            <AnnotationLabel
              title={
                hasCurrencyInTooltip
                  ? `AED ${convertToLocaleStringHelper(yAccessor(annotationDatum))}`
                  : hasPercentageInTooltip
                    ? `${yAccessor(annotationDatum)}%`
                    : yAccessor(annotationDatum)
              }
              subtitle={tooltipSubtext(annotationDatum)}
              showAnchorLine={false}
              width={175}
              backgroundFill={colors.black[400]}
              backgroundProps={{
                fillOpacity: 1,
                rx: 12,
              }}
              titleProps={{
                style: {
                  fill: colors.white,
                  fontFamily: "CircularXXWeb-Medium",
                  fontWeight: "unset",
                  fontSize: 14,
                },
              }}
              subtitleProps={{
                style: {
                  fill: colors.black[200],
                  fontFamily: "CircularXXWeb-Regular",
                  fontWeight: "unset",
                  fontSize: 14,
                },
              }}
              backgroundPadding={{
                top: 12,
                right: 16,
                bottom: 12,
                left: 16,
              }}
            />
          </Annotation>
        )}
        {isLaptop && (
          <Tooltip
            snapTooltipToDatumX
            snapTooltipToDatumY
            style={tooltipStyles}
            renderTooltip={({ tooltipData }) => {
              return (
                <div>
                  {Object.entries(tooltipData.datumByKey).map(
                    (lineDataArray) => {
                      const [key, value] = lineDataArray;

                      return (
                        <div className={sharedTooltipClasses("top")} key={key}>
                          {hasCurrencyInTooltip ? (
                            <span className="font-medium">
                              AED{" "}
                              {convertToLocaleStringHelper(
                                yAccessor(value.datum as any),
                              )}
                            </span>
                          ) : hasPercentageInTooltip ? (
                            <span className="font-medium">
                              {yAccessor(value.datum as any)}%
                            </span>
                          ) : (
                            <span className="font-medium">
                              {yAccessor(value.datum as any)}
                            </span>
                          )}

                          {tooltipSubtext && (
                            <span className="text-black-200">
                              {tooltipSubtext(value.datum)}
                            </span>
                          )}
                        </div>
                      );
                    },
                  )}
                </div>
              );
            }}
          />
        )}
        <style>{`
        .graph-card > div > div > svg {
          overflow: visible !important;
        }
      `}</style>
      </XYChart>
    </div>
  );
};
