import { useEffect, useState } from "react";

export const useScreenDimension = () => {
  const [dimension, setDimension] = useState("desktop");

  const getDimensions = () => {
    let dimension = "desktop";
    if (window.innerWidth < 768) {
      dimension = "mobile";
    }
    if (window.innerWidth > 767 && window.innerWidth < 1024) {
      dimension = "tablet";
    }
    if (window.innerWidth > 1024 && window.innerWidth < 1280) {
      dimension = "laptop";
    }
    setDimension(dimension);
  };

  useEffect(() => {
    window.addEventListener("resize", getDimensions);
    return () => {
      window.removeEventListener("resize", getDimensions);
    };
  });

  useEffect(() => {
    getDimensions();
  }, []);

  return {
    isMobile: dimension === "mobile",
    isTablet: dimension === "tablet",
    isLaptop: dimension === "laptop",
    isDesktop: dimension === "desktop",
  };
};
