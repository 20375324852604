import { yupResolver } from "@hookform/resolvers/yup";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import * as ibantools from "ibantools";
import { isEmpty, isUndefined, startsWith } from "lodash";
import { FormProvider, useForm } from "react-hook-form";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import * as Yup from "yup";

import { EditBankDetailsContainer } from "./EditBankDetailsContainer";
import { ReviewDetails } from "./ReviewDetails";
import { VerificationCode } from "./VerificationCode";

import routes from "constants/routes";
import type { TBankDetails } from "./shared/utils/types";

export const EditBankDetailsRoot = (props: TBankDetails) => {
  const queryClient = new QueryClient();

  const initFormControl = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        accountHolderName: Yup.string().required(
          "Please select an account holder name",
        ),
        iban: Yup.string()
          .required("Please enter a valid IBAN")
          .typeError("Please enter a valid IBAN")
          .test(
            "iban",
            "Please enter a valid IBAN",
            (val) =>
              !isEmpty(val) &&
              !isUndefined(val) &&
              ibantools.isValidIBAN(val) &&
              startsWith(val, "AE"),
          ),
        otp: window.location.href.includes(
          routes.MANAGE.ACCOUNT_SETTINGS.BANK.VERIFICATION_CODE(),
        )
          ? Yup.number()
              .required("Please make sure to enter your OTP")
              .typeError("Please make sure to enter your OTP")
          : Yup.number(),
      }),
    ),
    mode: "onBlur",
    shouldUnregister: false,
  });

  return (
    <main className="create-link">
      <FormProvider {...initFormControl}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <Routes>
              <Route
                path={routes.MANAGE.ACCOUNT_SETTINGS.BANK.UPDATE_IBAN()}
                element={<EditBankDetailsContainer {...props} />}
              />
              <Route
                path={routes.MANAGE.ACCOUNT_SETTINGS.BANK.REVIEW_DETAILS()}
                element={<ReviewDetails />}
              />
              <Route
                path={routes.MANAGE.ACCOUNT_SETTINGS.BANK.VERIFICATION_CODE()}
                element={<VerificationCode />}
              />
            </Routes>
          </BrowserRouter>
        </QueryClientProvider>
      </FormProvider>
    </main>
  );
};
