import { RadioGroup, RadioGroupItem } from "@radix-ui/react-radio-group";
import { useState } from "react";
import { cn } from "utils/classNames";

import Button, { ApplePayButton } from "components/Button/Button";
import TextInput from "components/Inputs/TextInput";
import Card from "components/ui/Card";

import ApplePayMark from "assets/logos/apple-pay-mark.svg";
import BgGradient from "assets/misc/gradient-bg.png";
import secureMastercard from "assets/misc/secure-mastercard.svg";
import securePCI from "assets/misc/secure-pci.svg";
import secureVisa from "assets/misc/secure-visa.svg";

var tinycolor = require("tinycolor2");

interface IDemoCardInformationProps {
  brandColor: string;
  accent: string;
  businessName: string;
}

const DemoCardInformation = ({
  brandColor,
  accent,
  businessName,
}: IDemoCardInformationProps) => {
  const [paymentType, setPaymentType] = useState("apple-pay");

  const tinyBrandColor = tinycolor(brandColor);
  const tinyAccentColor = tinycolor(accent);

  return (
    <>
      <main
        className="min-h-100dvh bg-center bg-cover bg-fixed bg-no-repeat md:min-h-screen md:pb-48 lg:pb-64"
        style={{
          backgroundImage:
            brandColor === "#3333FF" ? `url(${BgGradient})` : "unset",
          backgroundColor: brandColor === "#3333FF" ? "unset" : brandColor,
        }}
      >
        <div className="md:grid md:auto-rows-max md:grid-cols-8 lg:mx-auto lg:max-w-1200 lg:grid-cols-12">
          <div className="md:col-span-4 md:col-start-3 lg:col-start-5">
            <header className="sticky p-20 md:relative md:flex md:h-96 md:items-center">
              <div className="absolute top-14 left-24 md:hidden">
                <Button
                  type="button"
                  size="xs"
                  isIcon
                  iconFont="arrow_back"
                  iconStyle={{ fontSize: "16px" }}
                  classes={cn("border border-solid", {
                    "border-black-300 text-black-500 hover:bg-black-500 hover:bg-opacity-5":
                      tinyBrandColor.isLight(),
                    "border-white text-white hover:bg-white hover:bg-opacity-5":
                      tinyBrandColor.isDark(),
                  })}
                />
              </div>
              <div className="fixed top-24 left-48 hidden md:block xl:absolute xl:top-28 xl:left-0">
                <Button
                  type="button"
                  size="sm"
                  isIcon
                  iconFont="arrow_back"
                  classes={cn("border border-solid", {
                    "border-black-300 text-black-500 hover:bg-black-500 hover:bg-opacity-5":
                      tinyBrandColor.isLight(),
                    "border-white text-white hover:bg-white hover:bg-opacity-5":
                      tinyBrandColor.isDark(),
                  })}
                />
              </div>
              <h2
                className={cn(
                  "text-center font-bold text-16 text-white md:w-full md:text-18",
                  {
                    "text-wl-black-500": tinyBrandColor.isLight(),
                  },
                )}
              >
                {businessName}
              </h2>
            </header>

            <section className="absolute top-64 right-0 bottom-0 left-0 flex flex-col overflow-y-auto overflow-x-hidden rounded-t-32 bg-white px-24 shadow-md md:relative md:top-0 md:rounded-32">
              <header className="space-y-12 pt-24">
                <div className="space-y-4">
                  <div className="flex items-center justify-between font-medium text-18">
                    <p>Total</p>
                    <p>AED 550.00</p>
                  </div>
                </div>

                <div className="h-1 w-full bg-black-50"></div>
              </header>

              {/* PAYMENT METHOD OPTIONS */}
              <div className="mt-28 flex flex-grow-2 flex-col gap-24">
                <h3 className="font-medium">Choose a payment method</h3>

                {/* DEBIT/CREDIT CARD, APPLE/GOOGLE PAY */}
                <RadioGroup
                  aria-label="Payment methods"
                  className="mb-40 flex flex-col space-y-24 md:mb-32"
                  value={paymentType}
                >
                  {/* APPLE/GOOGLE PAY BUTTONS */}
                  <Card
                    hasBorder
                    classes="px-16 py-24 flex justify-between items-center"
                  >
                    <div className="form-field__radio-button-group">
                      <RadioGroupItem
                        id="pay-1"
                        value="apple-pay"
                        className="form-field__radio-button-group__radio-box"
                        onClick={() => setPaymentType("apple-pay")}
                      ></RadioGroupItem>
                      <label
                        htmlFor="pay-1"
                        className="form-field__radio-button-group__label font-medium"
                      >
                        Apple Pay
                      </label>
                    </div>
                    <img
                      src={ApplePayMark}
                      alt="apple pay mark"
                      className="h-24"
                    />
                  </Card>

                  {/* DEBIT/CREDIT CARD */}
                  <Card
                    hasBorder
                    classes="px-16 py-24 flex flex-col space-y-24"
                  >
                    <div className="flex items-center justify-between">
                      <div className="form-field__radio-button-group">
                        <RadioGroupItem
                          id="pay-2"
                          value="card"
                          className="form-field__radio-button-group__radio-box"
                          onClick={() => setPaymentType("card")}
                        ></RadioGroupItem>
                        <label
                          htmlFor="pay-2"
                          className="form-field__radio-button-group__label font-medium"
                        >
                          Debit/Credit card
                        </label>
                      </div>
                      <span className="material-icons-round text-24">
                        payment
                      </span>
                    </div>

                    <div
                      className={cn({
                        hidden: paymentType === "apple-pay",
                        block: paymentType === "card",
                      })}
                    >
                      <TextInput
                        type="text"
                        inputId="card-number"
                        name="card-number"
                        labelText="Card number"
                        onFocusText="0000 0000 0000 0000"
                        hasPremium
                      />

                      <div className="flex">
                        <TextInput
                          type="text"
                          inputId="expiry-date"
                          name="expiry-date"
                          labelText="Expiry date"
                          onFocusText="MM/YY"
                          hasPremium
                        />

                        <TextInput
                          type="text"
                          inputId="cvv"
                          name="cvv"
                          labelText="CVC / CVV"
                          onFocusText="***"
                          hasPremium
                        />
                      </div>

                      <TextInput
                        type="text"
                        inputId="cardholder-name"
                        name="cardholder-name"
                        labelText="Cardholder name"
                        onFocusText="Cardholder name"
                        hasPremium
                      />
                    </div>
                  </Card>
                </RadioGroup>
              </div>

              {/* FOOTER AKA WHERE THE CTAS LIVE */}
              <footer className="sticky bottom-0 space-y-12 bg-white py-12 md:py-24">
                <div className="flex flex-row justify-center gap-12">
                  <img src={secureVisa} alt="visa logo" className="h-20" />
                  <img
                    src={secureMastercard}
                    alt="mastercard logo"
                    className="h-20"
                  />
                  <img
                    src={securePCI}
                    alt="pci compliant logo"
                    className="h-16"
                  />
                </div>

                {/* DEBIT/CREDIT PAY BUTTON */}
                {paymentType === "card" && (
                  <Button
                    size="lg"
                    label="Pay AED 550.00"
                    classes={cn("w-full hover:opacity-80", {
                      "border border-black-200 border-solid":
                        accent == "#FFFFFF" || accent == "#FFF",
                      "text-wl-white-500": tinyAccentColor.isDark(),
                      "text-wl-black-500": tinyAccentColor.isLight(),
                    })}
                    style={{ backgroundColor: accent }}
                  />
                )}

                {/* APPLE/GOOGLE PAY BUTTON */}
                {paymentType === "apple-pay" && <ApplePayButton />}
              </footer>
            </section>
          </div>
        </div>
      </main>
    </>
  );
};

export default DemoCardInformation;
