import routes from "../constants/routes";

export const getVerificationStatuses = (status: string) => {
  return {
    isVerificationNotComplete: status === "not_complete",
    isVerificationPending: status === "pending_verification",
    isVerificationFailed: status === "failed_verification",
    isVerificationComplete: status === "verified",
  };
};

export const handleGoToVerification = () => {
  window.location.href = routes.MANAGE.VERIFICATION.PERSONAL_DETAILS();
};
