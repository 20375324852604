import { ThemeProvider, useTheme } from "@mui/material/styles";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import {
  type DatePickerProps,
  DatePicker as MuiDatePicker,
} from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { customTheme } from "../../shared/theme";
import { TextInput } from "../TextInput";

export interface IDatePickerProps
  extends Omit<
    DatePickerProps<Date | null, Date | null>,
    "value" | "onChange"
  > {
  label?: string;
  onChange: (newValue: Date | null) => void;
  presentInModal?: boolean;
  renderInput: any;
  value: any;
}

export const DatePicker = ({
  label,
  onChange,
  presentInModal,
  value,
  ...inputProps
}: IDatePickerProps) => {
  const outerTheme = useTheme();

  return (
    <ThemeProvider theme={customTheme(outerTheme)}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <MuiDatePicker
          DialogProps={{
            style: {
              zIndex: presentInModal ? 10002 : 1300,
            },
          }}
          label={label}
          value={value}
          inputFormat="DD/MM/YYYY"
          onChange={onChange}
          renderInput={(params) => <TextInput {...params} />}
          {...inputProps}
        />
      </LocalizationProvider>
    </ThemeProvider>
  );
};
