/**
 * Routes contains all our Rails endpoints to be utilized globally
 * throughout our React Components.
 *
 * NB: The object keys are arranged in Ascending Order
 *
 * @example Usage of a route to make a request to the endpoint
 * axios.post(routes.MANAGE.DOCUMENTS.RENEW(id))
 *   .then(payload => {
 *     // success action here
 *   })
 *   .catch(() => {
 *     // failure action here
 *   });
 */
export const routes = {
  ONBOARDING: {
    ACCOUNTS: {
      CREATE: () => "/manage/api/v1/registrations",
      RESEND_OTP: () => "/manage/api/v1/registrations/resend_otp",
      SIGN_UP_VALIDATION: () => "/manage/api/v1/registrations/validate",
      SIGN_UP_WITH_SSO_VALIDATION: () =>
        "/manage/account/sign_up_with_sso_validation",
      VERIFY_EMAIL_OTP: () => "/manage/api/v1/registrations/verify_otp",
    },
    CREATE_PASSWORD: () => "/onboarding/create-password",
    INDUSTRIES: () => "/manage/api/v1/registrations/industries",
    PLAN_TO_USE: () => "/onboarding/plan-to-use",
    REVIEW_TERMS: () => "/onboarding/review-terms",
    SIGN_UP: () => "/onboarding/signup",
    START_WITH_BASICS: () => "/onboarding/basics",
    SUCCESS: () => "/onboarding/success",
    TELL_US_MORE_BUSINESS: () => "/onboarding/tell-us-more-business",
    VERIFY_OTP: () => "/onboarding/otp",
  },
  MANAGE: {
    AUTH: {
      NEW_EMAIL_LOGIN: () => "/manage/new_email_login",
    },
    FIRST_TIME_LOGIN: {
      PHONE: () => "/manage/first-time-login/phone",
      CODE: () => "/manage/first-time-login/code",
      PASSWORD: () => "/manage/new_password",
    },
    ONBOARDING: {
      DELETE_DOCUMENT: () => "/manage/api/v1/onboarding/delete_document",
      INDUSTRIES: () => "/manage/api/v1/onboarding/industries",
      RESEND_EMAIL_VERIFICATION: () =>
        "/manage/api/v1/onboarding/resend_email_verification",
      SUBMIT: () => "/manage/api/v1/onboarding/submit_verification",
      UPDATE_DETAILS: () => "/manage/api/v1/onboarding",
      UPLOAD_DOCUMENT: () => "/manage/api/v1/onboarding/upload_document",
      VERIFICATION_INFO: () => "/manage/api/v1/onboarding/verification_info",
    },
    SETUP: {
      INDEX: () => "/manage/setup/welcome",
      SUBMISSION_SUCCESS: () => "/manage/setup/submission-success",
      OUR_REVIEW: () => "/manage/setup/our-review",
      SELECT_PLAN: () => "/manage/setup/select-plan",
      VERIFICATION_FAILED: () => "/manage/setup/verification-failed",
    },
    ACCOUNT_SETTINGS: {
      INDEX: () => "/manage/account/settings",
      ACCOUNTING: {
        INDEX: () => "/manage/account/accounting",
      },
      API_KEY: () => "/manage/account/api_key",
      BANK: {
        INDEX: () => "/manage/account/bank_details",
        UPDATE_IBAN: () => "/manage/account/bank_details/edit/update_iban",
        REVIEW_DETAILS: () =>
          "/manage/account/bank_details/edit/review_details",
        VERIFICATION_CODE: () =>
          "/manage/account/bank_details/edit/verification_code",
      },
      BRANDING: () => "/manage/account/branding_settings",
      BUSINESS: {
        INDEX: () => "/manage/account/business_details",
        UPDATE_DETAIL: () => "/manage/account/business_details/update",
      },
      EXPENSES: {
        INDEX: () => "/manage/account/expenses",
      },
      PARTNERS: () => "/manage/account/partners_details",
      PERSONAL: () => "/manage/account/personal_details",
      PLAN: () => "/manage/account/plan_details",
      TEAM: {
        INDEX: () => "/manage/account/team",
        USER_INFO: () => "/manage/account/team/invite",
        USER_ROLES: () => "/manage/account/team/edit-permission",
      },
      SWITCH_BUSINESS: () => "/manage/switch_account",
      UPDATE_BRANDING: () => "/manage/account/update_branding",
      INTEGRATION: {
        SLACK: () => "/manage/account/integration/slack",
      },
    },
    ANALYTICS: {
      INDEX: () => "/manage/analytics",
      DOWNLOAD_CSV: (kind: string, frequencyType: string) =>
        `/manage/analytics.csv?kind[]=${kind}&frequency_type[]=${frequencyType}`,
    },
    INSIGHTS: {
      INDEX: () => "/manage/insights",
    },
    DEVELOPER: {
      INDEX: () => "/manage/developer",
    },
    DISBURSEMENTS: {
      INDEX: () => "/manage/payouts",
      NEW: () => "/manage/payouts/new",
      SHOW: (identifier: string) => `/manage/payouts/${identifier}`,
    },
    DOCUMENTS: {
      RENEW: (id: number) => `/manage/documents/${id}/renew`,
    },
    HOME_PAGE: () => "/manage/finances",
    INVOICES: {
      CANCEL: (identifier: string) => `/manage/invoices/${identifier}/cancel`,
      CREATE: () => "/manage/invoices",
      INDEX: () => "/manage/invoices",
      NEW: () => "/manage/invoices/new",
      RESEND: (identifier: string) => `/manage/invoices/${identifier}/resend`,
      SHOW: (identifier: string) => `/manage/invoices/${identifier}`,
    },
    LINKS: {
      INDEX: () => "/manage/links",
      NEW: () => "/manage/links/new",
      CREATE: () => "/manage/links",
      QR_CODE: (identifier: string) => `/manage/links/${identifier}/qr_code`,
      DOWNLOAD_QR_CODE: (identifier: string) =>
        `/manage/links/${identifier}/download_qr_code`,
      SHOW: (identifier: string) => `/manage/links/${identifier}`,
      PAYMENTS: (identifier: string) => `/manage/links/${identifier}/payments`,
      EDIT: (identifier: string) => `/manage/links/${identifier}/edit`,
      DEACTIVATE: (identifier: string) =>
        `/manage/links/${identifier}/deactivate`,
      DELETE: (identifier: string) => `/manage/links/${identifier}/discard`,
      PAYMENT_CSV: (identifier: string) =>
        `/manage/links/${identifier}/payment_csv`,
      TABBY_TERM_AGREEMENT: () => "/manage/links/create_tabby_term_agreement",
      DEFAULT_SETTINGS: () => "/manage/links/default_settings",
    },
    WALLET: {
      CREDIT_CARD_DETAIL: () => "/manage/finances/credit",
      INDEX: () => "/manage/finances",
      SHOW: (identifier: string, slug = "") =>
        `/manage/wallet/${identifier}${slug}`,
      TRANSFER: () => "/manage/finances/transfer",
      CASHBACK: {
        INDEX: () => "/manage/finances/cashback",
        SINGLE: (identifier: string) =>
          `/manage/finances/cashback/${identifier}`,
      },
    },
    OVERVIEW: {
      INDEX: () => "/manage/insights",
    },
    PAYMENT_LINKS: {
      INDEX: () => "/manage/links",
    },
    PAYMENTS: {
      EXPORT: () => "/manage/payments/export",
      INDEX: () => "/manage/payments",
      REFUND: (identifier: string) => `/manage/payments/${identifier}/refund`,
      CANCEL_PENDING_REFUND: (identifier: string) =>
        `/manage/payments/${identifier}/cancel_pending_refund`,
      SHOW: (identifier: string) => `/manage/payments/${identifier}`,
      DISPUTES: {
        SHOW: (identifier: string) => `/manage/payments/disputed/${identifier}`,
      },
    },
    POS: {
      INDEX: () => "/manage/in_store_payments",
      GENERATE_PAYMENT: () => "/manage/in_store_payments/generate",
      CONFIRMATION: () => "/manage/in_store_payments/confirmation",
    },
    CARDS: {
      ACTIVATE: (cardIdentifier: string) =>
        `/manage/cards/${cardIdentifier}/activate`,
      BALANCE: () => "/manage/cards/balance",
      CARD_HOLDER: {
        INDEX: () => "/manage/cards/card-holders",
        SHOW: (identifier: string) => `/manage/cards/card-holder/${identifier}`,
      },
      CONFIRMATION: () => "/manage/cards/confirmation",
      CHANGE_LABEL: (identifier: string) =>
        `/manage/cards/${identifier}/label/edit`,
      INDEX: () => "/manage/cards/index",
      KYB: () => "/manage/cards/owners-detail/add",
      KYC: (identifier: string, cardIdentifier?: string) => {
        /*
          cardIdentifier is present when user lands on kyc screen
          from card detail page only
        */
        return cardIdentifier
          ? `/manage/cards/verification/${identifier}?c=${cardIdentifier}`
          : `/manage/cards/verification/${identifier}`;
      },
      NEW: () => "/manage/cards/new",
      SPEND_LIMIT_NEW: (identifier: string) =>
        `/manage/cards/${identifier}/spend_limit/new`,
      SPEND_LIMIT_EDIT: (cIdentifier: string, limitIdentifier: string) =>
        `/manage/cards/${cIdentifier}/spend_limit/${limitIdentifier}/edit`,
      SHOW: (identifier: string, slug = "") =>
        `/manage/cards/${identifier}${slug}`,
      TERMS: () => "/manage/cards/terms",
      TOPUP: () => "/manage/cards/topup",
      TRANSACTIONS: {
        INDEX: () => "/manage/cards/transactions",
        SHOW: (cIdentifier: string, tIdentifier: string) =>
          `/manage/cards/${cIdentifier}/transactions/${tIdentifier}`,
      },
      UPDATE_PIN: (identifier: string) =>
        `/manage/cards/${identifier}/update_pin`,
    },
    EXPENSES: {
      INDEX: () => "/manage/expenses",
      DOWNLOAD_RECEIPTS: (identifiers: string[]) =>
        "/manage/api/v1/expenses/bulk_download.zip?identifiers[]=MPB-EXP-8813DBA8D9",
    },
    PLANS: {
      UPGRADE: () => "/manage/plans/upgrade",
      INDEX: () => "/manage/plans",
      CHOOSE_SETTLEMENT: () => "/manage/plans/choose-settlement",
      CONFIRM_CHANGES: () => "/manage/plans/confirm-changes",
      SUCCESS: () => "/manage/plans/success",
    },
    SUBSCRIPTIONS: {
      CREATE: () => "/manage/subscriptions",
      INDEX: () => "/manage/subscriptions",
      NEW: () => "/manage/subscriptions/new",
      SHOW: (identifier: string) => `/manage/subscriptions/${identifier}`,
      SUBSCRIBERS: (identifier: string) =>
        `/manage/subscriptions/${identifier}/subscribers`,
    },
    SUBSCRIBERS: {
      SHOW: (subscriptionIdentifier: string, subscriberIdentifier: string) =>
        `/manage/subscriptions/${subscriptionIdentifier}/subscribers/${subscriberIdentifier}`,
      UNSUBSCRIBE: (
        subscriptionIdentifier: string,
        subscriberIdentifier: string,
      ) =>
        `/manage/subscriptions/${subscriptionIdentifier}/subscribers/${subscriberIdentifier}/unsubscribe`,
    },
    SESSIONS: {
      LOGIN: () => "/manage/sign_in",
      LOGOUT: () => "/manage/sign_out",
    },
    SETTINGS: {
      EXPENSES: () => "/manage/account/expenses",
    },
    SETTLEMENTS: {
      INDEX: () => "/manage/settlements",
      SHOW: (identifier: string) => `/manage/settlements/${identifier}`,
      DOWNLOAD_CSV: (identifier: string) =>
        `/manage/settlements/${identifier}.csv`,
    },
    VERIFICATION: {
      PERSONAL_DETAILS: () => "/manage/verification/personal-details",
      BUSINESS_DETAILS: () => "/manage/verification/business-details",
      REVENUE_INFORMATION: () => "/manage/verification/revenue-information",
      SPEND_INFORMATION: () => "/manage/verification/spend-information",
      TRADE_LICENSE: () => "/manage/verification/trade-license",
      MEMORANDUM: () => "/manage/verification/memorandum",
      PROOF_ADDRESS: () => "/manage/verification/proof-address",
      OTHER_DOCUMENTS: () => "/manage/verification/other-documents",
      BANK_INFORMATION: () => "/manage/verification/bank-information",
      BUSINESS_OWNERS: () => "/manage/verification/business-owners",
      SUCCESS: () => "/manage/verification/success",
    },
    ERRORS: {
      FORBIDDEN: () => "/manage/forbidden",
    },
  },
};

export default routes;
