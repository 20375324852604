import { Modal as MuiModal } from "@mui/material";
import { ThemeProvider, useTheme } from "@mui/material/styles";

import { GlobalSearchMobileView } from "./GlobalSearchMobileView";

import { customTheme } from "design_system/shared/theme";

interface IAdvancedSettingsModal {
  businessName: string | null;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  show: boolean;
}

export const GlobalSearchModal = ({
  businessName,
  setShow,
  show,
}: IAdvancedSettingsModal) => {
  const outerTheme = useTheme();
  const modalStyle = {
    height: "100%",
    maxWidth: "100%",
  };

  return (
    <ThemeProvider theme={customTheme(outerTheme)}>
      <MuiModal
        open={show}
        onClose={() => {
          setShow(false);
        }}
        className="BlazeModal"
      >
        <div
          className="w-full overflow-auto bg-surface-1 md:w-auto md:rounded-16 md:shadow-lg"
          style={modalStyle}
        >
          <GlobalSearchMobileView
            setShow={setShow}
            businessName={businessName}
          />
        </div>
      </MuiModal>
    </ThemeProvider>
  );
};
