import type { MerchantInfo } from "types/MerchantInfo";

export const MERCHANT_INFO_INIT_STATE: MerchantInfo = {
  currentMerchant: {
    address: null,
    cardKybStatus: null,
    cardPlatform: null,
    city: null,
    complianceType: null,
    country: null,
    documentsToUpdate: null,
    eligibleForExpeditedSettlements: false,
    expectSpendOnCardsMonthly: null,
    identifier: "",
    isMerchantVerified: false,
    kybCurrentStep: "",
    last30DaysGmv: null,
    name: "",
    notCompleteMessages: null,
    cardAgreeTermsAgreementAt: null,
    planSelected: true,
    premiumAccess: false,
    signedUpFor: null,
    status: "",
    trialAccess: null,
    trialRemainingDays: 0,
  },
  currentUser: {
    email: "",
    firstName: "",
    identifier: "",
    lastName: "",
    middleName: null,
    nameInitials: "",
    roles: [{ name: "", id: "" }],
    permissions: { name: "", permissions: [] },
    phoneNumber: "",
    phoneVerified: false,
  },
  featureToggles: {
    cardsFeatureEnabled: false,
    expensesAccountingIntegrationEnabled: false,
    expensesFeatureEnabled: false,
    walletFeatureEnabled: false,
  },
  platform: null,
};
