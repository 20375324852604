import * as Select from "@radix-ui/react-select";
import { Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import Button from "components/Button/Button";
import FieldMessage from "components/FieldMessage/FieldMessage";
import TextInput from "components/Inputs/TextInput";
import LoaderBusiness from "components/Loader/LoaderBusiness";

import routes from "constants/routes";
import { isAppPlatform } from "helpers/isAppPlatform";
import { handleClose } from "./shared/utils/close";
import type { TBankDetails } from "./shared/utils/types";
import {
  useGetAccountHolderNames,
  useUpdateIbanForm,
} from "./useEditBankDetails";

export const EditBankDetailsContainer = ({
  iban,
  accountHolderName,
}: TBankDetails) => {
  const navigate = useNavigate();
  const { getNames } = useGetAccountHolderNames();
  const { formControl, setAccountHolderName } = useUpdateIbanForm();

  const { data, isLoading } = getNames;
  const { control, errors, handleSubmit } = formControl;

  const onSubmit = handleSubmit(async () => {
    if (!Object.keys(errors).length) {
      // Only navigate if there are no validation errors
      navigate(routes.MANAGE.ACCOUNT_SETTINGS.BANK.REVIEW_DETAILS());
    }
  });

  if (isLoading) {
    return <LoaderBusiness />;
  }

  return (
    <>
      {!isAppPlatform() && (
        <header>
          <button
            type="button"
            className="create-link__close-button material-icons-round"
            onClick={handleClose}
          >
            close
          </button>
        </header>
      )}

      <section className="bg-white pt-48 md:mx-auto md:max-w-3xl md:rounded-16 md:p-48 lg:max-w-5xl">
        <div className="space-y-48">
          <div className="flex flex-col gap-16 text-center">
            <span data-testid="page-title" className="font-bold text-28">
              Update your IBAN
            </span>
            <span className="heading-03 text-black-300">
              The account must be under the business name or under the name of
              one of the business owners
            </span>
          </div>

          <div className="space-y-48">
            <div className="space-y-32">
              <div className="space-y-4">
                <p className="text-12 text-black-200">Account holder name</p>
                <div className="space-y-12">
                  <Controller
                    control={control}
                    name="accountHolderName"
                    defaultValue={accountHolderName}
                    render={(props) => (
                      <Select.Root
                        value={props.value}
                        onValueChange={(value) => {
                          setAccountHolderName(value);
                          props.onChange(value);
                        }}
                      >
                        <Select.Trigger
                          className="form-field__select"
                          data-testid="account-holder-name-dropdown"
                        >
                          <Select.Value aria-label={props.value}>
                            {props.value}
                          </Select.Value>
                          <Select.Icon className="material-icons-round">
                            keyboard_arrow_down
                          </Select.Icon>
                        </Select.Trigger>

                        <Select.Portal>
                          <Select.Content className="form-field__select__content">
                            <Select.Viewport>
                              {data.map((name: string, index: number) => (
                                <Select.Item
                                  key={index}
                                  value={name}
                                  className="form-field__select__item"
                                >
                                  <Select.ItemText>{name}</Select.ItemText>
                                </Select.Item>
                              ))}
                            </Select.Viewport>
                          </Select.Content>
                        </Select.Portal>
                      </Select.Root>
                    )}
                  />
                  {errors.accountHolderName && (
                    <FieldMessage
                      messageType="error"
                      icon="error"
                      messageText={errors.accountHolderName?.message}
                    />
                  )}
                </div>
              </div>

              <Controller
                control={control}
                name="iban"
                defaultValue={iban}
                render={(props) => (
                  <TextInput
                    inputId="iban"
                    name="iban"
                    labelText="IBAN"
                    onChange={(e) => {
                      props.onChange(
                        e.currentTarget.value.replace(/[^\w]/gi, ""),
                      );
                    }}
                    onBlur={props.onBlur}
                    value={props.value}
                    errors={errors.iban}
                    errorMessage={errors.iban?.message}
                  />
                )}
              />
            </div>

            <div className="text-center">
              <Button
                type="button"
                color="primary"
                size="lg"
                label="Continue"
                onClick={onSubmit}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
