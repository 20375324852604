import SyntaxHighlighter, {
  type SyntaxHighlighterProps,
} from "react-syntax-highlighter";

interface ICodeBlock extends SyntaxHighlighterProps {
  children: string;
}

export const CodeBlock = ({ children, ...props }: ICodeBlock) => {
  return (
    <SyntaxHighlighter
      showLineNumbers
      wrapLines
      customStyle={{
        width: "100%",
        padding: "16px",
        backgroundColor: "#F3F5F9",
        borderRadius: "12px",
        fontSize: "14px",
      }}
      {...props}
    >
      {children}
    </SyntaxHighlighter>
  );
};
