import { useMutation } from "@tanstack/react-query";
import axios, { type AxiosError, type AxiosResponse } from "axios";
import isEmpty from "lodash/isEmpty";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useAppSelector } from "store";

import { stringifiedQueryParams } from "constants/queryParams";
import routes from "constants/routes";
import { SentryLoggingService } from "init/SentryLoggingService";
import {
  updateData,
  updateErrorMessage,
  updateStepAnimationDirection,
} from "reducers/redux/Access/SignUp/signUpSlice";

export const useVerifyEmailOtp = () => {
  const { data } = useAppSelector((state) => state.signUp);
  const { email } = data;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const verifyEmailOtp = useMutation({
    mutationKey: ["verifyEmailOtp"],
    mutationFn: async (otp: number) => {
      await axios
        .post(
          `${routes.ONBOARDING.ACCOUNTS.VERIFY_EMAIL_OTP()}?${stringifiedQueryParams}`,
          {
            email,
            otp,
          },
        )
        .then((res: AxiosResponse) => {
          dispatch(
            updateData({
              data: {
                sessionId: res.data.session_id,
              },
            }),
          );
          navigate(routes.ONBOARDING.START_WITH_BASICS(), {
            replace: true,
          });
        });
    },
    onError: (error: AxiosError) => {
      if (!isEmpty(error.response.data.error_code)) {
        dispatch(
          updateErrorMessage({
            errorMessage: {
              otp: "Code is invalid. Please try again.",
            },
          }),
        );
      }

      SentryLoggingService.captureException(error.message, {
        feature: "[SignUp][VerifyEmailOtp]",
        function: "verifyEmailOtp",
        file: "useVerifyEmailOtp",
        data: data ? JSON.stringify(data) : null,
      });
    },
    onSuccess: () => {
      dispatch(
        updateErrorMessage({
          errorMessage: {
            email: null,
          },
        }),
      );
      dispatch(
        updateStepAnimationDirection({
          stepAnimationDirection: "bottom",
        }),
      );
    },
  });
  return {
    verifyEmailOtp,
  };
};
