import type React from "react";

import type IBrandingProps from "interfaces/IBrandingProps";
import type IRailsContext from "interfaces/IRailsContext";
import { BrandingContainer } from "./index";

export const BrandingRoot: React.FC<IBrandingProps> = (
  props: IBrandingProps,
  railsContext: IRailsContext,
) => {
  return <BrandingContainer branding={props} railsContext={railsContext} />;
};
