import { useMemo } from "react";

import { CircularProgress } from "design_system/CircularProgress";
import { Build } from "design_system/Icons";

import routes from "constants/routes";
import { cn } from "utils/classNames";

import { MerchantOnboardingStatus } from "enums/merchant/merchant-onboarding-status.enum";

import { getOurReviewProcess } from "features/VerificationSetup/_repository/review-process.query";
import { getVerificationProcess } from "features/VerificationSetup/_repository/verification-process.query";

export type SetupVerificationPrimaryItemProps = {
  onClick: () => void;
};

export const SetupVerificationPrimaryItem = ({
  onClick,
}: SetupVerificationPrimaryItemProps) => {
  const { pathname } = window.location;

  const { verificationProcess } = getVerificationProcess(true);
  const { reviewProcess } = getOurReviewProcess(true);

  const isCurrentPath = pathname.startsWith("/manage/setup");

  /**
   * Check if the process is loading
   */
  const isLoading = useMemo(
    () => verificationProcess.isFetching || reviewProcess.isFetching,
    [verificationProcess.isFetching, reviewProcess.isFetching],
  );

  const progress = useMemo(() => {
    if (isLoading) return 0;

    const allFieldsCompleted = Object.values(verificationProcess?.data).every(
      (field) => field.value === 100,
    )
      ? 25
      : 0;

    const statuses: MerchantOnboardingStatus[] = [
      MerchantOnboardingStatus.not_complete,
      MerchantOnboardingStatus.pending_verification,
    ];

    const statusProgress = statuses.includes(
      reviewProcess?.data.verificationStatus as MerchantOnboardingStatus,
    )
      ? 25
      : 0;

    return allFieldsCompleted + statusProgress;
  }, [verificationProcess?.data, reviewProcess?.data, isLoading]);

  return (
    <a
      onClick={onClick}
      href={routes.MANAGE.SETUP.INDEX()}
      className="group flex w-full flex-row-reverse items-center justify-between gap-12 border-border-3 border-b px-16 py-8 md:flex-col md:justify-center md:gap-4 md:border-none md:px-0 md:py-0"
    >
      <div
        className={cn(
          "flex h-46 w-46 items-center justify-center rounded-8 p-6 transition-all duration-300 hover:bg-surface-3",
          {
            "bg-accent-blue-02 hover:bg-accent-blue-02": isCurrentPath,
          },
        )}
      >
        <CircularProgress
          value={progress}
          progressSize="md"
          iconClassName="text-primary-1 transform transition-transform group-hover:scale-110 fill-primary-1 w-18 h-18"
          complete={false}
          Icon={Build}
          className="hidden md:block"
        />
      </div>

      <span className={cn("label-medium md:caption")}>Setup</span>
    </a>
  );
};
