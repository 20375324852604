import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import qs from "qs";
import ReactOnRails from "react-on-rails";

import { endpoints } from "constants/apiEndpoints";

export const useValidateExpenseCategory = (): {
  validateExpenseCategory: any;
} => {
  /* ---------------------------------
   *  MUTATION FN
   * ---------------------------------
   */
  const validateExpenseCategory: any = useMutation({
    mutationKey: ["validateExpenseCategory"],
    mutationFn: async (categoryName) => {
      const queryParams = {
        authenticity_token: ReactOnRails.authenticityToken(),
      };
      const response = await axios.post(
        `${endpoints.MANAGE.API.EXPENSES.VALIDATE_CATEGORIES}?${qs.stringify(queryParams)}`,
        {
          name: categoryName,
        },
      );
      return {
        ...response,
        isValid: response.data.success,
      };
    },
  });

  return {
    validateExpenseCategory,
  };
};
