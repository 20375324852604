import type { PaymentAccountsWithPagination } from "types/payments/PaymentAccount";
import { RefreshInfoToolbar } from "../shared/RefreshInfoToolbar";
import { PaymentAccountsTable } from "./PaymentAccountsTable";

import { IslandProvider } from "design_system/Island/IslandContext";

type PaymentAccountsProps = {
  accounts?: PaymentAccountsWithPagination;
};

export const PaymentAccounts = ({ accounts }: PaymentAccountsProps) => {
  return (
    <section className="flex flex-col gap-24">
      <RefreshInfoToolbar />
      <IslandProvider>
        <PaymentAccountsTable accounts={accounts} />
      </IslandProvider>
    </section>
  );
};
