import { colorsV2 } from "constants/colors";
import type { SvgIconProps } from "types/Icon";

export const UnfoldMore = ({
  size,
  fill = colorsV2.text[1],
  ...rest
}: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12 18.6625L14.7963 15.8663C14.9701 15.6964 15.1774 15.6115 15.418 15.6115C15.6585 15.6115 15.8638 15.6973 16.0338 15.869C16.2036 16.0405 16.2885 16.2473 16.2885 16.4893C16.2885 16.7313 16.2036 16.9381 16.0338 17.1098L12.6028 20.5408C12.5108 20.6318 12.414 20.698 12.3123 20.7395C12.2108 20.781 12.1054 20.8018 11.996 20.8018C11.8867 20.8018 11.7825 20.781 11.6835 20.7395C11.5844 20.698 11.489 20.6318 11.3973 20.5408L7.9663 17.1098C7.79646 16.9359 7.71155 16.7277 7.71155 16.485C7.71155 16.2425 7.79738 16.0353 7.96905 15.8633C8.14055 15.6954 8.3473 15.6115 8.5893 15.6115C8.8313 15.6115 9.03813 15.6964 9.2098 15.8663L12 18.6625ZM12 5.4255L9.2038 8.22175C9.02996 8.39175 8.82271 8.47675 8.58205 8.47675C8.34155 8.47675 8.1363 8.39092 7.9663 8.21925C7.79646 8.04759 7.71155 7.84075 7.71155 7.59875C7.71155 7.35675 7.79646 7.14992 7.9663 6.97825L11.3973 3.54125C11.4893 3.45392 11.586 3.38867 11.6875 3.3455C11.7892 3.30217 11.8946 3.2805 12.0038 3.2805C12.1131 3.2805 12.2175 3.30217 12.3168 3.3455C12.4161 3.38867 12.5115 3.45392 12.6028 3.54125L16.0338 6.97825C16.2036 7.15209 16.2885 7.36034 16.2885 7.603C16.2885 7.8455 16.2027 8.05275 16.031 8.22475C15.8595 8.39275 15.6523 8.47575 15.4093 8.47375C15.1665 8.47175 14.9601 8.38575 14.7903 8.21575L12 5.4255Z"
        fill={fill}
      />
    </svg>
  );
};
