import { MERCHANT_INFO_INIT_STATE } from "constants/initStates/merchantInfo";
import { SentryLoggingService } from "init/SentryLoggingService";
import type { MerchantInfo } from "types/MerchantInfo";

export const getMerchantInfoFromLocalStorage = (): MerchantInfo | undefined => {
  const infoStr = localStorage.getItem("merchant");

  if (infoStr) {
    try {
      return JSON.parse(infoStr) as MerchantInfo;
    } catch (error) {
      SentryLoggingService.captureException(error.message, {
        feature: "Global",
        function: "getMerchantInfoFromLocalStorage",
        file: "getMerchantInfoFromLocalStorage.ts",
        data: JSON.stringify({ infoStr }),
      });
      return MERCHANT_INFO_INIT_STATE;
    }
  }
  return MERCHANT_INFO_INIT_STATE;
};
