export const isNumberDefined = (value: any) => {
  if (value === 0 || Number.parseFloat(value) === 0) return true;
  return !!value && !isNaN(value);
};

export const preventAlphaKeyDown = (e) => {
  const key = e.key || String.fromCharCode(e.which);

  // Allow numbers (0-9) and control keys like backspace, delete, etc.
  if (
    !/^[0-9]$/.test(key) &&
    !e.ctrlKey &&
    !e.metaKey &&
    !e.altKey &&
    ![
      "Backspace",
      "ArrowLeft",
      "ArrowRight",
      "ArrowUp",
      "ArrowDown",
      "Delete",
      "Tab",
    ].includes(key)
  ) {
    e.preventDefault();
  }
};

export const preventAlphaOnPaste = (e) => {
  const paste = (e.clipboardData || Clipboard).getData("text");

  // Check if the pasted content contains only numbers
  if (!/^[0-9]+$/.test(paste)) {
    e.preventDefault();
  }
};
