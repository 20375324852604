import { useContext } from "react";
import { createPortal } from "react-dom";

import { cn } from "utils/classNames";
import { IslandContext } from "./IslandContext";

const Island = ({
  children,
  classNames = {
    container: "",
    content: "",
  },
}: {
  children: any;
  classNames?: {
    container?: string;
    content?: string;
  };
}) => {
  const { isIslandOpen, closeIsland } = useContext(IslandContext);

  const islandPortal = isIslandOpen && (
    <div
      style={{
        zIndex: 1000,
      }}
      className={cn(
        `fixed bottom-40 flex h-fit-content w-full justify-center ${isIslandOpen ? "animate-slide-up" : "animate-slide-down"}${classNames.container}`,
      )}
    >
      <div
        className={cn(
          `relative rounded-16 border border-border-2 bg-surface-2 px-24 py-12 shadow-lg ${classNames.content}`,
        )}
      >
        {children}
      </div>
    </div>
  );

  return createPortal(islandPortal, document.body);
};

export default Island;

Island.Label = ({ children, className = "" }) => {
  return (
    <span className={cn(`label-large text-text-color-03 ${className}`)}>
      {children}
    </span>
  );
};
