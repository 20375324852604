import { configureStore } from "@reduxjs/toolkit";
import { type TypedUseSelectorHook, useSelector } from "react-redux";

import { dashboardNavigationSlice } from "design_system/Layout/DashboardNavigation/core/reducer";
import { CardsDashboardSlice } from "features/Cards/CardDashboard/core/reducer";
import cardVccReducer from "features/Cards/CardsVcc/reducer";
import { createCardSlice } from "features/Cards/features/CreateCard/core/reducer";
import { expenseDashboardSlice } from "features/ExpenseManagement/ExpenseDashboardRoot/ExpenseDashboard/reducer";
import { expenseDetailPageReducer } from "features/ExpenseManagement/ExpenseDetailPageRoot/reducer";
import createLinkReducer from "features/PaymentLink/PaymentLinkCreate/createLinkSlice";
import { accountingDashboardSlice } from "features/Settings/Accounting/slice";
import { expenseSettingsReducer } from "features/Settings/Expense/Dashboard/reducer";
import verificationSlice from "features/Verification/verificationSlice";
import { walletSlice } from "features/Wallet/WalletV2/reducer";
import signUpReducer from "reducers/redux/Access/SignUp/signUpSlice";
import globalBannerReducer from "reducers/redux/GlobalBanner/globalBannerSlice";
import { merchantSlice } from "reducers/redux/Merchant";
import { permissionsSlice } from "reducers/redux/Permissions";
import scrollTriggerReducer from "reducers/redux/ScrollTrigger/scrollTriggerSlice";
import { tableReducer } from "reducers/redux/TableReducer";

export const store = configureStore({
  reducer: {
    // BASIC REDUCERS
    accountingDashboard: accountingDashboardSlice,
    cardVcc: cardVccReducer,
    cardsDashboard: CardsDashboardSlice,
    createCard: createCardSlice,
    createLink: createLinkReducer,
    expenseDashboard: expenseDashboardSlice,
    expenseDetailPage: expenseDetailPageReducer,
    expenseSettings: expenseSettingsReducer,
    signUp: signUpReducer,
    table: tableReducer,
    verification: verificationSlice,
    wallet: walletSlice,
    // OTHERS
    merchantSlice: merchantSlice,
    dashboardNavigation: dashboardNavigationSlice,
    globalBanner: globalBannerReducer,
    permissions: permissionsSlice,
    scrollTrigger: scrollTriggerReducer,
  },
  devTools: process.env.REACT_APP_ENV !== "production",
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
