import type { ReactNode } from "react";
import { cn } from "utils/classNames";

export type TimelineStepperProps = {
  children: ReactNode;
  className?: string;
};
export const TimelineStepper = ({
  children,
  className,
}: TimelineStepperProps) => {
  if (!children) return null;

  return (
    <div
      className={cn(
        "flex w-full items-center justify-between gap-6 pb-32",
        className,
      )}
    >
      {children}
    </div>
  );
};
