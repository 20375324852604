import { defaultStyles } from "@visx/tooltip";

import { formatAmount } from "helpers/currency";
import type { IChartProps } from "./typesDefs";

export type TChartData = {
  id: number;
  merchantId: number;
  kind: string;
  frequencyType: string;
  unit: string;
  value: number;
  percentageChange: string;
  year: number;
  month: string;
  week: number;
  createdAt: string;
};

export const chartData: Array<any> = [
  {
    id: 1,
    merchantId: 1,
    kind: "weekly_revenue",
    frequencyType: "weekly",
    unit: "currency",
    value: 10_000,
    percentageChange: "31.0",
    year: 2023,
    month: "January",
    week: 39,
    createdAt: "2023-09-27",
  },
  {
    id: 2,
    merchantId: 1,
    kind: "weekly_revenue",
    frequencyType: "weekly",
    unit: "currency",
    value: 7_000,
    percentageChange: "31.0",
    year: 2023,
    month: "February",
    week: 39,
    createdAt: "2023-09-27",
  },
  {
    id: 3,
    merchantId: 1,
    kind: "weekly_revenue",
    frequencyType: "weekly",
    unit: "currency",
    value: 5_600,
    percentageChange: "31.0",
    year: 2023,
    month: "March",
    week: 39,
    createdAt: "2023-09-27",
  },
  {
    id: 4,
    merchantId: 1,
    kind: "weekly_revenue",
    frequencyType: "weekly",
    unit: "currency",
    value: 800,
    percentageChange: "31.0",
    year: 2023,
    month: "April",
    week: 39,
    createdAt: "2023-09-27",
  },
  {
    id: 5,
    merchantId: 1,
    kind: "weekly_revenue",
    frequencyType: "weekly",
    unit: "currency",
    value: 2_400,
    percentageChange: "31.0",
    year: 2023,
    month: "May",
    week: 39,
    createdAt: "2023-09-27",
  },
  {
    id: 6,
    merchantId: 1,
    kind: "weekly_revenue",
    frequencyType: "weekly",
    unit: "currency",
    value: 7_900,
    percentageChange: "31.0",
    year: 2023,
    month: "June",
    week: 39,
    createdAt: "2023-09-27",
  },
  {
    id: 7,
    merchantId: 1,
    kind: "weekly_revenue",
    frequencyType: "weekly",
    unit: "currency",
    value: 1_500,
    percentageChange: "31.0",
    year: 2023,
    month: "July",
    week: 39,
    createdAt: "2023-09-27",
  },
  {
    id: 8,
    merchantId: 1,
    kind: "weekly_revenue",
    frequencyType: "weekly",
    unit: "currency",
    value: 12_240,
    percentageChange: "31.0",
    year: 2023,
    month: "August",
    week: 39,
    createdAt: "2023-09-27",
  },
  {
    id: 9,
    merchantId: 1,
    kind: "weekly_revenue",
    frequencyType: "weekly",
    unit: "currency",
    value: 4_920,
    percentageChange: "31.0",
    year: 2023,
    month: "September",
    week: 39,
    createdAt: "2023-09-27",
  },
  {
    id: 10,
    merchantId: 1,
    kind: "weekly_revenue",
    frequencyType: "weekly",
    unit: "currency",
    value: 8_900,
    percentageChange: "31.0",
    year: 2023,
    month: "October",
    week: 39,
    createdAt: "2023-09-27",
  },
  {
    id: 11,
    merchantId: 1,
    kind: "weekly_revenue",
    frequencyType: "weekly",
    unit: "currency",
    value: 300,
    percentageChange: "31.0",
    year: 2023,
    month: "November",
    week: 39,
    createdAt: "2023-09-27",
  },
  {
    id: 12,
    merchantId: 1,
    kind: "weekly_revenue",
    frequencyType: "weekly",
    unit: "currency",
    value: 2540,
    percentageChange: "31.0",
    year: 2023,
    month: "December",
    week: 39,
    createdAt: "2023-09-27",
  },
];

export const chartArgs: IChartProps = {
  bottomNumTicks: 1,
  data: chartData,
  hasCurrencyInTooltip: true,
  height: 270,
  margin: {
    top: 0,
    right: 0,
    bottom: 40,
    left: 80,
  },
  numTicks: 5,
  tooltipSubtext: (datum: any) => datum.month,
  xAccessor: (d: TChartData) => d.month,
  xValueFormat: (month: string) => month.substring(0, 3),
  yAccessor: (d: TChartData) => d.value,
  yValueFormat: (value: any) => `AED ${formatAmount(value)}`,
};

export const tooltipStyles = {
  ...defaultStyles,
  backgroundColor: "unset",
  borderRadius: 0,
  boxShadow: "none",
  color: "unset",
  lineHeight: "unset",
  padding: "unset",
  fontFamily: "CircularXXWeb-Regular",
  fontSize: 12,
};
