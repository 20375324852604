import { colorsV2 } from "constants/colors";
import type { SvgIconProps } from "types/Icon";

export const MoreHoriz = ({
  size,
  fill = colorsV2.text[1],
  ...rest
}: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g id="Name=more_horiz, Size=24">
        <path
          id="Vector"
          d="M5.05841 13.2657C4.71179 13.2657 4.41484 13.1416 4.16756 12.8933C3.92027 12.6451 3.79663 12.3466 3.79663 11.9979C3.79663 11.646 3.92092 11.3475 4.16951 11.1022C4.41807 10.8569 4.71688 10.7342 5.06593 10.7342C5.41126 10.7342 5.70896 10.8576 5.95903 11.1042C6.20911 11.3509 6.33416 11.6492 6.33416 11.9991C6.33416 12.349 6.20818 12.6476 5.95623 12.8948C5.7043 13.1421 5.40502 13.2657 5.05841 13.2657ZM12.0008 13.2657C11.6509 13.2657 11.3523 13.1416 11.1051 12.8933C10.8578 12.6451 10.7342 12.3466 10.7342 11.9979C10.7342 11.646 10.8583 11.3475 11.1066 11.1022C11.3548 10.8569 11.6533 10.7342 12.002 10.7342C12.3539 10.7342 12.6524 10.8576 12.8977 11.1042C13.143 11.3509 13.2657 11.6492 13.2657 11.9991C13.2657 12.349 13.1423 12.6476 12.8957 12.8948C12.649 13.1421 12.3507 13.2657 12.0008 13.2657ZM18.9341 13.2657C18.5833 13.2657 18.2842 13.1416 18.0368 12.8933C17.7894 12.6451 17.6657 12.3466 17.6657 11.9979C17.6657 11.646 17.7906 11.3475 18.0404 11.1022C18.2901 10.8569 18.5904 10.7342 18.9412 10.7342C19.2919 10.7342 19.5909 10.8576 19.8382 11.1042C20.0855 11.3509 20.2092 11.6492 20.2092 11.9991C20.2092 12.349 20.0843 12.6476 19.8347 12.8948C19.585 13.1421 19.2848 13.2657 18.9341 13.2657Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};
