import type { HTMLProps } from "react";
import { cn } from "utils/classNames";

interface IProps extends HTMLProps<HTMLButtonElement> {
  activeItem: number | null;
  hasCustomBranding?: boolean;
  isDisabled?: boolean;
  items: Array<number | string>;
  onItemClick: Function;
}

const Selector = ({
  activeItem,
  hasCustomBranding,
  isDisabled,
  items,
  onItemClick,
}: IProps) => {
  return (
    <>
      {items?.map((item: number | string) => (
        <button
          key={item}
          className={cn("selector", {
            "selector--pink": !hasCustomBranding,
            "selector--neutral": hasCustomBranding,
            "selector--pink-active": activeItem === item,
            "selector--neutral-active":
              hasCustomBranding && activeItem === item,
          })}
          onClick={() => onItemClick(item)}
          disabled={isDisabled}
          type="button"
        >
          <span>{item}</span>
          <span>%</span>
        </button>
      ))}
    </>
  );
};

export default Selector;
