import * as RadixPopover from "@radix-ui/react-popover";
import * as RadixTooltip from "@radix-ui/react-tooltip";

// todo(muj): use JSXElementConstructor<IIcon> for Icon type as the next update
export interface ITooltipWithoutDrawerProps {
  content: string;
  Icon: React.ReactNode;
  tooltipContentProps: {
    sideOffset: number;
    className: string;
    style: {
      width: string;
    };
    side: "top" | "right" | "bottom" | "left";
  };
}

export const TooltipWithoutDrawer = ({
  content,
  Icon,
  tooltipContentProps,
}: ITooltipWithoutDrawerProps) => {
  return (
    <>
      <div className="flex lg:hidden">
        <RadixPopover.Root>
          <RadixPopover.Trigger
            style={{
              backgroundColor: "unset",
              border: "unset",
            }}
            type="button"
          >
            {Icon}
          </RadixPopover.Trigger>

          <RadixPopover.Content {...tooltipContentProps}>
            {content}
            <RadixPopover.Arrow fill="#26303e" />
          </RadixPopover.Content>
        </RadixPopover.Root>
      </div>

      <div className="hidden lg:flex lg:items-center">
        <RadixTooltip.Root delayDuration={100}>
          <RadixTooltip.Trigger type="button">{Icon}</RadixTooltip.Trigger>

          <RadixTooltip.Content {...tooltipContentProps}>
            {content}
            <RadixTooltip.Arrow fill="#26303e" />
          </RadixTooltip.Content>
        </RadixTooltip.Root>
      </div>
    </>
  );
};
