import Button from "components/Button/Button";

import EmptyStateIllustration from "assets/illustrations/pos-empty-state.svg";
import routes from "constants/routes";
import { PageHeader } from "./PageHeader";

export const POSContainer = () => {
  const handleGoToGenerate = () => {
    window.location.href = routes.MANAGE.POS.GENERATE_PAYMENT();
  };

  return (
    <>
      <PageHeader />

      <section className="mx-auto max-w-1180 space-y-32 py-80 text-center">
        <img
          src={EmptyStateIllustration}
          alt="empty state"
          className="mx-auto"
        />

        <div className="space-y-8 text-black-200 leading-24">
          <p className="font-bold text-18">Generate a new payment</p>
          <p className="text-16">
            Allow customers to tap or scan the QR code to pay in store
          </p>
        </div>

        <Button
          data-testid="generate-payment-btn"
          color="primary"
          size="md"
          label="Generate payment"
          onClick={handleGoToGenerate}
        />
      </section>
    </>
  );
};
