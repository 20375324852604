export const fontRegular = "CircularXXWeb-Regular";
export const fontMedium = "CircularXXWeb-Medium";

export const typography = {
  display: {
    large: {
      fontFamily: fontMedium,
      fontSize: "4rem",
      lineHeight: "1.24",
    },
    medium: {
      fontFamily: fontMedium,
      fontSize: "3.2rem",
      lineHeight: "1.24",
    },
    small: {
      fontFamily: fontMedium,
      fontSize: "2.8rem",
      lineHeight: "1.28",
    },
  },
  header: {
    large: {
      fontFamily: fontMedium,
      fontSize: "2.6rem",
      lineHeight: "1.32",
    },
    medium: {
      fontFamily: fontMedium,
      fontSize: "2.2rem",
      lineHeight: "1.32",
    },
    small: {
      fontFamily: fontMedium,
      fontSize: "1.8rem",
      lineHeight: "1.32",
    },
  },
  label: {
    large: {
      fontFamily: fontMedium,
      fontSize: "1.6rem",
      lineHeight: "1.48",
    },
    medium: {
      fontFamily: fontMedium,
      fontSize: "1.4rem",
      lineHeight: "1.54",
    },
    small: {
      fontFamily: fontMedium,
      fontSize: "1.2rem",
      lineHeight: "1.64",
    },
  },
  body: {
    large: {
      fontFamily: fontRegular,
      fontSize: "1.6rem",
      lineHeight: "1.48",
    },
    medium: {
      fontFamily: fontRegular,
      fontSize: "1.4rem",
      lineHeight: "1.54",
    },
    small: {
      fontFamily: fontRegular,
      fontSize: "1.2rem",
      lineHeight: "1.64",
    },
  },
  caption: {
    fontFamily: fontRegular,
    fontSize: "1rem",
    lineHeight: "1.36",
  },
};
