import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { Button } from "design_system/Button";
import { Description, Done, Pending, VerifiedUser } from "design_system/Icons";
import { TimelineStep, TimelineStepper } from "design_system/TimelineStepper";

import TextButton from "components/ui/TextButton";

import routes from "constants/routes";

import {
  initialState,
  updateData,
  updateErrorMessage,
} from "reducers/redux/Access/SignUp/signUpSlice";

export const SignUpComplete = () => {
  const dispatch = useDispatch();

  const handleGoToVerification = () => {
    window.location.href = routes.MANAGE.VERIFICATION.PERSONAL_DETAILS();
  };

  const handleGoToHomePage = () => {
    window.location.href = routes.MANAGE.SETUP.INDEX();
  };

  useEffect(() => {
    dispatch(updateData(initialState));
    dispatch(updateErrorMessage({ errorMessage: null }));
  }, []);

  return (
    <div className="mx-auto flex flex-col gap-32">
      <div className="space-y-8 md:space-y-32">
        <p className="text-center text-40 md:text-56">🚀</p>

        <div className="mx-auto max-w-screen-md space-y-12 text-center">
          <h1 className="header-medium md:display-medium">
            Your account is created! <br />
            Next up: Complete verification
          </h1>
          <p className="body-medium md:body-large">
            This will take approximately 15 minutes.
          </p>
          <p className="body-medium md:body-large mx-auto w-3/4">
            Verifying your account allows you to start using Mamo and ensures
            compliance with financial regulation.
          </p>
        </div>
      </div>

      <TimelineStepper className="px-20 md:px-0">
        <TimelineStep
          variant="completed"
          label="Create account"
          icon={<Done fill="currentColor" />}
        />
        <TimelineStep
          variant="current"
          label="Complete verification"
          icon={<Description fill="currentColor" />}
        />
        <TimelineStep
          variant="upcoming"
          label="Our review"
          icon={<Pending fill="currentColor" />}
        />
        <TimelineStep
          isLast={true}
          variant="upcoming"
          label="Account verified"
          icon={<VerifiedUser fill="currentColor" />}
        />
      </TimelineStepper>

      <div className="flex flex-col gap-16">
        <Button
          variant="filled"
          size="lg"
          label="Start verification"
          classes="mx-auto"
          onClick={handleGoToVerification}
        />

        <TextButton
          size="md"
          color="primary"
          label="Remind me later"
          classes="mx-auto"
          onClick={handleGoToHomePage}
        />
      </div>
    </div>
  );
};
