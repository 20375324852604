import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useEffect } from "react";

import { SentryLoggingService } from "init/SentryLoggingService";

import routes from "constants/routes";

import type { MerchantOnboardingStatus } from "enums/merchant/merchant-onboarding-status.enum";

export type OurReviewResponse = {
  data: {
    selectedPlan: boolean;
    notCompleteMessages: string[];
    supportEmail: string | null;
    verificationStatus: MerchantOnboardingStatus;
  };
};

/**
 * Get our review process
 * It returns a query result
 */
export const getOurReviewProcess = (enabled: boolean) => {
  const url = "/manage/api/v1/setup/review_progress";

  const reviewProcess = useQuery({
    queryKey: ["getOurReviewProcess"],
    enabled,
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const response = await axios.get<OurReviewResponse>(url);

      return response.data.data;
    },
  });

  /**
   * Error Handling for Query only.
   */
  useEffect(() => {
    if (reviewProcess.isError) {
      if (reviewProcess.error instanceof Error) {
        SentryLoggingService.captureException(reviewProcess.error.message, {
          feature: "[Verification Setup][Get Our Review Process]",
          file: "review-process.query.ts",
          function: "getOurReviewProcess",
        });

        window.location.href = routes.MANAGE.HOME_PAGE();
      }
    }
  }, [reviewProcess.isError, reviewProcess.error]);

  return {
    reviewProcess,
  };
};
