import { cn } from "utils/classNames";

import { ArrowBack } from "design_system/Icons";
import { Snackbar } from "design_system/Snackbar";

import { routes } from "constants/routes";
import { PhoneNumberForm } from "./Form";

export const PhoneNumberStep = () => {
  return (
    <div className="grid w-full grid-cols-1 gap-24">
      <a className="cursor-pointer" href={routes.MANAGE.AUTH.NEW_EMAIL_LOGIN()}>
        <ArrowBack size="18px" />
      </a>

      {/* Header */}
      <div className="grid grid-cols-1 gap-8 text-center">
        <span className={cn("header-medium text-text-color-01")}>
          Enter your phone number
        </span>
        <span className={cn("body-medium text-text-color-0")}>
          We'll send you a code to verify your phone number
        </span>
      </div>

      {/* Form */}
      <PhoneNumberForm />
      <Snackbar />
    </div>
  );
};
