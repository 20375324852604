import { useContext } from "react";
import { useDispatch } from "react-redux";

import { Button } from "design_system/Button/Button";
import { Close } from "design_system/Icons";
import Island from "design_system/Island/Island";

import { useAppSelector } from "store";

import { IslandContext } from "design_system/Island/IslandContext";
import { SideSheetContext } from "design_system/Sidesheet/SideSheetContext";

import { colorsV2 } from "constants/colors";
import { clearSelectedRows } from "reducers/redux/TableReducer/tableReducer";

export const RepaymentsIsland = () => {
  /* ---------------------------------
   *  Context APIS & CACHES
   * ---------------------------------
   */
  const { closeIsland } = useContext(IslandContext);
  const { openSideSheet } = useContext(SideSheetContext);

  /* ---------------------------------
   *  Hooks
   * ---------------------------------
   */
  const dispatch = useDispatch();

  const {
    table: { repayments },
  } = useAppSelector((state) => state);

  /* ---------------------------------
   *  Constants
   * ---------------------------------
   */
  const selectedCount = repayments.selectedRows?.length ?? 0;
  /* ---------------------------------
   *  Helpers
   * ---------------------------------
   */
  const closeIslandAndResetSelections = () => {
    dispatch(clearSelectedRows("repayments"));
    closeIsland();
  };

  return (
    <Island>
      <div className="flex items-center gap-48">
        <Island.Label className="min-w-104 whitespace-nowrap">
          {selectedCount} selected
        </Island.Label>
        <div className="flex items-center gap-12">
          <Button
            label="Make a payment"
            size="md"
            variant="outlined"
            onClick={() => openSideSheet()}
          />
        </div>
        <div>
          <Close
            size="24"
            className="cursor-pointer"
            fill={colorsV2.text[2]}
            onClick={closeIslandAndResetSelections}
          />
        </div>
      </div>
    </Island>
  );
};
