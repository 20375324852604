import { useMutation } from "@tanstack/react-query";
import axios, { type AxiosError, type AxiosResponse } from "axios";
import isEmpty from "lodash/isEmpty";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useAppSelector } from "store";

import { queryParams } from "constants/queryParams";
import routes from "constants/routes";
import { snack } from "design_system/Snackbar";
import { SentryLoggingService } from "init/SentryLoggingService";
import {
  resetSignUpState,
  updateSessionExpired,
} from "reducers/redux/Access/SignUp/signUpSlice";
import { SignUpSteps } from "../../shared/constant";

export const useCreateAccount = () => {
  const { data } = useAppSelector((state) => state.signUp);
  const {
    accessToken,
    businessName,
    complianceType,
    email,
    firstName,
    industryId,
    lastName,
    password,
    planToUse,
    onboardingRole,
    sessionId,
  } = data;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const createAccountGTMTrigger = () => {
    return (window as any).dataLayer.push({
      event: "create-account",
      eventCategory: "button",
      eventAction: "click",
      eventLabel: "createAccount",
    });
  };

  const createAccount = useMutation({
    mutationKey: ["createAccount"],
    mutationFn: async () => {
      return await axios
        .post(routes.ONBOARDING.ACCOUNTS.CREATE(), {
          ...queryParams,
          step: SignUpSteps.TELL_US_MORE,
          access_token: isEmpty(accessToken) ? null : accessToken,
          compliance_type: complianceType,
          email: email,
          first_name: firstName,
          industry_id: industryId,
          last_name: lastName,
          name: businessName,
          password: password,
          plan_to_use: planToUse,
          onboarding_role: onboardingRole,
          session_id: sessionId,
        })
        .then((res: AxiosResponse) => {
          createAccountGTMTrigger();
          navigate(routes.ONBOARDING.SUCCESS());
        });
    },
    onError: (error: AxiosError) => {
      const messages = error.response.data.messages;

      if (error.response.data.error_code === "SESSION_EXPIRED") {
        dispatch(updateSessionExpired({ sessionExpired: true }));
        dispatch(resetSignUpState());
        navigate(routes.ONBOARDING.SIGN_UP());
      } else {
        const { password, ...payloadWithoutSensitiveData } = data;

        SentryLoggingService.captureException(error.message, {
          feature: "[SignUp][CreateAccount]",
          function: "createAccount",
          file: "useCreateAccount",
          errorDetails: messages ? JSON.stringify({ messages }) : null,
          data: data ? JSON.stringify(payloadWithoutSensitiveData) : null,
        });
      }

      snack({
        title: "Oops! Something went wrong. Try again later",
        leadingElement: "icon",
        variant: "critical",
      });
    },
  });
  return {
    createAccount,
  };
};
