import { useEffect, useState } from "react";

export function useTimer(initialTimer = 30) {
  const [timer, setTimer] = useState(initialTimer);
  const [isDisabled, setIsDisabled] = useState(false);

  const resetTimer = () => {
    setTimer(initialTimer);
  };

  useEffect(() => {
    let interval: any;

    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    setIsDisabled(timer > 0);

    return () => {
      clearInterval(interval);
    };
  }, [timer]);

  return {
    timer,
    isDisabled,
    resetTimer,
  };
}
