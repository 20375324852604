import { colorsV2 } from "constants/colors";
import type { SvgIconProps } from "types/Icon";

export const KeyboardDoubleArrowLeft = ({
  size,
  fill = colorsV2.text[1],
  ...rest
}: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M7.53853 11.975L11.8978 16.3282C12.0678 16.4982 12.1518 16.6972 12.1498 16.925C12.1478 17.1528 12.0638 17.3517 11.8978 17.5217C11.7239 17.6917 11.524 17.7767 11.298 17.7767C11.0722 17.7767 10.8743 17.6917 10.7043 17.5217L5.75428 12.5777C5.66311 12.4864 5.59686 12.3911 5.55553 12.2917C5.5142 12.1926 5.49353 12.087 5.49353 11.975C5.49353 11.863 5.5142 11.7574 5.55553 11.6582C5.59686 11.5589 5.66311 11.4636 5.75428 11.3722L10.7043 6.42825C10.8743 6.25825 11.0732 6.17325 11.301 6.17325C11.529 6.17325 11.7279 6.25825 11.8978 6.42825C12.0678 6.59825 12.1528 6.79717 12.1528 7.025C12.1528 7.25283 12.0678 7.45175 11.8978 7.62175L7.53853 11.975ZM13.9233 11.975L18.2768 16.3282C18.4466 16.4982 18.5305 16.6972 18.5285 16.925C18.5265 17.1528 18.4426 17.3517 18.2768 17.5217C18.1028 17.6917 17.9029 17.7767 17.677 17.7767C17.451 17.7767 17.2531 17.6917 17.0833 17.5217L12.1333 12.5777C12.0419 12.4864 11.9756 12.3911 11.9343 12.2917C11.8929 12.1926 11.8723 12.087 11.8723 11.975C11.8723 11.863 11.8929 11.7574 11.9343 11.6582C11.9756 11.5589 12.0419 11.4636 12.1333 11.3722L17.0833 6.42825C17.2531 6.25825 17.452 6.17325 17.68 6.17325C17.9079 6.17325 18.1068 6.25825 18.2768 6.42825C18.4466 6.59825 18.5315 6.79717 18.5315 7.025C18.5315 7.25283 18.4466 7.45175 18.2768 7.62175L13.9233 11.975Z"
        fill={fill}
      />
    </svg>
  );
};
