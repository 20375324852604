import PaymentInfo from "components/PaymentInfo/PaymentInfo";

import BgGradient from "assets/misc/gradient-bg.png";

var tinycolor = require("tinycolor2");

interface IDemoThankYouProps {
  brandColor: string;
  accent: string;
  hasPremium: boolean;
  businessName: string;
}

const DemoThankYou = ({
  brandColor,
  accent,
  hasPremium,
  businessName,
}: IDemoThankYouProps) => {
  const tinyAccentColor = tinycolor(accent);

  return (
    <section
      className="min-h-100dvh bg-center bg-cover bg-fixed bg-no-repeat md:min-h-screen"
      style={{
        backgroundImage:
          brandColor === "#3333FF" ? `url(${BgGradient})` : "unset",
        backgroundColor: brandColor === "#3333FF" ? "unset" : brandColor,
      }}
    >
      <div
        className="mx-auto flex h-screen items-center justify-center"
        style={{ width: "384px" }}
      >
        <PaymentInfo
          iconStyles={{
            backgroundColor: accent,
            color: tinyAccentColor.isDark() ? "#ffffff" : "#000000",
            border:
              accent == "#FFFFFF" || accent == "#FFF"
                ? "1px solid #9BA1B3"
                : "unset",
          }}
          title={"Payment complete!"}
          text={`You paid AED 550.00 to ${businessName}`}
          referenceInfo="Reference ID #3213451"
          hasCustomBranding={hasPremium}
        />
      </div>
    </section>
  );
};

export default DemoThankYou;
