export const extractFormDataFields = (formData) => {
  let payload = {};

  for (const pair of formData.entries()) {
    payload = {
      ...payload,
      [pair[0]]: pair[1],
    };
  }

  return payload;
};
