import axios from "axios";
import qs from "qs";
import ReactOnRails from "react-on-rails";

import { endpoints } from "constants/apiEndpoints";

type TUpdateRoles = {
  updateRoles: (
    identifier: string,
    requestData: any,
  ) => Promise<{ email: string }>;
};

export const useUpdateRoles = (): TUpdateRoles => {
  const updateRoles = async (identifier: string, requestData: any) => {
    try {
      const queryParams = {
        authenticity_token: ReactOnRails.authenticityToken(),
      };

      const url = endpoints.MANAGE.API.SETTINGS.TEAM.UPDATE_USER(identifier);
      const { data } = await axios.put(
        `${url}?${qs.stringify(queryParams)}`,
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );

      return {
        email: data.email,
      };
    } catch (error) {
      console.error(
        `[useUpdateRoles][updateRoles] Trouble updating roles: ${error}`,
      );
      throw error;
    }
  };

  return {
    updateRoles,
  };
};
