import type React from "react";

import type IRailsContext from "interfaces/IRailsContext";
import type IShareLinkCardProps from "interfaces/IShareLinkCardProps";
import { ShareLinkCardContainer } from "./index";

export const ShareLinkCardRoot: React.FC<IShareLinkCardProps> = (
  props: IShareLinkCardProps,
  railsContext: IRailsContext,
) => {
  return (
    <ShareLinkCardContainer linkCard={props} railsContext={railsContext} />
  );
};
