import { Box, CircularProgress, circularProgressClasses } from "@mui/material";
import { cn } from "utils/classNames";

import { colorsV2 } from "constants/colors";
import { hexToRgb } from "utils/hexToRgbUtils";

interface LoaderProps {
  color?: "blue" | "white";
  size?: number;
  variant?: "fullPage";
  className?: string;
}

export const Loader = ({
  color = "blue",
  size = 40,
  variant,
  className,
}: LoaderProps) => {
  const thickness = 4.6;

  return (
    <Box
      sx={{ position: "relative" }}
      className={cn(
        {
          "left-1/2 py-80": variant === "fullPage",
        },
        className,
      )}
    >
      <CircularProgress
        variant="determinate"
        sx={{
          color:
            color === "white"
              ? `rgba(${hexToRgb(colorsV2.surface[1])}, 0.15)`
              : `rgba(${hexToRgb(colorsV2.primary[1])}, 0.15)`,
        }}
        size={size}
        thickness={thickness}
        value={100}
      />
      <CircularProgress
        sx={{
          color: color === "white" ? colorsV2.surface[1] : colorsV2.primary[1],
          position: "absolute",
          left: 0,
          animationDuration: "4000ms",
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={size}
        thickness={thickness}
        variant="indeterminate"
      />
    </Box>
  );
};
