import { getCurrentLanguage } from "locales/helpers/lang-utils";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { cn } from "utils/classNames";

interface IProps {
  hasCustomBranding?: boolean;
  iconStyles?: object;
  referenceInfo?: string;
  createdAt?: string | undefined;
  text?: string;
  title?: string;
}

const PaymentInfo = ({
  hasCustomBranding,
  iconStyles,
  referenceInfo,
  createdAt,
  text,
  title,
}: IProps) => {
  const formatDateTime = (date: string, locale: string) => {
    // Set locale based on input ('en' for English, 'ar' for Arabic)
    moment.locale(locale);

    // Format the date and time based on the locale
    const formattedDateTime = moment(date).format(
      `MMMM DD, YYYY [${t("at")}] h:mm A`,
    );

    return formattedDateTime;
  };

  const { t } = useTranslation();
  const isArabic = getCurrentLanguage() === "ar";
  const formattedDateTime = formatDateTime(createdAt, getCurrentLanguage());
  return (
    <div
      className={cn(
        "flex flex-col items-center gap-24 rounded-24 bg-white px-24 py-40 shadow-md md:rounded-32",
        isArabic ? "rtl" : "ltr",
      )}
    >
      <span
        className="material-icons-round flex h-56 w-56 justify-center rounded-full bg-green-300 p-16 align-center text-24"
        style={iconStyles}
      >
        {t("check")}
      </span>

      <div className="space-y-12 text-center">
        <h2 data-testid="payment-info-title">{title}</h2>
        <p className="text-16 text-black-300">{text}</p>
      </div>

      <div
        className={cn("w-full rounded-12 p-12 text-center text-16", {
          "border border-black-50 border-solid text-black-300":
            hasCustomBranding,
          "bg-blue-25 text-blue-500": !hasCustomBranding,
        })}
      >
        <p>{referenceInfo}</p>
      </div>

      <p className="text-14 text-black-300">{formattedDateTime}</p>
    </div>
  );
};

export default PaymentInfo;
