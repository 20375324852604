import {
  CheckCircle,
  ErrorOutline,
  Pending,
  PublishedWithChanges,
  RotateLeft,
} from "design_system/Icons";

export const statusMappings = {
  // CARD TRANSACTION MAPPINGS
  completed_icon: {
    Icon: CheckCircle,
    color: "green",
  },
  declined_icon: {
    Icon: ErrorOutline,
    color: "red",
  },
  reversed_icon: {
    Icon: Pending,
    color: "red",
  },
  refunded_icon: {
    Icon: Pending,
    color: "gray",
  },
  // EXPENSE MAPPINGS
  incomplete_icon: {
    Icon: ErrorOutline,
    color: "yellow",
  },
  pending_review_icon: {
    Icon: Pending,
    color: "blue",
  },
  ready_icon: {
    Icon: CheckCircle,
    color: "green",
  },
  synced_icon: {
    Icon: PublishedWithChanges,
    color: "pink",
  },
  // PAYMENT MAPPINGS
  failed_icon: {
    Icon: ErrorOutline,
    color: "red",
  },
  partial_refund_icon: {
    Icon: RotateLeft,
    color: "gray",
  },
  refund_pending_icon: {
    Icon: Pending,
    color: "blue",
  },
  successful_icon: {
    Icon: CheckCircle,
    color: "green",
  },
  // SETTLEMENT MAPPINGS
  pending_icon: {
    Icon: Pending,
    color: "blue",
  },
  held_by_bank: {
    Icon: Pending,
    color: "blue",
  },
  settled: {
    Icon: Pending,
    color: "blue",
  },
  rejected_by_bank: {
    Icon: Pending,
    color: "blue",
  },
};
