import InputAdornment from "@mui/material/InputAdornment";
import { useEffect, useState } from "react";
import { useController } from "react-hook-form";
import { useDispatch } from "react-redux";

import { Button } from "design_system/Button";
import { IconButton } from "design_system/IconButton";
import { Visibility, VisibilityOff } from "design_system/Icons";
import { InputMessage } from "design_system/Inputs/InputMessage";
import { TextInput } from "design_system/Inputs/TextInput";

import { useAppSelector } from "store";
import { useRedirectToSignUp } from "../shared/useRedirectToSignUp";
import { useCreatePassword } from "./hooks/useCreatePassword";
import { useCreatePasswordForm } from "./hooks/useCreatePasswordForm";

import {
  updateActiveStep,
  updateData,
  updateStepAnimationDirection,
} from "reducers/redux/Access/SignUp/signUpSlice";
import type { CreatePasswordFormProps } from "../shared/typeDefs";

export const CreatePassword = () => {
  const { activeSignUpStep, data } = useAppSelector((state) => state.signUp);
  const { email } = data;
  const {
    control,
    errors,
    getValues,
    trigger,
    watch,
    isPasswordMatchingRequirements,
  } = useCreatePasswordForm();
  const { createPassword } = useCreatePassword();
  const { redirectToSignUp } = useRedirectToSignUp();
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState({
    minLength: false,
    specialCharacter: false,
    number: false,
    lowercase: false,
    uppercase: false,
  });

  const handleValidatePassword = (e) => {
    const value = e.target.value;
    const minLength = value.length >= 8;
    const specialCharacter = /[#?!@$%^&*-]/.test(value);
    const number = /[0-9]/.test(value);
    const lowercase = /[a-z]/.test(value);
    const uppercase = /[A-Z]/.test(value);

    setPasswordValidation({
      minLength,
      specialCharacter,
      number,
      lowercase,
      uppercase,
    });
  };

  const handleFormFieldBlur = (fieldName: keyof CreatePasswordFormProps) => {
    return () => {
      trigger(fieldName).then(() => {
        const isValid = !errors[fieldName];
        if (isValid) {
          dispatch(
            updateData({
              data: {
                ...getValues(),
              },
            }),
          );
        }
      });
    };
  };

  const handleCreatePassword = () => {
    createPassword.mutateAsync(watch().password);
    dispatch(updateActiveStep({ activeSignUpStep: activeSignUpStep + 1 }));
    dispatch(updateStepAnimationDirection({ stepAnimationDirection: "right" }));
  };

  useEffect(() => {
    redirectToSignUp(email);
  }, []);

  return (
    <div className="flex flex-col gap-32">
      <div className="space-y-8 text-center">
        <h1 className="header-large md:display-medium">Create a password</h1>
        <p className="body-medium md:body-large">
          Choose a unique password to keep your account safe.
        </p>
      </div>

      <div className="flex flex-col gap-16">
        <TextInput
          {...useController({ name: "password", control }).field}
          type={showPassword ? "text" : "password"}
          label="Password"
          className="w-full"
          error={!!errors.password}
          helperText={errors.password?.message}
          onBlur={handleFormFieldBlur("password")}
          onKeyUp={handleValidatePassword}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {showPassword ? (
                  <IconButton
                    size="sm"
                    Icon={Visibility}
                    onClick={() => setShowPassword(!showPassword)}
                  />
                ) : (
                  <IconButton
                    size="sm"
                    Icon={VisibilityOff}
                    onClick={() => setShowPassword(!showPassword)}
                  />
                )}
              </InputAdornment>
            ),
          }}
        />
        <div className="flex flex-wrap gap-8">
          <InputMessage
            label="Minimum 8 characters"
            active={passwordValidation.minLength}
          />
          <InputMessage
            label="One lowercase letter"
            active={passwordValidation.lowercase}
          />
          <InputMessage label="One number" active={passwordValidation.number} />
          <InputMessage
            label="One special character"
            active={passwordValidation.specialCharacter}
          />
          <InputMessage
            label="One uppercase letter"
            active={passwordValidation.uppercase}
          />
        </div>
      </div>

      <Button
        classes="w-full"
        disabled={!isPasswordMatchingRequirements}
        label="Continue"
        loading={createPassword.isLoading}
        size="lg"
        variant="filled"
        onClick={
          isPasswordMatchingRequirements ? handleCreatePassword : undefined
        }
      />
    </div>
  );
};
