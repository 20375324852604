import { cn } from "utils/classNames";

export type ModalProps = {
  show?: any;
  close?: any;
  back?: () => void;
  children?: any;
  fullOnMobile?: boolean;
  classes?: string;
  testId?: string;
};

export const Modal = ({
  show,
  close,
  back,
  children,
  fullOnMobile,
  classes,
  testId,
}: ModalProps) => {
  return (
    <>
      {show && (
        <div
          data-testid={testId}
          className={`modal ${fullOnMobile && "modal--full"}`}
        >
          <div
            className={cn(
              "modal__box",
              {
                "modal__box--full": fullOnMobile,
              },
              classes,
            )}
          >
            {back && (
              <i
                className="modal__box__back material-icons-round"
                onClick={back}
              >
                arrow_back
              </i>
            )}

            <i
              className="modal__box__close material-icons-round"
              onClick={close}
            >
              close
            </i>
            <div className="modal__box__content">{children}</div>
          </div>
          <div className="modal__overlay" onClick={close}></div>
        </div>
      )}
    </>
  );
};

export default Modal;
