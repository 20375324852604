import Card from "components/ui/Card";
import { convertToLocaleStringHelper } from "helpers/currency";
import type { ICapacityProps } from "interfaces/ICapacityProps";
import type { FunctionComponent } from "react";

const Capacity: FunctionComponent<ICapacityProps> = (props: ICapacityProps) => {
  const {
    paymentAmount,
    totalPaidAmount,
    capacity,
    totalPaidCount,
    amountCurrency,
    processingFeePercentage,
    processingFeeAmount,
  } = props;

  const totalPaymentAmount = () => {
    if (processingFeePercentage && paymentAmount) {
      const totalAmount =
        (processingFeePercentage * paymentAmount) / 100 + paymentAmount;
      return totalAmount;
    }

    if (processingFeeAmount && paymentAmount) {
      const totalAmount = processingFeeAmount + paymentAmount;
      return totalAmount;
    }

    return paymentAmount as number;
  };

  const handleFormatPercentage = () => {
    if (processingFeePercentage! % 1 === 0) {
      return Math.floor(processingFeePercentage!);
    }

    return processingFeePercentage;
  };

  const feeAmount = () => {
    if (processingFeePercentage && paymentAmount) {
      const amount = (processingFeePercentage * paymentAmount) / 100;
      return convertToLocaleStringHelper(amount);
    }

    return null;
  };

  return (
    <>
      {/* CAPACITY SECTION */}
      <div className="payment-link-details__general">
        {/* PAYMENT AMOUNT */}
        {paymentAmount && (
          <Card hasBorder classes="space-y-12">
            <p className="align-stretch font-bold text-14">Payment amount</p>
            <h1>
              <span data-testid="payment-link-details-payment-amount">
                {convertToLocaleStringHelper(totalPaymentAmount())}
              </span>{" "}
              <span
                data-testid="payment-link-details-currency"
                className="text-14 text-black-300"
              >
                {amountCurrency}
              </span>
            </h1>
            {processingFeePercentage && (
              <>
                <p className="text-12 text-black-300">
                  Amount: {amountCurrency}{" "}
                  {convertToLocaleStringHelper(paymentAmount)}
                </p>
                <p className="text-12 text-black-300">
                  Processing fee ({handleFormatPercentage()}%): {amountCurrency}{" "}
                  {feeAmount()}
                </p>
              </>
            )}
            {processingFeeAmount && (
              <>
                <p className="text-12 text-black-300">
                  Amount: {amountCurrency}{" "}
                  {convertToLocaleStringHelper(paymentAmount)}
                </p>
                <p className="text-12 text-black-300">
                  Processing fee: {amountCurrency}{" "}
                  {convertToLocaleStringHelper(processingFeeAmount)}
                </p>
              </>
            )}
          </Card>
        )}

        {/* SETTLEMENT AMOUNT */}
        <Card hasBorder classes="space-y-12">
          <p className="align-stretch font-bold text-14">Total paid</p>
          <h1>
            {totalPaidAmount > 0 ? (
              <>
                {convertToLocaleStringHelper(totalPaidAmount)}{" "}
                <span>{amountCurrency}</span>
              </>
            ) : (
              "-"
            )}
          </h1>
        </Card>

        {/* PAYMENT DATE */}
        <Card hasBorder classes="space-y-12">
          <p className="align-stretch font-bold text-14">
            {capacity != null ? "Payments quantity" : "Number of payments"}
          </p>
          <h1>
            {capacity != null ? (
              <>
                {" "}
                {totalPaidCount}/{capacity}
              </>
            ) : (
              totalPaidCount
            )}
          </h1>
        </Card>
      </div>
    </>
  );
};

export default Capacity;
