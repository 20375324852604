import { colorsV2 } from "constants/colors";
import type { SvgIconProps } from "types/Icon";

export const Code = ({
  size,
  fill = colorsV2.text[1],
  ...rest
}: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g id="Name=code, Size=24">
        <path
          id="Vector"
          d="M4.23366 11.944L8.56191 16.2723C8.73185 16.4462 8.81165 16.6461 8.80131 16.872C8.791 17.0979 8.69988 17.2978 8.52796 17.4718C8.35603 17.6456 8.15295 17.7316 7.91871 17.7296C7.68448 17.7276 7.4824 17.6417 7.31246 17.4718L2.38746 12.5527C2.29651 12.4608 2.2303 12.3651 2.18881 12.2657C2.14733 12.1663 2.12659 12.0608 2.12659 11.9493C2.12659 11.8378 2.14734 11.7325 2.18884 11.6335C2.2303 11.5344 2.29651 11.439 2.38746 11.3473L7.38746 6.34727C7.55898 6.17736 7.76245 6.0924 7.99789 6.0924C8.23332 6.0924 8.436 6.17736 8.60594 6.34727C8.77587 6.51721 8.86084 6.72089 8.86084 6.95833C8.86084 7.19574 8.77587 7.40022 8.60594 7.57175L4.23366 11.944ZM19.7722 11.956L15.444 7.62773C15.2701 7.45381 15.1883 7.2539 15.1986 7.028C15.2089 6.80208 15.3 6.60217 15.472 6.42825C15.6439 6.25435 15.847 6.16839 16.0812 6.17038C16.3154 6.17238 16.5175 6.25833 16.6875 6.42825L21.6125 11.3473C21.7034 11.4392 21.7696 11.5349 21.8111 11.6343C21.8526 11.7337 21.8733 11.8392 21.8733 11.9507C21.8733 12.0622 21.8526 12.1675 21.8111 12.2665C21.7696 12.3656 21.7034 12.461 21.6125 12.5527L16.6125 17.5527C16.4422 17.7226 16.2432 17.8044 16.0155 17.7981C15.7877 17.7918 15.5889 17.7016 15.419 17.5277C15.2451 17.3573 15.1581 17.154 15.1581 16.9181C15.1581 16.6821 15.2451 16.4792 15.419 16.3092L19.7722 11.956Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};
