import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

import type { DashboardNavigationId } from "design_system/Layout/DashboardNavigation/core/DashboardNavigation.types";

export interface PermissionsState {
  access: DashboardNavigationId[];
}

const initialState: PermissionsState = {
  access: [],
};

export const permissionsSlice = createSlice({
  name: "permissions",
  initialState,
  reducers: {
    setPermissions(state, action: PayloadAction<DashboardNavigationId>) {
      if (!state.access.includes(action.payload)) {
        state.access.push(action.payload);
      }
    },
  },
});

export const { setPermissions } = permissionsSlice.actions;

export default permissionsSlice.reducer;
