import isEmpty from "lodash/isEmpty";
import { useEffect, useRef } from "react";
import { useController } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { cn } from "utils/classNames";

import { Button } from "design_system/Button";
import { TextInput } from "design_system/Inputs/TextInput";

import { useResendOtp } from "features/shared/hooks/useResendOtp";
import { useOtp } from "hooks/useOtp";
import { useAppSelector } from "store";
import { useRedirectToSignUp } from "../shared/useRedirectToSignUp";
import { useVerifyEmailOtpForm } from "./hooks/useVerifyEmailForm";
import { useVerifyEmailOtp } from "./hooks/useVerifyEmailOtp";

import { preventAlphaKeyDown, preventAlphaOnPaste } from "helpers/numberUtils";
import { updateErrorMessage } from "reducers/redux/Access/SignUp/signUpSlice";

export const VerifyEmail = () => {
  const { data, errorMessage } = useAppSelector((state) => state.signUp);
  const { email } = data;
  const { resendOtp } = useResendOtp();
  const { verifyEmailOtp } = useVerifyEmailOtp();
  const { buttonText, resetTimer, isDisabled } = useOtp();
  const { control, errors, watch } = useVerifyEmailOtpForm();
  const { redirectToSignUp } = useRedirectToSignUp();
  const dispatch = useDispatch();
  const otpInputRef = useRef(null);

  const handleResendCode = async () => {
    await resendOtp.mutateAsync({ email });
    resetTimer();
  };

  const isContinueButtonDisabled = () => {
    return !!errors.otp || isEmpty(watch().otp);
  };

  const handleVerifyEmail = () => {
    verifyEmailOtp.mutateAsync(watch().otp);
  };

  useEffect(() => {
    redirectToSignUp(email);
    dispatch(updateErrorMessage({ errorMessage: { otp: null } }));

    if (otpInputRef.current) {
      otpInputRef.current.focus();
    }
  }, []);

  return (
    <div className="flex flex-col gap-32">
      <div className="space-y-8 text-center">
        <h1 className="header-large md:display-medium">
          Verify your email address
        </h1>
        <p className="body-medium md:body-large">
          Enter the code we sent to {email}
        </p>
      </div>

      <TextInput
        {...useController({ name: "otp", control }).field}
        ref={otpInputRef}
        type="number"
        label="Your 6-digit code"
        className="w-full"
        onKeyDown={preventAlphaKeyDown}
        onPaste={preventAlphaOnPaste}
        error={!!errors.otp || !isEmpty(errorMessage?.otp)}
        helperText={errors.otp?.message || errorMessage?.otp}
      />

      <Button
        variant="filled"
        size="lg"
        label="Continue"
        classes="w-full"
        onClick={verifyEmailOtp.isLoading ? () => {} : handleVerifyEmail}
        loading={verifyEmailOtp.isLoading}
        disabled={isContinueButtonDisabled()}
      />

      <span className="body-large text-center">
        Didn't get the code?{" "}
        <Link
          to="#"
          className={cn("label-large", {
            "text-primary-1": !isDisabled,
            "pointer-events-none text-text-color-disabled": isDisabled,
          })}
          onClick={handleResendCode}
        >
          {buttonText}
        </Link>
      </span>
    </div>
  );
};
