import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

interface InitState {
  otp: string;
  vccToken: string;
  currentView: "OTP_SCREEN" | "DETAIL_SCREEN" | "EMPTY_DETAIL_SCREEN";
  businessFlow: "WITH_OTP" | "WITHOUT_OTP";
  enableFetchCardDetails: boolean;
}

const initialState: InitState = {
  otp: "",
  vccToken: "",
  currentView: "OTP_SCREEN",
  businessFlow: "WITH_OTP",
  enableFetchCardDetails: false,
};

export const cardVccSlice = createSlice({
  name: "globalBanner",
  initialState,
  reducers: {
    clearOTP(state) {
      state.otp = "";
      state.enableFetchCardDetails = false;
    },
    navigateToOTPScreen(state) {
      state.currentView = "OTP_SCREEN";
    },
    navigateToDetailScreen(state) {
      state.currentView = "DETAIL_SCREEN";
    },
    navigateToEmptyDetailScreen(state) {
      state.currentView = "EMPTY_DETAIL_SCREEN";
    },
    setBusinessFlow(state, action: PayloadAction<InitState["businessFlow"]>) {
      state.businessFlow = action.payload;
      if (action.payload === "WITHOUT_OTP") {
        state.enableFetchCardDetails = true;
      }
    },
    setOTP(state, action: PayloadAction<InitState["otp"]>) {
      state.otp = action.payload;
    },
    setVccToken(state, action: PayloadAction<InitState["vccToken"]>) {
      state.vccToken = action.payload;
    },
    submitOTP(state) {
      state.enableFetchCardDetails = true;
    },
  },
});

export const {
  clearOTP,
  navigateToDetailScreen,
  navigateToEmptyDetailScreen,
  navigateToOTPScreen,
  setBusinessFlow,
  setOTP,
  setVccToken,
  submitOTP,
} = cardVccSlice.actions;

export default cardVccSlice.reducer;
