import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import toast from "react-hot-toast";

import { endpoints } from "constants/apiEndpoints";
import { generalErrors } from "constants/errorMessages";
import { SentryLoggingService } from "init/SentryLoggingService";
// import { SINGLE_WALLET_AND_CARD } from "../mocks/getWalletsAndCards";
import type {
  WalletsAndCardsApiResponse,
  WalletsAndCardsData,
  WalletsAndCardsDataApiResponse,
} from "../typeDefs";

function transformWalletsAndCardsData(data: WalletsAndCardsData) {
  return {
    wallets: data.wallets,
    cashback_wallet: data.cashback_wallet,
    cards: data.cards.map((card) => {
      if ("creditLimitFormatted" in card) {
        return {
          type: "credit",
          ...card,
        };
      }
      return {
        type: "debit",
        ...card,
      };
    }),
  };
}

export const useGetWalletsAndCards = () => {
  const getWalletsAndCards: WalletsAndCardsApiResponse = useQuery({
    queryKey: ["getWalletsAndCards"],
    queryFn: async () => {
      try {
        const {
          data: { data },
        } = await axios.get<WalletsAndCardsDataApiResponse>(
          endpoints.MANAGE.API.WALLETS.INDEX,
        );
        // const data = SINGLE_WALLET_AND_CARD;
        return transformWalletsAndCardsData(data as any);
      } catch (error: any) {
        toast.error(generalErrors.SOMETHING_WENT_WRONG, {
          duration: 3000,
        });
        SentryLoggingService.captureException(error.message, {
          feature: "[Wallets V2]",
          file: "useGetWalletsAndCards.tsx",
          function: "useGetWalletsAndCards",
        });
        return undefined;
      }
    },
  });

  return {
    getWalletsAndCards,
  };
};
