import { Provider } from "react-redux";

import { ReactQueryProvider } from "components/Providers";
import { AccountingDashboardRoot } from "./AccountingDashboardRoot";

import { store } from "store";

export const AccountingRoot = (props, railsContext) => {
  return () => (
    <ReactQueryProvider>
      <Provider store={store}>
        <AccountingDashboardRoot railsContext={railsContext} />
      </Provider>
    </ReactQueryProvider>
  );
};
