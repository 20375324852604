import last from "lodash/last";
import remove from "lodash/remove";
import { useMemo, useState } from "react";

import { MultipleTextInputV2 } from "design_system/Inputs/MultipleTextInput/MultipleTextInputV2";

import { SentryLoggingService } from "init/SentryLoggingService";

export const CategoriesInputField = ({
  categories,
  invalidCategoriesCount,
  setCategories,
  validateExpenseCategory,
}) => {
  const [inputValue, setInputValue] = useState("");

  const errorMessage = useMemo(() => {
    switch (true) {
      case invalidCategoriesCount === 1:
        return "Category already exists";
      case invalidCategoriesCount > 1:
        return "Categories already exist";
      default:
        return "";
    }
  }, [categories]);

  /**
   * This method:
   * 1. Saves the most recent tag entered for validation
   * 2. Makes an optimistic update to the tag list to avoid lag on UI
   */
  const handleSetCategories = async (list) => {
    // Optimistic Update
    setCategories(list);

    const newCategory: any = last(list);

    let isValid = true;
    try {
      const response = await validateExpenseCategory.mutateAsync(
        newCategory.name,
      );
      if (response.isValid === false) {
        isValid = false;
      }
    } catch (error: any) {
      SentryLoggingService.captureException(error, {
        feature: "[Settings][Expenses]",
        file: "CategoriesInputField.tsx",
        function: "handleSetCategories",
        data: JSON.stringify({ newCategory }),
      });
    }

    if (!isValid) {
      // Avoid mutation on react state
      const tagsWithOptimisticUpdate = [...categories];

      // Recover data without optimistic update
      const tagsWithoutOptimisticUpdate = remove(
        tagsWithOptimisticUpdate,
        (tag: any) => tag.name !== newCategory.name,
      );

      // Update the record that was the optimistic update
      const invalidTag = {
        ...newCategory,
        isValid: false,
      };

      // Update tags with new validation state
      setCategories([...tagsWithoutOptimisticUpdate, invalidTag]);
    }
  };

  return (
    <MultipleTextInputV2
      errorMessage={errorMessage}
      inputValue={inputValue}
      tags={categories}
      textInputProps={{
        placeholder: "Category, comma seperated",
      }}
      onDeleteTag={(filteredCategories) => setCategories(filteredCategories)}
      setTags={handleSetCategories}
      setInputValue={setInputValue}
    />
  );
};
