import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { endpoints } from "constants/apiEndpoints";
import { stringifiedQueryParams } from "constants/queryParams";
import { snack } from "design_system/Snackbar";
import { SentryLoggingService } from "init/SentryLoggingService";

export const useResendInviteMail = () => {
  const resendInviteMail = useMutation({
    mutationKey: ["resendInviteMail"],
    mutationFn: async (identifier: string) => {
      return await axios
        .patch(
          `${endpoints.MANAGE.API.SETTINGS.TEAM.RESEND_INVITE_MAIL(identifier)}?${stringifiedQueryParams}`,
        )
        .then((res) => res.data);
    },
    onError: (error: Error) => {
      SentryLoggingService.captureException(error.message, {
        feature: "[Team][ResendInviteMail]",
        function: "resendInviteMail",
        file: "useResendInviteMail",
        data: JSON.stringify({ error: error.message }),
      });
      snack({
        title: "Oops! Something went wrong. Try again later",
        leadingElement: "icon",
        variant: "critical",
      });
    },
  });

  return { resendInviteMail };
};
