import { type UseMutationResult, useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import ReactOnRails from "react-on-rails";

import { useAppSelector } from "store";

import { axiosClient } from "config/axios";
import { queryClient } from "config/reactQueryClient";
import { endpoints } from "constants/apiEndpoints";
import { generalErrors } from "constants/errorMessages";
import { SentryLoggingService } from "init/SentryLoggingService";
import type { VendorsWithPagination } from "types/Vendor";

type BulkUpdateVendorsPayload = {
  identifiers: string[];
  enabled: boolean;
};

export const useBulkUpdateVendors = (): {
  bulkUpdateVendors: UseMutationResult<
    void,
    unknown,
    BulkUpdateVendorsPayload,
    unknown
  >;
} => {
  /* ---------------------------------
   *  Cache
   * ---------------------------------
   */
  const { table } = useAppSelector((state) => state);

  /* ---------------------------------
   *  Cache
   * ---------------------------------
   */
  const page = table.vendors.page;
  const queryKey = ["getVendors", { page }];

  /* ---------------------------------
   *  API CALL
   * ---------------------------------
   */
  const bulkUpdateVendors = useMutation<
    void,
    unknown,
    BulkUpdateVendorsPayload
  >({
    mutationKey: ["bulkUpdateVendors"],
    mutationFn: async (payload: BulkUpdateVendorsPayload) => {
      const url =
        endpoints.MANAGE.API.ACCOUNTING_INTEGRATIONS.BULK_UPDATE_VENDORS;

      await axiosClient.patch(url, {
        identifiers: payload.identifiers,
        enabled: payload.enabled,
        authenticity_token: ReactOnRails.authenticityToken(),
      });
    },
    onMutate: async (payload: BulkUpdateVendorsPayload) => {
      await queryClient.cancelQueries({ queryKey });

      const previousData = queryClient.getQueryData(queryKey);

      queryClient.setQueryData(queryKey, (old: VendorsWithPagination) => {
        return {
          data: old?.data?.map((account) =>
            payload.identifiers.includes(account.identifier)
              ? { ...account, enabled: payload.enabled }
              : account,
          ),
          paginationMeta: old?.paginationMeta,
        };
      });

      return { previousData };
    },
    onError: (
      error: any,
      _,
      context: { previousData: VendorsWithPagination },
    ) => {
      if (context?.previousData) {
        queryClient.setQueryData(queryKey, context.previousData);
      }
      SentryLoggingService.captureException(error.message, {
        feature: "[Accounting][AccountingDashboard]",
        file: "useBulkUpdateVendors.ts",
        function: "bulkUpdateVendors",
      });
      toast.error(generalErrors.SOMETHING_WENT_WRONG, {
        duration: 3000,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  return {
    bulkUpdateVendors,
  };
};
