import axios from "axios";
import { useState } from "react";

import type { IPaymentLink } from "components/PaymentLinks/Details";
import routes from "constants/routes";
import { posLinkIdentifier } from "../utils/posLinkIdentifier";

export const useGetPOSLinkDetails = () => {
  const [linkDetails, setLinkDetails] = useState<IPaymentLink>();
  const [loading, setLoading] = useState<boolean>(false);

  const getPOSLinkDetails = async (identifier: string | undefined) => {
    setLoading(true);

    try {
      const url = `${routes.MANAGE.LINKS.SHOW(posLinkIdentifier(identifier))}.json`;
      const { data } = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setLinkDetails(data);
    } catch (error) {
      console.error(
        `[useGetPOSLinkDetails][getPOSLinkDetails] Trouble getting link info: ${error}`,
      );
    } finally {
      setLoading(false);
    }
  };

  return {
    getPOSLinkDetails,
    linkDetails,
    loading,
  };
};
