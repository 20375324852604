import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useReducer, useState } from "react";

import newDisbursementsReducer, {
  initialState,
  Steps,
} from "reducers/newDisbursementsReducer";
import BulkUpload from "./BulkUpload";
import ConfirmDisbursement from "./ConfirmDisbursement";
import DisbursementType from "./DisbursementType";
import ManualUpload from "./ManualUpload";
import ProcessingFile from "./ProcessingFile";
import { VerificationCode } from "./VerificationCode";

const CreateDisbursement = ({ isEnabledBulkInternationalPayout }: any) => {
  const [state, dispatch] = useReducer(newDisbursementsReducer, initialState);
  const [currentStep, setCurrentStep] = useState<number>(1);
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <main className="create-link">
        {currentStep == Steps.DisbursementType && (
          <DisbursementType setCurrentStep={setCurrentStep} />
        )}
        {currentStep == Steps.BulkUpload && (
          <BulkUpload
            dispatch={dispatch}
            setCurrentStep={setCurrentStep}
            onPrevStep={() => setCurrentStep(Steps.DisbursementType)}
            isEnabledBulkInternationalPayout={isEnabledBulkInternationalPayout}
          />
        )}
        {currentStep == Steps.ManualUpload && (
          <ManualUpload
            state={state}
            dispatch={dispatch}
            setCurrentStep={setCurrentStep}
            onPrevStep={() => setCurrentStep(Steps.DisbursementType)}
          />
        )}
        {currentStep == Steps.ConfirmDisbursement && (
          <ConfirmDisbursement
            state={state}
            setCurrentStep={setCurrentStep}
            onPrevStep={() => setCurrentStep(Steps.ManualUpload)}
          />
        )}
        {currentStep == Steps.VerificationCode && (
          <VerificationCode state={state} setCurrentStep={setCurrentStep} />
        )}
        {currentStep == Steps.ProcessingFile && <ProcessingFile />}
      </main>
    </QueryClientProvider>
  );
};

export default CreateDisbursement;
