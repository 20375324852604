import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import axios from "axios";
import { isEmpty, reverse } from "lodash";
import moment from "moment";
import { useEffect, useReducer, useState } from "react";

import LoaderBusiness from "components/Loader/LoaderBusiness";
import { Help } from "design_system/Icons";
import { List } from "design_system/List";
import { BarChart } from "design_system/Statistics/Charts/BarChart";
import { ChartCard } from "design_system/Statistics/Charts/ChartCard";

import { colorsV2 } from "constants/colors";
import routes from "constants/routes";
import { capitalizeFirstLetter } from "helpers/capitalizeFirstLetter";
import { convertToLocaleStringHelper, formatAmount } from "helpers/currency";
import { shrinkString } from "helpers/stringUtils";
import { useScreenDimension } from "hooks/useScreenDimension";
import analyticsReducer, {
  AnalyticsActionTypes,
  initialState,
} from "../reducers/analyticsReducer";
import { isChartEmptyData } from "../shared/chartUtils";
import type {
  TAnalyticsFrequency,
  TDropdownFrequency,
} from "../shared/typeDefs";
import { payoutsParams, totalSpendParams } from "./constant";
import { handleSpendByCardChartHeight } from "./utils";

export const SpendInsights = () => {
  const [totalSpendValue, setTotalSpendValue] =
    useState<TDropdownFrequency>("weekly");
  const [payoutsValue, setPayoutsValue] =
    useState<TDropdownFrequency>("weekly");
  const [state, dispatch] = useReducer(analyticsReducer, initialState);
  const { analytics, isLoading } = state;
  const { isMobile } = useScreenDimension();

  const fetchAnalyticsData = (
    analyticsKind: string,
    frequencyType: TAnalyticsFrequency,
  ) => {
    axios
      .get(routes.MANAGE.ANALYTICS.INDEX(), {
        headers: { accept: "application/json" },
        params: {
          kind: [analyticsKind],
          perPage: 12,
          frequency_type: [frequencyType],
        },
      })
      .then((res) => {
        dispatch({
          type: AnalyticsActionTypes.SET_DATA,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchAnalyticsKind = (
    analyticsKind: string,
    frequencyType: TAnalyticsFrequency,
    /** Setting reversed to true will have show the last data first and first data last. This would be needed for BarCharts as the order of oldest data will come first and newest data last. In Donut chart, this would be different */
    reversed: boolean,
  ) => {
    const data = analytics
      .filter((item: any) => item.kind.indexOf(analyticsKind) !== -1)
      .filter((item: any) => item.frequencyType.indexOf(frequencyType) !== -1);

    return reversed ? reverse(data) : data;
  };

  const handleDownloadAnalyticsCsv = (kind: string, frequencyType: string) => {
    window.location.href = `${routes.MANAGE.ANALYTICS.DOWNLOAD_CSV(kind, frequencyType)}`;
  };

  const handleWeeklyDateFormat = (valueDatum) => {
    const { week, year } = valueDatum;

    // Set the first day of the year to ensure correct week calculation
    const firstDayOfYear = moment().year(year).startOf("year");

    const startOfWeek = firstDayOfYear
      .clone()
      .add(week, "weeks")
      .startOf("isoWeek");
    const endOfWeek = startOfWeek.clone().endOf("isoWeek");

    const formattedStartDate = startOfWeek.format("MMM D");
    const formattedEndDate = endOfWeek.format("MMM D");

    return `${formattedStartDate} - ${formattedEndDate}, ${year}`;
  };

  const handleTotalSpendTooltipSubtext = (valueDatum: any) => {
    const { month, year } = valueDatum;

    if (totalSpendValue === "weekly") {
      return handleWeeklyDateFormat(valueDatum);
    }

    return `${moment(month, "M").format("MMM")} ${year}`;
  };

  const handlePayoutsTooltipSubtext = (valueDatum: any) => {
    const { month, year } = valueDatum;

    if (payoutsValue === "weekly") {
      return handleWeeklyDateFormat(valueDatum);
    }

    return `${moment(month, "M").format("MMM")} ${year}`;
  };

  const handleSpendByCardTooltipSubtext = (valueDatum: any) => {
    const { value } = valueDatum;

    return `AED ${convertToLocaleStringHelper(value)}`;
  };

  const formatCreatedAtDate = (createdAt: string) => {
    return moment(createdAt).format("MMM D");
  };

  const weeklyTotalSpendData = fetchAnalyticsKind(
    "card_total_spend",
    "weekly",
    true,
  );
  const monthlyTotalSpendData = fetchAnalyticsKind(
    "card_total_spend",
    "monthly",
    true,
  );
  const spendByCardData = fetchAnalyticsKind(
    "spend_by_card",
    "last_30_days",
    true,
  );
  const spendByCardValuesData = spendByCardData?.[0]?.values;
  const weeklyPayoutsData = fetchAnalyticsKind("payout", "weekly", true);
  const monthlyPayoutsData = fetchAnalyticsKind("payout", "monthly", true);
  const topVendorsData = fetchAnalyticsKind(
    "top_merchants",
    "last_30_days",
    false,
  );

  const selectedTotalSpendData = () => {
    if (totalSpendValue === "monthly") {
      return monthlyTotalSpendData;
    }

    return weeklyTotalSpendData;
  };

  const selectedPayoutsData = () => {
    if (payoutsValue === "monthly") {
      return monthlyPayoutsData;
    }

    return weeklyPayoutsData;
  };

  const TotalSpendDropdown = () => {
    const handleValueChange = (value) => {
      setTotalSpendValue(value);
    };

    return (
      <DropdownMenu.Root>
        <DropdownMenu.Trigger className="dropdown-menu__trigger flex h-40 w-full items-center gap-8 rounded-full border border-black-50 bg-white px-16 py-8 hover:border-black-200 focus:border-black-200 focus:outline-none">
          <span className="font-medium text-14">
            {capitalizeFirstLetter(totalSpendValue)}
          </span>
          <span className="dropdown-menu__icon material-icons-round text-18 text-black-200">
            keyboard_arrow_down
          </span>
        </DropdownMenu.Trigger>

        <DropdownMenu.Content className="dropdown-menu__content mt-4 rounded-16 bg-white p-8 shadow-lg">
          <DropdownMenu.RadioGroup
            value={totalSpendValue}
            onValueChange={handleValueChange}
          >
            {["monthly", "weekly"].map((option) => (
              <DropdownMenu.RadioItem
                key={option}
                className="flex cursor-pointer flex-row items-center justify-between gap-12 rounded-8 p-8 hover:bg-black-25 focus:outline-none"
                value={option}
              >
                <span className="font-medium text-14">
                  {capitalizeFirstLetter(option)}
                </span>
                <DropdownMenu.ItemIndicator className="material-icons-round text-18 text-green-400">
                  {totalSpendValue === option ? "check" : ""}
                </DropdownMenu.ItemIndicator>
              </DropdownMenu.RadioItem>
            ))}
          </DropdownMenu.RadioGroup>
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    );
  };

  const PayoutsDropdown = () => {
    const handleValueChange = (value) => {
      setPayoutsValue(value);
    };

    return (
      <DropdownMenu.Root>
        <DropdownMenu.Trigger className="dropdown-menu__trigger flex h-40 w-full items-center gap-8 rounded-full border border-black-50 bg-white px-16 py-8 hover:border-black-200 focus:border-black-200 focus:outline-none">
          <span className="font-medium text-14">
            {capitalizeFirstLetter(payoutsValue)}
          </span>
          <span className="dropdown-menu__icon material-icons-round text-18 text-black-200">
            keyboard_arrow_down
          </span>
        </DropdownMenu.Trigger>

        <DropdownMenu.Content className="dropdown-menu__content mt-4 rounded-16 bg-white p-8 shadow-lg">
          <DropdownMenu.RadioGroup
            value={payoutsValue}
            onValueChange={handleValueChange}
          >
            {["monthly", "weekly"].map((option) => (
              <DropdownMenu.RadioItem
                key={option}
                className="flex cursor-pointer flex-row items-center justify-between gap-12 rounded-8 p-8 hover:bg-black-25 focus:outline-none"
                value={option}
              >
                <span className="font-medium text-14">
                  {capitalizeFirstLetter(option)}
                </span>
                <DropdownMenu.ItemIndicator className="material-icons-round text-18 text-green-400">
                  {payoutsValue === option ? "check" : ""}
                </DropdownMenu.ItemIndicator>
              </DropdownMenu.RadioItem>
            ))}
          </DropdownMenu.RadioGroup>
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    );
  };

  useEffect(() => {
    fetchAnalyticsData("card_total_spend", "weekly");
    fetchAnalyticsData("card_total_spend", "monthly");
    fetchAnalyticsData("spend_by_card", "last_30_days");
    fetchAnalyticsData("payout", "weekly");
    fetchAnalyticsData("payout", "monthly");
    fetchAnalyticsData("top_merchants", "last_30_days");
  }, []);

  return (
    <>
      {isLoading && <LoaderBusiness />}
      {!isLoading && (
        <div className="space-y-24 md:space-y-40">
          {/* TOTAL SPEND DATA */}
          <div className="grid grid-cols-1">
            <ChartCard
              isDataEmpty={isChartEmptyData(selectedTotalSpendData())}
              showTitle
              title="Total spend"
              tooltipProps={{
                content: "Total spend on Mamo Cards",
                Icon: <Help size="18" fill={colorsV2.text[3]} />,
              }}
              showToolbar
              onDownload={() =>
                handleDownloadAnalyticsCsv(
                  totalSpendParams(totalSpendValue).kind,
                  totalSpendParams(totalSpendValue).frequencyType,
                )
              }
              additionalToolbarActions={<TotalSpendDropdown />}
            >
              <BarChart
                data={selectedTotalSpendData()}
                height={270}
                margin={{
                  top: 25,
                  right: 0,
                  bottom: 30,
                  left: isMobile ? 0 : 60,
                }}
                numTicks={5}
                bottomNumTicks={isMobile ? 1 : 99}
                xAccessor={(d) =>
                  totalSpendValue === "weekly" ? d?.createdAt : d?.month
                }
                xValueFormat={
                  totalSpendValue === "weekly"
                    ? (createdAt: string) => formatCreatedAtDate(createdAt)
                    : (month: number) => moment(month, "M").format("MMM")
                }
                yAccessor={(d) => d?.value}
                yValueFormat={(value: any) => `AED ${formatAmount(value)}`}
                tooltipSubtext={handleTotalSpendTooltipSubtext}
              />
            </ChartCard>
          </div>

          {/* SPEND BY CARD - LAST 30 DAYS */}
          <div className="grid grid-cols-1">
            <ChartCard
              isDataEmpty={isEmpty(spendByCardValuesData)}
              showTitle
              title="Spend by card - last 30 days"
            >
              <BarChart
                horizontal
                hasCurrencyInTooltip={false}
                data={spendByCardValuesData}
                height={handleSpendByCardChartHeight(
                  spendByCardValuesData?.length,
                )}
                margin={{
                  top: 25,
                  right: 0,
                  bottom: 30,
                  left: isMobile ? 0 : 60,
                }}
                leftNumTicks={99}
                bottomNumTicks={isMobile ? 1 : null}
                xAccessor={(d) => d?.value}
                xValueFormat={(value: number) => `AED ${formatAmount(value)}`}
                yAccessor={(d) => d?.name}
                yValueFormat={(name: string) => shrinkString(name, 1, 10)}
                tooltipSubtext={handleSpendByCardTooltipSubtext}
              />
            </ChartCard>
          </div>

          {/* PAYOUTS DATA */}
          <div className="grid grid-cols-1">
            <ChartCard
              isDataEmpty={isChartEmptyData(selectedPayoutsData())}
              showTitle
              title="Payouts"
              showToolbar
              onDownload={() =>
                handleDownloadAnalyticsCsv(
                  payoutsParams(payoutsValue).kind,
                  payoutsParams(payoutsValue).frequencyType,
                )
              }
              additionalToolbarActions={<PayoutsDropdown />}
            >
              <BarChart
                data={selectedPayoutsData()}
                height={270}
                margin={{
                  top: 25,
                  right: 0,
                  bottom: 30,
                  left: isMobile ? 0 : 60,
                }}
                numTicks={5}
                bottomNumTicks={isMobile ? 1 : 99}
                xAccessor={(d) =>
                  payoutsValue === "weekly" ? d?.createdAt : d?.month
                }
                xValueFormat={
                  payoutsValue === "weekly"
                    ? (createdAt: string) => formatCreatedAtDate(createdAt)
                    : (month: number) => moment(month, "M").format("MMM")
                }
                yAccessor={(d) => d?.value}
                yValueFormat={(value: any) => `AED ${formatAmount(value)}`}
                tooltipSubtext={handlePayoutsTooltipSubtext}
              />
            </ChartCard>
          </div>

          {/* TOP VENDORS DATA */}
          <div className="grid grid-cols-1 gap-24 lg:grid-cols-2 lg:gap-40">
            {!isEmpty(topVendorsData?.[0]?.values) && (
              <List
                title="Top vendors - last 30 days"
                columnHeader1="Vendor"
                columnHeader2="Spend"
              >
                {topVendorsData?.[0]?.values?.map(({ name, value }) => (
                  <List.Item
                    value1={name}
                    value2={`${convertToLocaleStringHelper(value)}%`}
                  />
                ))}
              </List>
            )}
          </div>
        </div>
      )}
    </>
  );
};
