import { eq, isEmpty } from "lodash";
import { useCallback, useContext, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { CurrentSessionContext } from "components/Providers/CurrentSession.provider";
import { routes } from "constants/routes";

import { MerchantOnboardingStatus } from "enums/merchant/merchant-onboarding-status.enum";
import { getOurReviewProcess } from "features/VerificationSetup/_repository/review-process.query";
import { getVerificationProcess } from "features/VerificationSetup/_repository/verification-process.query";

// It's a core hook to manage the verification process
export const useVerificationProcess = () => {
  const { sessionContext } = useContext(CurrentSessionContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  // Status of the merchant (pending_verification, verified, etc.)
  const status = useMemo(() => {
    return sessionContext.currentMerchant?.status;
  }, [sessionContext]);

  // HTTP request should be enabled for these statuses
  // In other case BE will throw Forbidden error
  const enabledFor: MerchantOnboardingStatus[] = [
    MerchantOnboardingStatus.not_complete,
    MerchantOnboardingStatus.pending_verification,
    MerchantOnboardingStatus.failed_verification,
  ];

  const enabled = enabledFor.includes(status);

  // API Queries
  const { verificationProcess } = getVerificationProcess(enabled);
  const { reviewProcess } = getOurReviewProcess(enabled);

  /**
   * Computed Values
   */

  const hasMissingInformation = useMemo(() => {
    return !isEmpty(reviewProcess.data?.notCompleteMessages);
  }, [reviewProcess.data?.notCompleteMessages]);

  // Check if 1st step is complete (Personal Details, Business Details, etc.)
  const isVerificationProcessComplete = useMemo(() => {
    const data = verificationProcess.data;

    return (
      data &&
      Object.values(verificationProcess?.data)
        .filter((value) => !isEmpty(value))
        .every((value) => value.value === 100)
    );
  }, [verificationProcess.data]);

  // A'la Route Guard - Validate the path and redirect to the correct page
  const validatePath = useCallback(() => {
    if (eq(pathname, routes.MANAGE.SETUP.SUBMISSION_SUCCESS())) {
      return;
    }

    switch (status) {
      case MerchantOnboardingStatus.not_complete: {
        if (
          eq(pathname, routes.MANAGE.SETUP.OUR_REVIEW()) &&
          !hasMissingInformation
        ) {
          navigate(routes.MANAGE.SETUP.INDEX());
        }

        if (
          eq(pathname, routes.MANAGE.SETUP.INDEX()) &&
          isVerificationProcessComplete &&
          !hasMissingInformation
        ) {
          return;
        }

        if (
          eq(pathname, routes.MANAGE.SETUP.INDEX()) &&
          isVerificationProcessComplete &&
          hasMissingInformation
        ) {
          navigate(routes.MANAGE.SETUP.OUR_REVIEW());
        }
        break;
      }

      case MerchantOnboardingStatus.pending_verification: {
        if (
          !eq(pathname, routes.MANAGE.SETUP.OUR_REVIEW()) &&
          isVerificationProcessComplete
        ) {
          navigate(routes.MANAGE.SETUP.OUR_REVIEW());
        }
        break;
      }

      case MerchantOnboardingStatus.failed_verification: {
        if (!eq(pathname, routes.MANAGE.SETUP.VERIFICATION_FAILED())) {
          navigate(routes.MANAGE.SETUP.VERIFICATION_FAILED());
        }
        break;
      }

      case MerchantOnboardingStatus.verified: {
        if (!eq(pathname, routes.MANAGE.HOME_PAGE())) {
          window.location.href = routes.MANAGE.HOME_PAGE();
        }
        break;
      }
      default:
        break;
    }
  }, [
    pathname,
    status,
    navigate,
    isVerificationProcessComplete,
    hasMissingInformation,
  ]);

  // Whenever Merchant is verified, we consider it as complete
  const isComplete = useMemo(() => {
    return status === MerchantOnboardingStatus.verified;
  }, [status]);

  const isFailed = useMemo(() => {
    return status === MerchantOnboardingStatus.failed_verification;
  }, [status]);

  // Return if the verification process is pending
  const isPendingVerification = useMemo(
    () => status === MerchantOnboardingStatus.pending_verification,
    [status],
  );

  // For Our Review process - check whenever any information is missing
  const isMissingInformation = useMemo(() => {
    if (status !== MerchantOnboardingStatus.not_complete) return false;

    return !isEmpty(reviewProcess.data?.notCompleteMessages);
  }, [status, reviewProcess.data?.notCompleteMessages]);

  return {
    // Query
    reviewProcess,
    verificationProcess,

    // Memo
    status,
    isComplete,
    isFailed,
    isVerificationProcessComplete,
    isPendingVerification,
    isMissingInformation,

    // Callback
    validatePath,
  };
};
