import Lottie from "lottie-react-web";
import { useNavigate } from "react-router-dom";

import GreenCheckMark from "assets/animations/green-checkmark.json";
import { FadeIn } from "components/AnimatedElements/FadeIn";
import Button from "components/Button/Button";
import routes from "constants/routes";

interface ISuccessPageProps {
  title: string;
  subtext: string | React.ReactNode;
}

const SuccessPage = ({ title, subtext }: ISuccessPageProps) => {
  const navigate = useNavigate();

  const handleGoDashboard = () => {
    navigate(routes.MANAGE.ACCOUNT_SETTINGS.PLAN());
    navigate(0);
  };

  return (
    <main className="success-page-wrapper">
      <div className="success-page-submitted flex flex-col justify-center space-y-48 text-center">
        {/* CONTENT */}
        <div className="space-y-24 md:space-y-32 ">
          <FadeIn custom={0} duration={0.3} yOffset={10}>
            <Lottie
              options={{
                animationData: GreenCheckMark,
                loop: false,
              }}
              width={80}
              height={80}
            />
          </FadeIn>

          <FadeIn custom={1} duration={0.3} yOffset={10}>
            <div className="space-y-16 md:space-y-12">
              <h1>{title}</h1>
              <div className="text-16 text-black-300 md:text-18">{subtext}</div>
            </div>
          </FadeIn>
        </div>

        {/* CTAs */}
        <FadeIn custom={1.5} duration={0.3} yOffset={10}>
          <div className="space-y-16 md:flex md:justify-center md:space-x-16 md:space-y-0">
            <Button
              color="primary"
              label="Go to dashboard"
              size="lg"
              classes="w-full"
              onClick={handleGoDashboard}
            />
          </div>
        </FadeIn>
      </div>
    </main>
  );
};

export default SuccessPage;
