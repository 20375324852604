import { useMemo } from "react";
import { useLocation } from "react-router-dom";

import routes from "constants/routes";

import { useVerificationProcess } from "features/VerificationSetup/_hooks/useVerificationProcess";
import { eq } from "lodash";

// Define step style based on the current state of validation
export const useTimelineSteps = () => {
  const { pathname } = useLocation();
  const {
    isMissingInformation,
    isPendingVerification,
    isVerificationProcessComplete,
    isComplete,
  } = useVerificationProcess();

  const ourReviewVariant = useMemo(() => {
    if (isMissingInformation) {
      return "warning" as const;
    }

    if (pathname === routes.MANAGE.SETUP.OUR_REVIEW()) {
      return "current" as const;
    }

    if (isComplete) {
      return "completed" as const;
    }

    return "upcoming" as const;
  }, [isMissingInformation, pathname, isComplete]);

  const completeVerificationVariant = useMemo(() => {
    const isCurrentPath = eq(pathname, routes.MANAGE.SETUP.INDEX());

    if (isCurrentPath) {
      return "current" as const;
    }

    if (!isCurrentPath && isVerificationProcessComplete) {
      return "completed" as const;
    }

    return "upcoming" as const;
  }, [pathname, isVerificationProcessComplete]);

  const accountVerifiedVariant = useMemo(() => {
    if (pathname === routes.MANAGE.SETUP.SUBMISSION_SUCCESS()) {
      return "current" as const;
    }

    if (isComplete) {
      return "completed" as const;
    }

    return "upcoming" as const;
  }, [isPendingVerification, pathname, isComplete]);

  return {
    ourReviewVariant,
    completeVerificationVariant,
    accountVerifiedVariant,
  };
};
