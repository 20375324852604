import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import * as RadixTooltip from "@radix-ui/react-tooltip";
import isFunction from "lodash/isFunction";
import type React from "react";
import { useEffect, useRef, useState } from "react";
import { cn } from "utils/classNames";

import { Button } from "design_system/Button/Button";
import { IconButton } from "design_system/IconButton";
import { ErrorOutline, ExpandMore } from "design_system/Icons";

import { colorsV2 } from "constants/colors";
import { sharedTooltipClasses } from "design_system/shared/constant";
import { customTheme } from "design_system/shared/theme";
import type { CommonProps, TDropdownTypeProps } from "./typeDefs";

export type TDropdownProps = CommonProps & TDropdownTypeProps;

export const Dropdown = ({
  borderless,
  buttonProps,
  children,
  highlightColor,
  iconButtonProps,
  label,
  onLoadMoreItems,
  options,
  presentInModal,
  selectProps = { errorMode: "onBlur" },
  showCaretOnHover,
  showTooltipOnButton,
  tooltipProps,
  type,
  onTriggerClick,
}: TDropdownProps) => {
  const outerTheme = useTheme();
  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = () => {
    setIsFocused(true);
  };

  const menuRef = useRef(null);

  const handleScrollEnd = () => {
    const menu = menuRef.current;

    // Tolerance to account for minor discrepancies in floating-point calculations
    const tolerance = 5;

    /**
     * menu.scrollTop: The distance in pixels from the top of the menu to the current scroll position.
     * menu.clientHeight: The visible height of the menu in pixels.
     * menu.scrollHeight: The total height of the menu’s content, including the portion not visible due to scrolling.
     *
     * Condition: The following condition checks if the sum of the current scroll position and the visible height
     * is greater than or equal to the total content height minus a small tolerance.
     * This accounts for minor floating-point precision issues, ensuring the bottom detection works correctly.
     */
    if (menu.scrollTop + menu.clientHeight >= menu.scrollHeight - tolerance) {
      onLoadMoreItems();
    }
  };

  const { MenuProps, ...restProps } = selectProps;
  const { PaperProps, style: MenuStyle, ...restMenuProps } = MenuProps || {};

  const updatedProps: any = {
    ...restProps,
    color: isFocused ? undefined : highlightColor,
    onFocus: handleFocus,
  };

  const tooltipContentProps = tooltipProps && {
    sideOffset: 10,
    className: sharedTooltipClasses(tooltipProps.contentPosition),
    style: { width: tooltipProps.contentWidth },
    side: tooltipProps.contentPosition,
  };

  // =======================================================================
  // BUTTON/ICON BUTTON DROPDOWN LOGIC
  // =======================================================================
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    isFunction(onTriggerClick) ? onTriggerClick() : null;
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // =======================================================================

  if (type === "select") {
    const [isErrorVisible, setIsErrorVisible] = useState(false);

    useEffect(() => {
      setIsErrorVisible(
        selectProps.errorMode === "immediate" && selectProps.hasError,
      );
    }, [selectProps.errorMode]);

    return (
      <ThemeProvider theme={customTheme(outerTheme)}>
        <FormControl variant="filled" className="w-full">
          {!borderless && label && (
            <InputLabel id="select-label">{label}</InputLabel>
          )}
          <Select
            // NOTE: DEFAULT VALUE 0 IS BASICALLY THE INDEXED VALUE AND NOT THE ACTUAL VALUE
            defaultValue={borderless && "0"}
            className={cn("", {
              "MuiInputBase-borderless": borderless,
              "Mui-error": isErrorVisible,
            })}
            MenuProps={{
              PaperProps: {
                ref: menuRef,
                onScroll: isFunction(onLoadMoreItems) ? handleScrollEnd : null,
                ...(PaperProps || {}),
              },
              style: {
                zIndex: presentInModal ? 10002 : 1300,
                ...(MenuStyle || {}),
              },
              ...(restMenuProps || {}),
            }}
            sx={{
              "& .MuiSelect-select": {
                display: "flex",
                alignItems: "center",
                gap: "8px",
                paddingRight: "32px !important", // Make space for the dropdown icon
              },
            }}
            endAdornment={
              isErrorVisible ? (
                <InputAdornment position="end">
                  <ErrorOutline size="18" fill={colorsV2.error[1]} />
                </InputAdornment>
              ) : null
            }
            IconComponent={(props) => (
              <ExpandMore
                size="18"
                fill={
                  selectProps.disabled
                    ? colorsV2.text.disabled
                    : colorsV2.text[2]
                }
                className={cn("", {
                  "MuiInputBase-hover-icon": showCaretOnHover,
                  [`${props.className}`]: !!props.className,
                })}
              />
            )}
            onBlur={() =>
              selectProps.errorMode === "onBlur" && selectProps.hasError
                ? setIsErrorVisible(true)
                : null
            }
            {...updatedProps}
          >
            {selectProps.placeholder && (
              <MenuItem disabled hidden value={selectProps.placeholder}>
                {selectProps.placeholder}
              </MenuItem>
            )}
            {children}
          </Select>
          {isErrorVisible && (
            <FormHelperText>{selectProps.helperText}</FormHelperText>
          )}
        </FormControl>
      </ThemeProvider>
    );
  }

  if (type === "icon-button") {
    return (
      <ThemeProvider theme={customTheme(outerTheme)}>
        {showTooltipOnButton && tooltipContentProps ? (
          <RadixTooltip.Root delayDuration={100}>
            <RadixTooltip.Trigger tabIndex={-1}>
              <IconButton
                id="trigger-button"
                aria-controls={open ? "trigger-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                {...iconButtonProps}
              />
            </RadixTooltip.Trigger>

            <RadixTooltip.Content {...tooltipContentProps}>
              {tooltipProps?.content}
              <RadixTooltip.Arrow fill="#26303e" />
            </RadixTooltip.Content>
          </RadixTooltip.Root>
        ) : (
          <IconButton
            id="trigger-button"
            aria-controls={open ? "trigger-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            {...iconButtonProps}
          />
        )}
        <Menu
          id="trigger-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "trigger-button",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          {options.map((option, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                option.onClick();
                handleClose();
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Menu>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={customTheme(outerTheme)}>
      {showTooltipOnButton && tooltipContentProps ? (
        <RadixTooltip.Root delayDuration={100}>
          <RadixTooltip.Trigger tabIndex={-1}>
            <Button
              type="button"
              id="trigger-button"
              aria-controls={open ? "trigger-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              {...buttonProps}
            />
          </RadixTooltip.Trigger>

          <RadixTooltip.Content {...tooltipContentProps}>
            {tooltipProps?.content}
            <RadixTooltip.Arrow fill="#26303e" />
          </RadixTooltip.Content>
        </RadixTooltip.Root>
      ) : (
        <Button
          id="trigger-button"
          aria-controls={open ? "trigger-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          {...buttonProps}
        />
      )}
      <Menu
        id="trigger-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "trigger-button",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              option.onClick();
              handleClose();
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </ThemeProvider>
  );
};

Dropdown.MenuItem = ({ children, ...props }) => {
  return <MenuItem {...props}>{children}</MenuItem>;
};
