import Button from "components/Button/Button";

import imagePaywall from "assets/images/paywall/subscription-paywall.png";
import routes from "constants/routes";
import { isAppPlatform } from "helpers/isAppPlatform";
import { handleGoToVerification } from "utils/verificationUtils";
interface ISubscriptionPaywallProps {
  hasBasic: boolean;
  status: string;
}

const SubscriptionPaywall = ({
  hasBasic,
  status,
}: ISubscriptionPaywallProps) => {
  const isVerificationPending = status === "pending_verification";
  const isVerificationFailed = status === "failed_verification";
  const isVerificationComplete = status === "verified" || status === "on_hold";

  const handleGoToPlans = () => {
    window.location.href = routes.MANAGE.PLANS.INDEX();
  };

  return (
    <>
      <section className="paywall">
        <div className="paywall__content">
          {/* HEADER */}
          <h1>Set and forget, payment collection made easy</h1>
          <p className="text-16 text-black-300">
            Create recurring payments to streamline payments from your
            customers.
          </p>

          {/* FEATURES */}
          <div>
            <ul className="paywall__list">
              <li className="paywall__list__list-item">Set an amount</li>
              <li className="paywall__list__list-item">
                Define payment’s lifespan and frequency
              </li>
              <li className="paywall__list__list-item">
                Share the link with your customers
              </li>
              <li className="paywall__list__list-item">Sit back and relax!</li>
            </ul>
          </div>

          {/* CTA */}
          {!isAppPlatform() && (
            <div className="paywall__cta">
              {isVerificationComplete && hasBasic ? (
                <Button
                  color="primary"
                  size="md"
                  iconPosition="left"
                  iconFont="star"
                  iconClasses="text-yellow-500"
                  label="Upgrade to unlock"
                  classes="w-full md:w-auto"
                  onClick={handleGoToPlans}
                />
              ) : (
                !(isVerificationPending || isVerificationFailed) && (
                  <Button
                    color="primary"
                    size="md"
                    label="Verify account"
                    classes="w-full md:w-auto"
                    onClick={handleGoToVerification}
                  />
                )
              )}
            </div>
          )}
        </div>

        {/* IMAGE */}
        <div className="paywall__image">
          <img src={imagePaywall} alt="" />
        </div>
      </section>
    </>
  );
};

export default SubscriptionPaywall;
