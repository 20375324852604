import { Banner } from "design_system/Banner";

interface HolidayBannerProps {
  heading: string;
  subtext: string;
}

export const HolidayBanner = ({ heading, subtext }: HolidayBannerProps) => {
  return (
    <Banner
      id="holiday"
      variant="info"
      title={heading}
      description={subtext}
      leadingElement="emoji"
      emoji="🏛️"
      dismissable
    />
  );
};
