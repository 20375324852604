import type {
  ComplianceDocument,
  ComplianceDocumentType,
} from "types/MerchantInfo";

export const getDocumentName = (document: ComplianceDocument): string => {
  const type: ComplianceDocumentType = document?.documentType;

  switch (type) {
    case "articles_of_association":
      return "Articles of Association";
    case "emirates_id":
      return "Emirates ID";
    case "freelance_permit":
      return "Freelance permit";
    case "latest_bank_statement":
      return "Latest bank statement";
    case "memorandum_of_association":
      return "Memorandum of Association";
    case "other":
      return "Other document";
    case "passport":
      return "Passport";
    case "proof_of_address":
      return "Proof of address";
    case "proof_of_service":
      return "Proof of service";
    case "public_register":
      return "Public register";
    case "sample_invoice":
      return "Sample invoice";
    case "trade_license":
      return "Trade license";
    case "visa":
      return "Visa";
    default:
      return "Other document";
  }
};
