import { Cookies } from "react-cookie";

import type { DashboardNavigationItem } from "../DashboardNavigation.types";

const cookies = new Cookies();
const ACTIVE_PRIMARY_ITEM_COOKIE = "activePrimaryItem";

export const setActivePrimaryItemCookie = (item: DashboardNavigationItem) => {
  const expires = new Date();
  expires.setTime(expires.getTime() + 30 * 24 * 3600 * 1000); // 30 days
  cookies.set(ACTIVE_PRIMARY_ITEM_COOKIE, JSON.stringify(item), {
    path: "/",
    expires,
  });
};

export const getActivePrimaryItemCookie =
  (): DashboardNavigationItem | null => {
    const item = cookies.get(ACTIVE_PRIMARY_ITEM_COOKIE);
    return item ? item : null;
  };
