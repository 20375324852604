import axios from "axios";
import qs from "qs";
import { useEffect, useReducer } from "react";
import ReactOnRails from "react-on-rails";

import Card from "components/ui/Card";
import Pagination from "components/ui/Pagination";
import PaymentsList from "features/Payments/PaymentsList/PaymentsList";
import { DetailPageHeader } from "./DetailPageHeader";

import routes from "constants/routes";
import type IRailsContext from "interfaces/IRailsContext";
import type ISubscriberDetails from "interfaces/subscriptions/ISubscriberDetailsProps";
import paymentsReducer, {
  initialState,
  PaymentActionTypes,
} from "reducers/paymentsReducer";
import { useGetRole } from "utils/rbacUtils";

interface IProps extends ISubscriberDetails {
  railsContext?: IRailsContext;
}

export const SubscriberDetails = (props: IProps) => {
  const {
    totalPaidFormatted,
    currency,
    totalNumberOfPayments,
    email,
    phone,
    lastPaymentDateFormatted,
    nextPaymentDateFormatted,
    cardLast4,
    paymentLinkId,
    identifier,
  } = props.customerSubscription;

  const permissions =
    props.railsContext?.sessionContext?.currentUser?.permissions!;
  const [state, dispatch] = useReducer(paymentsReducer, initialState);
  const { payments, paginationMeta, filters } = state;
  const { hasPermission } = useGetRole();

  const queryParams: any = {
    authenticity_token: ReactOnRails.authenticityToken(),
    page: paginationMeta.page,
    ...filters,
    status: ["failed", ...filters.status],
    subscriber: {
      id: identifier,
    },
    merchant_link: {
      id: paymentLinkId,
    },
  };

  const handleSetPage = (page: number | null) => {
    if (page) {
      dispatch({ type: PaymentActionTypes.SET_PAGE, payload: page });
    }
  };

  const fetchPayments = () => {
    dispatch({ type: PaymentActionTypes.LOADING, payload: true });

    axios
      .get(
        `${routes.MANAGE.PAYMENTS.INDEX()}?${qs.stringify(queryParams, {
          skipNulls: true,
          arrayFormat: "brackets",
        })}`,
        {
          headers: { accept: "application/json" },
        },
      )
      .then((res) => {
        dispatch({ type: PaymentActionTypes.LOADING, payload: false });
        dispatch({ type: PaymentActionTypes.SET_DATA, payload: res.data });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const runEffect = () => {
    fetchPayments();
  };

  useEffect(runEffect, [paginationMeta.page, filters]);
  useEffect(() => {}, [filters]);

  return (
    <>
      <DetailPageHeader
        joinedDateFormatted={props.joinedDateFormatted}
        subscriber={props.subscriber}
        subscription={props.subscription}
      />

      <section className="subscriber-details">
        <div className="subscriber-details__overview">
          <Card hasBorder>
            <p className="subscriber-details__overview__label">Total paid</p>
            <p className="subscriber-details__overview__description">
              {totalPaidFormatted} <span>{currency}</span>
            </p>
          </Card>
          <Card hasBorder>
            <p className="subscriber-details__overview__label">
              Number of payments
            </p>
            <p className="subscriber-details__overview__description">
              {totalNumberOfPayments}
            </p>
          </Card>
        </div>
        <Card hasBorder>
          <div className="subscriber-details__details">
            <div className="subscriber-details__details__heading">
              <h3>Details</h3>
            </div>

            <ul className="subscriber-details__details__list">
              <li className="subscriber-details__details__item">
                <p className="subscriber-details__details__item__label">
                  Email address
                </p>
                <p className="subscriber-details__details__item__description">
                  {email}
                </p>
              </li>
              <li className="subscriber-details__details__item">
                <p className="subscriber-details__details__item__label">
                  Phone
                </p>
                <p className="subscriber-details__details__item__description">
                  {phone}
                </p>
              </li>
              <li className="subscriber-details__details__item">
                <p className="subscriber-details__details__item__label">
                  Last payment date
                </p>
                <p className="subscriber-details__details__item__description">
                  {lastPaymentDateFormatted}
                </p>
              </li>
              <li className="subscriber-details__details__item">
                <p className="subscriber-details__details__item__label">
                  Next payment date
                </p>
                <p className="subscriber-details__details__item__description">
                  {nextPaymentDateFormatted}
                </p>
              </li>
              <li className="subscriber-details__details__item">
                <p className="subscriber-details__details__item__label">
                  Card number
                </p>
                <p className="subscriber-details__details__item__description">
                  •••• {cardLast4}
                </p>
              </li>
            </ul>
          </div>
        </Card>

        {hasPermission("payments:read", permissions) && (
          <div className="payment-link-details__payments">
            <div className="payment-link-details__payments__header">
              <h3>All payments</h3>
              {/* TODO: Implements this later */}
              {/* <Button
                onClick={handleDownloadPaymentsCsv}
                color="secondary"
                size="md"
                iconPosition="left"
                iconFont="download"
                label="Download CSV"
              /> */}
            </div>
            <PaymentsList payments={payments} />
            <div className="subscriber-details__links__pagination">
              <Pagination
                itemName="payments"
                meta={paginationMeta}
                onSetPage={handleSetPage}
              />
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default SubscriberDetails;
