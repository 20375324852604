import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

interface GlobalBannerState {
  showGlobalBanner: boolean;
}

const initialState: GlobalBannerState = {
  showGlobalBanner: false,
};

export const globalBannerSlice = createSlice({
  name: "globalBanner",
  initialState,
  reducers: {
    setShowGlobalBanner(state, action: PayloadAction<boolean>) {
      state.showGlobalBanner = action.payload;
    },
  },
});

export const { setShowGlobalBanner } = globalBannerSlice.actions;

export default globalBannerSlice.reducer;
