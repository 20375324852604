import type { IEmptyStateProps } from "interfaces/IEmptyStateProps";

const EmptyState = ({
  imgSrc,
  imgAlt,
  title,
  info,
  isButton,
  buttonLabel,
  buttonCtaLink,
}: IEmptyStateProps) => {
  return (
    <div className="empty-state">
      <div className="empty-state__image">
        <img src={imgSrc} alt={imgAlt} />
      </div>
      <div className="empty-state__content">
        <h3 className="empty-state__content__heading">{title}</h3>
        <p className="empty-state__content__paragraph">{info}</p>
        {isButton && (
          <a
            href={buttonCtaLink}
            className="empty-state__content__cta button button--primary button--solid button--sm"
          >
            {buttonLabel}
          </a>
        )}
      </div>
    </div>
  );
};

export default EmptyState;
