import pick from "lodash/pick";
import { useMemo } from "react";

import * as Icons from "design_system/Icons/GlobalSearchIcons";
import { SearchRecord } from "../components/SearchRecord";

import { SentryLoggingService } from "init/SentryLoggingService";
import { FIRST_TIMER_EMPTY_STATE } from "../constants/magicStrings";
import { statusMappings } from "../constants/statusMappings";
import { flattenSearchResults, getIconName } from "../utils";

export const useRenderOptions = (searchResults: any, searchText) => {
  const options = useMemo(() => {
    try {
      const transformedOptions = flattenSearchResults(searchResults);

      if (!searchText) {
        return transformedOptions?.length > 0
          ? transformedOptions
          : [FIRST_TIMER_EMPTY_STATE];
      }

      return transformedOptions?.length > 0
        ? transformedOptions
        : [
            {
              label: searchText,
              hasNoResults: true,
            },
          ];
    } catch (error: any) {
      SentryLoggingService.captureException(error.message, {
        feature: "[Global Search]",
        file: "useOptions.ts",
        function: "[useMemo][options]",
        data: JSON.stringify({ searchResults }),
      });
      return [];
    }
  }, [searchResults]);

  const getOptionLabel = (option) => {
    try {
      return option?.label ? JSON.stringify(option) : option;
    } catch (error: any) {
      SentryLoggingService.captureException(error.message, {
        feature: "[Global Search]",
        file: "useOptions.tsx",
        function: "getOptionLabel",
        data: JSON.stringify({ option }),
      });
    }
  };

  const renderOption = (props, option) => {
    try {
      if (option?.label) {
        const updatedProps = pick(props, [
          "id",
          "tabIndex",
          "role",
          "data-option-index",
        ]);
        const iconName = getIconName(option);
        const handleRecordClick = () => {
          localStorage.setItem("referrer", window.location.pathname);
          window.location.href = option.url;
        };
        const mapping = statusMappings[iconName];
        return (
          <div {...updatedProps} key={option?.identifier}>
            <SearchRecord onClick={handleRecordClick}>
              {option.viewKind === "icon" && (
                <SearchRecord.Icon
                  Icon={Icons[iconName]}
                  iconProps={{
                    size: "18",
                    fill: "gray",
                  }}
                />
              )}
              {option.viewKind === "status" && (
                <SearchRecord.Tag
                  tagProps={{
                    Icon: mapping?.Icon || Icons.LinkIcon,
                    size: "sm",
                    color: mapping?.color || "gray",
                  }}
                />
              )}
              <SearchRecord.Column1
                label={option.label}
                supportingText={
                  option.supportingText?.length > 0
                    ? option.supportingText[0]
                    : ""
                }
                onClick={handleRecordClick}
              />
              {option.metaInfo && (
                <SearchRecord.Column2
                  label={option.metaInfo}
                  onClick={handleRecordClick}
                />
              )}
            </SearchRecord>
          </div>
        );
      }
      return (
        <li key={option} {...props}>
          {option}
        </li>
      );
    } catch (error: any) {
      SentryLoggingService.captureException(error.message, {
        feature: "[Global Search]",
        function: "renderOption",
        file: "useRecordOptions.tsx",
        data: JSON.stringify({ option }),
      });
      return (
        <li key="error" {...props}>
          Error
        </li>
      );
    }
  };
  return {
    options,
    getOptionLabel,
    renderOption,
  };
};
