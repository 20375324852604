import Card from "components/ui/Card";
import { PageHeader } from "./PageHeader";

import eFunderLogo from "assets/logos/efunder.svg";
import RakezLogo from "assets/logos/rakez.png";
import tabbyLogo from "assets/logos/tabby-badge.svg";

const Partners = () => {
  const tabbyGTMTrigger = () => {
    return (window as any).dataLayer.push({
      event: "tabby-track",
      eventCategory: "button",
      eventAction: "click",
      eventLabel: "tabbyTrack",
    });
  };

  const eFunderGTMTrigger = () => {
    return (window as any).dataLayer.push({
      event: "efunder-track",
      eventCategory: "button",
      eventAction: "click",
      eventLabel: "efunderTrack",
    });
  };

  const rakezGTMTrigger = () => {
    return (window as any).dataLayer.push({
      event: "rakez-track",
      eventCategory: "button",
      eventAction: "click",
      eventLabel: "rakezTrack",
    });
  };

  return (
    <>
      <PageHeader />

      <section className="account-settings">
        <div className="account-settings__partners">
          <Card classes="space-y-16">
            {/* TABBY */}
            <Card hasBorder classes="p-24 space-y-8">
              {/* LOGO + LINK */}
              <header className="flex items-center justify-between">
                <img
                  src={tabbyLogo}
                  alt="tabby logo"
                  className="h-24 md:h-32"
                />
                <a
                  href="https://tabby.ai/"
                  target="_blank"
                  onClick={tabbyGTMTrigger}
                  className="material-icons-round text-18 text-black-200"
                  rel="noreferrer"
                >
                  open_in_new
                </a>
              </header>

              <div className="space-y-8">
                <p className="heading-03 font-bold">
                  Boost sales with buy now, pay later
                </p>
                <p className="text-14 text-black-300 md:text-16">
                  We’ve partnered with Tabby to let your customers split their
                  purchases in 4 interest-free payments while you get paid
                  upfront.
                  <br />
                  <br />
                  <span className="font-bold">Increase sales:</span> Boost
                  average order value and improve checkout conversion.
                  <br />
                  <br />
                  <span className="font-bold">
                    Get paid upfront, risk-free:
                  </span>{" "}
                  All the upside with none of the risk. You get paid in full and
                  upfront while Tabby takes care of the rest.
                  <br />
                  <br />
                  <span className="font-bold">Gain new customers:</span> Meet
                  +2.5 million Tabby customers. Tabby’s marketplace drives
                  millions in revenue for partner brands.
                  <br />
                  <br />
                  Simply create a new payment link and select the option to
                  "Activate Buy Now Pay Later for this payment link"!
                </p>
              </div>
            </Card>

            {/* EFUNDER */}
            <Card hasBorder classes="p-24 space-y-8">
              {/* LOGO + LINK */}
              <header className="flex items-center justify-between">
                <img
                  src={eFunderLogo}
                  alt="eFunder logo"
                  className="h-24 md:h-32"
                />
                <a
                  href="https://app.efunder.ai/user/createAccount"
                  target="_blank"
                  onClick={eFunderGTMTrigger}
                  className="material-icons-round text-18 text-black-200"
                  rel="noreferrer"
                >
                  open_in_new
                </a>
              </header>

              <div className="space-y-8">
                <p className="heading-03 font-bold">eFunder</p>
                <p className="text-14 text-black-300 md:text-16">
                  eFunder.ai is a ADGM FRSA regulated Category 4 licensed
                  financial institution that provides revenue backed financing
                  solutions to online merchants. eFunder was started with the
                  sole purpose of lifting an SME&rsquo;s financial burden by
                  providing immediate funds against receivables and revenue
                  potential thereby filling in the financial gap that hampered
                  their vision for growth.
                  <br />
                  <br />
                  Available exclusively for Mamo users - you can now sign up on
                  eFunder and obtain{" "}
                  <span className="font-bold text-18">10% off</span> in your
                  transaction fees. Simply enter the code{" "}
                  <span className="font-bold text-18">MAMOFUND</span> while
                  registering.
                </p>
              </div>
            </Card>

            {/* RAKEZ */}
            <Card hasBorder classes="p-24 space-y-8">
              {/* LOGO + LINK */}
              <header className="flex items-center justify-between">
                <img
                  src={RakezLogo}
                  alt="rakez logo"
                  className="h-32 md:h-48"
                />
                <a
                  href="https://rakez.com/en/"
                  target="_blank"
                  onClick={rakezGTMTrigger}
                  className="material-icons-round text-18 text-black-200"
                  rel="noreferrer"
                >
                  open_in_new
                </a>
              </header>

              <div className="space-y-8">
                <p className="heading-03 font-bold">Rakez</p>
                <p className="text-14 text-black-300 md:text-16">
                  Ras Al Khaimah Economic Zone (RAKEZ) is a powerhouse business
                  and industrial hub offering customizable solutions to free
                  zone and non-free zone businesses that are flourishing in over
                  50 sectors.
                  <br />
                  <br />
                  Mamo’s partnership with RAKEZ opens doors to serve many more
                  new and existing businesses which come within the RAKEZ zone.
                  We are so excited to onboard and share Mamo’s, business-first
                  full-stack SME payments services!
                </p>
              </div>
            </Card>
          </Card>
        </div>
      </section>
    </>
  );
};

export default Partners;
