import { Tabs, TabsContent, TabsList, TabsTrigger } from "@radix-ui/react-tabs";
import axios from "axios";
import Pagination from "components/ui/Pagination";
import routes from "constants/routes";
import qs from "qs";
import { useEffect, useReducer, useState } from "react";
import ReactOnRails from "react-on-rails";
import subscribersReducer, {
  initialState,
  SubscriberActionTypes,
} from "reducers/subscribersReducer";
import SubscriberList from "./SubscriberList";

const SubscriberListContainer = () => {
  const [currentTab, setCurrentTab] = useState<string>("all");
  const [state, dispatch] = useReducer(subscribersReducer, initialState);
  const { subscribers, paginationMeta, statusCounts } = state;
  const [currentPage, setCurrentPage] = useState(paginationMeta.page);
  const isEmptySubscribers = subscribers.length == 0;
  const url = new URL(window.location.href);
  const pathnames = url.pathname.split("/");
  const subscriptionIdentifier = pathnames[pathnames.length - 1];

  let queryParams: any = {
    authenticity_token: ReactOnRails.authenticityToken(),
  };

  const handleTabChange = (tab: string) => {
    setCurrentTab(tab);
    setCurrentPage(1);
  };

  const setQueryParams = () => {
    queryParams = {
      ...queryParams,
      page: currentPage,
    };

    if (currentTab == "all") return queryParams;

    return {
      ...queryParams,
      status: currentTab,
    };
  };

  const handleSetPage = (page: number | null) => {
    if (page) setCurrentPage(page);
  };

  const fetchSubscribers = () => {
    dispatch({ type: SubscriberActionTypes.LOADING, payload: true });
    const queryParams = setQueryParams();

    axios
      .get(
        `${routes.MANAGE.SUBSCRIPTIONS.SUBSCRIBERS(subscriptionIdentifier)}?${qs.stringify(queryParams)}`,
        {
          headers: { accept: "application/json" },
        },
      )
      .then((res) => {
        dispatch({ type: SubscriberActionTypes.LOADING, payload: false });
        dispatch({ type: SubscriberActionTypes.SET_DATA, payload: res.data });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const runEffect = () => {
    fetchSubscribers();
  };
  useEffect(runEffect, [currentTab, currentPage]);

  return (
    <div className="subscription-details__links">
      <Tabs defaultValue={currentTab} onValueChange={handleTabChange}>
        <div className="tabs tabs--sm tabs--divider">
          <TabsList className="tabs__list">
            <TabsTrigger value="all" className="tabs__link">
              All subscribers ({statusCounts.all})
            </TabsTrigger>
            <TabsTrigger value="active" className="tabs__link">
              Active ({statusCounts.active})
            </TabsTrigger>
            <TabsTrigger value="unsubscribed" className="tabs__link">
              Unsubscribed ({statusCounts.unsubscribed})
            </TabsTrigger>
          </TabsList>
        </div>
        <TabsContent value="all" className="tabs__content" tabIndex={-1}>
          <SubscriberList
            subscribers={subscribers}
            subscriptionIdentifier={subscriptionIdentifier}
            currentTab={currentTab}
          />
        </TabsContent>
        <TabsContent value="active" className="tabs__content" tabIndex={-1}>
          <SubscriberList
            subscribers={subscribers}
            subscriptionIdentifier={subscriptionIdentifier}
            currentTab={currentTab}
          />
        </TabsContent>
        <TabsContent
          value="unsubscribed"
          className="tabs__content"
          tabIndex={-1}
        >
          <SubscriberList
            subscribers={subscribers}
            subscriptionIdentifier={subscriptionIdentifier}
            currentTab={currentTab}
          />
        </TabsContent>

        {!isEmptySubscribers && (
          <div className="subscriptions__links__pagination">
            <Pagination
              itemName="subscribers"
              meta={paginationMeta}
              onSetPage={handleSetPage}
            />
          </div>
        )}
      </Tabs>
    </div>
  );
};

export default SubscriberListContainer;
