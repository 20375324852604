import { cn } from "utils/classNames";

import Button from "components/Button/Button";
import TextInput from "components/Inputs/TextInput";

import BgGradient from "assets/misc/gradient-bg.png";

var tinycolor = require("tinycolor2");

interface ICustomerDetailsProps {
  brandColor: string;
  accent: string;
  businessName: string;
}

const DemoCustomerDetails = ({
  brandColor,
  accent,
  businessName,
}: ICustomerDetailsProps) => {
  const tinyBrandColor = tinycolor(brandColor);
  const tinyAccentColor = tinycolor(accent);

  return (
    <>
      <main
        className="min-h-100dvh bg-center bg-cover bg-fixed bg-no-repeat md:min-h-screen md:pb-48 lg:pb-64"
        style={{
          backgroundImage:
            brandColor === "#3333FF" ? `url(${BgGradient})` : "unset",
          backgroundColor: brandColor === "#3333FF" ? "unset" : brandColor,
        }}
      >
        <div className="md:grid md:auto-rows-max md:grid-cols-8 lg:mx-auto lg:max-w-1200 lg:grid-cols-12">
          <div className="md:col-span-4 md:col-start-3 lg:col-start-5">
            <header className="sticky p-20 md:relative md:flex md:h-96 md:items-center">
              <div className="absolute top-14 left-24 md:hidden">
                <Button
                  type="button"
                  size="xs"
                  isIcon
                  iconFont="arrow_back"
                  iconStyle={{ fontSize: "16px" }}
                  classes={cn("border border-solid", {
                    "border-black-300 text-black-500 hover:bg-black-500 hover:bg-opacity-5":
                      tinyBrandColor.isLight(),
                    "border-white text-white hover:bg-white hover:bg-opacity-5":
                      tinyBrandColor.isDark(),
                  })}
                />
              </div>
              <div className="fixed top-24 left-48 hidden md:block xl:absolute xl:top-28 xl:left-0">
                <Button
                  type="button"
                  size="sm"
                  isIcon
                  iconFont="arrow_back"
                  classes={cn("border border-solid", {
                    "border-black-300 text-black-500 hover:bg-black-500 hover:bg-opacity-5":
                      tinyBrandColor.isLight(),
                    "border-white text-white hover:bg-white hover:bg-opacity-5":
                      tinyBrandColor.isDark(),
                  })}
                />
              </div>

              <h2
                className={cn(
                  "text-center font-bold text-16 text-white md:w-full md:text-18",
                  {
                    "text-wl-black-500": tinyBrandColor.isLight(),
                  },
                )}
              >
                {businessName}
              </h2>
            </header>

            <section className="absolute top-64 right-0 bottom-0 left-0 flex flex-col overflow-y-auto overflow-x-hidden rounded-t-32 bg-white px-24 shadow-md md:relative md:top-0 md:rounded-32">
              <div className="mt-24 space-y-24">
                <h3 className="font-medium">Enter your details</h3>

                <TextInput
                  inputId="firstname"
                  name="firstName"
                  labelText="First name"
                  autoComplete="given-name"
                  hasPremium
                  autoFocus
                />

                <TextInput
                  inputId="lastname"
                  name="lastName"
                  labelText="Last name"
                  autoComplete="family-name"
                  hasPremium
                />

                <TextInput
                  inputId="phone"
                  name="phone"
                  labelText="Phone"
                  autoComplete="tel-country-code"
                  hasPremium
                />

                <TextInput
                  type="email"
                  inputId="email"
                  name="email"
                  hideReplay
                  autoComplete="email"
                  labelText="Email"
                  hasPremium
                />
                <TextInput
                  inputId="comment"
                  name="paymentPurpose"
                  labelText="Add a message"
                  hasPremium
                />

                {/* CONTINUE/SUBSCRIBE BUTTON */}
                <div className="w-full pb-16 md:pb-24">
                  {accent ? (
                    <Button
                      type="submit"
                      size="lg"
                      label="Continue"
                      classes={cn("w-full hover:opacity-80", {
                        "border border-black-200 border-solid":
                          accent == "#FFFFFF" || accent == "#FFF",
                        "text-wl-white-500": tinyAccentColor.isDark(),
                        "text-wl-black-500": tinyAccentColor.isLight(),
                      })}
                      style={{ backgroundColor: accent }}
                    />
                  ) : (
                    <Button
                      type="submit"
                      color="primary"
                      size="lg"
                      label="Continue"
                      classes="w-full"
                    />
                  )}
                </div>
              </div>
            </section>
          </div>
        </div>
      </main>
    </>
  );
};

export default DemoCustomerDetails;
