import { ToggleGroup, ToggleGroupItem } from "@radix-ui/react-toggle-group";

import routes from "constants/routes";
import { isAppPlatform } from "helpers/isAppPlatform";
import { Steps } from "reducers/newDisbursementsReducer";

const DisbursementType = ({ setCurrentStep }: any) => {
  return (
    <>
      {!isAppPlatform() && (
        <header>
          <a
            href={routes.MANAGE.DISBURSEMENTS.INDEX()}
            className="create-link__close-button material-icons-round"
          >
            close
          </a>
        </header>
      )}
      <section className="create-link__disbursements">
        {/* PROCESS COMPONENT */}
        <div className="process">
          <div className="process__content">
            <h1 className="process__content__heading">
              How do you want to create your payout?
            </h1>
            <p className="process__content__info">
              Choose how you would like to add payouts
            </p>
          </div>

          <ToggleGroup type="single">
            <div className="create-disbursement">
              {/* MANUAL UPLOAD OPTION */}
              <div className="radio-group">
                <ToggleGroupItem
                  value="manual"
                  className="radio-group__label"
                  onClick={() => setCurrentStep(Steps.ManualUpload)}
                >
                  <span className="material-icons-round radio-group__icon">
                    touch_app
                  </span>
                  <div className="radio-group__content">
                    <span className="heading-03 text-black-500">Manually</span>
                    <p>Enter details of your payment manually</p>
                  </div>
                </ToggleGroupItem>
              </div>

              {/* NOTE: HIDE INVOICE UPLOAD OPTION AS USERS DON'T USE IT */}
              {/* <div className="radio-group">
                <ToggleGroupItem
                  value="manual"
                  className="radio-group__label"
                  onClick={() => setCurrentStep(5)}
                >
                  <span className="material-icons-round radio-group__icon">
                    request_quote
                  </span>
                  <div className="radio-group__content">
                    <span className="heading-03 text-black-500">
                      Invoice upload
                    </span>
                    <p>Upload an invoice to create your payout</p>
                  </div>
                </ToggleGroupItem>
              </div> */}

              {/* BULK PAYMENT OPTION */}
              <div className="radio-group">
                <ToggleGroupItem
                  value="upload-file"
                  className="radio-group__label"
                  onClick={() => setCurrentStep(Steps.BulkUpload)}
                >
                  <span className="material-icons-round radio-group__icon">
                    upload_file
                  </span>
                  <div className="radio-group__content">
                    <span className="heading-03 text-black-500">
                      Bulk payment
                    </span>
                    <p>Upload a single CSV file to create bulk payouts</p>
                  </div>
                </ToggleGroupItem>
              </div>
            </div>
          </ToggleGroup>
        </div>
      </section>
    </>
  );
};

export default DisbursementType;
