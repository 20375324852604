import axios from "axios";
import moment from "moment";
import qs from "qs";
import toast from "react-hot-toast";
import ReactOnRails from "react-on-rails";

import routes from "constants/routes";

interface RemoveSubscriberModalProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

interface HandleRemoveSubscriberProps extends RemoveSubscriberModalProps {
  subscriberIdentifier: string;
  subscriptionIdentifier: string;
}

const queryParams = {
  authenticity_token: ReactOnRails.authenticityToken(),
};
const date = new Date();

const toggleRemoveSubscriberModal = ({
  showModal,
  setShowModal,
}: RemoveSubscriberModalProps) => setShowModal(!showModal);

export const todayDateFormatted = moment(date).format("Do MMMM, YYYY");

export const handleRemoveSubscriber = async ({
  subscriberIdentifier,
  subscriptionIdentifier,
  showModal,
  setShowModal,
}: HandleRemoveSubscriberProps) => {
  await axios
    .patch(
      `${routes.MANAGE.SUBSCRIBERS.UNSUBSCRIBE(subscriptionIdentifier, subscriberIdentifier)}?${qs.stringify(
        queryParams,
      )}`,
      {},
      { headers: { accept: "application/json" } },
    )
    .then(() => {
      toggleRemoveSubscriberModal({ showModal, setShowModal });
      toast.success("Subscriber removed and has been notified", {
        id: "subscribed-removed",
        duration: 2000,
      });
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    });
};
