import { useContext, useMemo } from "react";
import { useDispatch } from "react-redux";

import { Divider } from "components/Divider";
import { TableRecord } from "components/Table/TableRecord";
import Table from "components/Table/V2/TableV2";
import { Button } from "design_system/Button";
import { Dropdown } from "design_system/Dropdown/DropdownV2";
import { Add, CheckCircle, MoreVert, Pending } from "design_system/Icons";
import { RemoveUserModal } from "./RemoveUserModal";

import { useScreenDimension } from "hooks/useScreenDimension";
import { useTeamTable } from "./useTeamTable";
import { useUserOptions } from "./useUserOptions";

import { isAppPlatform } from "helpers/isAppPlatform";
import { setPagination } from "reducers/redux/TableReducer/tableReducer";
import type {
  TeamMember,
  TeamMemberRole,
  TeamWithPagination,
} from "types/Team";
import { teamRoutes } from "../../shared/constant";
import { UserContext } from "../../shared/context/UserProvider";

interface TeamTableProps {
  team: TeamWithPagination;
}

export const TeamTable = ({ team }: TeamTableProps) => {
  const { COLUMNS } = useTeamTable();
  const { isMobile } = useScreenDimension();
  const { showRemoveUserModal, setShowRemoveUserModal } =
    useContext(UserContext);
  const { getOptions } = useUserOptions();
  const dispatch = useDispatch();
  const isApp = isAppPlatform();

  const paginationMeta = {
    ...team.paginationMeta,
    itemName: "Users",
  };

  const columns = useMemo(() => COLUMNS, []);
  const tableData = useMemo(() => team.data, [team.data]);

  const handleSetPage = (newPageNumber: number) => {
    dispatch(setPagination({ tableName: "team", page: newPageNumber }));
  };

  const handleDisplayRoles = (roles: TeamMemberRole[]): string => {
    return roles.map((role: TeamMemberRole) => role.name).join(", ");
  };

  const handleGoToInviteUser = () => {
    window.location.href = teamRoutes.userInfo;
  };

  if (!isMobile) {
    return (
      <div className="mt-24 flex w-full justify-center">
        <Table
          name="team-table"
          columns={columns}
          data={tableData}
          paginationMeta={paginationMeta}
          onSetPage={handleSetPage}
          tableClass="table"
          headerRowClass="bg-black-25"
          headerCellClass="w-auto"
          rowProps={{
            selectable: false,
            selectedRows: [],
            setSelectedRows: () => {},
          }}
          emptyState={null}
        />
        <RemoveUserModal
          showModal={showRemoveUserModal}
          setShowModal={setShowRemoveUserModal}
        />
      </div>
    );
  }

  return (
    <div className="mt-48">
      {isApp && (
        <div className="mb-24 flex justify-end px-16">
          <Button
            variant="filled"
            size="md"
            label="Invite user"
            iconPosition="left"
            Icon={Add}
            onClick={handleGoToInviteUser}
          />
        </div>
      )}

      {tableData.map((user: TeamMember) => {
        const { isPending, fullName, roles, email, identifier } = user;
        const options = getOptions(user);

        return (
          <div key={identifier}>
            <TableRecord>
              <TableRecord.Tag
                tagProps={{
                  size: "xl",
                  iconOnly: true,
                  color: isPending ? "blue" : "green",
                  label: isPending ? "Invite pending" : "Active",
                  Icon: isPending ? Pending : CheckCircle,
                }}
              />

              <TableRecord.Column1
                label={fullName}
                supportingText={[email, handleDisplayRoles(roles)]}
              />

              <TableRecord.Column2
                className="self-end"
                label={
                  <Dropdown
                    type="icon-button"
                    iconButtonProps={{
                      size: "md",
                      Icon: MoreVert,
                    }}
                    showTooltipOnButton={false}
                    options={options}
                  />
                }
              />
            </TableRecord>
            <Divider />
          </div>
        );
      })}
      <RemoveUserModal
        showModal={showRemoveUserModal}
        setShowModal={setShowRemoveUserModal}
      />
    </div>
  );
};
