import type { ExpenseSettingsInitState } from "../typeDefs";

export const expenseSettingsInitState_tableCheckbox = {
  areAllSelected: false,
  isSingleRowSelected: false,
  selectedCount: 0,
  selectedRows: [],
  selectedRowsIdentifiers: [],
  selectionSource: undefined,
};
export const expenseSettingsInitState_tableCell = {
  categoryDetail: undefined,
  selectionSource: undefined,
};

export const expenseSettingsInitState: ExpenseSettingsInitState = {
  dashboard: {
    ...expenseSettingsInitState_tableCheckbox,
    ...expenseSettingsInitState_tableCell,
    // OTHERS
    page: 1,
    showDeleteConfirmationModal: false,
    showAddCategoryModal: false,
  },
};
