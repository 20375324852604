import { BrowserRouter, Route, Routes } from "react-router-dom";
import { OverviewContainer } from "./index";

import routes from "constants/routes";

export const OverviewRoot = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={routes.MANAGE.OVERVIEW.INDEX()}
          element={<OverviewContainer />}
        />
      </Routes>
    </BrowserRouter>
  );
};
