import { useState } from "react";
import { cn } from "utils/classNames";

import { Loader } from "design_system/Loader";
import { Tabs } from "design_system/Tab";
import { PageHeader } from "./PageHeader";
import { WalletActivity } from "./WalletActivity/WalletActivity";
import { WalletOverview } from "./WalletOverview/WalletOverview";

import { useScreenDimension } from "hooks/useScreenDimension";
import { useGetWalletDetail } from "./hooks/useGetWalletDetail";

import type { WalletTabs } from "../typeDefs";

export const DetailPage = () => {
  const { getWalletDetail } = useGetWalletDetail();
  const { isMobile } = useScreenDimension();

  const [currentTab, setCurrentTab] = useState<WalletTabs>("Overview");

  const wallet = getWalletDetail.data;

  if (getWalletDetail.isLoading && !getWalletDetail.isError) {
    return (
      <div className="flex h-screen items-center justify-center">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <PageHeader
        totalBalance={wallet.selectedWallet.totalBalanceFormatted}
        isTotalBalanceNegative={wallet.totalBalance.isNegative}
      />

      <section>
        <Tabs
          value={currentTab}
          onChange={(e, newTab) => setCurrentTab(newTab)}
          size={isMobile ? "md" : "lg"}
        >
          <Tabs.Tab value="Overview" label="Overview" />
          <Tabs.Tab value="Activity" label="Activity" />
        </Tabs>

        <section
          className={cn("md:px-40", {
            "mt-20 px-16 md:mt-40": currentTab === "Overview",
            "mt-20 md:mt-36": currentTab === "Activity",
          })}
        >
          {currentTab === "Overview" && <WalletOverview wallet={wallet} />}
          {currentTab === "Activity" && <WalletActivity wallet={wallet} />}
        </section>
      </section>
    </>
  );
};
