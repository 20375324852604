import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { useAppSelector } from "store";

import type { StartWithBasicsFormProps } from "../../shared/typeDefs";

const nameRegex = /^[a-zA-ZÀ-ÖØ-öø-ÿÆæŒœ\s'-]+$/;

const validationSchema = yup.object().shape({
  firstName: yup
    .string()
    .trim()
    .required("Please enter first name")
    .typeError("Please enter first name")
    .matches(
      nameRegex,
      "First name should contain only alphabetic characters, spaces or dashes",
    ),
  lastName: yup
    .string()
    .trim()
    .required("Please enter last name")
    .typeError("Please enter last name")
    .matches(
      nameRegex,
      "Last name should contain only alphabetic characters, spaces or dashes",
    ),
});

export const useStartWithBasicsForm = () => {
  const { data } = useAppSelector((state) => state.signUp);

  const initValues: StartWithBasicsFormProps = {
    firstName: data.firstName,
    lastName: data.lastName,
  };

  const form = useForm<StartWithBasicsFormProps>({
    defaultValues: initValues,
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
  });

  return {
    ...form,
  };
};
