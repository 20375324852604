import { ShareLinkCardContainer } from "components/ShareLinkCard";
import Card from "components/ui/Card";
import { convertToLocaleStringHelper } from "helpers/currency";
import { isNumberDefined } from "helpers/numberUtils";
import type IRailsContext from "interfaces/IRailsContext";
import type IShareLinkCardProps from "interfaces/IShareLinkCardProps";
import type ISubscriptionDetailsProps from "interfaces/subscriptions/ISubscriptionDetailsProps";
import { useGetRole } from "utils/rbacUtils";
import SubscriberListContainer from "../Subscribers/SubscriberListContainer";
import { DetailPageHeader } from "./DetailPageHeader";

interface ISubscriptionDetailContainerProps {
  subscription: ISubscriptionDetailsProps;
  railsContext: IRailsContext;
}

export const SubscriptionDetailsContainer: React.FC<
  ISubscriptionDetailContainerProps
> = (props) => {
  const {
    paymentLinkIdentifier,
    active,
    merchant,
    frequencyInterval,
    description,
    amount,
    amountCurrency,
    subscriptionUrl,
    startDateFormatted,
    endDateFormatted,
    repeatsEvery,
    totalPaid,
    totalSubscribers,
    processingFeePercentage,
    processingFeeAmount,
    returnUrl,
  } = props.subscription;

  const permissions =
    props.railsContext?.sessionContext?.currentUser?.permissions!;
  const { hasPermission } = useGetRole();

  const hasBasic = merchant.hasBasic;

  const linkCard: IShareLinkCardProps = {
    linkUrl: subscriptionUrl,
    active: active,
    identifier: paymentLinkIdentifier,
  };

  const isProcessingFeeDefined =
    isNumberDefined(processingFeePercentage) ||
    isNumberDefined(processingFeeAmount);

  const calculateFeeAmount = () => {
    const percentage = processingFeePercentage;
    if (percentage) {
      return convertToLocaleStringHelper(amount * (percentage / 100));
    }

    return;
  };

  const totalAmount = () => {
    let totalAmt = Number(amount);

    if (processingFeePercentage) {
      totalAmt += Number(calculateFeeAmount());
    }

    if (processingFeeAmount) {
      totalAmt += Number(processingFeeAmount);
    }

    return totalAmt;
  };

  return (
    <>
      <DetailPageHeader {...props.subscription} />

      <section className="subscription-details">
        {!hasBasic && active && (
          <ShareLinkCardContainer
            linkCard={linkCard}
            railsContext={props.railsContext}
          />
        )}
        <div className="subscription-details__overview">
          <Card hasBorder>
            <p className="subscription-details__overview__label">Total paid</p>
            <p className="subscription-details__overview__description">
              {totalPaid} <span>{amountCurrency}</span>
            </p>
          </Card>
          <Card hasBorder>
            <p className="subscription-details__overview__label">
              Total subscribers
            </p>
            <p className="subscription-details__overview__description">
              {totalSubscribers}
            </p>
          </Card>
        </div>
        <Card hasBorder>
          <div className="subscription-details__details">
            <h3>Details</h3>
          </div>
          <ul className="subscription-details__details__list">
            <li className="subscription-details__details__item">
              <p className="subscription-details__details__item__label">
                Amount
              </p>
              <p className="subscription-details__details__item__description">
                {amountCurrency} {convertToLocaleStringHelper(totalAmount())}
              </p>
            </li>
            {isProcessingFeeDefined && (
              <li className="subscription-details__details__item">
                <p className="subscription-details__details__item__label">
                  Processing fee
                </p>
                <p className="subscription-details__details__item__description">
                  {processingFeePercentage &&
                    `${Number(processingFeePercentage)}% (${amountCurrency} ${calculateFeeAmount()})`}
                  {processingFeeAmount &&
                    `${amountCurrency} ${convertToLocaleStringHelper(processingFeeAmount)}`}
                </p>
              </li>
            )}
            <li className="subscription-details__details__item">
              <p className="subscription-details__details__item__label">
                Repeat every
              </p>
              <p
                className="subscription-details__details__item__description"
                style={{
                  textTransform:
                    frequencyInterval === 1 ? "capitalize" : "inherit",
                }}
              >
                {repeatsEvery}
              </p>
            </li>
            <li className="subscription-details__details__item">
              <p className="subscription-details__details__item__label">
                Start date
              </p>
              <p className="subscription-details__details__item__description">
                {!props.subscription.startDateFormatted
                  ? "First payment date"
                  : startDateFormatted}
              </p>
            </li>
            <li className="subscription-details__details__item">
              <p className="subscription-details__details__item__label">
                {props.subscription.endDateFormatted === "Never"
                  ? "End date"
                  : "End after"}
              </p>
              <p className="subscription-details__details__item__description">
                {endDateFormatted}
              </p>
            </li>
            <li className="subscription-details__details__item">
              <p className="subscription-details__details__item__label">
                Description
              </p>
              <p className="subscription-details__details__item__description">
                {description}
              </p>
            </li>
            <li className="subscription-details__details__item">
              <p className="subscription-details__details__item__label">
                Redirect URL
              </p>
              <p className="subscription-details__details__item__description">
                {returnUrl}
              </p>
            </li>
          </ul>
        </Card>
        {hasPermission("payments:read", permissions) && (
          <SubscriberListContainer />
        )}
      </section>
    </>
  );
};
