import axios from "axios";
import routes from "constants/routes";
import { copyText } from "pages/Payment/Pay/helpers";
import qs from "qs";
import toast from "react-hot-toast";
import ReactOnRails from "react-on-rails";
import { scriptString } from "./constant";

export const fetchApiKey = async () => {
  const queryParams = {
    authenticity_token: ReactOnRails.authenticityToken(),
  };

  const response = await axios.get(
    `${routes.MANAGE.ACCOUNT_SETTINGS.API_KEY()}?${qs.stringify(queryParams)}`,
    {
      headers: { accept: "application/json" },
    },
  );

  return response.data.api_key;
};

export const copyAPIkey = () => {
  try {
    return (window as any)?.dataLayer?.push({
      event: "copy-developer-key",
      eventCategory: "button",
      eventAction: "click",
      eventLabel: "copyDeveloperKey",
    });
  } catch (error) {
    console.error("An error occurred in copyAPIkey:", error);
  }
};

export const downloadPrestaShopExt = () => {
  try {
    return (window as any)?.dataLayer?.push({
      event: "presta-shop-plugin-download",
      eventCategory: "button",
      eventAction: "click",
      eventLabel: "prestaShopPluginDownload",
    });
  } catch (error) {
    console.error("An error occurred in downloadPrestaShopExt:", error);
  }
};

export const downloadWooCommerceExt = () => {
  try {
    return (window as any)?.dataLayer?.push({
      event: "woocommerce-plugin-download",
      eventCategory: "button",
      eventAction: "click",
      eventLabel: "wooCommercePluginDownload",
    });
  } catch (error) {
    console.error("An error occurred in downloadWooCommerceExt:", error);
  }
};

export const downloadMagentoExt = () => {
  try {
    return (window as any)?.dataLayer?.push({
      event: "magento-plugin-download",
      eventCategory: "button",
      eventAction: "click",
      eventLabel: "magentoPluginDownload",
    });
  } catch (error) {
    console.error("An error occurred in downloadMagentoExt:", error);
  }
};

export const handleCopyScript = () => {
  try {
    copyText(scriptString);
    toast.success("Script copied!", {
      duration: 2000,
      style: { fontSize: "16px" },
      id: "toast-script-copied",
      ariaProps: {
        role: "status",
        "aria-live": "polite",
      },
    });
  } catch (error) {
    toast.error("Oops! Something went wrong. Try again later", {
      duration: 3000,
    });
  }
};
