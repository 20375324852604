import { Button } from "design_system/Button";
import { ChevronLeft } from "design_system/Icons";
import { SearchInput } from "../SearchInput";
import { GlobalSearchResults } from "./GlobalSearchResults/GlobalSearchResults";

import { useSearchField } from "../../hooks/useSearchField";

export const GlobalSearchMobileView = ({ setShow, businessName }) => {
  const {
    searchText,
    searchResults,
    getGlobalSearchResults,
    handleTextChange,
  } = useSearchField();

  return (
    <>
      <header className="flex items-center justify-between gap-16 px-16 py-12">
        <div className="min-w-40">
          <Button
            variant="outlined"
            size="md"
            iconOnly
            Icon={ChevronLeft}
            classes="opacity-100 cursor-pointer"
            onClick={() => {
              setShow(false);
            }}
          />
        </div>
        <SearchInput
          placeholder={businessName ? `Search ${businessName}` : "Search"}
          value={searchText}
          hasText={!!searchText}
          onChange={(e: any) => handleTextChange(e.target.value)}
          isLoading={getGlobalSearchResults.isLoading}
          onClose={() => handleTextChange("")}
        />
      </header>

      <GlobalSearchResults searchResults={searchResults} />
    </>
  );
};
