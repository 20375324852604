import UserInformation from "./UserInformation";
import UserRoles from "./UserRoles";

import type IRailsContext from "interfaces/IRailsContext";

type TInviteUser = {
  currentStep: number;
  setCurrentStep: any;
  railsContext: IRailsContext;
};

const InviteUser = ({
  currentStep,
  setCurrentStep,
  railsContext,
}: TInviteUser) => {
  const merchantName = railsContext?.sessionContext?.currentMerchant?.name;

  return (
    <>
      {currentStep === 1 && (
        <UserInformation
          setCurrentStep={setCurrentStep}
          merchantName={merchantName}
        />
      )}

      {currentStep === 2 && (
        <UserRoles setCurrentStep={setCurrentStep} type="invite" />
      )}
    </>
  );
};

export default InviteUser;
