import { colorsV2 } from "constants/colors";
import type { SvgIconProps } from "types/Icon";

export const DoNotDisturbOn = ({
  size,
  fill = colorsV2.text[1],
  ...rest
}: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g id="Name=do_not_disturb_on, Size=24">
        <path
          d="M7.85166 12.7766H16.1484C16.3888 12.7766 16.5908 12.6944 16.7545 12.5298C16.9182 12.3653 17 12.1623 17 11.9206C17 11.679 16.9182 11.4773 16.7545 11.3157C16.5908 11.1542 16.3888 11.0734 16.1484 11.0734H7.85166C7.61126 11.0734 7.40923 11.1556 7.24556 11.3201C7.08188 11.4847 7.00004 11.6877 7.00004 11.9294C7.00004 12.171 7.08188 12.3726 7.24556 12.5342C7.40923 12.6958 7.61126 12.7766 7.85166 12.7766ZM12.0075 22.1494C10.6041 22.1494 9.28714 21.8838 8.05649 21.3525C6.82584 20.8212 5.74951 20.0945 4.82751 19.1725C3.90551 18.2505 3.17885 17.1738 2.64754 15.9425C2.11624 14.7112 1.85059 13.3931 1.85059 11.9883C1.85059 10.5835 2.11624 9.2652 2.64754 8.03332C3.17885 6.80142 3.90519 5.72853 4.82656 4.81464C5.74794 3.90078 6.82445 3.17729 8.05609 2.64419C9.28774 2.11109 10.6061 1.84454 12.0112 1.84454C13.4163 1.84454 14.7351 2.11094 15.9674 2.64374C17.1998 3.17653 18.2728 3.89959 19.1863 4.81294C20.0999 5.72629 20.8232 6.80032 21.3561 8.03502C21.889 9.26974 22.1555 10.5892 22.1555 11.9933C22.1555 13.3973 21.8889 14.7145 21.3558 15.9451C20.8227 17.1757 20.0993 18.2502 19.1854 19.1686C18.2715 20.0871 17.1976 20.8135 15.9636 21.3479C14.7296 21.8823 13.4109 22.1494 12.0075 22.1494ZM12.0125 20.4462C14.3549 20.4462 16.3461 19.6233 17.9862 17.9774C19.6262 16.3315 20.4462 14.3349 20.4462 11.9875C20.4462 9.64511 19.6278 7.65389 17.9909 6.01384C16.3539 4.37381 14.356 3.55379 11.997 3.55379C9.66099 3.55379 7.66978 4.37225 6.02341 6.00917C4.37703 7.64609 3.55384 9.64403 3.55384 12.003C3.55384 14.339 4.37677 16.3303 6.02264 17.9766C7.6685 19.623 9.66514 20.4462 12.0125 20.4462Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};
