import { cn } from "utils/classNames";

interface IProps {
  hasBorder?: boolean;
  classes?: string;
  children?: any;
}

const Card = ({ hasBorder, classes, children }: IProps) => {
  return (
    <div className={cn("card", { "card--border": hasBorder }, `${classes}`)}>
      {children}
    </div>
  );
};

export default Card;
