import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

interface VerifyEmailFormProps {
  otp: number;
}

const validationSchema = yup.object().shape({
  otp: yup
    .number()
    .required("Please enter your verification code.")
    .typeError("Code is invalid. Please try again."),
});

export const useVerifyEmailOtpForm = () => {
  const form = useForm<VerifyEmailFormProps>({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
  });

  return {
    ...form,
  };
};
