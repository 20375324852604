import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import {
  FlagImage,
  defaultCountries,
  parseCountry,
  usePhoneInput,
} from "react-international-phone";

import { Dropdown } from "design_system/Dropdown/DropdownV2";
import { TextInput } from "../TextInput";

// Defining the list of ISO2 codes of countries to be excluded
const excludedCountries = ["cu", "ir", "kp", "sy", "ae"];

// Filter out excluded countries from the defaultCountries array
const filteredCountries = defaultCountries.filter(
  (country) => !excludedCountries.includes(country[1]),
);
filteredCountries.push(["United Arab Emirates", "ae", "971", ".. ... ...."]);

// Sort the filteredd countries alphabetcially by country name
filteredCountries.sort((a, b) => a[0].localeCompare(b[0]));

export interface IPhoneNumberInput {
  className?: string;
  error: boolean;
  helperText: string;
  onChange: any;
  onBlur: () => void;
  value: string;
}

export const PhoneNumberInput = ({
  className,
  error,
  helperText,
  onChange,
  onBlur,
  value,
}: IPhoneNumberInput) => {
  const { inputValue, handlePhoneValueChange, inputRef, country, setCountry } =
    usePhoneInput({
      defaultCountry: "ae",
      value: value ?? "",
      countries: filteredCountries,
      onChange: (data) => {
        onChange(data.phone);
      },
    });

  return (
    <TextInput
      value={inputValue}
      onChange={handlePhoneValueChange}
      onBlur={onBlur}
      type="tel"
      inputRef={inputRef}
      InputProps={{
        startAdornment: (
          <InputAdornment
            position="start"
            style={{ marginTop: "unset", paddingTop: "2px" }}
          >
            <Dropdown
              type="select"
              borderless
              selectProps={{
                // NOTE: Empty className below is required to ignore the styles coming in from theme.ts
                className: "",
                MenuProps: {
                  style: {
                    left: "-34px",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                },
                sx: {
                  width: "45px",
                  padding: 0,
                  border: "unset",
                  ".MuiSelect-select": {
                    paddingRight: "24px !important",
                  },
                  ".MuiSelect-select ~ .MuiSelect-icon": {
                    right: 0,
                  },
                },
                value: country.iso2,
                onChange: (e: any) => setCountry(e.target.value),
                renderValue: (value: any) => <FlagImage iso2={value} />,
              }}
            >
              {filteredCountries.map((c) => {
                const country = parseCountry(c);
                return (
                  <MenuItem key={country.iso2} value={country.iso2}>
                    <FlagImage
                      iso2={country.iso2}
                      style={{ marginRight: "8px" }}
                      size="25px"
                    />
                    <p className="mr-8 text-text-color-01">{country.name}</p>
                    <p className="text-text-color-03">+{country.dialCode}</p>
                  </MenuItem>
                );
              })}
            </Dropdown>
          </InputAdornment>
        ),
      }}
      error={error}
      helperText={helperText}
      className={className}
    />
  );
};
