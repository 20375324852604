import { useContext } from "react";

import { Button } from "design_system/Button";
import { Modal } from "design_system/Modal";

import { useRemoveUser } from "../../shared/hooks/useRemoveUser";

import { snack } from "design_system/Snackbar";
import { UserContext } from "../../shared/context/UserProvider";

interface RemoveUserModalProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const RemoveUserModal = ({
  showModal,
  setShowModal,
}: RemoveUserModalProps) => {
  const { userInfo } = useContext(UserContext);
  const { identifier, fullName } = userInfo;
  const { removeUser } = useRemoveUser(identifier);

  const handleRemoveUser = () => {
    removeUser.mutateAsync().then(() => {
      setShowModal(false);
      snack({
        title: `${fullName} was successfully removed from your team`,
        leadingElement: "icon",
        variant: "success",
      });
    });
  };

  return (
    <Modal
      show={showModal}
      onClose={() => setShowModal(false)}
      modalWidth="48rem"
      keepMounted
    >
      <div className="space-y-24">
        <div className="space-y-16 text-center">
          <h1 className="header-medium">
            Are you sure you want to remove this user?
          </h1>
          <p className="body-medium">
            The user will permanently be removed from your team and won't be
            able to access their account.
          </p>
        </div>

        <div className="flex items-center gap-16">
          <Button
            variant="outlined"
            size="lg"
            label="Cancel"
            classes="w-full"
            onClick={() => setShowModal(false)}
          />
          <Button
            variant="critical"
            size="lg"
            label="Yes, I'm sure"
            classes="w-full"
            loading={removeUser.isLoading}
            onClick={handleRemoveUser}
          />
        </div>
      </div>
    </Modal>
  );
};
