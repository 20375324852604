import { useCallback, useEffect, useState } from "react";
import type * as Yup from "yup";

/**
 * Hook to parse Window Location Search
 * It's used to get the search query params from the URL and validate them against a schema
 * @param schema - The schema to validate the search query params against
 * @returns An object containing the parsed search query params and a function to update them
 */
export const useSearchQueryParams = <T extends Record<string, any>>(
  schema: Yup.AnySchema<T>,
) => {
  const [parsed, setParsed] = useState<T>(() =>
    schema.cast(
      Object.fromEntries(new URLSearchParams(window.location.search)),
    ),
  );

  const parse = useCallback(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const _parsed = schema.validateSync(Object.fromEntries(queryParams), {
      stripUnknown: true,
    }) as T;

    setParsed((prev) => {
      // Only update state if it has changed
      if (JSON.stringify(prev) !== JSON.stringify(_parsed)) {
        return _parsed;
      }

      return prev;
    });
  }, [schema]);

  useEffect(() => {
    const handlePopState = () => {
      parse(); // Re-parse when the URL changes
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [parse]);

  const setSearchQueryParams = useCallback(
    (newParams: Partial<T>) => {
      const updatedParams = { ...parsed, ...newParams };
      const validParams = Object.entries(updatedParams).filter(
        ([, value]) => value !== undefined,
      );

      const searchString = new URLSearchParams(validParams).toString();
      window.history.pushState(
        null,
        "",
        searchString ? `?${searchString}` : window.location.pathname,
      );

      // Trigger the parse to update state after modifying the URL
      parse();
    },
    [parsed, parse],
  );

  return { searchParams: parsed, setSearchQueryParams };
};
