import { defaultDateFilter } from "./date/dateFilters";

export const filterByAllOption = {
  id: "all",
  value: "all",
  label: "All",
};

export const GENERIC_TABLE_FILTERS = {
  card_holder_identifiers: [filterByAllOption],
  card_identifiers: [filterByAllOption],
  expense_category_identifiers: [filterByAllOption],
  receipt_attached: filterByAllOption,
  statuses: [filterByAllOption],
  types: [filterByAllOption],
  user_identifiers: [filterByAllOption],
  // DATE RANGE SPECIFIC FILTERS
  date_range: defaultDateFilter,
  start_date: null,
  end_date: null,
};
