import TextButton from "components/ui/TextButton";

export const EmptyState = () => {
  const handleClick = () => {
    window.open(
      "https://help.mamopay.com/en/articles/7234092-what-you-can-do-with-mamo",
      "_blank",
      "noopener",
    );
  };

  return (
    <section className="flex w-full flex-col gap-32 md:w-1/2">
      <h1 className="header-small md:header-medium">Balances</h1>

      {/* AVAILABLE BALANCE SECTION */}
      <div className="flex flex-col gap-16">
        <div className="flex justify-between">
          <h2 className="label-large md:header-small">Available</h2>
          <h2 className="label-large md:header-small">AED 0.00</h2>
        </div>

        <p className="body-large text-text-color-02">
          Funds you've received from payments will be shown here after the
          settlement period.{" "}
          <TextButton
            id="resend-otp"
            type="button"
            color="primary"
            size="md"
            label="Learn more"
            onClick={handleClick}
          />
        </p>
      </div>

      {/* INCOMING BALANCE SECTION */}
      <div className="flex flex-col gap-16">
        <div className="flex justify-between">
          <h2 className="label-large md:header-small">Incoming</h2>
          <h2 className="label-large md:header-small">AED 0.00</h2>
        </div>

        <p className="body-large text-text-color-02">
          Funds that are pending settlement will be shown here with a breakdown
          of when you can expect each amount
        </p>
      </div>
    </section>
  );
};
