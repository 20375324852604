import axios from "axios";
import qs from "qs";
import ReactOnRails from "react-on-rails";

import { endpoints } from "constants/apiEndpoints";

type IRequestData = {
  first_name: string;
  last_name: string;
  email: string;
  role_ids: string[];
};

export const useInviteUser = async (data: any) => {
  try {
    const queryParams = {
      authenticity_token: ReactOnRails.authenticityToken(),
    };

    const requestData: IRequestData = {
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      role_ids: data.roles,
    };

    const url = endpoints.MANAGE.API.SETTINGS.TEAM.INVITE_USER;
    const response = await axios.post(
      `${url}?${qs.stringify(queryParams)}`,
      requestData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    return response;
  } catch (error) {
    console.error(`[useInviteUser] Trouble inviting user: ${error}`);
    return error.response;
  }
};
