import { useMutation } from "@tanstack/react-query";
import axios, { type AxiosError } from "axios";
import { queryClient } from "config/reactQueryClient";

import { endpoints } from "constants/apiEndpoints";
import { stringifiedQueryParams } from "constants/queryParams";
import { snack } from "design_system/Snackbar";
import { SentryLoggingService } from "init/SentryLoggingService";
import { extractFormDataFields } from "utils/fileUtils";

export const useInitiateRepayment = () => {
  const initiateRepayment = useMutation({
    mutationKey: ["initiateRepayment"],
    mutationFn: async (formData: any) => {
      return await axios.post(
        `${endpoints.MANAGE.API.CREDIT_ACCOUNTS.REPAYMENTS}?${stringifiedQueryParams}`,
        formData,
      );
    },
    onSuccess: () => {
      snack({
        title: "Repayment successful",
        leadingElement: "icon",
        variant: "success",
      });
      queryClient.invalidateQueries({ queryKey: ["getWalletsAndCards"] });
      queryClient.invalidateQueries({ queryKey: ["getCreditCardDetail"] });
      queryClient.invalidateQueries({ queryKey: ["getRepaymentTransactions"] });
      queryClient.invalidateQueries({ queryKey: ["getCreditCardActivities"] });
    },
    onError: (error: AxiosError, variables) => {
      const payload = extractFormDataFields(variables);
      const messages = error.response.data.messages;

      snack({
        title: "Repayment failed",
        leadingElement: "icon",
        variant: "critical",
      });

      SentryLoggingService.captureException(error.message, {
        feature: "[Finance][Credit Card]",
        function: "useInitiateRepayment",
        file: "useInitiateRepayment.tsx",
        data: payload ? JSON.stringify(payload) : null,
        errorDetails: messages ? JSON.stringify(messages) : null,
      });
    },
  });

  return { initiateRepayment };
};
