import type { SvgIconProps } from "types/Icon";

export const VerifiedUserFilled = ({ ...props }: SvgIconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>Verified user</title>
      <g clipPath="url(#clip0_4203_19747)">
        <mask
          id="mask0_4203_19747"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="24"
          height="24"
        >
          <rect width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_4203_19747)">
          <path
            d="M10.9498 12.6343L9.57376 11.2643C9.36576 11.0563 9.12143 10.9533 8.84076 10.9553C8.56009 10.9573 8.31584 11.0622 8.10801 11.27C7.90001 11.478 7.79601 11.7245 7.79601 12.0095C7.79601 12.2945 7.90001 12.541 8.10801 12.749L10.148 14.8143C10.376 15.0421 10.6433 15.156 10.9498 15.156C11.2563 15.156 11.5235 15.0421 11.7515 14.8143L15.9298 10.6358C16.1376 10.428 16.2415 10.1795 16.2415 9.89054C16.2415 9.60154 16.1376 9.35304 15.9298 9.14504C15.7218 8.93721 15.4733 8.83329 15.1843 8.83329C14.8953 8.83329 14.6468 8.93721 14.439 9.14504L10.9498 12.6343ZM11.9998 22.0913C11.8711 22.0913 11.7488 22.082 11.633 22.0633C11.517 22.0446 11.401 22.0166 11.285 21.9793C9.00718 21.2215 7.18959 19.8081 5.83226 17.7393C4.47509 15.6703 3.79651 13.4572 3.79651 11.1V6.42879C3.79651 5.95246 3.93326 5.52371 4.20676 5.14254C4.48043 4.76137 4.83676 4.48579 5.27576 4.31579L11.204 2.09579C11.4653 2.00046 11.7306 1.95279 11.9998 1.95279C12.2689 1.95279 12.5342 2.00046 12.7955 2.09579L18.7238 4.31579C19.1628 4.48579 19.5191 4.76137 19.7928 5.14254C20.0663 5.52371 20.203 5.95246 20.203 6.42879V11.1C20.203 13.4572 19.5244 15.6703 18.1673 17.7393C16.8099 19.8081 14.9923 21.2215 12.7145 21.9793C12.5985 22.0166 12.4825 22.0446 12.3665 22.0633C12.2507 22.082 12.1284 22.0913 11.9998 22.0913Z"
            fill="#23272E"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_4203_19747">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
