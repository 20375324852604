import { useState } from "react";

import { Button } from "design_system/Button";
import { DoNotDisturbOn } from "design_system/Icons";
import { MasterHeader } from "design_system/MasterHeader";
import { Modal } from "design_system/Modal";
import { Tag } from "design_system/Tag";

import routes from "constants/routes";
import { isAppPlatform } from "helpers/isAppPlatform";
import { handleRemoveSubscriber, todayDateFormatted } from "./helpers";

interface SubscriberDetailPageHeaderProps {
  joinedDateFormatted: string;
  subscriber: {
    active: boolean;
    identifier: string;
    name: string;
  };
  subscription: {
    identifier: string;
  };
}

export const DetailPageHeader = ({
  joinedDateFormatted,
  subscriber,
  subscription,
}: SubscriberDetailPageHeaderProps) => {
  const { active, identifier: subscriberIdentifier, name } = subscriber;
  const { identifier: subscriptionIdentifier } = subscription;

  const [showModal, setShowModal] = useState(false);

  const handleGoBackToSubscriptionDetails = () => {
    window.location.href = routes.MANAGE.SUBSCRIPTIONS.SHOW(
      subscriptionIdentifier,
    );
  };

  if (isAppPlatform()) {
    return <></>;
  }

  return (
    <>
      <MasterHeader onBack={handleGoBackToSubscriptionDetails}>
        <MasterHeader.Label>Subscriber</MasterHeader.Label>
        <MasterHeader.Title>{subscriber.name}</MasterHeader.Title>
        <MasterHeader.Tag>
          <Tag
            size="md"
            color={active ? "green" : "gray"}
            label={active ? "Active" : "Unsubscribed"}
          />
        </MasterHeader.Tag>
        <MasterHeader.Description>{`Joined on ${joinedDateFormatted}`}</MasterHeader.Description>

        {active && (
          <MasterHeader.Actions>
            <Button
              variant="outlined"
              size="md"
              label="Remove subscriber"
              iconPosition="left"
              Icon={DoNotDisturbOn}
              onClick={() => setShowModal(true)}
            />
          </MasterHeader.Actions>
        )}
      </MasterHeader>

      <Modal
        show={showModal}
        title="Are you sure you want to remove this subscriber?"
        modalWidth="45rem"
        onClose={() => setShowModal(false)}
      >
        <div className="space-y-24">
          <div className="body-medium text-center">
            {name} will be notified of this cancellation instantly and the
            subscriber will not be charged after {todayDateFormatted}
          </div>

          <div className="flex flex-col gap-12">
            <Button
              variant="outlined"
              size="md"
              label="No, I don't want to remove this subscriber"
              onClick={() => setShowModal(false)}
              classes="w-full"
            />
            <Button
              variant="critical"
              size="md"
              label="Yes, remove this subscriber"
              onClick={() =>
                handleRemoveSubscriber({
                  subscriberIdentifier,
                  subscriptionIdentifier,
                  showModal,
                  setShowModal,
                })
              }
            />
          </div>
        </div>
      </Modal>
    </>
  );
};
