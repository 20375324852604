import { ItemRow } from "design_system/LabeledGrid/ItemRow";
import { SectionTitle } from "design_system/LabeledGrid/SectionTitle";

/**
 * This component is mostly used inside the Sidesheet component
 */
export const LabeledGrid = {
  SectionTitle: SectionTitle,
  ItemRow: ItemRow,
};
