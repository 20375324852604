import i18n from "../i18n";

type InterpolationOptions = {
  [key: string]: string | number;
};

export function getTranslatedMessage(key, options: InterpolationOptions = {}) {
  if (i18n.isInitialized) {
    return i18n.t(key, options);
  }
  return key; // Fallback to returning the key if translations are not initialized yet
}

export function changeLanguage(language: "ar" | "en") {
  const fallbackLanguage =
    typeof i18n.options.fallbackLng === "string"
      ? i18n.options.fallbackLng
      : "en";

  const switchLanguage = (lang: string) => {
    i18n.changeLanguage(lang).catch((error) => {
      console.error(`Failed to change language to ${lang}:`, error);
    });
  };

  if (
    Array.isArray(i18n.options.supportedLngs) &&
    i18n.options.supportedLngs.includes(language)
  ) {
    switchLanguage(language);
  } else {
    console.warn(
      `Language ${language} is not supported. Falling back to default.`,
    );
    switchLanguage(fallbackLanguage);
  }
}

/**
 * Returns the currently active language.
 * @returns {string} The current language code (e.g., 'en', 'ar').
 */
export function getCurrentLanguage(): string {
  return i18n.language || (i18n.options.fallbackLng as string) || "en";
}
