import { isEmpty } from "lodash";

import { Divider } from "components/Divider";
import { FirstTimerEmptyState } from "../../FirstTimerEmptyState";
import { SectionItems } from "../../SectionItems";
import { SectionLabel } from "../../SectionLabel";

export const GlobalSearchResults = ({ searchResults }) => {
  if (isEmpty(searchResults)) {
    return (
      <section className="mt-112">
        <FirstTimerEmptyState />
      </section>
    );
  }

  return (
    <section>
      {searchResults.map((group, index) => {
        return (
          <section key={`${group.sectionId}-${index}`}>
            <SectionLabel label={group.sectionName} />

            <SectionItems {...group} />

            {index < searchResults.length - 1 && <Divider classNames="my-8" />}
          </section>
        );
      })}
    </section>
  );
};
