import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import qs from "qs";
import toast from "react-hot-toast";
import ReactOnRails from "react-on-rails";

import { endpoints } from "constants/apiEndpoints";
import { generalErrors } from "constants/errorMessages";
import { SentryLoggingService } from "init/SentryLoggingService";

export const useRefreshAccountingInfo = () => {
  const refreshAccountingInfo = useMutation({
    mutationKey: ["refreshAccountingInfo"],
    mutationFn: async () => {
      const queryParams = {
        authenticity_token: ReactOnRails.authenticityToken(),
      };

      await axios.patch(
        `${endpoints.MANAGE.API.SETTINGS.ACCOUNTING.REFRESH_INFO}?${qs.stringify(queryParams)}`,
      );
    },
    onError: (error: any) => {
      toast.error(generalErrors.SOMETHING_WENT_WRONG, {
        duration: 3000,
      });

      SentryLoggingService.captureException(error.message, {
        feature: "[Settings][Accounting Integration",
        file: "useRefreshAccountingInfo.ts",
        function: "useRefreshAccountingInfo",
      });
    },
  });

  return {
    refreshAccountingInfo,
  };
};
