import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { Cookies } from "react-cookie";

import { stringifiedQueryParams } from "constants/queryParams";
import routes from "constants/routes";
import { SentryLoggingService } from "init/SentryLoggingService";

export const useChangeUsersAccount = () => {
  const cookies = new Cookies();

  const changeUsersAccount = useMutation({
    mutationKey: ["changeUsersAccount"],
    mutationFn: async (identifier: string) => {
      return await axios.post(
        `${routes.MANAGE.ACCOUNT_SETTINGS.SWITCH_BUSINESS()}?${stringifiedQueryParams}`,
        { identifier },
        { headers: { accept: "application/json" } },
      );
    },
    onSuccess: async (data) => {
      // Remove merchant info api detail from localstorage
      localStorage.removeItem("merchant");

      // Remove payment search & filters and page state
      localStorage.removeItem("paymentsFilters");
      localStorage.removeItem("paymentsQuery");
      localStorage.removeItem("paymentsPage");

      // Remove paymentLinks search & filters and page state
      localStorage.removeItem("paymentLinkFilters");
      localStorage.removeItem("paymentLinkPage");
      localStorage.removeItem("paymentLinkTab");

      // Remove cookies for the active primary navigation
      cookies.remove("activePrimaryItem", { path: "/" });

      window.location.href = data.data.url;
    },
    onError: async (error: any, payload) => {
      SentryLoggingService.captureException(error.message, {
        feature: "[Global][Switching businesses]",
        function: "changeUsersAccount",
        file: "useChangeUsersAccount",
        data: payload ? JSON.stringify(payload) : null,
      });
    },
  });

  return {
    changeUsersAccount,
  };
};
