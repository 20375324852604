import { BrowserRouter, Route, Routes } from "react-router-dom";

import routes from "constants/routes";
import { POSContainer } from "./POSContainer";

export const POSRoot = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={routes.MANAGE.POS.INDEX()} element={<POSContainer />} />
      </Routes>
    </BrowserRouter>
  );
};
