import { type QueryObserverResult, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { get, omit } from "lodash";
import qs from "qs";

import { endpoints } from "constants/apiEndpoints";
import { PAGINATION_META_INIT_STATE } from "constants/table";
import { SentryLoggingService } from "init/SentryLoggingService";
import type { ExpenseCategoriesWithPagination } from "types/Expenses";

interface useGetExpenseCategoriesParams {
  mode?: "withPagination" | "withoutPagination";
  page?: number;
}

type GetExpenseCategories = QueryObserverResult<
  ExpenseCategoriesWithPagination,
  Error
>;

const defaultParams: useGetExpenseCategoriesParams = {
  mode: "withoutPagination",
  page: 1,
};

export const useGetExpenseCategories = (
  params: useGetExpenseCategoriesParams = defaultParams,
) => {
  const queryParams = {
    page: params.page,
  };

  const getExpenseCategories: GetExpenseCategories = useQuery({
    queryKey: [
      "useGetExpenseCategories",
      ...(params.mode === "withPagination" ? [{ page: params.page }] : []),
    ],
    queryFn: async () => {
      // Create URL based on mode param
      let url = endpoints.MANAGE.API.EXPENSES.CATEGORIES;
      if (params.mode === "withPagination") {
        url = `${url}?${qs.stringify(queryParams)}`;
      }

      // API Call
      const response = await axios.get(url);

      // Response extraction
      const categories = get(response, "data.data", []);
      const paginationMeta = get(
        response,
        "data.paginationMeta",
        PAGINATION_META_INIT_STATE,
      );

      // Response formatting for tables
      if (params.mode === "withPagination") {
        return {
          data: categories,
          paginationMeta: paginationMeta,
        };
      }

      // Response formatting for dropdowns
      const categoryOptions = categories.map((category: any) => {
        const formattedCategory = {
          ...category,
          value: category.name,
          label: category.name,
        };
        return omit(formattedCategory, ["name"]);
      });
      return categoryOptions;
    },
    onError: (error: any) => {
      SentryLoggingService.captureException(error.message, {
        feature: "[Expense][DashboardTable]",
        file: "useGetExpenseCategories.ts",
        function: "useGetExpenseCategories",
      });
    },
  });

  return {
    getExpenseCategories,
  };
};
