import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import axios from "axios";
import { reverse } from "lodash";
import moment from "moment";
import { useEffect, useReducer, useState } from "react";

import LoaderBusiness from "components/Loader/LoaderBusiness";
import { Help } from "design_system/Icons";
import { BarChart } from "design_system/Statistics/Charts/BarChart";
import { ChartCard } from "design_system/Statistics/Charts/ChartCard";
import { StatCard } from "design_system/Statistics/StatCard";
import { SettlementBalance } from "features/Wallet/WalletV1/SettlementBalance";

import { colorsV2 } from "constants/colors";
import routes from "constants/routes";
import { capitalizeFirstLetter } from "helpers/capitalizeFirstLetter";
import { formatAmount } from "helpers/currency";
import { isAppPlatform } from "helpers/isAppPlatform";
import { useGetUpcomingSettlements } from "hooks/useGetUpcomingSettlements";
import { useScreenDimension } from "hooks/useScreenDimension";
import analyticsReducer, {
  AnalyticsActionTypes,
  initialState,
} from "../reducers/analyticsReducer";
import { isChartEmptyData } from "../shared/chartUtils";
import type {
  TAnalyticsFrequency,
  TDropdownFrequency,
} from "../shared/typeDefs";
import { nopParams, revenueParams } from "./constant";

export const RevenueInsights = () => {
  const [revenueValue, setRevenueValue] =
    useState<TDropdownFrequency>("weekly");
  const [nopValue, setNopValue] = useState<TDropdownFrequency>("weekly");
  const [state, dispatch] = useReducer(analyticsReducer, initialState);
  const { analytics, isLoading } = state;
  const { isMobile } = useScreenDimension();
  const { getUpcomingSettlements } = useGetUpcomingSettlements({
    enabled: isAppPlatform(),
  });
  const { data, isLoading: loadingUpcomingSettlements } =
    getUpcomingSettlements;

  const showLoadingUpcomingSettlements =
    isAppPlatform() && loadingUpcomingSettlements;

  const fetchAnalyticsData = (
    analyticsKind: string,
    frequencyType: TAnalyticsFrequency,
  ) => {
    axios
      .get(routes.MANAGE.ANALYTICS.INDEX(), {
        headers: { accept: "application/json" },
        params: {
          kind: [analyticsKind],
          perPage: 12,
          frequency_type: [frequencyType],
        },
      })
      .then((res) => {
        dispatch({
          type: AnalyticsActionTypes.SET_DATA,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchAnalyticsKind = (
    analyticsKind: string,
    frequencyType: TAnalyticsFrequency,
    /** Setting reversed to true will have show the last data first and first data last. This would be needed for BarCharts as the order of oldest data will come first and newest data last. In Donut chart, this would be different */
    reversed: boolean,
  ) => {
    const data = analytics
      .filter((item: any) => item.kind.indexOf(analyticsKind) !== -1)
      .filter((item: any) => item.frequencyType.indexOf(frequencyType) !== -1);

    return reversed ? reverse(data) : data;
  };

  const handleDownloadAnalyticsCsv = (kind: string, frequencyType: string) => {
    window.location.href = `${routes.MANAGE.ANALYTICS.DOWNLOAD_CSV(kind, frequencyType)}`;
  };

  const handleWeeklyDateFormat = (valueDatum) => {
    const { week, year } = valueDatum;

    // Set the first day of the year to ensure correct week calculation
    const firstDayOfYear = moment().year(year).startOf("year");

    const startOfWeek = firstDayOfYear
      .clone()
      .add(week, "weeks")
      .startOf("isoWeek");
    const endOfWeek = startOfWeek.clone().endOf("isoWeek");

    const formattedStartDate = startOfWeek.format("MMM D");
    const formattedEndDate = endOfWeek.format("MMM D");

    return `${formattedStartDate} - ${formattedEndDate}, ${year}`;
  };

  const handleRevenueTooltipSubtext = (valueDatum: any) => {
    const { month, year, createdAt } = valueDatum;

    if (revenueValue === "weekly") {
      return handleWeeklyDateFormat(valueDatum);
    }

    if (revenueValue === "daily") {
      return `${moment(createdAt).format("MMM D, YYYY")}`;
    }

    return `${moment(month, "M").format("MMM")} ${year}`;
  };

  const handleRevenueChangeTooltipSubtext = (valueDatum: any) => {
    const { month, year } = valueDatum;

    if (nopValue === "weekly") {
      return handleWeeklyDateFormat(valueDatum);
    }

    return `${moment(month, "M").format("MMM")} ${year}`;
  };

  const formatCreatedAtDate = (createdAt: string) => {
    return moment(createdAt).format("MMM D");
  };

  const weeklyRevenueData = fetchAnalyticsKind(
    "weekly_revenue",
    "weekly",
    true,
  );
  const monthlyRevenueData = fetchAnalyticsKind(
    "monthly_revenue",
    "monthly",
    true,
  );
  const dailyRevenueData = fetchAnalyticsKind("revenue", "daily", true);
  const tvdWeekData = fetchAnalyticsKind("transaction_volume", "weekly", true);
  const tvdMonthData = fetchAnalyticsKind(
    "transaction_volume",
    "monthly",
    true,
  );

  const wrdPercentageChange = weeklyRevenueData.slice(-1)[0]?.percentageChange;
  const isWRDPositiveChange = wrdPercentageChange > 0;

  const mrdPercentageChange = monthlyRevenueData.slice(-1)[0]?.percentageChange;
  const isMRDPositiveChange = mrdPercentageChange > 0;

  const tvdWeekPercentageChange = tvdWeekData.slice(-1)[0]?.percentageChange;
  const isTVDWeekPositiveChange = tvdWeekPercentageChange > 0;

  const tvdMonthPercentageChange = tvdMonthData.slice(-1)[0]?.percentageChange;
  const isTVDMonthPositiveChange = tvdMonthPercentageChange > 0;

  const selectedRevenueData = () => {
    if (revenueValue === "monthly") {
      return monthlyRevenueData;
    }

    if (revenueValue === "daily") {
      return dailyRevenueData;
    }

    return weeklyRevenueData;
  };

  const selectedNumberOfPayments = () => {
    if (nopValue === "monthly") {
      return tvdMonthData;
    }

    return tvdWeekData;
  };

  const RevenueDropdown = () => {
    const handleValueChange = (value) => {
      setRevenueValue(value);
    };

    return (
      <DropdownMenu.Root>
        <DropdownMenu.Trigger className="dropdown-menu__trigger flex h-40 w-full items-center gap-8 rounded-full border border-black-50 bg-white px-16 py-8 hover:border-black-200 focus:border-black-200 focus:outline-none">
          <span className="font-medium text-14">
            {capitalizeFirstLetter(revenueValue)}
          </span>
          <span className="dropdown-menu__icon material-icons-round text-18 text-black-200">
            keyboard_arrow_down
          </span>
        </DropdownMenu.Trigger>

        <DropdownMenu.Content className="dropdown-menu__content mt-4 rounded-16 bg-white p-8 shadow-lg">
          <DropdownMenu.RadioGroup
            value={revenueValue}
            onValueChange={handleValueChange}
          >
            {["monthly", "weekly", "daily"].map((option) => (
              <DropdownMenu.RadioItem
                key={option}
                className="flex cursor-pointer flex-row items-center justify-between gap-12 rounded-8 p-8 hover:bg-black-25 focus:outline-none"
                value={option}
              >
                <span className="font-medium text-14">
                  {capitalizeFirstLetter(option)}
                </span>
                <DropdownMenu.ItemIndicator className="material-icons-round text-18 text-green-400">
                  {revenueValue === option ? "check" : ""}
                </DropdownMenu.ItemIndicator>
              </DropdownMenu.RadioItem>
            ))}
          </DropdownMenu.RadioGroup>
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    );
  };

  const NumberOfPaymentsDropdown = () => {
    const handleValueChange = (value) => {
      setNopValue(value);
    };

    return (
      <DropdownMenu.Root>
        <DropdownMenu.Trigger className="dropdown-menu__trigger flex h-40 w-full items-center gap-8 rounded-full border border-black-50 bg-white px-16 py-8 hover:border-black-200 focus:border-black-200 focus:outline-none">
          <span className="font-medium text-14">
            {capitalizeFirstLetter(nopValue)}
          </span>
          <span className="dropdown-menu__icon material-icons-round text-18 text-black-200">
            keyboard_arrow_down
          </span>
        </DropdownMenu.Trigger>

        <DropdownMenu.Content className="dropdown-menu__content mt-4 rounded-16 bg-white p-8 shadow-lg">
          <DropdownMenu.RadioGroup
            value={nopValue}
            onValueChange={handleValueChange}
          >
            {["weekly", "monthly"].map((option) => (
              <DropdownMenu.RadioItem
                key={option}
                className="flex cursor-pointer flex-row items-center justify-between gap-12 rounded-8 p-8 hover:bg-black-25 focus:outline-none"
                value={option}
              >
                <span className="font-medium text-14">
                  {capitalizeFirstLetter(option)}
                </span>
                <DropdownMenu.ItemIndicator className="material-icons-round text-18 text-green-400">
                  {nopValue === option ? "check" : ""}
                </DropdownMenu.ItemIndicator>
              </DropdownMenu.RadioItem>
            ))}
          </DropdownMenu.RadioGroup>
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    );
  };

  useEffect(() => {
    fetchAnalyticsData("weekly_revenue", "weekly");
    fetchAnalyticsData("monthly_revenue", "monthly");
    fetchAnalyticsData("revenue", "daily");
    fetchAnalyticsData("transaction_volume", "weekly");
    fetchAnalyticsData("transaction_volume", "monthly");
  }, []);

  return (
    <>
      {(isLoading || showLoadingUpcomingSettlements) && <LoaderBusiness />}
      {!isLoading && !showLoadingUpcomingSettlements && (
        <div className="space-y-24 md:space-y-40">
          {isAppPlatform() && (
            <SettlementBalance
              nextSettlementAmount={data?.nextSettlementAmount}
              outstandingBalance={data?.outstandingBalance}
              hasBorder
            />
          )}

          {/* REVENUE DATA */}
          <div className="grid grid-cols-1">
            <ChartCard
              isDataEmpty={isChartEmptyData(selectedRevenueData())}
              showTitle
              title="Revenue"
              tooltipProps={{
                content: "Total payment amount received",
                Icon: <Help size="18" fill={colorsV2.text[3]} />,
              }}
              showToolbar
              onDownload={() =>
                handleDownloadAnalyticsCsv(
                  revenueParams(revenueValue).kind,
                  revenueParams(revenueValue).frequencyType,
                )
              }
              additionalToolbarActions={<RevenueDropdown />}
            >
              <BarChart
                data={selectedRevenueData()}
                height={270}
                margin={{
                  top: 25,
                  right: 0,
                  bottom: 30,
                  left: isMobile ? 0 : 60,
                }}
                numTicks={5}
                bottomNumTicks={isMobile ? 1 : 99}
                xAccessor={(d) =>
                  revenueValue === "weekly" || revenueValue === "daily"
                    ? d?.createdAt
                    : d?.month
                }
                xValueFormat={
                  revenueValue === "weekly" || revenueValue === "daily"
                    ? (createdAt: string) => formatCreatedAtDate(createdAt)
                    : (month: number) => moment(month, "M").format("MMM")
                }
                yAccessor={(d) => d?.value}
                yValueFormat={(value: any) => `AED ${formatAmount(value)}`}
                tooltipSubtext={handleRevenueTooltipSubtext}
              />
            </ChartCard>
          </div>

          {/* REVENUE PERCENTAGE CHANGE DATA */}
          <div className="grid grid-cols-1">
            <ChartCard
              isDataEmpty={isChartEmptyData(selectedRevenueData())}
              showTitle
              title="Revenue % change"
              showToolbar
              onDownload={() =>
                handleDownloadAnalyticsCsv(
                  revenueParams(revenueValue).kind,
                  revenueParams(revenueValue).frequencyType,
                )
              }
              additionalToolbarActions={<RevenueDropdown />}
            >
              <BarChart
                data={selectedRevenueData()}
                hasCurrencyInTooltip={false}
                hasPercentageInTooltip
                height={270}
                margin={{
                  top: 25,
                  right: isMobile ? 5 : 0,
                  bottom: 30,
                  left: isMobile ? 5 : 30,
                }}
                numTicks={5}
                bottomNumTicks={isMobile ? 1 : 99}
                xAccessor={(d) =>
                  revenueValue === "weekly" || revenueValue === "daily"
                    ? d?.createdAt
                    : d?.month
                }
                xValueFormat={
                  revenueValue === "weekly" || revenueValue === "daily"
                    ? (createdAt: string) => formatCreatedAtDate(createdAt)
                    : (month: number) => moment(month, "M").format("MMM")
                }
                yAccessor={(d) => d?.percentageChange}
                yValueFormat={(value: any) => `${formatAmount(value)}%`}
                tooltipSubtext={handleRevenueTooltipSubtext}
                frequencyChange={revenueValue}
              />
            </ChartCard>
          </div>

          {/* STATS DATA */}
          <div className="grid grid-cols-1 gap-24 md:grid-cols-2 md:gap-40">
            {/* WEEK TO DATE REVENUE */}
            <StatCard
              cardClasses="flex-grow space-y-24"
              isPositiveValue={isWRDPositiveChange}
              title="Week to date revenue"
              value={weeklyRevenueData.slice(-1)[0]?.value}
              valuePercentage={wrdPercentageChange}
            />

            {/* MONTH TO DATE REVENUE */}
            <StatCard
              cardClasses="flex-grow space-y-24"
              isMonth
              isPositiveValue={isMRDPositiveChange}
              title="Month to date revenue"
              valuePercentage={mrdPercentageChange}
              value={monthlyRevenueData.slice(-1)[0]?.value}
            />

            {/* WEEK TO DATE NUMBER OF PAYMENTS */}
            <StatCard
              cardClasses="flex-grow space-y-24"
              isCurrencyValue={false}
              isPositiveValue={isTVDWeekPositiveChange}
              title="Week to date number of payments"
              valuePercentage={tvdWeekPercentageChange}
              value={tvdWeekData.slice(-1)[0]?.value}
            />

            {/* MONTH TO DATE NUMBER OF PAYMENTS */}
            <StatCard
              cardClasses="flex-grow space-y-24"
              isMonth
              isCurrencyValue={false}
              isPositiveValue={isTVDMonthPositiveChange}
              title="Month to date number of payments"
              valuePercentage={tvdMonthPercentageChange}
              value={tvdMonthData.slice(-1)[0]?.value}
            />
          </div>

          {/* NUMBER OF PAYMENTS */}
          <div className="grid grid-cols-1">
            <ChartCard
              isDataEmpty={isChartEmptyData(selectedNumberOfPayments())}
              showTitle
              title="Number of payments"
              tooltipProps={{
                content: "Number of successful payments received",
                Icon: <Help size="18" fill={colorsV2.text[3]} />,
              }}
              showToolbar
              onDownload={() =>
                handleDownloadAnalyticsCsv(
                  nopParams(nopValue).kind,
                  nopParams(nopValue).frequencyType,
                )
              }
              additionalToolbarActions={<NumberOfPaymentsDropdown />}
            >
              <BarChart
                data={selectedNumberOfPayments()}
                hasCurrencyInTooltip={false}
                height={270}
                margin={{
                  top: 25,
                  right: isMobile ? 5 : 0,
                  bottom: 30,
                  left: isMobile ? 5 : 30,
                }}
                numTicks={5}
                bottomNumTicks={isMobile ? 1 : 99}
                xAccessor={(d) =>
                  nopValue === "weekly" ? d?.createdAt : d?.month
                }
                xValueFormat={
                  nopValue === "weekly"
                    ? (createdAt: string) => formatCreatedAtDate(createdAt)
                    : (month: number) => moment(month, "M").format("MMM")
                }
                yAccessor={(d) => d?.value}
                yValueFormat={(value: any) => formatAmount(value)}
                tooltipSubtext={handleRevenueChangeTooltipSubtext}
              />
            </ChartCard>
          </div>
        </div>
      )}
    </>
  );
};
