import { Button } from "design_system/Button";
import { Replay } from "design_system/Icons";

import { queryClient } from "config/reactQueryClient";
import { useRefreshAccountingInfo } from "./useRefreshAccountingInfo";

const refetchAllAccountingTables = async () => {
  await queryClient.refetchQueries(["getPaymentAccounts"]);
  await queryClient.refetchQueries(["getExpenseAccounts"]);
  await queryClient.refetchQueries(["getTaxRates"]);
  await queryClient.refetchQueries(["getVendors"]);
};

export const RefreshInfoToolbar = () => {
  const { refreshAccountingInfo } = useRefreshAccountingInfo();

  const handleRefresh = async () => {
    // @ts-ignore
    refreshAccountingInfo.mutateAsync(_, {
      onSuccess: refetchAllAccountingTables,
    });
  };

  if (refreshAccountingInfo.isLoading) {
    return (
      <section className="flex items-center justify-end gap-16 self-stretch">
        <span className="body-small text-text-color-03">
          Refreshing your accounting details, this may take a minute,,,
        </span>
        <Button
          disabled
          label="Refresh"
          size="lg"
          variant="outlined"
          Icon={Replay}
        />
      </section>
    );
  }
  return (
    <section className="flex items-center justify-end gap-16 self-stretch">
      <span className="body-small text-text-color-03">
        Information pulled from your accounting software
      </span>
      <Button
        label="Refresh"
        size="lg"
        variant="outlined"
        Icon={Replay}
        onClick={handleRefresh}
      />
    </section>
  );
};
