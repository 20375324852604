import { type QueryObserverResult, useQuery } from "@tanstack/react-query";
import axios from "axios";

import { stringifiedQueryParams } from "constants/queryParams";
import routes from "constants/routes";

export const useGetIndustries = () => {
  const getIndustries: QueryObserverResult<any> = useQuery({
    queryKey: ["getIndustries"],
    queryFn: async () => {
      const response = await axios.get(
        `${routes.ONBOARDING.INDUSTRIES()}?${stringifiedQueryParams}`,
      );
      return response.data;
    },
  });

  return {
    getIndustries,
  };
};
