import deniedIcon from "assets/icons/denied.svg";
import Button from "components/Button/Button";
import routes from "constants/routes";

const Forbidden = () => {
  return (
    <section className="relative flex min-h-screen px-20 py-24 text-center">
      <div className="col-10-md col-12-lg m-auto space-y-32 md:space-y-40 lg:space-y-48">
        <img
          src={deniedIcon}
          alt="inactive link illustration"
          className="mx-auto mt-32"
        />

        <div className="space-y-16">
          <h1>You don&apos;t have permission to view this page</h1>
          <p className="text-16 text-black-300 md:text-18">
            Please contact your admin for more information
          </p>
        </div>

        <Button
          onClick={() => {
            location.href = routes.MANAGE.SESSIONS.LOGIN();
          }}
          color="pink"
          size="lg"
          label="Take me home"
          classes="mt-32 md:mt-40 lg:mt-48"
        />
      </div>
    </section>
  );
};

export default Forbidden;
