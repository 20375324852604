import { type VariantProps, cva } from "class-variance-authority";
import { type ReactNode, useContext } from "react";

import { FadeIn } from "components/AnimatedElements/FadeIn";
import { Divider } from "components/Divider";

import { cn } from "utils/classNames";

import { FullPageLayoutContext } from "../context/FullPageLayout.context";

const contentLayout = cva(
  "flex w-full max-w-628 flex-1 flex-col overflow-y-auto px-16 pt-40 md:flex-row md:px-0 md:pt-24 lg:pt-40",
  {
    variants: {
      position: {
        left: "md:mr-0 md:px-80",
        center: "md:mx-auto",
        right: "md:ml-0 md:px-80",
      },
    },
    defaultVariants: {
      position: "center",
    },
  },
);

type ContentProps = VariantProps<typeof contentLayout> & {
  hasFadeIn?: boolean;
  children: ReactNode;
  className?: string;
};

export const ContentLayout = ({
  hasFadeIn,
  position,
  children,
  className,
}: ContentProps) => {
  const { isMobile } = useContext(FullPageLayoutContext);

  const contentClasses = contentLayout({ position, className: cn(className) });

  const content = (
    <div className={cn("w-full", className)}>
      {hasFadeIn ? <FadeIn custom={1}>{children}</FadeIn> : children}
    </div>
  );

  return (
    <>
      <section data-testid="content-area" className={cn(contentClasses)}>
        {content}
      </section>
      {!isMobile && <Divider />}
    </>
  );
};
