import { createSlice } from "@reduxjs/toolkit";

export const expenseDashboard = createSlice({
  name: "expenseDashboard",
  initialState: {
    receiptModal: {
      show: false,
      isUploading: false,
    },
    // SIDE SHEET STATES
    accountingIntegration: {
      isXeroConnected: false,
      isMS365Connected: false,
    },
  },
  reducers: {
    setAccountingIntegration: (state, action) => {
      state.accountingIntegration = action.payload;
    },
    setShowReviewReceiptModal: (state, action) => {
      state.receiptModal.show = action.payload;
    },
    setIsUploadingReceipt: (state, action) => {
      state.receiptModal.isUploading = action.payload;
    },
    initiateReceiptModal: (state) => {
      state.receiptModal.show = true;
      state.receiptModal.isUploading = true;
    },
  },
});

export const {
  // SIDE SHEET STATES
  setAccountingIntegration,
  setShowReviewReceiptModal,
  setIsUploadingReceipt,
  initiateReceiptModal,
} = expenseDashboard.actions;

export default expenseDashboard.reducer;
