export const calculateDynamicDx = (xCoord) => {
  // 285 is the width, 160 is annotation width
  const remainingSpace = 285 - 160 - xCoord;
  const dynamicDx = remainingSpace < 0 ? -15 : 15;

  return dynamicDx;
};

export const calculateDynamicDy = (height, yCoord) => {
  // 100 is annotation height
  const remainingSpace = height - 100 - yCoord;
  const dynamicDy = remainingSpace < 0 ? -15 : 15;

  return dynamicDy;
};
