import { memo } from "react";
import { PageHeader } from "./PageHeader";

import { Skeleton } from "design_system/Skeleton";
import { useGetUserInfo } from "repositories/user/useGetUserInfo";

export const PersonalContainer = memo(() => {
  const { data, isLoading } = useGetUserInfo();

  return (
    <>
      <PageHeader />

      <div className="account-settings">
        <div className="account-settings__personal-details">
          <div className="account-settings__personal-details__card">
            <ul className="account-settings__personal-details__card__list">
              {/* <!-- FIRST NAME --> */}
              <li className="account-settings__personal-details__card__list__item">
                <p className="account-settings__personal-details__card__list__item__heading">
                  First name
                </p>
                <p className="account-settings__personal-details__card__list__item__info">
                  {isLoading ? <Skeleton /> : data?.firstName}
                </p>
              </li>

              {/* <!-- LAST NAME --> */}
              <li className="account-settings__personal-details__card__list__item">
                <p className="account-settings__personal-details__card__list__item__heading">
                  Last name
                </p>
                <p className="account-settings__personal-details__card__list__item__info">
                  {isLoading ? <Skeleton /> : data?.lastName}
                </p>
              </li>

              {/* <!-- PHONE NUMBER --> */}
              <li className="account-settings__personal-details__card__list__item">
                <p className="account-settings__personal-details__card__list__item__heading">
                  Phone number
                </p>
                <p className="account-settings__personal-details__card__list__item__info">
                  {isLoading ? <Skeleton /> : data?.phoneNumber}
                </p>
              </li>

              {/* <!-- EMAIL --> */}
              <li className="account-settings__personal-details__card__list__item">
                <p className="account-settings__personal-details__card__list__item__heading">
                  Email
                </p>
                <p className="account-settings__personal-details__card__list__item__info">
                  {isLoading ? <Skeleton /> : data?.email}
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
});

PersonalContainer.displayName = "PersonalContainer";
