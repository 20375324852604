import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

import type { DashboardNavigationItem } from "../DashboardNavigation.types";
import {
  getActivePrimaryItemCookie,
  setActivePrimaryItemCookie,
} from "../utils/cookies";

interface DashboardNavigationState {
  activePrimaryItem: DashboardNavigationItem | null;
  isMobileNavigationOpen: boolean;
}

const initialState: DashboardNavigationState = {
  activePrimaryItem: getActivePrimaryItemCookie(),
  isMobileNavigationOpen: false,
};

export const dashboardNavigationSlice = createSlice({
  name: "dashboardNavigation",
  initialState,
  reducers: {
    setActivePrimaryItem(
      state,
      action: PayloadAction<DashboardNavigationItem>,
    ) {
      state.activePrimaryItem = action.payload;
      setActivePrimaryItemCookie(action.payload);
    },
    setOpenMobileNavigation(state, action: PayloadAction<boolean>) {
      state.isMobileNavigationOpen = action.payload;
    },
  },
});

export const { setActivePrimaryItem, setOpenMobileNavigation } =
  dashboardNavigationSlice.actions;

export default dashboardNavigationSlice.reducer;
