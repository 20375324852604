import { colorsV2 } from "constants/colors";
import type { SvgIconProps } from "types/Icon";

export const Pending = ({
  size,
  fill = colorsV2.text[1],
  ...rest
}: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g id="Name=pending, Size=24">
        <path
          id="Vector"
          d="M12.8908 11.6614V7.51467C12.8908 7.27937 12.8138 7.08459 12.6598 6.93032C12.5057 6.77605 12.3112 6.69892 12.0763 6.69892C11.8414 6.69892 11.6465 6.77605 11.4916 6.93032C11.3367 7.08459 11.2593 7.27937 11.2593 7.51467V11.988C11.2593 12.1 11.2779 12.2033 11.3153 12.298C11.3526 12.3928 11.4086 12.4864 11.4832 12.5788L15.0114 16.2321C15.1785 16.4107 15.3822 16.4958 15.6226 16.4875C15.863 16.4792 16.0662 16.394 16.2321 16.2321C16.398 16.0701 16.481 15.8708 16.481 15.6342C16.481 15.3976 16.398 15.1964 16.2321 15.0304L12.8908 11.6614ZM12.0009 22.1494C10.6097 22.1494 9.29711 21.8832 8.06301 21.3508C6.82891 20.8184 5.75124 20.0916 4.83001 19.1705C3.90878 18.2494 3.18189 17.1719 2.64936 15.938C2.11684 14.7041 1.85059 13.3917 1.85059 12.0006C1.85059 10.6096 2.11724 9.29684 2.65054 8.06229C3.18384 6.82774 3.91049 5.75047 4.83051 4.83047C5.75051 3.91045 6.8276 3.18279 8.06179 2.64749C9.29595 2.11219 10.6087 1.84454 12 1.84454C13.3914 1.84454 14.7041 2.11219 15.9383 2.64749C17.1725 3.18279 18.2496 3.91045 19.1696 4.83047C20.0896 5.75047 20.8172 6.82756 21.3525 8.06174C21.8878 9.29591 22.1555 10.6087 22.1555 12C22.1555 13.3913 21.8878 14.7041 21.3525 15.9382C20.8172 17.1724 20.0896 18.2495 19.1696 19.1695C18.2496 20.0895 17.1726 20.8162 15.9386 21.3495C14.7046 21.8828 13.392 22.1494 12.0009 22.1494ZM11.997 20.4462C14.3164 20.4462 16.3045 19.6198 17.9612 17.9671C19.6179 16.3144 20.4462 14.3264 20.4462 12.003C20.4462 9.67961 19.6181 7.69057 17.962 6.03587C16.3058 4.38115 14.3185 3.55379 12.0001 3.55379C9.67867 3.55379 7.69061 4.3809 6.03591 6.03512C4.38119 7.68934 3.55384 9.67763 3.55384 12C3.55384 14.3254 4.38118 16.3144 6.03586 17.9671C7.69053 19.6198 9.67757 20.4462 11.997 20.4462Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};
