import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { useAppSelector } from "store";

import type { TellUsMoreBusinessFormProps } from "../../shared/typeDefs";

const validationSchema = yup.object().shape({
  businessName: yup
    .string()
    .trim()
    .required("Please enter business name")
    .typeError("Please enter business name"),
});

export const useTellUsMoreBusinessForm = () => {
  const { data } = useAppSelector((state) => state.signUp);

  const initValues: TellUsMoreBusinessFormProps = {
    businessName: data.businessName,
  };

  const form = useForm<TellUsMoreBusinessFormProps>({
    defaultValues: initValues,
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
  });

  return {
    ...form,
  };
};
