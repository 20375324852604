import { cn } from "utils/classNames";

export const Divider = ({
  classNames,
  position = "horizontal",
}: {
  classNames?: string;
  position?: "horizontal" | "vertical";
}) => {
  if (position === "horizontal") {
    return (
      <hr
        className={cn(`border-border-3 ${classNames}`)}
        style={{ height: "1px" }}
      />
    );
  }
  return (
    <hr
      className={cn(`border border-border-3 ${classNames}`)}
      style={{ width: "1px", height: "auto" }}
    />
  );
};
