import { ReactQueryProvider } from "components/Providers";

import { BankContainer } from "./BankContainer";

export const BankRoot = () => {
  return (
    <ReactQueryProvider>
      <BankContainer />
    </ReactQueryProvider>
  );
};
