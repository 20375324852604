import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

import type { WalletSlice, WalletTransaction } from "../typeDefs";

const initialState: WalletSlice = {
  // TRANSACTION
  transaction: {
    // This state contains the transaction detail we show in sidesheet
    data: undefined,
  },
};

export const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    onSelectTransaction: (state, action: PayloadAction<WalletTransaction>) => {
      state.transaction.data = action.payload;
    },
    onCloseTransaction: (state) => {
      state.transaction.data = undefined;
    },
  },
});

export const { onSelectTransaction, onCloseTransaction } = walletSlice.actions;

export default walletSlice.reducer;
