import { get } from "lodash";
import { cn } from "utils/classNames";

import { Divider } from "components/Divider";
import { InfoCard } from "design_system/InfoCard";
import { EmptyState } from "./EmptyState";

import type { WalletAmountByDate, WalletOverviewProps } from "../../typeDefs";

export const WalletOverview = ({ wallet }: WalletOverviewProps) => {
  const { availableBalance, bannerDetail, incomingBalance, selectedWallet } =
    wallet;

  const incomingAmountByDates = get(
    selectedWallet,
    "incomingAmountByDates",
    [],
  );

  if (wallet.availableBalance.isZero && wallet.totalBalance.isZero) {
    return <EmptyState />;
  }
  if (selectedWallet) {
    return (
      <section className="flex w-full flex-col gap-32 md:w-1/2">
        <h1 className="header-small md:header-medium">Balances</h1>

        {/* AVAILABLE BALANCE SECTION */}
        <div className="flex flex-col gap-16">
          <div className="flex justify-between">
            <h2 className="label-large md:header-small">Available</h2>
            <h2
              className={cn("label-large md:header-small", {
                "font-medium text-error-1": availableBalance.isNegative,
              })}
            >
              {selectedWallet.availableBalanceFormatted}
            </h2>
          </div>

          <p className="body-large text-text-color-02">
            {availableBalance.subText}
          </p>
        </div>

        {/* INCOMING BALANCE SECTION */}
        <div className="flex flex-col gap-16">
          <div className="flex justify-between">
            <h2 className="label-large md:header-small">Incoming</h2>
            <h2 className="label-large md:header-small">
              {selectedWallet.incomingBalanceFormatted}
            </h2>
          </div>

          <p className="body-large text-text-color-02">
            {incomingBalance.subText}
          </p>

          <div>
            {incomingAmountByDates.length > 0
              ? incomingAmountByDates.map((row: WalletAmountByDate, index) => {
                  return (
                    <div key={index}>
                      <div className="body-medium md:body-large flex justify-between py-12">
                        <p>{row.date}</p>
                        <p>{row.amountFormatted}</p>
                      </div>
                      {index < incomingAmountByDates.length - 1 && <Divider />}
                    </div>
                  );
                })
              : null}
          </div>
        </div>

        {!!bannerDetail && (
          <InfoCard
            showIcon
            description={bannerDetail.description}
            title={bannerDetail.title}
            variant={bannerDetail.variant}
          />
        )}
      </section>
    );
  }
  return null;
};
