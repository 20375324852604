import axios from "axios";
import qs from "qs";
import ReactOnRails from "react-on-rails";

import { endpoints } from "constants/apiEndpoints";

export const useUpdateAmount = () => {
  const updateAmount = async (
    identifier: string | undefined,
    requestData: any,
  ) => {
    try {
      const queryParams = {
        authenticity_token: ReactOnRails.authenticityToken(),
      };

      const url = endpoints.MANAGE.API.POS.UPDATE_AMOUNT(identifier);
      const { data } = await axios.patch(
        `${url}?${qs.stringify(queryParams)}`,
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );

      return {
        data,
      };
    } catch (error) {
      console.error(
        `[useUpdateAmount][updateAmount] Trouble updating amount: ${error}`,
      );
      throw error;
    }
  };

  return {
    updateAmount,
  };
};
