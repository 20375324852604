import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import type { CreatePasswordFormProps } from "../../shared/typeDefs";

const validationSchema = yup.object().shape({
  password: yup
    .string()
    .min(8, "Password must be 8 characters long")
    .matches(/[0-9]/, "Password requires a number")
    .matches(/[a-z]/, "Password requires a lowercase letter")
    .matches(/[A-Z]/, "Password requires an uppercase letter")
    .matches(/[^\w]/, "Password requires a symbol"),
});

export const useCreatePasswordForm = () => {
  const initValues: CreatePasswordFormProps = {
    password: "",
  };

  const form = useForm<CreatePasswordFormProps>({
    defaultValues: initValues,
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
  });

  const password = form.watch().password;
  const isPasswordMatchingRequirements = validationSchema.isValidSync({
    password,
  });

  return {
    ...form,
    isPasswordMatchingRequirements,
  };
};
