export const FirstTimerEmptyState = () => {
  return (
    <section className="my-40 space-y-4 text-center">
      <p className="label-large">Explore your business</p>
      <p className="body-medium text-text-color-02">
        Search for payments, invoices, settlements, or more...
      </p>
    </section>
  );
};
