import isEmpty from "lodash/isEmpty";

import { TransactionSidesheet } from "features/Cards/Transactions/TransactionsTable/TransactionSidesheet";
import { CreditActivitiesTable } from "./Table";
import { CreditActivitySidesheet } from "./Table/Sidesheet/CreditActivitySidesheet";

import { IslandProvider } from "design_system/Island/IslandContext";
import { SideSheetProvider } from "design_system/Sidesheet/SideSheetContext";

export const CreditActivities = () => {
  return (
    <SideSheetProvider>
      {() => (
        <IslandProvider>
          <CreditActivitiesTable>
            {(isSideSheetOpen, isTransactionSidesheet, recordDetail) => {
              return (
                <>
                  {isSideSheetOpen &&
                    isTransactionSidesheet &&
                    !isEmpty(recordDetail) && (
                      <TransactionSidesheet record={recordDetail} />
                    )}
                  {isSideSheetOpen && !isTransactionSidesheet && (
                    <CreditActivitySidesheet />
                  )}
                </>
              );
            }}
          </CreditActivitiesTable>
        </IslandProvider>
      )}
    </SideSheetProvider>
  );
};
