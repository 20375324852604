import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import qs from "qs";
import toast from "react-hot-toast";

import { RECORDS_PER_PAGE } from "constants/api";
import { endpoints } from "constants/apiEndpoints";
import { generalErrors } from "constants/errorMessages";
import type { useGetWalletTransactionsParams } from "features/Wallet/WalletV2/typeDefs";
import { SentryLoggingService } from "init/SentryLoggingService";

export const useGetWalletTransactions = ({
  wallet,
  configs,
}: useGetWalletTransactionsParams) => {
  const { page } = configs;

  const queryParams = {
    page,
    per_page: RECORDS_PER_PAGE,
  };

  const getWalletTransactions = useQuery({
    queryKey: ["getWalletTransactions", { page }],
    queryFn: async () => {
      try {
        const { data } = await axios.get(
          `${endpoints.MANAGE.API.WALLETS.TRANSACTIONS(wallet.id)}?${qs.stringify(queryParams)}`,
        );

        return data;
      } catch (error: any) {
        toast.error(generalErrors.SOMETHING_WENT_WRONG, {
          duration: 3000,
        });
        SentryLoggingService.captureException(error.message, {
          feature: "[Wallets V2]",
          file: "useGetWalletTransactions.tsx",
          function: "useGetWalletTransactions",
        });
        return undefined;
      }
    },
  });

  return {
    getWalletTransactions,
  };
};
