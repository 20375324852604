import axios from "axios";
import routes from "constants/routes";
import qs from "qs";
import ReactOnRails from "react-on-rails";

interface IUpdateBranding {
  brandColor: string;
  accentColor: string;
  logo?: any;
}

export async function updateBranding({
  brandColor,
  accentColor,
  logo,
}: IUpdateBranding) {
  const queryParams = {
    authenticity_token: ReactOnRails.authenticityToken(),
  };

  const formData = new FormData();
  formData.append("brand_color", brandColor);
  formData.append("accent_color", accentColor);

  if (logo) {
    formData.append("brand_logo", logo);
  }

  await axios.patch(
    `${routes.MANAGE.ACCOUNT_SETTINGS.UPDATE_BRANDING()}?${qs.stringify(queryParams)}`,
    formData,
  );
}
