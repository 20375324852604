import type React from "react";

import type IRailsContext from "interfaces/IRailsContext";
import { POSGeneratePaymentContainer } from "./POSGeneratePaymentContainer";

type IProps = {};

export const POSGeneratePaymentRoot: React.FC<IProps> = (
  props: IProps,
  railsContext: IRailsContext,
) => {
  return (
    <POSGeneratePaymentContainer pos={props} railsContext={railsContext} />
  );
};
