import LinearProgress from "@mui/material/LinearProgress";

export const ProgressBar = ({
  progress,
  height = "6px",
  backgroundColor = "#E5E9F0",
  progressColor = "#FF6B57",
  formula = "",
  extraInfo = "",
}: {
  progress: number;
  height?: string;
  backgroundColor?: string;
  progressColor?: string;
  formula?: any;
  extraInfo?: any;
}) => {
  return (
    <div data-testid="progress-bar-wrapper" className="space-y-6">
      <LinearProgress
        variant="determinate"
        value={progress}
        sx={{
          height,
          backgroundColor,
          borderRadius: "9999px",
          "& .MuiLinearProgress-bar": {
            height,
            backgroundColor: progressColor,
            borderRadius: "9999px",
          },
        }}
      />
      {formula && extraInfo && (
        <div className="flex flex-col gap-8 text-12 text-black-200 leading-20 lg:flex-row lg:items-center lg:justify-between lg:gap-2">
          <span>{formula}</span>
          <span>{extraInfo}</span>
        </div>
      )}
    </div>
  );
};
