export function convertToLocaleStringHelper(value?: number | string): string {
  if (value == undefined || value == null) return "";

  const string = Number.parseFloat(value.toString()).toLocaleString("en-AE", {
    style: "decimal",
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

  return string;
}

export function convertToLocalFormatAmountHelper(
  value?: number | string,
  forceDecimalPlaces?: boolean,
): string {
  if (!value || value == "") return "";

  const stringValue = value.toString();
  const decimalPart = stringValue.split(".")[1] || "";

  if (decimalPart.length <= 2 && !forceDecimalPlaces) return stringValue;

  return Number.parseFloat(stringValue).toFixed(2);
}

export function formatAmount(amount: number) {
  if (Math.abs(amount) >= 1000000) {
    const millionValue = (amount / 1000000).toFixed(1);
    return millionValue.endsWith(".0")
      ? millionValue.slice(0, -2) + "M"
      : millionValue + "M";
  } else if (Math.abs(amount) >= 1000) {
    const thousandValue = (amount / 1000).toFixed(1);
    return thousandValue.endsWith(".0")
      ? thousandValue.slice(0, -2) + "K"
      : thousandValue + "K";
  } else {
    return amount.toLocaleString();
  }
}
