import { createSlice } from "@reduxjs/toolkit";

interface AccountingDashboardSlice {
  accountingIntegrations: {
    isInitialFetch: boolean;
    shouldRefetch: boolean;
  };
  rootfi: {
    selectedIntegration: "quickbooks" | "xero" | "ms_dynamics_365";
  };
}

const initialState: AccountingDashboardSlice = {
  accountingIntegrations: {
    isInitialFetch: true,
    shouldRefetch: false,
  },
  rootfi: {
    selectedIntegration: undefined,
  },
};

export const accountingDashboardSlice = createSlice({
  name: "accountingDashboard",
  initialState,
  reducers: {
    // INTEGRATION STATUS HANDLERS
    handleGetIntegrationsSuccess: (state, action) => {
      const { submissionStatus, connectionStatus } = action.payload;

      state.accountingIntegrations.isInitialFetch = false;
      // This indicates that rootfi SDK process was successfully completed, now we are waiting for connection status to be updated to "healthy"
      state.accountingIntegrations.shouldRefetch =
        submissionStatus === "submitted" && connectionStatus === "pending";
    },

    // ROOTFI HANDLERS
    onInitRootfi: (state, action) => {
      state.rootfi.selectedIntegration = action.payload.selectedIntegration;
    },
    onRootfiConnectionSuccess: (state) => {
      state.accountingIntegrations.shouldRefetch = true; // Setting to true to ensure that getIntegrations api is not disabled
      state.rootfi.selectedIntegration = undefined;
    },
    onRootfiExit: (state) => {
      state.rootfi.selectedIntegration = undefined;
    },
  },
});

export const {
  // INTEGRATION STATUS HANDLERS
  handleGetIntegrationsSuccess,
  // ROOTFI HANDLERS
  onInitRootfi,
  onRootfiConnectionSuccess,
  onRootfiExit,
} = accountingDashboardSlice.actions;

export default accountingDashboardSlice.reducer;
