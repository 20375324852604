import Microsoft from "assets/logos/microsoft.svg";
import QuickBooks from "assets/logos/quickbooks.svg";
import Xero from "assets/logos/xero.svg";

export const allAccountingIntegrations = [
  {
    platform: "quickbooks",
    imageSrc: QuickBooks,
    imageAltText: "QuickBooks Online logo",
    title: "QuickBooks Online",
    subTitle: "Sync with quickbooks",
  },
  // Commented out for this release
  {
    platform: "xero",
    imageSrc: Xero,
    imageAltText: "Xero logo",
    title: "Xero",
    subTitle: "Sync with xero",
  },
  {
    platform: "ms_dynamics_365",
    imageSrc: Microsoft,
    imageAltText: "Microsoft logo",
    title: "Microsoft",
    subTitle: "Sync with microsoft",
  },
];
