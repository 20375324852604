import type { TDropdownFrequency } from "../shared/typeDefs";

// Revenue and Revenue percentage change
export const revenueParams = (frequency: TDropdownFrequency) => {
  return {
    kind:
      frequency === "weekly"
        ? "weekly_revenue"
        : frequency === "monthly"
          ? "monthly_revenue"
          : frequency === "daily" && "revenue",
    frequencyType:
      frequency === "weekly"
        ? "weekly"
        : frequency === "monthly"
          ? "monthly"
          : frequency === "daily" && "daily",
  };
};

// Number of payments
export const nopParams = (frequency: TDropdownFrequency) => {
  return {
    kind: "transaction_volume",
    frequencyType: frequency === "weekly" ? "weekly" : "monthly",
  };
};
