import { createSlice } from "@reduxjs/toolkit";
import { EXPENSE_DETAIL_FIELDS_INIT_STATE } from "features/ExpenseManagement/shared/constants";

export type ExpenseDetailPayload = {
  key:
    | "attachments"
    | "category"
    | "description"
    | "expenseAccount"
    | "invoiceNumber"
    | "paymentAccount"
    | "taxRate"
    | "vendor";
  value: any;
};
export type ExpenseDetailAction = {
  payload: ExpenseDetailPayload;
};

export const expenseDetailPageSlice = createSlice({
  name: "expensesTable",
  initialState: {
    isButtonDisabled: true,
    sidesheetPreview: "EXPENSE_RECEIPT", // EXPENSE_DETAIL, EXPENSE_RECEIPT
    inputFields: EXPENSE_DETAIL_FIELDS_INIT_STATE,
  },
  reducers: {
    setInputFields: (state, action: ExpenseDetailAction) => {
      state.inputFields[action.payload.key] = action.payload.value;
    },
    setSidesheetPreview: (state, action) => {
      state.sidesheetPreview = action.payload;
    },
    setIsButtonDisabled: (state, action) => {
      state.isButtonDisabled = action.payload;
    },
    // Used for resetting expense detail form when user closes the sidesheet
    clearInputFields: (state) => {
      state.inputFields = EXPENSE_DETAIL_FIELDS_INIT_STATE;
    },
  },
});

export const {
  clearInputFields,
  setInputFields,
  setSidesheetPreview,
  setIsButtonDisabled,
} = expenseDetailPageSlice.actions;

export default expenseDetailPageSlice.reducer;
