import type { RouteComponentProps } from "@reach/router";

import Button from "components/Button/Button";

import fourOhFourIllustration from "assets/illustrations/404-illustration.svg";
import routes from "constants/routes";

const NotFound = (props: RouteComponentProps) => {
  return (
    <section className="relative flex min-h-screen px-20 py-24 text-center">
      <div className="col-10-md col-12-lg m-auto">
        <img
          src={fourOhFourIllustration}
          alt="inactive link illustration"
          className="mx-auto mt-32"
        />

        <h1 className="mt-32 px-48">Hmm... looks like you’re lost</h1>
        <p className="mt-16 text-16 text-black-300 md:text-18">
          And so are we. But hey, if you click the button below, you’ll find
          your way back home!
        </p>

        <Button
          onClick={() => {
            location.href = routes.MANAGE.SESSIONS.LOGIN();
          }}
          color="pink"
          size="lg"
          label="Take me home"
          classes="mt-32 md:mt-40 lg:mt-48"
        />
      </div>
    </section>
  );
};

export default NotFound;
