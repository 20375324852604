import axios from "axios";
import isEmpty from "lodash/isEmpty";
import qs from "qs";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import ReactOnRails from "react-on-rails";
import { cn } from "utils/classNames";

import SingleFileUpload from "components/FileUpload/SingleFileUpload";
import { Button } from "design_system/Button";
import { Modal } from "design_system/Modal";

import routes from "constants/routes";
import { pluralize } from "helpers/stringUtils";
import { SentryLoggingService } from "init/SentryLoggingService";
import type { ComplianceDocument } from "types/MerchantInfo";
import { extractFormDataFields } from "utils/fileUtils";
import { getDocumentName } from "./utils";

interface ComplianceBannerModalProps {
  show: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  documentsList: ComplianceDocument[];
  setDocumentsList: React.Dispatch<React.SetStateAction<ComplianceDocument[]>>;
}

export const ComplianceBannerModal = ({
  show,
  setShowModal,
  documentsList,
  setDocumentsList,
}: ComplianceBannerModalProps) => {
  const [selectedDocument, setSelectedDocument] =
    useState<ComplianceDocument>();
  const [file, setFile] = useState<File>(null);
  const [eidFrontFile, setEidFrontFile] = useState<any>(null);
  const [eidBackFile, setEidBackFile] = useState<any>(null);
  const [fileUploadFormData, setFileUploadFormData] = useState<FormData>(null);
  const [uploading, setUploading] = useState<boolean>(false);

  const documentsCount = documentsList?.length;
  const pluralizeDocument = pluralize(documentsCount, "document");
  const hasSelectedDocument = !isEmpty(selectedDocument);
  const isEmiratesIdUpload = selectedDocument?.documentType === "emirates_id";

  const notifyUpdate = () => {
    toast.success(`Thanks for updating your ${pluralizeDocument}!`, {
      duration: 5000,
      style: { fontSize: "16px" },
    });
  };

  const handleDocUpload = () => {
    setUploading(true);

    const queryParams = {
      authenticity_token: ReactOnRails.authenticityToken(),
    };

    axios
      .post(
        `${routes.MANAGE.DOCUMENTS.RENEW(selectedDocument.id)}?${qs.stringify(queryParams)}`,
        fileUploadFormData,
      )
      .then(() => {
        const updatedDocumentsList = documentsList.filter(
          (document: ComplianceDocument) => document.id !== selectedDocument.id,
        );

        setUploading(false);
        setDocumentsList(updatedDocumentsList);

        if (documentsCount === 1) {
          setShowModal(false);
          setSelectedDocument(null);
          notifyUpdate();
        } else {
          setSelectedDocument(null);
        }
      })
      .catch((error) => {
        const payload = extractFormDataFields(fileUploadFormData);
        const messages = error.response.data.messages;

        setUploading(false);
        SentryLoggingService.captureException(error.message, {
          feature: "[GlobalBanner][ComplianceBanner]",
          function: "handleDocUpload",
          file: "ComplianceBannerModal",
          errorDetails: messages ? JSON.stringify({ messages }) : null,
          data: payload ? JSON.stringify(payload) : null,
        });
      });
  };

  useEffect(() => {
    const formData = new FormData();
    formData.append("isEmiratesId", `${isEmiratesIdUpload}`);

    if (file !== null) {
      formData.append("files", file);
    }
    if (eidFrontFile !== null && eidBackFile !== null) {
      formData.append("frontImage", eidFrontFile);
      formData.append("backImage", eidBackFile);
    }

    formData.append("description", selectedDocument?.documentType);
    setFileUploadFormData(formData);
  }, [file, eidFrontFile, eidBackFile, selectedDocument]);

  return (
    <Modal
      hasCloseButton
      hasBackButton={hasSelectedDocument}
      show={show}
      title={`${selectedDocument ? "Upload your document" : `Update your ${pluralizeDocument}`}`}
      modalWidth="45rem"
      onBack={() => setSelectedDocument(null)}
      onClose={() => {
        setShowModal(false);
        setSelectedDocument(null);
      }}
    >
      <div className="mt-40 space-y-24">
        {hasSelectedDocument && (
          <>
            {!isEmiratesIdUpload && (
              <SingleFileUpload
                id="doc_upload"
                icon="upload_file"
                label="Upload document"
                supportedFiles={["jpg", "jpeg", "png", "pdf"]}
                supportedFilesContent="JPG, PNG, or PDF"
                fileSize={20_000_000}
                fileSizeContent="20 MB"
                previewType="attachmentWithInfo"
                onGetSelectedFile={(file: File) => setFile(file)}
                compactLayout
              />
            )}

            {isEmiratesIdUpload && (
              <>
                <SingleFileUpload
                  id="eid_front"
                  customIcon="identity-card-front"
                  label="Upload front side"
                  supportedFiles={["jpg", "jpeg", "png", "pdf"]}
                  supportedFilesContent="JPG, PNG, or PDF"
                  fileSize={20_000_000}
                  fileSizeContent="20 MB"
                  previewType="attachmentWithInfo"
                  onGetSelectedFile={(file: File) => setEidFrontFile(file)}
                  compactLayout
                />
                <SingleFileUpload
                  id="eid_back"
                  customIcon="card-back"
                  label="Upload back side"
                  supportedFiles={["jpg", "jpeg", "png", "pdf"]}
                  supportedFilesContent="JPG, PNG, or PDF"
                  fileSize={20_000_000}
                  fileSizeContent="20 MB"
                  previewType="attachmentWithInfo"
                  onGetSelectedFile={(file: File) => setEidBackFile(file)}
                  compactLayout
                />
              </>
            )}

            <Button
              variant="filled"
              size="lg"
              label="Update"
              classes="w-full"
              onClick={() => handleDocUpload()}
              disabled={uploading || documentsCount === 0}
            />
          </>
        )}

        {!hasSelectedDocument && (
          <div className="space-y-16">
            {documentsList?.map((document: ComplianceDocument) => {
              return (
                <div
                  className="flex items-center justify-between"
                  key={document.id}
                >
                  <div className="space-y-4">
                    <p className="label-medium">{getDocumentName(document)}</p>
                    <p
                      className={cn("body-small", {
                        "text-error-1": document.expired,
                        "text-text-color-02": !document.expired,
                      })}
                    >
                      {document.expired ? "Expired" : "Expiring"} on{" "}
                      {document.expiredAt}
                    </p>
                  </div>
                  <Button
                    variant="filled"
                    size="sm"
                    label="Update"
                    onClick={() => setSelectedDocument(document)}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </Modal>
  );
};
