import CurrencyInput from "react-currency-input-field";
import type { CurrencyInputProps } from "react-currency-input-field/dist/components/CurrencyInputProps";
import { type Control, Controller, type FieldErrors } from "react-hook-form";
import { cn } from "utils/classNames";

import FieldMessage from "components/FieldMessage/FieldMessage";

import { camelToHyphen } from "utils/camelToHyphenUtil";

interface IMBCurrencyInputProps extends CurrencyInputProps {
  containerClasses?: string;
  control: Control;
  controllerDefaultValue?: unknown;
  errors: FieldErrors;
  fieldName: string;
  isDisplay?: boolean;
  setValue?: any;
  value?: string | ReadonlyArray<string> | number;
}

export const MBCurrencyInput = ({
  containerClasses,
  control,
  controllerDefaultValue,
  errors,
  fieldName,
  isDisplay = false,
  setValue,
  value,
  ...rest
}: IMBCurrencyInputProps) => {
  return (
    <>
      <Controller
        control={control}
        name={fieldName}
        setValue={setValue}
        defaultValue={controllerDefaultValue}
        render={(props) => (
          <CurrencyInput
            id={fieldName}
            data-testid={`${camelToHyphen(fieldName)}-field`}
            className={cn(containerClasses, {
              "form-field__input--error": errors[fieldName],
              "form-field__display-input": isDisplay,
              "form-field__input": !isDisplay,
            })}
            decimalsLimit={2}
            decimalScale={2}
            value={value ?? props.value}
            onValueChange={(value, _name) => props.onChange(value)}
            onBlur={props.onBlur}
            {...rest}
          />
        )}
      />
      {errors[fieldName] && (
        <FieldMessage
          messageType="error"
          icon="error"
          messageText={errors[fieldName]?.message}
          classes="mt-12"
        />
      )}
    </>
  );
};
