import * as Yup from "yup";

export const BUSINESS_NAME_SCHEMA = Yup.object().shape({
  name: Yup.string().trim().required("Please enter your business name"),
});

export const ADDRESS_SCHEMA = Yup.object().shape({
  address: Yup.string().trim().required("Please enter an address"),
  city: Yup.string().required("Please enter a city"),
});

export const SUPPORT_EMAIL_SCHEMA = Yup.object().shape({
  replyTo: Yup.string()
    .trim()
    .required("Please enter email")
    .email("Email is not valid"),
});

export const TRN_SCHEMA = Yup.object().shape({
  trn: Yup.string().trim().required("Please enter your TRN"),
});
