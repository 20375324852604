import debounce from "lodash/debounce";
import { useCallback, useEffect, useState } from "react";

import { useGetGlobalSearchResults } from "./useGetGlobalSearchResults";

export const useSearchField = () => {
  /* ---------------------------------
   *  States & Ref
   * ---------------------------------
   */
  const [searchText, setSearchText] = useState("");
  const [debouncedSearchText, setDebouncedSearchText] = useState(searchText);
  /* ---------------------------------
   *  Hooks
   * ---------------------------------
   */
  const { getGlobalSearchResults } = useGetGlobalSearchResults({
    searchQuery: searchText,
    debouncedSearchText,
  });

  let searchResults = [];
  if (searchText && getGlobalSearchResults.data?.data) {
    searchResults = getGlobalSearchResults.data?.data;
  }
  /* ---------------------------------
   *  Helpers
   * ---------------------------------
   */
  // Define a debounced function that updates the debounced search text
  const updateDebouncedSearchText = useCallback(
    debounce(setDebouncedSearchText, 1000),
    [],
  );

  const handleTextChange = (value) => {
    setSearchText(value);
    updateDebouncedSearchText(value);
  };

  useEffect(() => {
    // Call the debounced function with the current search text
    updateDebouncedSearchText(searchText);

    // Cleanup the debounced call if the component unmounts or before the next call
    return updateDebouncedSearchText.cancel;
  }, [searchText, updateDebouncedSearchText]);

  return {
    searchText,
    searchResults,
    getGlobalSearchResults,
    handleTextChange,
  };
};
