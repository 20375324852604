import { Toaster } from "sonner/dist/index";

import { useScreenDimension } from "hooks/useScreenDimension";

import { colorsV2 } from "constants/colors";

export const Snackbar = () => {
  const { isMobile } = useScreenDimension();

  return (
    <Toaster
      expand
      position={isMobile ? "bottom-center" : "top-right"}
      toastOptions={{
        style: {
          backgroundColor: colorsV2.surface[7],
          border: `1px solid ${colorsV2.border[3]}`,
          borderRadius: "0.8rem",
          boxShadow: "0px 4px 32px 0px rgba(0, 0, 0, 0.15)",
          backdropFilter: "blur(12.5px)",
          boxSizing: "border-box",
          padding: "1.6rem",
          width: isMobile ? "revert-layer" : "100%",
        },
      }}
    />
  );
};
