import { ReactQueryProvider } from "components/Providers";
import { PersonalContainer } from "./PersonalContainer";

const PersonalRoot = () => {
  return (
    <ReactQueryProvider>
      <PersonalContainer />
    </ReactQueryProvider>
  );
};

export default PersonalRoot;
