import type { HTMLAttributes, ReactNode } from "react";
import { cn } from "utils/classNames";

export type LabelProps = HTMLAttributes<HTMLLabelElement> & {
  children: ReactNode;
};

export const Label = ({ children, className, ...props }: LabelProps) => {
  return (
    <label className={cn("label-large", className)} {...props}>
      {children}
    </label>
  );
};
