import { useQuery } from "@tanstack/react-query";
import { snack } from "design_system/Snackbar";

import { PLANS_QUERY_KEYS } from "repositories/plans/keys";
import { plansRepository } from "repositories/plans/plans.repository";

export const useGetPlans = () => {
  return useQuery({
    queryKey: [PLANS_QUERY_KEYS.GET_PLANS],
    queryFn: async () => {
      const response = await plansRepository.getPlans();

      if (!response.success && "error" in response) {
        snack({
          title: response.error.message,
          leadingElement: "icon",
          variant: "critical",
        });

        plansRepository.sentryLogError({
          error: response.error.message,
          feature: "useGetPlans",
          functionName: "plansQuery",
          data: JSON.stringify(response.error),
        });
      }

      return response.data;
    },
  });
};
