import { Button } from "design_system/Button";
import { Add } from "design_system/Icons";
import { MasterHeader } from "design_system/MasterHeader";

import { useScreenDimension } from "hooks/useScreenDimension";

import { handleGoBackToSettings } from "features/Settings/shared/utils";
import { isAppPlatform } from "helpers/isAppPlatform";

interface PageHeader {
  isLoading?: boolean;
  onClickAddCategory: () => void;
}

export const PageHeader = ({ isLoading, onClickAddCategory }: PageHeader) => {
  const { isMobile } = useScreenDimension();

  if (isAppPlatform()) {
    return <></>;
  }

  return (
    <MasterHeader onBack={handleGoBackToSettings}>
      <MasterHeader.Label>Settings</MasterHeader.Label>
      <MasterHeader.Title>Expenses</MasterHeader.Title>

      {isMobile && !isLoading && (
        <MasterHeader.Actions>
          <Button
            variant="filled"
            size="md"
            label="New"
            iconPosition="left"
            iconOnly
            Icon={Add}
            onClick={onClickAddCategory}
          />
        </MasterHeader.Actions>
      )}
    </MasterHeader>
  );
};
