import type { TokenResponse } from "@react-oauth/google";
import { useMutation } from "@tanstack/react-query";
import axios, { type AxiosError, type AxiosResponse } from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { queryParams } from "constants/queryParams";
import routes from "constants/routes";
import {
  updateData,
  updateErrorMessage,
  updateSessionExpired,
} from "reducers/redux/Access/SignUp/signUpSlice";
import { errorType, errorsMapping } from "../../shared/constant";

interface GoogleSsoResponse {
  first_name: string;
  last_name: string;
  email: string;
}

export const useGoogleSsoSignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const googleSsoSignUp = useMutation({
    mutationKey: ["googleSsoSignUp"],
    mutationFn: async (tokenResponse: TokenResponse) => {
      await axios
        .post(routes.ONBOARDING.ACCOUNTS.SIGN_UP_WITH_SSO_VALIDATION(), {
          ...tokenResponse,
          ...queryParams,
        })
        .then((res: AxiosResponse<GoogleSsoResponse>) => {
          dispatch(
            updateData({
              data: {
                accessToken: tokenResponse.access_token,
                firstName: res.data.first_name,
                lastName: res.data.last_name,
                email: res.data.email,
              },
            }),
          );
          dispatch(updateSessionExpired({ sessionExpired: false }));
          navigate(routes.ONBOARDING.START_WITH_BASICS());
        });
    },
    onError: (error: AxiosError) => {
      if (errorType[error.response.data.error_code] === "email") {
        dispatch(
          updateErrorMessage({
            errorMessage: {
              email: errorsMapping[error.response.data.error_code],
            },
          }),
        );
      }
    },
  });

  return { googleSsoSignUp };
};
