import type { TDropdownFrequency } from "../shared/typeDefs";

// Total spend
export const totalSpendParams = (frequency: TDropdownFrequency) => {
  return {
    kind: "card_total_spend",
    frequencyType: frequency === "weekly" ? "weekly" : "monthly",
  };
};

// Payouts
export const payoutsParams = (frequency: TDropdownFrequency) => {
  return {
    kind: "payout",
    frequencyType: frequency === "weekly" ? "weekly" : "monthly",
  };
};
