import { yupResolver } from "@hookform/resolvers/yup";
import { useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { FormProvider, useForm } from "react-hook-form";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import * as Yup from "yup";

import { Snackbar } from "design_system/Snackbar";
import { Dashboard } from "./Dashboard";
import InviteUser from "./InviteUser";
import UserRoles from "./InviteUser/UserRoles";

import {
  type UserContext,
  UserProvider,
  initState,
} from "./shared/context/UserProvider";

import type IRailsContext from "interfaces/IRailsContext";
import type { TeamMember } from "types/Team";
import { teamRoutes } from "./shared/constant";
import { USER_INFORMATION_SCHEMA, USER_ROLES_SCHEMA } from "./shared/schema";

interface ITeamContainer {
  props: any;
  railsContext: IRailsContext;
}

const TeamContainer = (props: ITeamContainer) => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [userInfo, setUserInfo] = useState<TeamMember>(initState.userInfo);
  const [showRemoveUserModal, setShowRemoveUserModal] = useState<boolean>(
    initState.showRemoveUserModal,
  );

  const { railsContext } = props;

  const { formSchema } = useMemo(() => {
    switch (true) {
      case window.location.href.includes(teamRoutes.userInfo) &&
        currentStep === 1:
        return {
          formSchema: USER_INFORMATION_SCHEMA,
        };
      case window.location.href.includes(teamRoutes.userInfo) &&
        currentStep === 2:
        return {
          formSchema: USER_ROLES_SCHEMA,
        };
      case window.location.href.includes(teamRoutes.userRoles):
        return {
          formSchema: USER_ROLES_SCHEMA,
        };
      default:
        return {
          formSchema: Yup.object().shape({}),
        };
    }
  }, [currentStep]);

  const formControl = useForm<UserContext>({
    defaultValues: {
      userInfo: {
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        email: userInfo.email,
        roles: userInfo.roles,
      },
    },
    resolver: yupResolver(formSchema),
    mode: "onSubmit",
  });

  return (
    <UserProvider
      value={{
        userInfo,
        showRemoveUserModal,
        setUserInfo,
        setShowRemoveUserModal,
      }}
    >
      <BrowserRouter>
        <Snackbar />
        <Routes>
          <Route path={teamRoutes.overview} element={<Dashboard />} />
          <Route
            path={teamRoutes.userInfo}
            element={
              <div
                className="create-link absolute inset-0"
                style={{
                  zIndex: 1003,
                }}
              >
                <FormProvider {...formControl}>
                  <InviteUser
                    railsContext={railsContext}
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                  />
                </FormProvider>
              </div>
            }
          />
          <Route
            path={teamRoutes.userRoles}
            element={createPortal(
              <div
                className="create-link absolute inset-0"
                style={{
                  zIndex: 1003,
                }}
              >
                <FormProvider {...formControl}>
                  <UserRoles type="edit" />
                </FormProvider>
              </div>,
              document.body,
            )}
          />
        </Routes>
      </BrowserRouter>
    </UserProvider>
  );
};

export default TeamContainer;
