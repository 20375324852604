import { cn } from "utils/classNames";

interface IProps {
  classes?: string;
  colorValue?: string;
  inputId?: string;
  pattern?: any;
  maxLength?: number;
  inputName?: string;
  onColorChange: (color: string) => any;
}

const ColorInput = ({
  classes,
  colorValue,
  inputId,
  pattern,
  maxLength,
  inputName,
  onColorChange,
}: IProps) => {
  return (
    <div className="form-field">
      <div
        className={cn(
          `form-field__control form-field__control--color`,
          classes,
        )}
      >
        <input
          type="color"
          className="form-field__color__box"
          value={colorValue}
          onChange={(e) => onColorChange(e.target.value)}
        />
        <input
          className="form-field__color"
          id={inputId}
          value={colorValue}
          pattern={pattern}
          maxLength={maxLength}
          onChange={(e) => onColorChange(e.target.value)}
          name={inputName}
        />
      </div>
    </div>
  );
};

export default ColorInput;
