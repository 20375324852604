import { type ReactNode, createContext, useState } from "react";

export const islandInitialValues = {
  isIslandOpen: false,
  openIsland: () => {},
  closeIsland: () => {},
};

// Create a context to manage the state of the Island
export const IslandContext = createContext(islandInitialValues);

export type IslandProviderProps = {
  children: ReactNode;
};
export const IslandProvider = ({ children }: IslandProviderProps) => {
  const [isIslandOpen, setIsIslandOpen] = useState(false);
  // Function to open the Island
  const openIsland = () => {
    setIsIslandOpen(true);
  };

  // Function to close the Island
  const closeIsland = () => {
    setIsIslandOpen(false);
  };

  return (
    <IslandContext.Provider value={{ isIslandOpen, openIsland, closeIsland }}>
      {children}
    </IslandContext.Provider>
  );
};
