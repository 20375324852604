import { type UseMutationResult, useMutation } from "@tanstack/react-query";
import axios, { type AxiosResponse } from "axios";
import qs from "qs";
import ReactOnRails from "react-on-rails";

import { queryClient } from "config/reactQueryClient";
import { endpoints } from "constants/apiEndpoints";
import { generalErrors } from "constants/errorMessages";
import { snack } from "design_system/Snackbar";
import { SentryLoggingService } from "init/SentryLoggingService";

export const useDeleteExpenseCategories = (): {
  deleteExpenseCategories: UseMutationResult<
    AxiosResponse<any>,
    unknown,
    any,
    unknown
  >;
} => {
  /* ---------------------------------
   *  MUTATION FN
   * ---------------------------------
   */
  const deleteExpenseCategories = useMutation<AxiosResponse<any>, unknown, any>(
    {
      mutationKey: ["deleteExpenseCategories"],
      mutationFn: async (payload: any) => {
        const queryParams = {
          authenticity_token: ReactOnRails.authenticityToken(),
        };
        const response = await axios.delete(
          `${endpoints.MANAGE.API.EXPENSES.DELETE_CATEGORIES}?${qs.stringify(queryParams)}`,
          {
            data: {
              identifiers: payload,
            },
          },
        );
        return response;
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ["useGetExpenseCategories"],
        });
      },
      onError: (error: any, variables) => {
        snack({
          title: generalErrors.SOMETHING_WENT_WRONG,
          leadingElement: "icon",
          variant: "critical",
        });
        SentryLoggingService.captureException(error.message, {
          feature: "[Expense Settings][deleteExpenseCategories]",
          function: "deleteExpenseCategories",
          file: "useDeleteExpenseCategories.tsx",
          data: JSON.stringify(variables),
        });
        return error;
      },
    },
  );
  return {
    deleteExpenseCategories,
  };
};
