import { Provider } from "react-redux";

import { ReactQueryProvider } from "components/Providers";
import { DashboardNavigationContainer } from "./DashboardNavigationContainer";

import { store } from "store";

export const DashboardNavigationRoot = () => {
  return (
    <ReactQueryProvider>
      <Provider store={store}>
        <DashboardNavigationContainer />
      </Provider>
    </ReactQueryProvider>
  );
};
