import { useQuery } from "@tanstack/react-query";

import { snack } from "design_system/Snackbar";

import { MERCHANT_QUERY_KEYS } from "repositories/merchant/keys";
import { merchantRepository } from "./merchant.repository";

export const useMerchantInfo = () => {
  return useQuery({
    queryKey: [MERCHANT_QUERY_KEYS.GET_INFO],
    queryFn: async () => {
      const response = await merchantRepository.getInfo();

      if (!response.success && "error" in response) {
        snack({
          title: response.error.message,
          leadingElement: "icon",
          variant: "critical",
        });

        merchantRepository.sentryLogError({
          error: response.error.message,
          feature: "useMerchantInfo",
          functionName: "merchantInfoQuery",
          data: JSON.stringify(response.error),
        });
      }

      return response.data;
    },
  });
};
