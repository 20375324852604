import * as Icons from "design_system/Icons/GlobalSearchIcons";
import { getIconName } from "features/shared/GlobalSearch/utils";
import { SearchRecord } from "./SearchRecord";

import { statusMappings } from "features/shared/GlobalSearch/constants/statusMappings";

export const SectionItems = ({ viewKind, defaultIconName, items }) => {
  return items.map((item, index) => {
    const iconName = getIconName({
      defaultIconName,
      iconName: item.iconName,
      viewKind,
    });

    const handleRecordClick = () => {
      window.location.href = item.url;
    };
    const mapping = statusMappings[iconName];

    return (
      <SearchRecord
        key={`${item.identifier}_${index}`}
        onClick={handleRecordClick}
      >
        {viewKind === "icon" && (
          <SearchRecord.Icon
            Icon={Icons[iconName]}
            iconProps={{
              size: "18",
              fill: "gray",
            }}
          />
        )}
        {viewKind === "status" && (
          <SearchRecord.Tag
            tagProps={{
              color: mapping?.color || "gray",
              Icon: mapping?.Icon || Icons.LinkIcon,
              iconOnly: true,
              size: "sm",
            }}
          />
        )}
        <SearchRecord.Column1
          label={item.label}
          supportingText={[
            item.supportingText?.length > 0 ? item.supportingText[0] : "",
            item.metaInfo,
          ]}
          onClick={handleRecordClick}
        />
      </SearchRecord>
    );
  });
};
