import { colorsV2 } from "constants/colors";
import { useScreenDimension } from "hooks/useScreenDimension";

const tinycolor = require("tinycolor2");

// The below function takes an array of any type. The goal of this function is to handle the padding between the bars in the bar graph based on the length of the data in the array
// BE should always return 12 data points which is what's handled for on the FE. If there's any changes in the BE then we'd have to update the logic for the UI accordingly
export const handleBarChartPadding = (data: Array<any>) => {
  const { isMobile } = useScreenDimension();

  const dataLength = data.length;
  const barCount = {
    ONE: 1,
    TWO: 2,
    THREE: 3,
    FOUR: 4,
    FIVE: 5,
    SIX: 6,
    SEVEN: 7,
    EIGHT: 8,
    NINE: 9,
    TEN: 10,
    ELEVEN: 11,
  };

  if (dataLength === barCount.ONE) {
    return 0.9;
  } else if (dataLength === barCount.TWO) {
    return 0.9;
  } else if (dataLength === barCount.THREE) {
    return 0.85;
  } else if (dataLength === barCount.FOUR) {
    return 0.8;
  } else if (dataLength === barCount.FIVE) {
    return 0.8;
  } else if (dataLength === barCount.SIX) {
    return 0.8;
  } else if (dataLength === barCount.SEVEN) {
    return 0.75;
  } else if (dataLength === barCount.EIGHT) {
    return 0.75;
  } else if (dataLength === barCount.NINE) {
    return 0.7;
  } else if (dataLength === barCount.TEN) {
    return 0.7;
  } else if (dataLength === barCount.ELEVEN) {
    return isMobile ? 0.4 : 0.65;
  } else {
    return isMobile ? 0.4 : 0.65;
  }
};

export const calculateBarColor = (hovered: boolean, horizontal: boolean) => {
  const baseColor = horizontal
    ? colorsV2.qualitative.aqua
    : colorsV2.qualitative.blue[1];

  // If hovered, add 25% white opacity
  return hovered
    ? tinycolor(baseColor).setAlpha(0.75).toRgbString()
    : baseColor;
};
