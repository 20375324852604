import { Button } from "design_system/Button";
import { Add } from "design_system/Icons";
import { MasterHeader } from "design_system/MasterHeader";

import { handleGoBackToSettings } from "features/Settings/shared/utils";
import { isAppPlatform } from "helpers/isAppPlatform";
import { teamRoutes } from "../shared/constant";

export const PageHeader = () => {
  const handleGoToInviteUser = () => {
    window.location.href = teamRoutes.userInfo;
  };

  if (isAppPlatform()) {
    return <></>;
  }

  return (
    <MasterHeader onBack={handleGoBackToSettings}>
      <MasterHeader.Label>Settings</MasterHeader.Label>
      <MasterHeader.Title>Team</MasterHeader.Title>
      <MasterHeader.Actions>
        <Button
          variant="filled"
          size="md"
          label="Invite user"
          iconPosition="left"
          Icon={Add}
          onClick={handleGoToInviteUser}
        />
      </MasterHeader.Actions>
    </MasterHeader>
  );
};
