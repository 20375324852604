import type { HTMLAttributes, ReactNode } from "react";
import { cn } from "utils/classNames";

export type FormItemProps = HTMLAttributes<HTMLDivElement> & {
  children: ReactNode;
};

export const FormItem = ({ children, className, ...props }: FormItemProps) => {
  return (
    <div className={cn("space-y-12", className)} {...props}>
      {children}
    </div>
  );
};
