import { ErrorBoundary } from "@sentry/react";
import { Analytics } from "components/Analytics";
import { PageHeader } from "./PageHeader";

export const OverviewContainer = () => {
  return (
    <>
      <PageHeader />

      <ErrorBoundary>
        <Analytics />
      </ErrorBoundary>
    </>
  );
};
