import { colorsV2 } from "constants/colors";
import type { SvgIconProps } from "types/Icon";

export const ChevronRight = ({
  size,
  fill = colorsV2.text[1],
  ...rest
}: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g id="Name=chevron_right, Size=24">
        <path
          id="Vector"
          d="M13.1065 11.975L8.75321 7.62175C8.5833 7.45182 8.5025 7.2529 8.51084 7.025C8.51917 6.7971 8.6083 6.59818 8.77821 6.42825C8.94815 6.25833 9.15123 6.17337 9.38746 6.17337C9.6237 6.17337 9.82678 6.25833 9.99671 6.42825L14.9407 11.3723C15.032 11.4636 15.0983 11.5589 15.1396 11.6581C15.1809 11.7574 15.2016 11.863 15.2016 11.975C15.2016 12.0869 15.1809 12.1926 15.1396 12.2918C15.0983 12.3911 15.032 12.4864 14.9407 12.5777L9.97171 17.5467C9.80178 17.7167 9.60286 17.7975 9.37496 17.7891C9.14706 17.7808 8.94815 17.6917 8.77821 17.5217C8.6083 17.3518 8.52334 17.1487 8.52334 16.9125C8.52334 16.6763 8.6083 16.4732 8.77821 16.3032L13.1065 11.975Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};
