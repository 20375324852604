import { memo } from "react";

export const ArticlesLoader = memo(() => {
  return (
    <div className="grid grid-cols-1 gap-16">
      <div className="h-24 w-104 animate-pulse rounded-8 bg-surface-2" />

      <div className="flex flex-col gap-16">
        {Array.from({ length: 3 }).map((_, index) => (
          // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
          <div
            key={index}
            className="h-48 w-full animate-pulse rounded-8 bg-surface-2"
          />
        ))}
      </div>

      <div className="mr-auto h-20 w-104 animate-pulse rounded-8 bg-surface-2" />
    </div>
  );
});

ArticlesLoader.displayName = "ArticlesLoader";

export const VideoLoader = memo(() => {
  return (
    <div className="grid grid-cols-1 gap-16">
      <div className="h-24 w-104 animate-pulse rounded-8 bg-surface-2" />

      <div className="flex flex-col gap-16">
        {Array.from({ length: 2 }).map((_, index) => (
          // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
          <div
            key={index}
            style={{ maxWidth: "320px" }}
            className="h-128 w-full animate-pulse rounded-8 bg-surface-2"
          />
        ))}
      </div>

      <div className="mr-auto h-20 w-104 animate-pulse rounded-8 bg-surface-2" />
    </div>
  );
});

VideoLoader.displayName = "VideoLoader";
