import { useContext, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";

import { Divider } from "components/Divider";
import { TableRecord } from "components/Table/TableRecord";
import Table from "components/Table/V2/TableV2";
import { Switch } from "design_system/Inputs/Switch";
import { TableEmptyState } from "../../../../../../Expense/Dashboard/Table/components/TableEmptyState";
import { PaymentAccountsIsland } from "./Island/PaymentAccountsIsland";

import { useScreenDimension } from "hooks/useScreenDimension";
import { useAppSelector } from "store";
import type {
  PaymentAccountOption,
  PaymentAccountsWithPagination,
} from "types/payments/PaymentAccount";
import { usePaymentAccountsTable } from "./usePaymentAccountsTable";

import { IslandContext } from "design_system/Island/IslandContext";

import { setSelectedRows } from "reducers/redux/TableReducer/tableReducer";
import { cn } from "utils/classNames";

type PaymentAccountsTableProps = {
  accounts?: PaymentAccountsWithPagination;
};

export const PaymentAccountsTable = ({
  accounts,
}: PaymentAccountsTableProps) => {
  /* ---------------------------------
   *  Context APIS & CACHES
   * ---------------------------------
   */

  const {
    table: {
      paymentAccounts: { selectedRows },
    },
  } = useAppSelector((state) => state);
  const { closeIsland, openIsland } = useContext(IslandContext);

  /* ---------------------------------
   *  Hooks
   * ---------------------------------
   */
  const dispatch = useDispatch();
  const { isMobile } = useScreenDimension();

  const {
    bulkUpdatePaymentAccounts,
    COLUMNS,
    handleSetPage,
    handleToggleClick,
  } = usePaymentAccountsTable();

  /* ---------------------------------
   *  Constants
   * ---------------------------------
   */
  const paginationMeta = {
    ...accounts.paginationMeta,
    itemName: "Payment accounts",
  };

  const columns = useMemo(() => COLUMNS, []);
  const tableData = useMemo(() => accounts.data, [accounts.data]);

  const isEmptyTable = !accounts || accounts.data?.length === 0;

  /* ---------------------------------
   *  Handlers
   * ---------------------------------
   */
  const handleRowSelections = (rows: PaymentAccountOption[]) => {
    dispatch(
      setSelectedRows({ tableName: "paymentAccounts", selectedRows: rows }),
    );
  };

  /* ---------------------------------
   *  Effects
   * ---------------------------------
   */

  // OPEN/CLOSE ISLAND ON ROW SELECTIONS
  useEffect(() => {
    if (selectedRows?.length > 0) {
      openIsland();
    } else closeIsland();
  }, [selectedRows]);

  if (!isMobile) {
    return (
      <div className="flex w-full justify-center">
        <Table
          name="payment-accounts-table"
          columns={columns}
          data={tableData}
          paginationMeta={paginationMeta}
          onSetPage={handleSetPage}
          tableClass="table"
          headerRowClass="bg-black-25"
          headerCellClass="w-auto"
          rowProps={{
            selectable: true,
            selectedRows,
            setSelectedRows: handleRowSelections,
          }}
          emptyState={<TableEmptyState />}
        />
        <PaymentAccountsIsland />
      </div>
    );
  }
  return isEmptyTable ? (
    <TableEmptyState />
  ) : (
    <>
      {accounts.data.map((account: PaymentAccountOption, index) => {
        const { currency, enabled, institutionName, identifier } = account;
        return (
          <>
            <TableRecord
              key={identifier}
              variant="noAvatar"
              className={cn({ "-mt-16": index === 0 })}
            >
              <TableRecord.Column1 label={institutionName} />
              <TableRecord.Column2
                label={
                  <div className="flex items-center justify-end">
                    {currency}

                    <Switch
                      id={identifier}
                      checked={account.enabled}
                      disabled={bulkUpdatePaymentAccounts.isLoading}
                      onClick={() =>
                        handleToggleClick({ identifier, enabled: !enabled })
                      }
                    />
                  </div>
                }
              />
            </TableRecord>
            <Divider />
          </>
        );
      })}
    </>
  );
};
