import { GoogleOAuthProvider } from "@react-oauth/google";
import { QueryClientProvider } from "@tanstack/react-query";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { Snackbar } from "design_system/Snackbar";
import { SignUpContainer } from "./SignUpContainer";

import { queryClient } from "config/reactQueryClient";
import { variables } from "config/variables";
import { store } from "store";

export const SignUpRoot = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <BrowserRouter>
          <GoogleOAuthProvider clientId={variables.googleSso.clientId}>
            <Snackbar />
            <SignUpContainer />
          </GoogleOAuthProvider>
        </BrowserRouter>
      </Provider>
    </QueryClientProvider>
  );
};
