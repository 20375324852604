interface IProps {
  height?: string;
  width?: string;
  fill?: any;
}

const Logo = ({ height, width, fill }: IProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 123 122"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M61.1074 0.18584C52.924 0.191752 44.8265 1.85312 37.3019 5.06999C29.7773 8.28685 22.9813 12.9927 17.3228 18.9044C11.6643 24.8161 7.2602 31.8115 4.37555 39.4695C1.4909 47.1276 0.185325 55.2901 0.537371 63.4658C1.2442 78.6908 7.63699 93.0966 18.4522 103.836C29.2673 114.575 43.7178 120.866 58.9474 121.466C59.6774 121.466 60.3974 121.466 61.1274 121.466C69.1609 121.569 77.1349 120.076 84.5867 117.073C92.0386 114.07 98.8199 109.617 104.537 103.973C110.255 98.3282 114.794 91.6046 117.893 84.1919C120.991 76.7792 122.586 68.825 122.586 60.7908C122.586 52.7567 120.991 44.8025 117.893 37.3898C114.794 29.9771 110.255 23.2535 104.537 17.6091C98.8199 11.9646 92.0386 7.51179 84.5867 4.50885C77.1349 1.50592 69.1609 0.0127046 61.1274 0.11584L61.1074 0.18584ZM107.807 63.2858C107.252 73.9714 103.044 84.1422 95.8879 92.0968C88.7315 100.051 79.0605 105.307 68.4929 106.985C57.9253 108.663 47.102 106.661 37.8338 101.314C28.5655 95.9668 21.4145 87.5993 17.577 77.6112C13.7395 67.623 13.4484 56.62 16.7522 46.4429C20.0561 36.2658 26.7545 27.5318 35.727 21.7022C44.6995 15.8726 55.4017 13.301 66.0433 14.4175C76.6848 15.534 86.6202 20.2709 94.1874 27.8358C98.7943 32.4755 102.385 38.0236 104.729 44.127C107.074 50.2303 108.123 56.7551 107.807 63.2858Z"
        fill={fill}
      />
      <path
        d="M61.1675 97.1158C70.5678 97.1005 79.5908 93.416 86.3149 86.8469C93.039 80.2778 96.9329 71.3432 97.1675 61.9458H25.1675C25.3995 71.344 29.2926 80.2801 36.0173 86.8497C42.742 93.4193 51.7664 97.103 61.1675 97.1158Z"
        fill={fill}
      />
    </svg>
  );
};

export default Logo;
