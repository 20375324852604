import DesktopBrandedImage from "assets/images/branding-desktop-screen.png";
import MobileBrandedImage from "assets/images/branding-mobile-screen.png";
import routes from "constants/routes";

const BrandingPaywall = () => {
  return (
    <section className="account-settings">
      <div className="account-settings__branding account-settings__branding--basic-plan">
        <div className="account-settings__branding__content">
          {/* HEADER */}
          <div className="account-settings__branding__header">
            <h1 className="account-settings__branding__header__heading">
              Brand your payment links like you mean it
            </h1>
            <p className="account-settings__branding__header__info">
              With our Premium plan, customize how your payment links appear to
              your customers.
            </p>
          </div>

          {/* FEATURES LIST */}
          <div>
            <ul className="account-settings__branding__list">
              <li className="account-settings__branding__list__list-item">
                Use your logo
              </li>
              <li className="account-settings__branding__list__list-item">
                Add your brand&rsquo;s colors
              </li>
              <li className="account-settings__branding__list__list-item">
                Personalize the experience for your customers
              </li>
            </ul>
          </div>

          {/* CTA */}
          <div className="account-settings__branding__cta">
            <a
              href={routes.MANAGE.PLANS.INDEX()}
              className="button button--primary button--solid button--icon-left button--md w-full md:w-auto"
            >
              <i className="material-icons-round text-yellow-500">star</i>
              Upgrade to unlock
            </a>
          </div>
        </div>

        {/* HERO IMAGES (MOBILE AND DESKTOP) */}
        <div className="account-settings__branding__image">
          <img
            src={MobileBrandedImage}
            alt="Branded payment forms"
            className="account-settings__branding__image--mobile"
          />
          <img
            src={DesktopBrandedImage}
            alt="Branded payment forms"
            className="account-settings__branding__image--tablet"
          />
        </div>
      </div>
    </section>
  );
};

export default BrandingPaywall;
