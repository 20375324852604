export const generalErrors = {
  // TODO: Move this inside an API object
  SOMETHING_WENT_WRONG: "Oops! Something went wrong. Try again later",
  FORMS: {
    VALIDATION_ERRORS: {
      ONLY_CHARS_ALLOWED: {
        GENERAL_MSG: "Special characters and numbers are not allowed",
        NAME_FIELD_MSG: "Name can't contain any numbers or special characters",
      },
    },
  },
};
