import { createSlice } from "@reduxjs/toolkit";

import { GENERIC_TABLE_FILTERS, filterByAllOption } from "constants/filters";
import { PAGINATION_META_INIT_STATE } from "constants/table";

const COMMON_TABLES_INIT_STATE = {
  /**
   * API RELATED STATES
   */
  data: [],
  isLoading: false,
  paginationMeta: PAGINATION_META_INIT_STATE,
  /**
   * FILTER RELATED STATES
   */
  areFiltersApplied: false,
  filters: GENERIC_TABLE_FILTERS,
  isSearchApplied: false,
  page: 1,
  searchQuery: "",
  selectedRows: [],
  // OTHERS
  recordDetail: null,
};

export const tableSlice = createSlice({
  name: "table",
  initialState: {
    // Accounting feature
    expenseAccounts: COMMON_TABLES_INIT_STATE,
    paymentAccounts: COMMON_TABLES_INIT_STATE,
    taxRates: COMMON_TABLES_INIT_STATE,
    vendors: COMMON_TABLES_INIT_STATE,
    // Cards feature
    cards: COMMON_TABLES_INIT_STATE,
    transactions: COMMON_TABLES_INIT_STATE,
    // Expense feature
    expenses: COMMON_TABLES_INIT_STATE,
    // Finance_Accounts feature
    repayments: COMMON_TABLES_INIT_STATE,
    creditActivities: COMMON_TABLES_INIT_STATE,
    // All Payments Links table
    allPaymentLinks: COMMON_TABLES_INIT_STATE,
    // Invoices feature
    invoices: COMMON_TABLES_INIT_STATE,
    // Payments feature
    disputes: COMMON_TABLES_INIT_STATE,
    payments: COMMON_TABLES_INIT_STATE,
    // Payout feature
    payouts: COMMON_TABLES_INIT_STATE,
    // Settings feature
    team: COMMON_TABLES_INIT_STATE,
    expenseCategories: COMMON_TABLES_INIT_STATE,
    // Wallets feature
    wallets: COMMON_TABLES_INIT_STATE,
  },
  reducers: {
    // Reducers for updating the state
    setSearchQuery: (state, action) => {
      const { tableName, searchQuery } = action.payload;
      state[tableName].searchQuery = searchQuery;
    },
    setFilters: (state, action) => {
      const { tableName, filters } = action.payload;
      state[tableName].areFiltersApplied = filtersAppliedChecker(filters);
      state[tableName].filters = filters;
    },
    clearSearchAndFilters: (state, action) => {
      state[action.payload].areFiltersApplied = false;
      state[action.payload].filters = GENERIC_TABLE_FILTERS;
    },
    setSelectedRows: (state, action) => {
      const { tableName, selectedRows } = action.payload;
      state[tableName].selectedRows = selectedRows;
    },
    clearTableData: (state, action) => {
      const { tableName } = action.payload;
      state[tableName].data = [];
    },
    clearSelectedRows: (state, action) => {
      state[action.payload].areFiltersApplied = false;
      state[action.payload].selectedRows = [];
    },
    concatinateTableData: (state, action) => {
      const { tableName, value } = action.payload;
      if (value?.length > 0) {
        // Add the new array to previous array
        state[tableName].data = [...state[tableName].data, ...value];
      }
    },
    setRecordDetail: (state, action) => {
      const { tableName, recordDetail } = action.payload;
      state[tableName].recordDetail = recordDetail;
    },
    clearSelectedRecord: (state, action) => {
      state[action.payload].recordDetail = null;
    },
    // Set to trigger new fetch from api
    setPagination: (state, action) => {
      const { tableName, page } = action.payload;
      state[tableName].page = page;
    },
    /**
     * API RELATED STATES
     */
    setLoadingData: (state, action) => {
      const { tableName, isLoading } = action.payload;
      state[tableName].isLoading = isLoading;
    },
    // Set from api's last response
    setPaginationMeta: (state, action) => {
      const { tableName, paginationMeta } = action.payload;
      state[tableName].paginationMeta = paginationMeta;
    },
  },
});

export const {
  clearSearchAndFilters,
  clearSelectedRows,
  clearTableData,
  concatinateTableData,
  setFilters,
  setPagination,
  setPaginationMeta,
  setRecordDetail,
  setSearchQuery,
  setSelectedRows,
  setLoadingData,
} = tableSlice.actions;

export default tableSlice.reducer;

function filtersAppliedChecker(filters) {
  const hasDateFilter = filters?.start_date || filters?.end_date;

  const hasReceiptAttachedFilter =
    filters?.receipt_attached &&
    filters.receipt_attached !== filterByAllOption.value;

  const hasExpenseCategoryFilter =
    filters?.expense_category_identifiers?.length > 0 &&
    filters.expense_category_identifiers[0].value !== filterByAllOption.value;

  const hasStatusFilter =
    filters?.statuses?.length > 0 &&
    filters.statuses[0].value !== filterByAllOption.value;

  const hasUserIdentifierFilter =
    filters?.user_identifiers?.length > 0 &&
    filters.user_identifiers[0].value !== filterByAllOption.value;

  return !!(
    hasDateFilter ||
    hasReceiptAttachedFilter ||
    hasExpenseCategoryFilter ||
    hasStatusFilter ||
    hasUserIdentifierFilter
  );
}
