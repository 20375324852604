import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

import type {
  ShowHideAddCategoryModalAction,
  ShowHideDeleteCategoryModalAction,
} from "../typeDefs";
import {
  expenseSettingsInitState,
  expenseSettingsInitState_tableCell,
  expenseSettingsInitState_tableCheckbox,
} from "./constants";

export const expenseSettingsSlice = createSlice({
  name: "expenseSettings",
  initialState: expenseSettingsInitState,
  reducers: {
    // TABLE SPECIFIC REDUCERS
    clearSelectedRows: (state) => {
      state.dashboard = {
        ...state.dashboard,
        ...expenseSettingsInitState_tableCheckbox,
      };
    },
    setSelectedRows: (
      state,
      action: PayloadAction<{
        rows: any[];
        areAllSelected: boolean;
      }>,
    ) => {
      const count = action.payload.rows.length;
      const rows = action.payload.rows || [];

      state.dashboard.areAllSelected = action.payload.areAllSelected;
      state.dashboard.isSingleRowSelected = count === 1;
      state.dashboard.selectedCount = count;
      state.dashboard.selectedRows = rows;
      state.dashboard.selectedRowsIdentifiers = rows.map(
        ({ identifier }) => identifier,
      );
      state.dashboard.selectionSource = "Checkbox";
    },
    setCategoryDetail: (state, action) => {
      state.dashboard.categoryDetail = action.payload;
      state.dashboard.selectionSource = "Actions";
    },
    showHideDeleteCategoryModal: (
      state,
      action: ShowHideDeleteCategoryModalAction,
    ) => {
      const { showOrHideModal, shouldClearState } = action.payload;
      const { dashboard } = state;

      // CHECK SHOW HIDE MODAL
      if (showOrHideModal === "show") {
        dashboard.showDeleteConfirmationModal = true;
      } else {
        dashboard.showDeleteConfirmationModal = false;

        // CHECK FOR CLEAR STATE OR NOT
        if (shouldClearState) {
          // CHECK FOR SOURCE THAT TRIGGERED THE CALL
          if (dashboard.selectionSource === "Actions") {
            state.dashboard = {
              ...dashboard,
              ...expenseSettingsInitState_tableCell,
            };
          } else {
            state.dashboard = {
              ...dashboard,
              ...expenseSettingsInitState_tableCheckbox,
            };
          }
        }
      }
    },
    showHideAddCategoryModal: (
      state,
      action: ShowHideAddCategoryModalAction,
    ) => {
      const { showOrHideModal, shouldClearState } = action.payload;
      const { dashboard } = state;

      state.dashboard.showAddCategoryModal = showOrHideModal === "show";
    },
  },
});

export const {
  // TABLE SPECIFIC STATES
  setSelectedRows,
  clearSelectedRows,
  setCategoryDetail,
  // OTHERS
  showHideDeleteCategoryModal,
  showHideAddCategoryModal,
} = expenseSettingsSlice.actions;

export default expenseSettingsSlice.reducer;
