import { ContentCopy } from "design_system/Icons";
import { cn } from "utils/classNames";

interface BankTransferDetailsItemProps {
  label: string;
  value: string;
  classNames?: {
    container?: string;
  };
  onCopy: (value: string) => void;
}

export const BankTransferDetailsItem = ({
  label,
  value,
  onCopy,
  classNames,
}: BankTransferDetailsItemProps) => {
  return (
    <div className={cn("flex flex-col py-24", classNames?.container)}>
      <p className="font-bold text-16">{label}</p>
      <div className="flex items-start justify-between">
        <p className="text-16 text-black-300">{value}</p>
        <ContentCopy
          size="18"
          className="mt-2 ml-8 flex-shrink-0 cursor-pointer"
          onClick={() => onCopy(value)}
        />
      </div>
    </div>
  );
};
