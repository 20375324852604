import {
  AccountBalance,
  AutoRenew,
  BarChart,
  Build,
  CreditCard,
  Group,
  History,
  ImportContacts,
  Invoice,
  Link,
  Payments,
  ReceiptLong,
  SendMoney,
  Settings,
  Store,
  Toll,
  Wallet,
} from "design_system/Icons";

export const iconMapping = {
  AccountBalance,
  AutoRenew,
  BarChart,
  CreditCard,
  Group,
  History,
  ImportContacts,
  Invoice,
  Build,
  Link,
  Payments,
  ReceiptLong,
  SendMoney,
  Settings,
  Store,
  Toll,
  Wallet,
};
