import { compact } from "lodash";

export const statusFilters: any[] = [
  {
    id: "captured",
    value: "captured",
    label: "Successful",
    selected: true,
  },
  {
    id: "refunded",
    value: "refunded",
    label: "Refunded",
    selected: true,
  },
  {
    id: "partial-refund",
    value: "partial_refund",
    label: "Partial refund",
    selected: true,
  },
  {
    id: "refund-pending",
    value: "refund_pending",
    label: "Refund pending",
    selected: true,
  },
  {
    id: "failed",
    value: "failed",
    label: "Failed",
    selected: false,
  },
];

export const statusFiltersV2: any[] = [
  {
    id: "all",
    value: "all",
    label: "All",
    selected: true,
  },
  ...statusFilters,
];

export const DEFAULT_SUCCESS_STATUS_FILTERS = compact(
  statusFilters.map((filter) => (filter.selected ? filter.value : null)),
);
