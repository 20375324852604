import partyIllustration from "assets/illustrations/party-illustration.svg";
import Button from "components/Button/Button";
import TextWithIcon from "components/Text/TextWithIcon";
import routes from "constants/routes";

interface IResultQrCode {
  serial: number;
  totalQuantity: number;
  isUsed: boolean;
  customerFullName: string;
  purpose: string;
  chargeIdentifier: string;
}

const ResultScanQrCode = ({
  serial,
  totalQuantity,
  isUsed,
  customerFullName,
  purpose,
  chargeIdentifier,
}: IResultQrCode) => {
  const handleViewPaymentDetails = () => {
    window.location.href = routes.MANAGE.PAYMENTS.SHOW(chargeIdentifier);
  };

  return (
    <section className="min-h-screen space-y-16 bg-blue-25 p-20 md:flex md:flex-col md:items-center md:justify-center">
      <div className="flex flex-col items-center justify-center space-y-20 rounded-16 bg-white p-32 text-16">
        {isUsed && (
          <>
            <div className="material-icons-round rounded-full bg-red-50 p-16 text-24 text-red-500 ">
              info
            </div>
            <h1 className="heading-03">QR code already used</h1>
          </>
        )}
        {!isUsed && (
          <>
            <img src={partyIllustration} alt="" />
            <h1 className="heading-03">Validation successful</h1>
          </>
        )}

        <p className="font-medium text-black-300">{purpose}</p>

        {/* CUSTOMER NAME */}
        <div className="flex w-full justify-between text-black-300">
          <p className="font-medium">Name</p>
          <p>{customerFullName}</p>
        </div>

        {/* TICKET NUMBER */}
        <div className="flex w-full justify-between text-black-300">
          <p className="font-medium">Ticket number</p>
          <p>
            {serial}/{totalQuantity}
          </p>
        </div>

        <Button
          color="primary"
          size="lg"
          label="View details"
          classes="w-full"
          onClick={handleViewPaymentDetails}
        />
      </div>

      <TextWithIcon
        color="black-200"
        containerClasses="absolute bottom-24 right-0 left-0"
        iconFont="favorite"
        iconSize="14"
        textSize="14"
        textContent="Powered by Mamo"
      />
    </section>
  );
};

export default ResultScanQrCode;
