import { Divider } from "components/Divider";
import { FirstTimerEmptyState } from "../components/FirstTimerEmptyState";
import { NoResultsEmptyState } from "../components/NoResultsEmptyState";
import { SectionLabel } from "../components/SectionLabel";

import { SentryLoggingService } from "init/SentryLoggingService";
import { FIRST_TIMER_EMPTY_STATE } from "../constants/magicStrings";

export const useRenderGroup = ({ lastGroupLabel }) => {
  const renderGroup = (params) => {
    try {
      if (params.group === FIRST_TIMER_EMPTY_STATE) {
        return <FirstTimerEmptyState key="First-timer-empty-state" />;
      }
      if (params.group?.hasNoResults) {
        return <NoResultsEmptyState key="no-results-found" />;
      }
      return (
        <li key={params.key}>
          <GroupedSearchResult
            group={params.group}
            children={params.children}
            lastGroupLabel={lastGroupLabel}
          />
        </li>
      );
    } catch (error: any) {
      SentryLoggingService.captureException(error.message, {
        feature: "[Global Search]",
        function: "renderGroup",
        file: "useRenderGroup.tsx",
        data: JSON.stringify({ params }),
      });
      return <li>Error</li>;
    }
  };

  const getGroupLabel = (option) => {
    try {
      return option?.sectionName ? option.sectionName : option;
    } catch (error: any) {
      SentryLoggingService.captureException(error.message, {
        feature: "[Global Search]",
        function: "getGroupLabel",
        file: "useRenderGroup.tsx",
        data: JSON.stringify({ option }),
      });
      return "";
    }
  };

  return {
    renderGroup,
    getGroupLabel,
  };
};

function GroupedSearchResult({ group, children, lastGroupLabel }) {
  try {
    return (
      <section key={group} data-testid={`${group}-group-wrapper`}>
        <SectionLabel label={group} />
        <section data-testid={`${group}-group-wrapper_list-wrapper`}>
          {children}
        </section>
        {group !== lastGroupLabel && <Divider classNames="my-8" />}
      </section>
    );
  } catch (error: any) {
    SentryLoggingService.captureException(error.message, {
      feature: "[Global Search]",
      function: "GroupedSearchResult",
      file: "useRenderGroup.tsx",
      data: JSON.stringify({ group, children }),
    });
    return <section>Error</section>;
  }
}
