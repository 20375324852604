import { ProgressBar } from "components/ProgressBar";

import { colorsV2 } from "constants/colors";

export const CreditConsumedProgressBar = ({ creditCard }) => {
  const {
    creditLimitFormatted,
    progress,
    creditConsumedFormatted,
    balanceFormatted,
  } = creditCard;

  return (
    <section
      data-testid="progress-section"
      className="flex flex-col gap-24 px-16 py-24 md:px-40 md:py-40"
    >
      <p className="body-medium md:body-large text-text-color-01">
        You're at <span className="font-medium">{progress}%</span> of your{" "}
        {creditLimitFormatted} credit limit
      </p>
      <div className="flex flex-col space-y-12">
        <ProgressBar
          progress={progress}
          height="12px"
          backgroundColor={colorsV2.accent.blue[3]}
          progressColor={colorsV2.qualitative.blue[1]}
        />

        <div className="body-small md:body-medium flex justify-between text-text-color-02">
          <p>Consumed credit: {creditConsumedFormatted}</p>
          <p>Available credit: {balanceFormatted}</p>
        </div>
      </div>
    </section>
  );
};
