import { useContext } from "react";
import { useDispatch } from "react-redux";

import { Button } from "design_system/Button";
import { Modal } from "design_system/Modal";

import { useScreenDimension } from "hooks/useScreenDimension";
import { useAppSelector } from "store";
import { useDeleteExpenseCategories } from "../../hooks/useDeleteExpenseCategories";

import { IslandContext } from "design_system/Island/IslandContext";

import { snack } from "design_system/Snackbar";
import { showHideDeleteCategoryModal } from "../../../reducer/expenseSettingsSlice";

export const DeleteConfirmationModal = ({ show }) => {
  /* ---------------------------------
   *  Context APIS & CACHES
   * ---------------------------------
   */
  const { closeIsland } = useContext(IslandContext);

  const { isMobile } = useScreenDimension();
  const dispatch = useDispatch();

  const {
    dashboard: {
      categoryDetail,
      selectedCount,
      selectedRows,
      selectedRowsIdentifiers,
      selectionSource,
    },
  } = useAppSelector((state) => state.expenseSettings);

  const { deleteExpenseCategories } = useDeleteExpenseCategories();

  const recordCount = selectionSource === "Actions" ? 1 : selectedCount;

  const recordName =
    selectionSource === "Actions" ? categoryDetail.name : selectedRows[0]?.name;

  const handleCloseModal = () => {
    dispatch(
      showHideDeleteCategoryModal({
        showOrHideModal: "hide",
        shouldClearState: selectionSource !== "Checkbox", // We do not want to clear states if user tried to delete from island component
      }),
    );
  };

  const onRemoveConfirmation = async () => {
    if (selectionSource === "Checkbox") {
      await deleteExpenseCategories.mutateAsync(selectedRowsIdentifiers);
    } else {
      await deleteExpenseCategories.mutateAsync([categoryDetail.identifier]);
    }

    snack({
      title: recordCount === 1 ? "Category deleted" : "Categories deleted",
      leadingElement: "icon",
      variant: "success",
    });

    closeIsland();
    dispatch(
      showHideDeleteCategoryModal({
        showOrHideModal: "hide",
        shouldClearState: true,
      }),
    );
  };

  return (
    <>
      <Modal
        show={show}
        modalWidth="48rem"
        hasCloseButton={isMobile}
        onClose={handleCloseModal}
      >
        <div className={"space-y-32 md:space-y-40"}>
          <section className="text-center md:space-y-16">
            <h1 className="header-medium">
              {recordCount === 1
                ? "Are you sure you want to delete this category?"
                : `Are you sure you want to delete ${recordCount} categories?`}
            </h1>
            <p className="body-medium w-11/12">
              {recordCount === 1
                ? `${recordName} will be deleted. You will no longer be able to link expenses to this categories. However, any existing expenses with this category assigned will remain as is.`
                : `${recordCount} categories will be deleted. You will no longer be able to link expenses to these categories. However, any existing expenses with these categories assigned to them will remain as is.`}
            </p>
          </section>

          <section className="flex items-center gap-16">
            <Button
              label="Cancel"
              variant="outlined"
              size="lg"
              classes="w-full"
              onClick={handleCloseModal}
            />
            <Button
              label="Yes, I'm sure"
              variant="critical"
              size="lg"
              classes="w-full"
              onClick={onRemoveConfirmation}
            />
          </section>
        </div>
      </Modal>
    </>
  );
};
