import MenuItem from "@mui/material/MenuItem";
import isEmpty from "lodash/isEmpty";
import { useEffect } from "react";
import { useController } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Button } from "design_system/Button";
import { Dropdown } from "design_system/Dropdown/DropdownV2";
import { TextInput } from "design_system/Inputs/TextInput";

import { useAppSelector } from "store";
import { useRedirectToSignUp } from "../shared/useRedirectToSignUp";
import { useGetIndustries } from "./hooks/useGetIndustries";
import { useTellUsMoreBusinessForm } from "./hooks/useTellUsMoreBusinessForm";

import routes from "constants/routes";
import {
  updateActiveStep,
  updateData,
  updateStepAnimationDirection,
} from "reducers/redux/Access/SignUp/signUpSlice";
import type { TellUsMoreBusinessFormProps } from "../shared/typeDefs";

export const TellUsMoreBusiness = () => {
  const { activeSignUpStep, data } = useAppSelector((state) => state.signUp);
  const { email, industryId } = data;
  const { getIndustries } = useGetIndustries();
  const { control, errors, getValues, trigger, watch } =
    useTellUsMoreBusinessForm();
  const { redirectToSignUp } = useRedirectToSignUp();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleFormFieldBlur = (
    fieldName: keyof TellUsMoreBusinessFormProps,
  ) => {
    return () => {
      trigger(fieldName).then(() => {
        const isValid = !errors[fieldName];
        if (isValid) {
          dispatch(
            updateData({
              data: {
                ...getValues(),
              },
            }),
          );
        }
      });
    };
  };

  const handleIndustryChange = (e) => {
    const industryId = e.target.value;

    dispatch(
      updateData({
        data: {
          industryId,
        },
      }),
    );
  };

  const handleGoToNextStep = () => {
    navigate(routes.ONBOARDING.PLAN_TO_USE());
    dispatch(updateActiveStep({ activeSignUpStep: activeSignUpStep + 1 }));
    dispatch(updateStepAnimationDirection({ stepAnimationDirection: "right" }));
  };

  const isContinueButtonDisabled =
    !!errors.businessName || isEmpty(watch().businessName) || !industryId;

  useEffect(() => {
    redirectToSignUp(email);
  }, []);

  return (
    <div className="flex flex-col gap-32">
      <div className="space-y-8 text-center">
        <h1 className="header-large md:display-medium">
          Tell us a bit more about your business
        </h1>
        <p className="body-medium md:body-large">
          Note that some industries are not currently supported by Mamo. <br />
          Have a look at the{" "}
          <a
            href="https://help.mamopay.com/en/articles/7894650-prohibited-and-restricted-businesses"
            target="_blank"
            className="text-primary-1"
            rel="noreferrer"
          >
            list of restricted businesses
          </a>
          .
        </p>
      </div>

      <div className="flex flex-col gap-24">
        <TextInput
          {...useController({ name: "businessName", control }).field}
          label="Business name"
          className="w-full"
          error={!!errors.businessName}
          helperText={errors.businessName?.message}
          onBlur={handleFormFieldBlur("businessName")}
        />

        <Dropdown
          type="select"
          selectProps={{
            name: "industry",
            defaultValue: industryId,
            onChange: handleIndustryChange,
          }}
          label="Industry"
        >
          {getIndustries?.data?.map(({ id, name }) => (
            <MenuItem key={`${id}-${name}`} value={id}>
              {name}
            </MenuItem>
          ))}
        </Dropdown>
      </div>

      <Button
        classes="w-full"
        disabled={isContinueButtonDisabled}
        label="Continue"
        size="lg"
        variant="filled"
        onClick={handleGoToNextStep}
      />
    </div>
  );
};
