import { isEmpty } from "lodash";
import { useContext, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import FieldMessage from "components/FieldMessage/FieldMessage";
import LoaderBusiness from "components/Loader/LoaderBusiness";
import { Checkbox } from "design_system/Inputs/Checkbox";

import { useGetRoles } from "../../shared/hooks/useGetRoles";
import { useInviteUser } from "../../shared/hooks/useInviteUser";
import { useUpdateRoles } from "../../shared/hooks/useUpdateRoles";

import { UserContext } from "../../shared/context/UserProvider";

import { snack } from "design_system/Snackbar";
import { isAppPlatform } from "helpers/isAppPlatform";
import { teamRoutes } from "../../shared/constant";

type TUserRoles = {
  setCurrentStep?: any;
  type: "invite" | "edit";
};

const UserRoles = ({ setCurrentStep, type }: TUserRoles) => {
  const [checkedRoles, setCheckedRoles] = useState<string[]>([]);

  const { userInfo } = useContext(UserContext);
  const { handleSubmit, register, formState } = useFormContext();
  const { errors } = formState;
  const { roles, loading: rolesLoading, getAllRoles } = useGetRoles();
  const { updateRoles } = useUpdateRoles();
  const navigate = useNavigate();

  const handleGoBack = () => {
    setCurrentStep(1);
  };

  const onSubmit = async () => {
    try {
      const payload = {
        ...userInfo,
        roles: checkedRoles,
      };

      if (type === "invite") {
        const response = await useInviteUser(payload);

        if (response.status === 201) {
          snack({
            title: `Invitation sent to ${userInfo.email}`,
            leadingElement: "icon",
            variant: "success",
          });

          // go to teams overview page
          navigate(teamRoutes.overview);
        } else {
          snack({
            title: response.data.messages,
            leadingElement: "icon",
            variant: "critical",
          });
        }
      } else {
        await updateRoles(userInfo.identifier, {
          role_ids: checkedRoles,
        });

        snack({
          title: `Permissions updated for ${userInfo.fullName}`,
          leadingElement: "icon",
          variant: "success",
        });

        // go to teams overview page
        navigate(teamRoutes.overview);
      }
    } catch (error) {
      console.error(`[UserRoles][InviteUser] Trouble creating user: ${error}`);
    }
  };

  useEffect(() => {
    getAllRoles();

    // we're doing this if the user refreshes the page
    // if that happens then we basically lose the data that we get from our table
    // so if it's empty we should redirect back to teamOverview
    // P.S. we're targeting firstName here because going from first step to the next we should be passing the first name
    // so if it's empty which means the user has refreshed the page
    if (isEmpty(userInfo.firstName)) {
      navigate(teamRoutes.overview, { replace: true });
    }
  }, []);

  useEffect(() => {
    if (type === "edit") {
      if (userInfo.roles.length > 0) {
        const checkedRoleIds = userInfo.roles.map((role) => String(role.id));
        setCheckedRoles(checkedRoleIds);
      } else {
        setCheckedRoles([]);
      }
    }
  }, [userInfo.roles]);

  if (rolesLoading) {
    return <LoaderBusiness />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <header>
        {type === "invite" && (
          <button
            type="button"
            onClick={handleGoBack}
            className="create-link__back-button material-icons-round"
          >
            arrow_back
          </button>
        )}
        {!isAppPlatform() && (
          <button
            type="button"
            onClick={() => navigate(teamRoutes.overview)}
            className="create-link__close-button material-icons-round"
          >
            close
          </button>
        )}
      </header>
      <section className="create-link__customize-link-step">
        {/* PROCESS COMPONENT */}
        <div className="process space-y-40 md:space-y-48">
          <div className="process__content">
            <h1 className="process__content__heading">User role settings</h1>
            <p className="process__content__info">
              Define one or more roles for your user. Each role defines areas
              and features that a user can access.
            </p>
          </div>

          <div>
            {roles.map((role) => (
              <div className="p-16" key={role.id}>
                <Checkbox
                  key={role.id}
                  inputId={`${role.id}`}
                  name="roles"
                  value={role.id}
                  labelText={role.name}
                  labelClasses="text-16 font-medium"
                  description={role.description}
                  register={register}
                  checked={checkedRoles.includes(String(role.id))}
                  onChange={(e: any) => {
                    const { checked, value } = e.target;
                    if (checked) {
                      setCheckedRoles((prevRoles) => [...prevRoles, value]);
                    } else {
                      setCheckedRoles((prevRoles) =>
                        prevRoles.filter((role) => role !== value),
                      );
                    }
                  }}
                />
              </div>
            ))}
            {errors.roles && (
              <FieldMessage
                messageType="error"
                icon="error"
                messageText={errors.roles?.message}
                classes="mt-12"
              />
            )}
          </div>

          <div className="text-center">
            <button
              type="submit"
              className="button button--primary button--solid button--lg w-full md:w-auto"
            >
              {type === "invite" ? "Invite user" : "Save changes"}
            </button>
          </div>
        </div>
      </section>
    </form>
  );
};

export default UserRoles;
